import { Button, Input, Tooltip } from '@nextui-org/react';
import { Paperclip, UploadSimple, TrashSimple, Trash } from '@phosphor-icons/react';
import React, { useRef, useState } from 'react';
import toast from 'react-hot-toast';

const ALLOW_FILE_EXTENSION = '.hwp,.doc,.docx,.xls,.xlsx,.pdf,.jpg,.jpeg,.png';
const FILE_SIZE_MAX_LIMIT = 10 * 1024 * 1024; // 10MB

const CustomFileInput = ({ onChange = () => {}, accept, fileSize, size, initFileName = '', name = '' }) => {
  const [fileName, setFileName] = useState(initFileName);
  const [file, setFile] = useState(null); // State to track the uploaded file
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = e => {
    const file = e.target.files[0];
    if (file) {
      const fileExtension = '.' + file.name.split('.').pop().toLowerCase();
      const allowedExtensions = (accept || ALLOW_FILE_EXTENSION).split(',');

      if (!allowedExtensions.includes(fileExtension)) {
        toast.error(`허용되지 않은 파일 확장자입니다.\n(${ALLOW_FILE_EXTENSION}파일만 업로드 가능)`);
        return;
      }

      if (file.size > (fileSize || FILE_SIZE_MAX_LIMIT)) {
        toast.error('파일 사이즈가 너무 큽니다. 10MB 이하의 파일만 업로드 가능합니다.');
        return;
      }

      setFileName(file.name);
      setFile(file);
      onChange(e);
    }
  };

  const handleDeleteFile = () => {
    fileInputRef.current.value = null;
    setFileName('');
    setFile(null);
    onChange(null);
  };

  return (
    <Tooltip showArrow={true} content={`${(fileSize || FILE_SIZE_MAX_LIMIT) / 1024 / 1024}mb이하 ${(accept || ALLOW_FILE_EXTENSION).split(',').join(', ')}`}>
      <div className="flex flex-1 gap-2 shrink-0">
        <input name={name} accept={accept || ALLOW_FILE_EXTENSION} type="file" className="hidden" ref={fileInputRef} onChange={handleFileChange} aria-label="File input" />
        <Input size={size || 'md'} placeholder={`파일을 업로드해주세요`} value={fileName} readOnly onClick={handleButtonClick} aria-label="File name" fullWidth />
        {file || fileName ? (
          <Button color="danger" variant="flat" size={size || 'md'} onClick={handleDeleteFile} isIconOnly aria-label="Delete file">
            <Trash className="text-lg"></Trash>
          </Button>
        ) : (
          <Button color="primary" variant="flat" size={size || 'md'} onClick={handleButtonClick} isIconOnly aria-label="Upload file">
            <UploadSimple className="text-lg"></UploadSimple>
          </Button>
        )}
      </div>
    </Tooltip>
  );
};

export default CustomFileInput;
