import { Api } from '@/api/index';
import LoadingBackground from '@/components/LoadingBackground';
import PaginationTable from '@/components/PaginationTable';
import useCustomSearchParams from '@/hooks/useCustomSearchParams';
import useDebounce from '@/hooks/useDebounce';
import { formatUtil } from '@/utils/FormatUtil';
import { Chip, Input, Select, SelectItem, Spinner, getKeyValue, useDisclosure } from '@nextui-org/react';
import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import AdminAdd from './ModalAdd';
import AdminView from './ModalView';
import ContentWrapper from '@/components/ContentWrapper';
import ModalUpdate from './ModalUpdate';
import { GroupSelect } from '@/components/common/filter';

const columns = [
  { name: 'No', uid: 'no' },
  { name: '아이디', uid: 'loginId' },
  { name: '구분', uid: 'adminRoleId' },
  { name: '회사명', uid: 'pndGroupId' },
  { name: '관리자명', uid: 'adminName' },
  { name: '사용여부', uid: 'useYn' },
  { name: '등록일자', uid: 'regDt' },
];
const search = [
  { value: 'loginId', label: '아이디' },
  { value: 'adminName', label: '이름' },
];
const List = () => {
  const { isOpen: isOpenView, onOpen: onOpenView, onOpenChange: onOpenChangeView, onClose: onCloseView } = useDisclosure();
  const { isOpen: isOpenAdd, onOpen: onOpenAdd, onOpenChange: onOpenChangeAdd, onClose: onCloseAdd } = useDisclosure();
  const { isOpen: isOpenUpdate, onOpen: onOpenUpdate, onOpenChange: onOpenChangeUpdate, onClose: onCloseUpdate } = useDisclosure();

  const selectedId = useRef('');
  const queryClient = useQueryClient();

  const [searchParams, setSearchParams] = useSearchParams();

  const initialParams = {
    pndGroupId: searchParams.get('pndGroupId') || '',
    adminRoleId: searchParams.get('adminRoleId') || '',
    searchType: searchParams.get('searchType') || 'loginId',
    searchText: searchParams.get('searchText') || '',
    useYn: searchParams.get('useYn') || '',
  };
  const { params, setParams, queryKey, onRowsPerPageChange, onPageChange, onInputChange } = useCustomSearchParams(initialParams);

  const debounce = useDebounce(params.searchText, 500);

  const [textLoading, setTextLoading] = useState(false);

  useEffect(() => {
    if (debounce != params.searchText) {
      setTextLoading(true);
    } else {
      setTextLoading(false);
    }
  }, [debounce, params.searchText]);

  const fetchAdminList = () => {
    const postParams = { pageNo: params.pageNo, pageSize: params.pageSize };
    postParams.useYn = params.useYn;
    postParams.pndGroupId = params.pndGroupId;
    postParams.adminRoleId = params.adminRoleId;
    if (params.searchText) {
      postParams[params.searchType] = params.searchText;
    }
    const trimParams = Object.fromEntries(Object.entries(postParams).filter(([, value]) => value));
    return Api.adminList(trimParams);
  };

  const reloadList = () => {
    selectedId.current = null;
    queryClient.invalidateQueries(['adminList', { ...queryKey, searchText: debounce }]);
  };

  const groupQuery = useQuery({
    queryKey: ['groups'],
    queryFn: () => Api.groups(),
    select: res => res.data.data,
  });

  let groupList = groupQuery?.data?.groupList ?? [];

  const listQuery = useQuery({
    queryKey: ['adminList', { ...queryKey, searchText: debounce }],
    queryFn: fetchAdminList,
    placeholderData: keepPreviousData,
    select: res => res.data.data,
    enabled: !!params.pndGroupId,
  });

  const handleGroupFirstLoad = firstGroupId => {
    setParams(prevParams => ({
      ...prevParams,
      pndGroupId: firstGroupId,
    }));
  };

  const roleQuery = useQuery({
    queryKey: ['roles'],
    queryFn: () => Api.roles(),
    select: res => res.data.data,
  });

  let roleList = [{ id: '', roleName: '전체' }];
  let roleDtList = roleQuery?.data?.adminRoleList || [];
  if (roleQuery?.data?.adminRoleList) roleList = [...roleList, ...roleDtList];

  const renderCell = useCallback(
    (item, columnKey, idx) => {
      switch (columnKey) {
        case 'no': {
          const rowNumber = Number(listQuery?.data?.adminPage?.totalElements ? listQuery?.data?.adminPage?.totalElements : 0) - ((Number(params.pageNo) - 1) * Number(params.pageSize) + idx);
          return <div>{rowNumber}</div>;
        }
        case 'pndGroupId': {
          let group = groupList.find(g => {
            return item.pndGroupId == g.id;
          });

          return <div>{group?.name}</div>;
        }
        case 'useYn':
          return item.useYn === 'Y' ? (
            <Chip size="sm" variant="flat" color={'success'}>
              사용
            </Chip>
          ) : (
            <Chip size="sm" variant="flat" color={'default'}>
              미사용
            </Chip>
          );
        case 'adminRoleId': {
          let role = roleQuery.data?.adminRoleList?.find(role => {
            return item.adminRoleId == role.id;
          });
          return role?.roleName;
        }
        case 'regDt':
          return (
            <Chip variant="light" color={'default'}>
              {formatUtil.getDate(getKeyValue(item, columnKey), '-')}
            </Chip>
          );
        default:
          return getKeyValue(item, columnKey);
      }
    },
    [listQuery?.data?.adminPage?.totalElements, params.pageNo, roleList]
  );

  const handlerRowClick = item => {
    onOpenView();
    selectedId.current = item.id;
  };

  const handlerAddClick = () => {
    onOpenAdd();
  };

  return (
    <ContentWrapper>
      {listQuery.isFetching && <LoadingBackground />}
      <AdminAdd isOpenAdd={isOpenAdd} onOpenChangeAdd={onOpenChangeAdd} onCloseAdd={onCloseAdd} reloadList={reloadList}></AdminAdd>
      {isOpenView && (
        <AdminView
          isOpenView={isOpenView}
          onOpenChangeView={onOpenChangeView}
          onCloseView={onCloseView}
          selectedId={selectedId.current}
          reloadList={reloadList}
          onOpenView={onOpenView}
          onOpenUpdate={onOpenUpdate}
        ></AdminView>
      )}

      <ModalUpdate
        isOpenUpdate={isOpenUpdate}
        onOpenChangeUpdate={onOpenChangeUpdate}
        onCloseUpdate={onCloseUpdate}
        size="5xl"
        selectedId={selectedId.current}
        scrollBehavior={'outside'}
        onOpenView={onOpenView}
      />
      <div className="flex flex-col gap-4 p-4 mb-4 bg-opacity-75 border bg-default-50 border-default-100 rounded-medium">
        <div className="flex gap-4">
          <GroupSelect label="운반회사" selectedKey={params.pndGroupId} onChange={onInputChange} name="pndGroupId" onFirstLoad={handleGroupFirstLoad} />

          <Select
            size="sm"
            className="max-w-48"
            labelPlacement="outside"
            label="구분"
            disallowEmptySelection
            selectedKeys={[params.adminRoleId.toString()]}
            items={roleList}
            onChange={onInputChange}
            name="adminRoleId"
          >
            {item => <SelectItem key={item.id}>{item.roleName}</SelectItem>}
          </Select>
          <Select size="sm" className="max-w-48" labelPlacement="outside" disallowEmptySelection label="사용여부" selectedKeys={[params.useYn]} onChange={onInputChange} name="useYn">
            <SelectItem key="">전체</SelectItem>
            <SelectItem key="Y">사용</SelectItem>
            <SelectItem key="N">미사용</SelectItem>
          </Select>
        </div>
        <div className="flex gap-4">
          <Input
            labelPlacement="outside"
            label="검색"
            size="sm"
            className="w-full"
            placeholder="검색어를 입력하세요"
            onChange={onInputChange}
            value={params.searchText}
            name="searchText"
            startContent={
              <Select
                disallowEmptySelection
                className="w-32 shrink-0"
                classNames={{
                  base: 'h-full items-center flex-row -mx-2',
                  trigger: '!bg-transparent !shadow-none h-full min-h-min',
                  mainWrapper: 'h-full',
                }}
                selectedKeys={[params.searchType]}
                items={search}
                onChange={onInputChange}
                name="searchType"
              >
                {item => <SelectItem key={item.value}>{item.label}</SelectItem>}
              </Select>
            }
            endContent={textLoading && <Spinner size="sm" color="primary" labelColor="primary" />}
          />
        </div>
      </div>

      <PaginationTable
        data={{
          page: params.pageNo,
          size: params.pageSize,
          totalPages: listQuery?.data?.adminPage.totalPages,
          totalElements: listQuery?.data?.adminPage.totalElements,
          columns: columns,
          items: listQuery?.data?.adminPage.content,
        }}
        renderCell={renderCell}
        isFetching={listQuery.isFetching}
        isLoading={listQuery.isLoading}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        onRowClick={handlerRowClick}
        onAddClick={handlerAddClick}
      />
    </ContentWrapper>
  );
};

export default List;
