import { Api } from '@/api';
import ConfirmModal from '@/components/Modal/ConfirmModal';
import { AlertModal, useAlertModal } from '@/components/Modal/useAlertModal';
import useConfirmModal from '@/hooks/useConfirmModal';
import { Button, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Tab, Tabs, Textarea } from '@nextui-org/react';
import { useMutation } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';

const ModalAdd = ({ isOpenAdd, onOpenChangeAdd, onCloseAdd, reloadList }) => {
  const { callAlert } = useAlertModal();
  const { confirmOpen, message, requestConfirm, handleConfirm, handleClose } = useConfirmModal();

  const addVersion = useMutation({
    mutationFn: values => Api.versionAdd(values),
  });

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, validateForm, resetForm, setValues } = useFormik({
    initialValues: {
      id: '',
      appName: '',
      appTypeCode: '',
      version: '',
      description: '',
    },
    validationSchema: Yup.object({
      appName: Yup.string().required('제목을 입력하세요.'),
      version: Yup.string().required('버전을 입력하세요.'),
      description: Yup.string().required('내용을 입력하세요.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      addVersion.mutate(values, {
        onSettled: () => {
          setSubmitting(false);
        },
        onSuccess: () => {
          callAlert('등록 되었습니다.', () => {
            onCloseAdd();
            reloadList();
          });
        },
        onError: error => {
          console.error('등록 중 오류가 발생했습니다:', error);
          callAlert('등록 중 오류가 발생했습니다.');
        },
      });
    },
  });

  const handleRegisterClick = async () => {
    const errors = await validateForm();

    if (Object.keys(errors).length === 0) {
      requestConfirm({
        message: '등록 하시겠습니까?',
        onConfirm: handleSubmit,
      });
    } else {
      Object.keys(errors).map(item => {
        touched[item] = true;
      });
      callAlert('입력한 데이터가 올바르지 않습니다.');
    }
  };

  useEffect(() => {
    resetForm();
  }, [isOpenAdd]);

  return (
    <>
      <Modal isDismissable={false} isOpen={isOpenAdd} onOpenChange={onOpenChangeAdd} size="5xl" scrollBehavior={'outside'}>
        <ConfirmModal isOpen={confirmOpen} title={message} onConfirm={handleConfirm} onClose={handleClose} />
        <ModalContent>
          <form
            onSubmit={event => {
              event.preventDefault();
              handleSubmit();
            }}
          >
            <ModalHeader>버전관리 등록</ModalHeader>
            <ModalBody>
              <div className="flex flex-col space-y-4">
                <div>
                  <Input
                    name="appName"
                    label="앱 이름"
                    labelPlacement="outside"
                    placeholder="앱 이름을 입력하세요"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.appName}
                    isInvalid={touched.appName && !!errors.appName}
                    errorMessage={touched.appName && errors.appName}
                  ></Input>
                </div>

                <div>
                  <div className="mb-2 text-sm">플랫폼</div>

                  <Tabs
                    fullWidth
                    selectedKey={String(values.appTypeCode)}
                    onSelectionChange={e => {
                      if (e !== values.appTypeCode) {
                        setFieldValue('appTypeCode', e);
                      }
                    }}
                  >
                    <Tab key={'AOS'} title={'Android'} />
                    <Tab key={'IOS'} title={'IOS'} />
                  </Tabs>
                </div>

                <div>
                  <Input
                    name="version"
                    label="버전"
                    labelPlacement="outside"
                    placeholder="버전을 입력하세요"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.version}
                    isInvalid={touched.version && !!errors.version}
                    errorMessage={touched.version && errors.version}
                  ></Input>
                </div>

                <div>
                  <div className="mb-2 text-sm">패치내용</div>

                  <Textarea
                    name="description"
                    onChange={handleChange}
                    placeholder="패치내용을 입력해주세요"
                    value={values.description}
                    onBlur={handleBlur}
                    isInvalid={touched.description && !!errors.description}
                    errorMessage={touched.description && errors.description}
                    type="text"
                  />
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button isLoading={addVersion.isLoading} fullWidth color="primary" disabled={isSubmitting} onPress={handleRegisterClick}>
                등록
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
      <AlertModal />
    </>
  );
};

export default ModalAdd;
