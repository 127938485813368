import { Api } from '@/api/index';
import ContentWrapper from '@/components/ContentWrapper';
import PaginationTable from '@/components/PaginationTable';
import useCustomSearchParams from '@/hooks/useCustomSearchParams';
import ModalUpdate from '@/pages/Role/ModalUpdate';
import { Chip, getKeyValue, useDisclosure } from '@nextui-org/react';
import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

const columns = [
  { name: '레벨', uid: 'roleLevel' },
  { name: '이름', uid: 'roleName' },
  { name: '권한관리', uid: 'manage' },
];

const List = () => {
  const { isOpen: isOpenUpdate, onOpen: onOpenUpdate, onOpenChange: onOpenChangeUpdate, onClose: onCloseUpdate } = useDisclosure();

  const selectedId = useRef('');
  const queryClient = useQueryClient();

  const [searchParams] = useSearchParams();

  const initialParams = {
    pndGroupId: searchParams.get('pndGroupId') || '',
  };

  const { params, queryKey, onRowsPerPageChange, onPageChange, onInputChange } = useCustomSearchParams(initialParams);

  const fetchRoleList = () => {
    const postParams = { pageNo: params.pageNo, pageSize: params.pageSize };
    postParams.useYn = params.useYn;
    if (params.searchText) {
      postParams[params.searchType] = params.searchText;
    }
    const trimParams = Object.fromEntries(Object.entries(postParams).filter(([, value]) => value));
    return Api.roles(trimParams);
  };

  const reloadList = () => {
    queryClient.invalidateQueries(['termsList', searchParams.get('pndGroupId')]);
  };

  const listQuery = useQuery({
    queryKey: ['roleList', searchParams.get('pndGroupId')],
    queryFn: fetchRoleList,
    placeholderData: keepPreviousData,
    select: res => res.data.data,
  });

  const renderCell = useCallback((item, columnKey, idx) => {
    switch (columnKey) {
      case 'manage':
        return (
          <div>
            <Chip
              color="primary"
              className="cursor-pointer"
              onClick={() => {
                selectedId.current = item.id;
                onOpenUpdate();
              }}
            >
              check
            </Chip>
          </div>
        );
      default:
        return getKeyValue(item, columnKey);
    }
  }, []);

  const handlerRowClick = item => {
    selectedId.current = item.id;
  };

  return (
    <ContentWrapper>
      <ModalUpdate isOpenUpdate={isOpenUpdate} onOpenChangeUpdate={onOpenChangeUpdate} onCloseUpdate={onCloseUpdate} selectedId={selectedId.current}></ModalUpdate>

      {/* <div className="sticky flex gap-4 p-4 mb-2 bg-opacity-75 border-b bg-default-50 border-default-100 rounded-medium">
        <Select
          size="sm"
          labelPlacement="outside"
          label="회사명"
          disallowEmptySelection
          className="max-w-48"
          //selectedKeys={[params.searchType]}
          //items={}
          //onChange={onInputChange}
          //name="searchType"
        >
           {item => <SelectItem key={item.value}>{item.label}</SelectItem>}
        </Select>
      </div> */}
      <PaginationTable
        data={{
          // page: params.pageNo,
          //  size: params.pageSize,
          // totalPages: listQuery?.data?.driverTermsPage?.totalPages,
          //  totalElements: listQuery?.data?.driverTermsPage?.totalElements,
          columns: columns,
          items: listQuery?.data?.adminRoleList,
        }}
        renderCell={renderCell}
        isFetching={listQuery.isFetching}
        isLoading={listQuery.isLoading}
        // onPageChange={onPageChange}
        // onRowsPerPageChange={onRowsPerPageChange}
        // onRowClick={handlerRowClick}
      />
    </ContentWrapper>
  );
};

export default List;
