import { Api } from '@/api';
import ConfirmModal from '@/components/Modal/ConfirmModal';
import { AlertModal, useAlertModal } from '@/components/Modal/useAlertModal';
import useConfirmModal from '@/hooks/useConfirmModal';
import { formatUtil } from '@/utils/FormatUtil';
import { Button, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Select, SelectItem, Tab, Tabs, useDisclosure } from '@nextui-org/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import ModalPwUpdate from './ModalPwUpdate';
import { FlexTable, FlexTableRow, FlexTableHeader, FlexTableData } from '@/components/FlexTable';

const ModalUpdate = ({ isOpenUpdate, onOpenChangeUpdate, onCloseUpdate, reloadList, selectedId, onOpenView }) => {
  const { callAlert } = useAlertModal();
  const queryClient = useQueryClient();

  const reloadAdmin = () => {
    queryClient.invalidateQueries(['admin', selectedId]);
  };

  const { confirmOpen, message, requestConfirm, handleConfirm, handleClose } = useConfirmModal();
  const { isOpen: isOpenPwUpdate, onOpen: onOpenPwUpdate, onOpenChange: onOpenChangePwUpdate, onClose: onClosePwUpdate } = useDisclosure();

  const adminQuery = useQuery({
    queryKey: ['admin', selectedId],
    queryFn: () => Api.adminView({ id: selectedId }),
    select: res => res.data.data,
    enabled: !!selectedId && isOpenUpdate,
  });

  const adminData = adminQuery.data?.admin;

  const groupQuery = useQuery({
    queryKey: ['groups'],
    queryFn: () => Api.groups(),
    select: res => res.data.data,
  });

  const roleQuery = useQuery({
    queryKey: ['roles'],
    queryFn: () => Api.roles(),
    select: res => res.data.data,
  });

  const updateAdmin = useMutation({
    mutationFn: values => Api.adminUpdate(values),
  });

  const handleClosePage = () => {
    //onOpenView();
    onCloseUpdate();
  };

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, validateForm, resetForm, setValues } = useFormik({
    initialValues: {
      id: adminData?.id || '',
      pndGroupId: adminData?.pndGroupId || '',
      loginId: adminData?.loginId || '',
      adminRoleId: adminData?.adminRoleId || '',
      adminName: adminData?.adminName || '',
      adminTel: adminData?.adminTelDec || '',
      adminEmail: adminData?.adminEmailDec || '',
      useYn: adminData?.useYn || '',
      lockYn: adminData?.lockYn || '',
    },
    validationSchema: Yup.object({
      adminName: Yup.string().required('사용자명을 입력하세요.'),
      adminTel: Yup.string()
        .matches(/^\d{2,3}-\d{3,4}-\d{4}$/, '전화번호 형식이 잘못되었습니다.')
        .required('전화번호를 입력하세요.'),
      adminEmail: Yup.string()
        .matches(/^[^\u3131-\uD79D]+$/, '이메일에 한글은 입력할 수 없습니다.')
        .email('이메일 형식이 잘못되었습니다.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      const { loginPwCheck, ...restValues } = values; // loginPwCheck 값을 제외
      updateAdmin.mutate(restValues, {
        onSettled: () => {
          setSubmitting(false);
        },
        onSuccess: () => {
          callAlert('수정되었습니다.', () => {
            onCloseUpdate();
            reloadAdmin();
            onOpenView();
          });
        },
        onError: error => {
          console.error('수정 중 오류가 발생했습니다:', error);
          callAlert('수정 중 오류가 발생했습니다.');
        },
      });
    },
  });

  useEffect(() => {
    if (values.adminTel) {
      const value = values.adminTel.replace(/\D/g, '');
      setFieldValue('adminTel', formatUtil.getTel(value));
    }
  }, [values.adminTel, setFieldValue]);

  const handleRegisterClick = async () => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      requestConfirm({
        message: '수정 하시겠습니까?',
        onConfirm: handleSubmit,
      });
    } else {
      callAlert('입력한 데이터가 올바르지 않습니다.');
    }
  };

  useEffect(() => {
    if (!isOpenUpdate) {
      resetForm();
    } else if (adminQuery.data) {
      setValues({
        id: adminData.id,
        pndGroupId: adminQuery.data.admin.pndGroupId,
        loginId: adminQuery.data.admin.loginId,
        adminRoleId: adminData?.adminRoleId,
        adminName: adminQuery.data.admin.adminName,
        adminTel: adminQuery.data.admin.adminTelDec,
        adminEmail: adminQuery.data.admin.adminEmailDec,
        useYn: adminQuery.data.admin.useYn,
        lockYn: adminQuery.data.admin.lockYn,
      });
    }
  }, [isOpenUpdate, adminQuery.data, resetForm, setValues]);

  return (
    <>
      <Modal isDismissable={false} isOpen={isOpenUpdate} onOpenChange={onOpenChangeUpdate} size="xl" scrollBehavior={'outside'}>
        <ConfirmModal isOpen={confirmOpen} title={message} onConfirm={handleConfirm} onClose={handleClose} />
        {roleQuery.data?.adminRoleList && (
          <ModalContent>
            <form
              onSubmit={event => {
                event.preventDefault();
                handleSubmit();
              }}
            >
              <ModalHeader>계정 수정</ModalHeader>
              <ModalBody>
                <FlexTable>
                  <FlexTableRow>
                    <FlexTableHeader>운반회사</FlexTableHeader>
                    <FlexTableData>
                      <Select
                        name="pndGroupId"
                        aria-label="pndGroupId"
                        size="md"
                        isDisabled={true}
                        onChange={handleChange}
                        selectedKeys={values.pndGroupId ? [values.pndGroupId.toString()] : []}
                        disallowEmptySelection
                      >
                        {groupQuery.data?.groupList?.map(item => (
                          <SelectItem key={item.id}>{item.name}</SelectItem>
                        ))}
                      </Select>
                    </FlexTableData>
                  </FlexTableRow>

                  <FlexTableRow>
                    <FlexTableHeader>아이디</FlexTableHeader>
                    <FlexTableData>{values.loginId}</FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>비밀번호</FlexTableHeader>
                    <FlexTableData>
                      <Button size="sm" variant="flat" color="danger" onPress={onOpenPwUpdate}>
                        비밀번호 변경
                      </Button>
                    </FlexTableData>
                  </FlexTableRow>

                  {/* <FlexTableRow>
                    <FlexTableHeader>구분</FlexTableHeader>
                    <FlexTableData>
                      <Tabs
                        fullWidth
                        selectedKey={values.adminRoleId.toString()}
                        onSelectionChange={e => {
                          if (e !== values.adminRoleId) {
                            setFieldValue('adminRoleId', e);
                          }
                        }}
                      >
                        {roleQuery.data?.adminRoleList?.map(item => (
                          <Tab key={item.id} title={item.roleName} />
                        ))}
                      </Tabs>
                    </FlexTableData>
                  </FlexTableRow> */}

                  <FlexTableRow>
                    <FlexTableHeader>이름</FlexTableHeader>
                    <FlexTableData>
                      <Input
                        name="adminName"
                        labelPlacement="outside"
                        placeholder="사용자명을 입력해주세요"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.adminName}
                        isInvalid={touched.adminName && !!errors.adminName}
                        errorMessage={touched.adminName && errors.adminName}
                      ></Input>
                    </FlexTableData>
                  </FlexTableRow>

                  <FlexTableRow>
                    <FlexTableHeader>전화번호</FlexTableHeader>
                    <FlexTableData>
                      <Input
                        name="adminTel"
                        labelPlacement="outside"
                        placeholder="010-0000-0000"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.adminTel}
                        isInvalid={touched.adminTel && !!errors.adminTel}
                        errorMessage={touched.adminTel && errors.adminTel}
                        maxLength={16}
                      ></Input>
                    </FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>이메일</FlexTableHeader>
                    <FlexTableData>
                      <Input
                        name="adminEmail"
                        labelPlacement="outside"
                        placeholder="example@example.com"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.adminEmail}
                        isInvalid={touched.adminEmail && !!errors.adminEmail}
                        errorMessage={touched.adminEmail && errors.adminEmail}
                      ></Input>
                    </FlexTableData>
                  </FlexTableRow>
                </FlexTable>
              </ModalBody>
              <ModalFooter>
                <Button color="default" className="ml-auto" onPress={handleClosePage}>
                  닫기
                </Button>
                <Button color="primary" isDisabled={!adminQuery.data} disabled={isSubmitting} onPress={handleRegisterClick}>
                  확인
                </Button>
              </ModalFooter>
            </form>
          </ModalContent>
        )}
      </Modal>

      <ModalPwUpdate isOpen={isOpenPwUpdate} onOpenChange={onOpenChangePwUpdate} onClose={onClosePwUpdate} selectedId={selectedId} />
      <AlertModal />
    </>
  );
};

export default ModalUpdate;
