import React from 'react';
import * as Icon from '@phosphor-icons/react';

const DynamicIcon = ({ iconName, alt, color, size, weight, mirrored = false, className = '' }) => {
  const IconComponent = Icon[iconName];

  if (!IconComponent) {
    return <Icon.QuestionMark aria-label={alt} color={color} size={size} weight={weight} mirrored={mirrored} className={className} />;
  }

  return <IconComponent aria-label={alt} color={color} size={size} weight={weight} mirrored={mirrored} className={className} />;
};

export default DynamicIcon;
