import { Api } from '@/api';
import { formatUtil } from '@/utils/FormatUtil';
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, useDisclosure } from '@nextui-org/react';

import LoadingBackground from '@/components/LoadingBackground';
import ConfirmModal from '@/components/Modal/ConfirmModal';
import { AlertModal, useAlertModal } from '@/components/Modal/useAlertModal';
import useConfirmModal from '@/hooks/useConfirmModal';
import { useMutation, useQuery } from '@tanstack/react-query';

import { FlexTable, FlexTableRow, FlexTableHeader, FlexTableData } from '@/components/FlexTable';

const ModalView = ({ isOpenView, onOpenView, onOpenChangeView, onCloseView, selectedId, reloadList, onOpenUpdate }) => {
  const { callAlert } = useAlertModal();
  const versionQuery = useQuery({
    queryKey: ['version', selectedId],
    queryFn: () => Api.versionView({ id: selectedId }),
    select: res => res.data.data,
    enabled: !!selectedId,
  });

  const { confirmOpen, message, requestConfirm, handleConfirm, handleClose } = useConfirmModal();

  const handlerUpdatePage = () => {
    onCloseView();
    onOpenUpdate();
  };

  const delVersion = useMutation({
    mutationFn: id => Api.versionDel({ id }),
    onSuccess: data => {
      onCloseView();
      callAlert('처리되었습니다.', () => {
        reloadList();
      });
    },
    onError: error => {
      console.error('Error adding user', error);
    },
  });

  const handlerDelete = id => {
    delVersion.mutate(id);
  };

  let version = versionQuery?.data?.appVersion;

  return (
    <>
      {versionQuery.isPending && isOpenView && <LoadingBackground />}
      <Modal isDismissable={false} isOpen={isOpenView} onOpenChange={onOpenChangeView} size="5xl" scrollBehavior={'outside'}>
        <ModalContent>
          {close => (
            <>
              <ModalHeader>약관관리 상세</ModalHeader>
              <ModalBody>
                <FlexTable>
                  <FlexTableRow>
                    <FlexTableHeader>등록일</FlexTableHeader>
                    <FlexTableData>{formatUtil.getDate(version?.regDt, '-')}</FlexTableData>
                    <FlexTableHeader>수정일</FlexTableHeader>
                    <FlexTableData>{formatUtil.getDate(version?.modDt, '-')}</FlexTableData>
                    <FlexTableHeader>작성자</FlexTableHeader>
                    <FlexTableData>{version?.regName}</FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>앱이름</FlexTableHeader>
                    <FlexTableData>{version?.appName}</FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>플랫폼</FlexTableHeader>
                    <FlexTableData>{version?.appTypeCode}</FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>버전</FlexTableHeader>
                    <FlexTableData>{version?.version}</FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>패치내용</FlexTableHeader>
                    <FlexTableData>{version?.description}</FlexTableData>
                  </FlexTableRow>
                </FlexTable>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="danger"
                  className="mr-auto"
                  isLoading={delVersion.isPending}
                  onPressEnd={() => {
                    requestConfirm({
                      message: '삭제하시겠습니까??',
                      onConfirm: () => {
                        handlerDelete(version?.id);
                      },
                    });
                  }}
                >
                  삭제
                </Button>
                <Button color="default" onPress={onCloseView}>
                  닫기
                </Button>
                <Button color="primary" onPress={handlerUpdatePage}>
                  수정
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

      <ConfirmModal isOpen={confirmOpen} title={message} onConfirm={handleConfirm} onClose={handleClose} />
      <AlertModal />
    </>
  );
};

export default ModalView;
