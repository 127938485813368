import { Api } from '@/api';
import { Button, Input, Modal, ModalHeader, Tab, Tabs, ModalContent, ModalFooter, ModalBody, Select, SelectItem, DatePicker, Textarea } from '@nextui-org/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useEffect } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import useConfirmModal from '@/hooks/useConfirmModal';
import ConfirmModal from '@/components/Modal/ConfirmModal';
import { useAlertModal, AlertModal } from '@/components/Modal/useAlertModal';
import { formatUtil } from '@/utils/FormatUtil';
import { getLocalTimeZone, today } from '@internationalized/date';

const ModalAdd = ({ isOpenAdd, onOpenChangeAdd, onCloseAdd, reloadList }) => {
  const { confirmOpen, message, requestConfirm, handleConfirm, handleClose } = useConfirmModal();
  const { callAlert } = useAlertModal();
  let defaultDate = today(getLocalTimeZone());
  const groupQuery = useQuery({
    queryKey: ['groups'],
    queryFn: () => Api.groups(),
    select: res => res.data.data,
  });

  const { data: codeMapData } = useQuery({
    queryKey: ['codeMap', 'TERMS_TYPE'],
    queryFn: () => Api.codeMap({ cate: 'TERMS_TYPE' }),
    select: res => res.data.data,
  });

  const codeMap = codeMapData?.codeMap;

  const addTerms = useMutation({
    mutationFn: values => Api.driverTermsAdd(values),
  });

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, validateForm, resetForm, setValues } = useFormik({
    initialValues: {
      pndGroupId: '',
      termsTypeCode: '',
      termsTitle: '',
      termsContent: '',
      termsDate: defaultDate,
      showYn: '',
    },
    validationSchema: Yup.object({
      termsTitle: Yup.string().required('제목을 입력하세요.'),
      termsContent: Yup.string().required('내용을 입력하세요.'),
      termsDate: Yup.string().required('날짜를 선택하세요.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      let data = { ...values };
      data.termsDate = String(data.termsDate).replaceAll('-', '');
      addTerms.mutate(data, {
        onSettled: () => {
          setSubmitting(false);
        },
        onSuccess: () => {
          callAlert('등록 되었습니다.', () => {
            onCloseAdd();
            reloadList();
          });
        },
        onError: error => {
          if (error?.response?.data?.code === 'FAIL' && error?.response?.data?.msg) {
            callAlert(error?.response?.data?.msg);
          } else {
            callAlert('등록에 실패하였습니다.');
          }
        },
      });
    },
  });

  useEffect(() => {
    if (values.adminTel) {
      const value = values.adminTel.replace(/\D/g, '');
      setFieldValue('adminTel', formatUtil.getTel(value));
    }
  }, [values.adminTel, setFieldValue]);

  const handleRegisterClick = async () => {
    const errors = await validateForm();

    if (Object.keys(errors).length === 0) {
      requestConfirm({
        message: '등록 하시겠습니까?',
        onConfirm: handleSubmit,
      });
    } else {
      Object.keys(errors).map(item => {
        touched[item] = true;
      });
      callAlert('입력한 데이터가 올바르지 않습니다.');
    }
  };

  useEffect(() => {
    resetForm();
    Object.keys(errors).map(item => {
      touched[item] = false;
    });
  }, [isOpenAdd]);

  useEffect(() => {
    if (groupQuery.data?.groupList && isOpenAdd) {
      setFieldValue('pndGroupId', String(groupQuery.data.groupList[0].id));
    }
  }, [groupQuery?.data, isOpenAdd]);

  return (
    <>
      <Modal isDismissable={false} isOpen={isOpenAdd} onOpenChange={onOpenChangeAdd} size="5xl" scrollBehavior={'outside'}>
        <ConfirmModal isOpen={confirmOpen} title={message} onConfirm={handleConfirm} onClose={handleClose} />
        <ModalContent>
          <form
            onSubmit={event => {
              event.preventDefault();
              handleSubmit();
            }}
          >
            <ModalHeader>약관관리 등록</ModalHeader>
            <ModalBody>
              <div className="flex flex-col space-y-4">
                <div className="">
                  <div className="mb-2 text-sm">회사명</div>
                  <Select name="pndGroupId" aria-label="pndGroupId" size="md" onChange={handleChange} disallowEmptySelection selectedKeys={[String(values.pndGroupId)]}>
                    {groupQuery.data?.groupList?.map(item => (
                      <SelectItem key={item.id}>{item.name}</SelectItem>
                    ))}
                  </Select>
                </div>

                <div>
                  <div className="mb-2 text-sm">노출 상태</div>

                  <Tabs
                    fullWidth
                    selectedKey={String(values.showYn)}
                    onSelectionChange={e => {
                      if (e !== values.showYn) {
                        setFieldValue('showYn', e);
                      }
                    }}
                  >
                    <Tab key="Y" title="ON" />
                    <Tab key="N" title="OFF" />
                  </Tabs>
                </div>

                <div>
                  <div className="mb-2 text-sm">약관 유형</div>

                  <Tabs
                    fullWidth
                    selectedKey={String(values.termsTypeCode)}
                    onSelectionChange={e => {
                      if (e !== values.termsTypeCode) {
                        setFieldValue('termsTypeCode', e);
                      }
                    }}
                  >
                    {codeMap && Object.keys(codeMap).map(key => <Tab key={key} title={codeMap[key]} />)}
                  </Tabs>
                </div>

                <div className="flex space-x-2">
                  <Input
                    name="termsTitle"
                    label="제목"
                    labelPlacement="outside"
                    placeholder="제목을 입력하세요"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.termsTitle}
                    isInvalid={touched.termsTitle && !!errors.termsTitle}
                    errorMessage={touched.termsTitle && errors.termsTitle}
                  ></Input>
                </div>

                <div>
                  <div className="mb-2 text-sm">내용</div>

                  <Textarea
                    name="termsContent"
                    onChange={handleChange}
                    placeholder="내용을 입력해주세요"
                    //value={values.termsContent ? values.termsContent}
                    onBlur={handleBlur}
                    isInvalid={touched.termsContent && !!errors.termsContent}
                    errorMessage={touched.termsContent && errors.termsContent}
                    type="text"
                  />
                </div>
                <div>
                  <div className="mb-2 text-sm">약관 시작일</div>

                  <DatePicker
                    name="termsDate"
                    aria-label="termsDate"
                    onBlur={handleBlur}
                    className=""
                    onChange={e => {
                      if (e !== values.termsDate) {
                        setFieldValue('termsDate', e);
                      }
                    }}
                    value={values.termsDate}
                    isInvalid={touched.termsDate && !!errors.termsDate}
                    errorMessage={touched.termsDate && errors.termsDate}
                  ></DatePicker>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button isLoading={addTerms.isLoading} fullWidth color="primary" disabled={isSubmitting} onPress={handleRegisterClick}>
                등록
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
      <AlertModal />
    </>
  );
};

export default ModalAdd;
