import React from 'react';
import { Button } from '@nextui-org/react';
import { useLocation, useNavigate } from 'react-router-dom';

import { SmileySad } from '@phosphor-icons/react';

function ErrorPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const status = location.state?.status;
  return (
    <div className="w-full h-full flex items-center justify-center">
      <div className="flex flex-col align-middle items-center gap-5 ">
        <div>
          <SmileySad size="300" />
        </div>
        <div className="text-3xl">{status === '404' ? 'Page not found' : status === '400' ? 'Bad request.' : 'Internet server error'}</div>
        <div>
          {status === '404' || !status
            ? '페이지를 찾을수 없습니다.'
            : status === '400'
            ? '요청이 유효하지 않습니다. 관리자에 문의해주세요.'
            : status === '500'
            ? '내부서버에서 문제가 발생하였습니다. 관리자에 문의해주세요.'
            : status === 'snw'
            ? '서버에서 응답이 없습니다.'
            : ''}
        </div>
        <Button
          color="primary"
          variant="bordered"
          style={{ marginRight: '8px' }}
          onPress={() => {
            navigate('/job');
          }}
        >
          메인으로 이동
        </Button>
      </div>
    </div>
  );
}

export default ErrorPage;
