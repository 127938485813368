import { useState } from 'react';

export function useInput(initialValue, submitAction, options = {}) {
  const { onlyNumbers = false, phoneNumberFormat = false } = options;

  const [inputValue, setInputValue] = useState(initialValue ? initialValue : '');

  const handleChange = e => {
    let value = e.target.value;

    if (onlyNumbers) {
      value = value.replace(/\D/g, ''); // 숫자만 남김
    }

    if (phoneNumberFormat) {
      value = value.replace(/\D/g, ''); // 숫자만 남김
      if (value.length <= 3) {
        value = value;
      } else if (value.length <= 7) {
        value = `${value.slice(0, 3)}-${value.slice(3)}`;
      } else {
        value = `${value.slice(0, 3)}-${value.slice(3, 7)}-${value.slice(7)}`;
      }
    }

    setInputValue(value);
  };

  const handleSubmit = () => {
    setInputValue('');
    submitAction && submitAction(inputValue);
  };

  // 입력값 초기화 함수 추가
  const resetInput = () => {
    setInputValue('');
  };

  return [inputValue, handleChange, handleSubmit, resetInput];
}
