import { axiosInstance } from './axiosInstance';

/**
 * @namespace Api
 * @description API 요청을 처리하는 메서드를 포함하는 객체입니다.
 */

export const Api = {
  refresh: async ({ refreshToken }) => axiosInstance.post('/jwt/refresh', null, { params: { refreshToken } }),
  login: async ({ id, pw }) => axiosInstance.post('/login', { id, pw }),

  adminList: async params => axiosInstance.get('/admins', { params }),
  adminView: async ({ id }) => axiosInstance.get('/admin', { params: { id } }),
  adminAdd: async values => axiosInstance.post('/admin', { ...values }),
  adminUpdate: async values => axiosInstance.put('/admin', { ...values }),
  adminDel: async values => axiosInstance.put('/admin', { ...values }),
  adminExistCheck: async ({ loginId }) => axiosInstance.get('/admin/exist/check', { params: { loginId } }),
  adminPwChange: async ({ id, loginPw }) => axiosInstance.put('/admin/pw/change', { id, loginPw }),

  driverTermsList: async params => axiosInstance.get('/terms/list', { params }),
  driverTermsView: async ({ id }) => axiosInstance.get('/terms', { params: { id } }),
  driverTermsAdd: async values => axiosInstance.post('/terms', { ...values }),
  driverTermsUpdate: async values => axiosInstance.put('/terms', { ...values }),
  driverTermsDel: async values => axiosInstance.put('/terms', { ...values }),

  versionList: async params => axiosInstance.get('/versions', { params }),
  versionView: async ({ id }) => axiosInstance.get('/version', { params: { id } }),
  versionAdd: async values => axiosInstance.post('/version', { ...values }),
  versionUpdate: async values => axiosInstance.put('/version', { ...values }),
  versionDel: async params => axiosInstance.delete('/version', { params }),

  driverList: async params => axiosInstance.get('/drivers', { params }),
  driverView: async params => axiosInstance.get('/driver', { params }),
  driverAdd: async values => axiosInstance.post('/driver', { ...values }),
  driverUpdate: async values => axiosInstance.put('/driver', { ...values }),
  driverDel: async params => axiosInstance.delete('/driver', { params }),

  vehicleList: async params => axiosInstance.get('/vehicles', { params }),
  vehicleView: async params => axiosInstance.get('/vehicle', { params }),
  vehicleAdd: async values => axiosInstance.post('/vehicle', { ...values }),
  vehicleUpdate: async values => axiosInstance.put('/vehicle', { ...values }),
  vehicleDel: async params => axiosInstance.delete('/vehicle', { params }),
  vehicleOwnerList: async params => axiosInstance.get('/vehicle/owners', { params }),

  placeList: async params => axiosInstance.get('/places', { params }),
  placeView: async params => axiosInstance.get('/place', { params }),
  placeAdd: async values => axiosInstance.post('/place', { ...values }),
  placeUpdate: async values => axiosInstance.put('/place', { ...values }),
  placeDel: async params => axiosInstance.delete('/place', { params }),
  placeFilesUpload: async ({ placeId, uploadFiles }) => {
    const formData = new FormData();
    formData.append('placeId', placeId);

    uploadFiles.forEach(file => {
      formData.append('uploadFiles', file);
    });

    return axiosInstance.post('/place/files', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  placeFile: async ({ placeId, filePath }) => {
    const response = await axiosInstance.get('/place/file', {
      params: { placeId, filePath },
      responseType: 'blob',
    });
    return response;
  },

  jobList: async params => axiosInstance.get('/job/units', { params }),
  jobWeightImageList: async params => axiosInstance.get('/job/unit/weight/images', { params }),
  jobWeightImageCount: async params => axiosInstance.get('/job/unit/weight/image/count', { params }),
  jobWeightImageConfirm: async values => axiosInstance.patch('/job/unit/weight/image/confirm', { ...values }),
  jobView: async params => axiosInstance.get('/job/unit', { params }),
  jobAdd: async values => axiosInstance.post('/job/unit', { ...values }),
  jobUpdate: async values => axiosInstance.put('/job/unit', { ...values }),
  jobDel: async params => axiosInstance.delete('/job/unit', { params }),

  jobImage: async ({ jobUnitId, filePath }) => {
    const response = await axiosInstance.get('/job/unit/image', {
      params: { jobUnitId, filePath: filePath },
      responseType: 'blob',
    });
    return response.data;
  },

  jobImages: async params => {
    const response = await axiosInstance.get('/job/unit/images', {
      params,
      responseType: 'blob',
    });
    return response;
  },

  planList: async params => axiosInstance.get('/job/plans', { params }),
  planView: async params => axiosInstance.get('/job/plan', { params }),
  planUpdate: async values => axiosInstance.put('/job/plan', { ...values }),
  planCancel: async values => axiosInstance.patch('/job/plan/cancel', { ...values }),
  planAdd: async values => axiosInstance.post('/job/plan', { ...values }),
  planDirect: async values => axiosInstance.patch('/job/plan', { ...values }),
  planDirectCancle: async values => axiosInstance.patch('/job/plan', { ...values }),
  plan: async values => axiosInstance.put('/job/plan', { ...values }),
  planDel: async params => axiosInstance.delete('/job/plan', { params }),
  planDrivers: async params => axiosInstance.get('/job/plan/drivers', { params }),
  planSmart: async values => axiosInstance.patch('/job/plan/smart', { ...values }),
  planCopy: async values => axiosInstance.post('/job/plan/copy', { ...values }),

  jobCostList: async params => axiosInstance.get('/job-costs', { params }),
  jobCostView: async params => axiosInstance.get('/job-cost', { params }),
  jobCostAdd: async values => axiosInstance.post('/job-cost', { ...values }),
  jobCostUpdate: async values => axiosInstance.put('/job-cost', { ...values }),
  jobCostDel: async params => axiosInstance.delete('/job-cost', { params }),

  easyPlanList: async params => axiosInstance.get('/job/plan/batch/place', { params }),
  easyPlanSnapShotList: async params => axiosInstance.get('/job/plan/batch/confirm', { params }),
  addEasyPlan: async values => axiosInstance.post('/job/plan/batch/place', { ...values }),
  addEasySchedule: async values => axiosInstance.post('/job/plan/batch', { ...values }),
  confirmEasySchedule: async values => axiosInstance.post('/job/plan/batch/confirm', { ...values }),
  updatePlanList: async values => axiosInstance.patch('/job/plan/batch', { ...values }),

  easyPlanLogList: async params => axiosInstance.get('/job/plan/batch/logs', { params }),
  easyPlanLogCommentAdd: async ({ placeWeekChangeLogId, content }) => axiosInstance.post('/job/plan/batch/log/comment', { placeWeekChangeLogId, content }),
  easyPlanLogCommentMod: async ({ placeWeekChangeLogCommentId, content }) => axiosInstance.put('/job/plan/batch/log/comment', { placeWeekChangeLogCommentId, content }),
  easyPlanLogCommentDel: async ({ placeWeekChangeLogCommentId }) => axiosInstance.delete('/job/plan/batch/log/comment', { params: { placeWeekChangeLogCommentId } }),

  groups: async () => axiosInstance.get('/groups'),
  roles: async () => axiosInstance.get('/roles'),
  myinfo: async () => axiosInstance.get('/myinfo'),
  menu: async () => axiosInstance.get('/myInfo/menu'),

  groupList: async params => axiosInstance.get('/group/page', { params }),
  groupView: async params => axiosInstance.get('/group', { params }),
  groupAdd: async values => axiosInstance.post('/group', { ...values }),
  groupDel: async params => axiosInstance.delete('/group', { params }),
  groupUpdate: async values => axiosInstance.put('/group', { ...values }),

  codeMap: async params => axiosInstance.get('/codeMap', { params }),

  termsNotAgree: async () => axiosInstance.get('/adminTerms/notAgree'),
  termsAgree: async params => axiosInstance.post('/adminTerms/agree', params),

  packages: async params => axiosInstance.get('/packages', { params }),

  dashboardAlarm: async params => axiosInstance.get('/stat/dashboard/alarm', { params }),
  statusCount: async params => axiosInstance.get('/stat/daily/status/count', { params }),

  placeAddress: async params => axiosInstance.get('/place/addresses', { params }),

  vehicleLocationHistory: async params => axiosInstance.get('/vehicle/location/history', { params }),
  vehicleRunSummary: async params => axiosInstance.get('/vehicle/run/summary', { params }),
};
