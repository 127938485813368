import React from 'react';
import { Button, ModalBody, Modal, ModalFooter, ModalContent, ModalHeader } from '@nextui-org/react';

function ConfirmModal({ isOpen, onClose, title, onConfirm, onOpenChange, oneButton }) {
  return (
    <Modal isDismissable={false} isOpen={isOpen} onOpenChange={onOpenChange} onClose={onClose}>
      <ModalContent>
        <ModalHeader className="flex flex-col gap-1">{/* 비밀번호 변경 */}</ModalHeader>
        <ModalBody>
          <div>{title}</div>
        </ModalBody>
        <ModalFooter>
          {!oneButton && (
            <Button color="primary" variant="bordered" style={{ marginRight: '8px' }} onPress={onClose}>
              취소
            </Button>
          )}
          <Button color="primary" onPress={onConfirm}>
            확인
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ConfirmModal;
