import { useEffect, useRef } from 'react';

export function useDebouncedEffect(callback, delay, dependencies) {
  const firstUpdate = useRef(true);
  const timeoutRef = useRef(null);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    timeoutRef.current && clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      callback();
    }, delay);

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [callback, delay, ...dependencies]);
}
