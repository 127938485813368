import { Api } from '@/api';
import DynamicSelect from '@/components/DynamicSelect';
import { FlexTable, FlexTableData, FlexTableHeader, FlexTableRow } from '@/components/FlexTable';
import LoadingBackground from '@/components/LoadingBackground';
import ModalAddress from '@/components/Modal/AddressModal';
import ConfirmModal from '@/components/Modal/ConfirmModal';
import { AlertModal, useAlertModal } from '@/components/Modal/useAlertModal';
import modalMotionProps from '@/constants/modalMotionProps';
import useCodeMap from '@/hooks/useCodeMap';
import useConfirmModal from '@/hooks/useConfirmModal';
import { formatUtil } from '@/utils/FormatUtil';
import { Button, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, useDisclosure } from '@nextui-org/react';
import { Tab, Tabs } from '@nextui-org/tabs';
import { useMutation } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';

const ModalAdd = ({ isOpenAdd, onCloseAdd, reloadList }) => {
  const { callAlert } = useAlertModal();
  const { confirmOpen, message, requestConfirm, handleConfirm, handleClose } = useConfirmModal();
  const { isOpen: isOpenAddress, onOpen: onOpenAddress, onOpenChange: onOpenChangeAddress, onClose: onCloseAddress } = useDisclosure();

  const groupTypeQuery = useCodeMap('GROUP_TYPE');

  const validationSchema = Yup.object({
    bizName: Yup.string().required('상호를 입력해주세요.'),
    groupTypeCd: Yup.string().required('사업자형태를 입력해주세요.'),
    bizNo: Yup.string().required('사업자등록번호를 입력해주세요.'),
    bizCeo: Yup.string().required('대표자를 입력해주세요.'),
    bizZipCode: Yup.string().required('주소를 선택해주세요.'),
    bizAddr1: Yup.string().required('주소를 선택해주세요.'),
    bizAddr2: Yup.string().required('상세주소를 입력해주세요.'),
    bizEmail: Yup.string().email('유효한 이메일을 입력해주세요.'),
  });

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, dirty, resetForm, validateForm, setTouched } = useFormik({
    initialValues: {
      useYn: 'Y',
      groupTypeCd: '',
      bizName: '',
      bizNo: '',
      bizCeo: '',
      bizAddr1: '',
      bizAddr2: '',
      bizZipCode: '',
      bizTel: '',
      bizFax: '',
      bizEmail: '',
      pickupImgUpload: 'DENY',
      pickupDistanceYn: 'N',
      pickupDistanceMeter: 0,
      deliveryImgUpload: 'DENY',
      deliveryDistanceYn: 'N',
      deliveryDistanceMeter: 0,
      shipmentDirection: 'OUTBOUND',
      jobCostStartDay: 1,
      jobCostEndDay: 31,
      jobCostCreateYn: 'N',
      jobCostCreateDay: 0,
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      values.name = values.bizName;
      addGroup.mutate(values, {
        onSettled: () => {
          setSubmitting(false);
        },
      });
    },
  });

  const addGroup = useMutation({
    mutationFn: Api.groupAdd,
    onSuccess: result => {
      if (result.data.code === 'SUCC') {
        callAlert('생성되었습니다.', () => {
          onCloseAdd();
          reloadList();
        });
      } else if (result.data.code === 'FAIL') {
        callAlert(result.data.msg);
      } else {
        callAlert('생성에 실패하였습니다.');
      }
    },
    onError: error => {
      if (error.response.data.code === 'FAIL') {
        callAlert(error.response.data.msg);
      } else {
        callAlert('생성에 실패하였습니다.');
      }
    },
  });

  const handleModalClose = isOpen => {
    if (!isOpen) {
      if (dirty) {
        requestConfirm({
          message: '변경사항을 저장하지 않고 창을 닫으시겠습니까?',
          onConfirm: () => {
            resetForm();
            onCloseAdd();
          },
        });
      } else {
        onCloseAdd();
      }
    }
  };

  useEffect(() => {
    if (!isOpenAdd) {
      resetForm();
    }
  }, [isOpenAdd]);

  return (
    <>
      {isSubmitting && <LoadingBackground />}
      <Modal motionProps={modalMotionProps} isDismissable={false} isOpen={isOpenAdd} onOpenChange={handleModalClose} size="3xl" scrollBehavior={'outside'}>
        <ModalContent>
          <ModalHeader>새 고객사 추가</ModalHeader>
          <ModalBody>
            <Tabs classNames={{ tabList: 'gap-4 p-0', panel: 'p-0 flex flex-col gap-2', tab: 'p-0', cursor: 'w-full' }} variant={'underlined'} size="lg">
              <Tab key="1" title="회사정보">
                <FlexTable>
                  <FlexTableRow>
                    <FlexTableHeader>계약상태</FlexTableHeader>
                    <FlexTableData>
                      <Tabs
                        selectedKey={values.useYn}
                        onSelectionChange={key => {
                          if (key !== values.useYn) {
                            setFieldValue('useYn', key);
                          }
                        }}
                      >
                        <Tab key="Y" title="활성화" />
                        <Tab key="N" title="비활성화" />
                      </Tabs>
                    </FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>
                      상호 <span className="text-rose-500">*</span>
                    </FlexTableHeader>
                    <FlexTableData>
                      <Input
                        className="max-w-48"
                        name="bizName"
                        value={values.bizName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.bizName && touched.bizName}
                        errorMessage={errors.bizName && touched.bizName ? errors.bizName : undefined}
                      />
                    </FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>
                      사업자형태 <span className="text-rose-500">*</span>
                    </FlexTableHeader>
                    <FlexTableData>
                      <DynamicSelect
                        name="groupTypeCd"
                        selectedKey={values.groupTypeCd}
                        query={groupTypeQuery}
                        dataSelector={data => data.codeMap}
                        onChange={e => {
                          setFieldValue('groupTypeCd', e.target.value);
                        }}
                        isAll={false}
                        isInvalid={errors.groupTypeCd && touched.groupTypeCd}
                        errorMessage={errors.groupTypeCd && touched.groupTypeCd ? errors.groupTypeCd : undefined}
                      />
                    </FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>
                      사업자{'\n'}등록번호 <span className="text-rose-500">*</span>
                    </FlexTableHeader>
                    <FlexTableData>
                      <Input
                        name="bizNo"
                        className="max-w-48"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.bizNo}
                        isInvalid={errors.bizNo && touched.bizNo}
                        errorMessage={errors.bizNo && touched.bizNo ? errors.bizNo : undefined}
                      />
                    </FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>
                      대표자 <span className="text-rose-500">*</span>
                    </FlexTableHeader>
                    <FlexTableData>
                      <Input
                        name="bizCeo"
                        className="max-w-48"
                        value={values.bizCeo}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.bizCeo && touched.bizCeo}
                        errorMessage={errors.bizCeo && touched.bizCeo ? errors.bizCeo : undefined}
                      />
                    </FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>
                      주소 <span className="text-rose-500">*</span>
                    </FlexTableHeader>
                    <FlexTableData className="flex-col !items-start">
                      <div className="flex gap-2">
                        <Input
                          name="bizZipCode"
                          isReadOnly
                          maxLength={16}
                          size="sm"
                          type="text"
                          placeholder="우편번호(자동입력)"
                          onChange={handleChange}
                          value={values.bizZipCode}
                          isInvalid={touched.bizZipCode && !!errors.bizZipCode}
                          errorMessage={touched.bizZipCode && errors.bizZipCode}
                        />
                        <Button color="primary" size="sm" variant="flat" style={{ marginRight: '8px' }} onPress={onOpenAddress}>
                          주소찾기
                        </Button>
                      </div>
                      <Input
                        name="bizAddr1"
                        isReadOnly
                        size="sm"
                        type="text"
                        placeholder="도로명 주소(자동입력)"
                        onChange={handleChange}
                        value={values.bizAddr1}
                        isInvalid={touched.bizAddr1 && !!errors.bizAddr1}
                        errorMessage={touched.bizAddr1 && errors.bizAddr1}
                      />
                      <Input
                        name="bizAddr2"
                        size="sm"
                        type="text"
                        placeholder="상세주소를 입력해주세요."
                        onChange={handleChange}
                        value={values.bizAddr2}
                        onBlur={handleBlur}
                        isInvalid={touched.bizAddr2 && !!errors.bizAddr2}
                        errorMessage={touched.bizAddr2 && errors.bizAddr2}
                      />
                    </FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>전화번호</FlexTableHeader>
                    <FlexTableData>
                      <Input
                        name="bizTel"
                        onBlur={handleBlur}
                        onChange={e => {
                          let value = e.target.value.replace(/[^\d]/g, '');
                          setFieldValue('bizTel', value);
                        }}
                        value={formatUtil.getTel(values.bizTel)}
                      />
                    </FlexTableData>
                    <FlexTableHeader>팩스</FlexTableHeader>
                    <FlexTableData>
                      <Input
                        name="bizFax"
                        onBlur={handleBlur}
                        onChange={e => {
                          let value = e.target.value.replace(/[^\d]/g, '');
                          setFieldValue('bizFax', value);
                        }}
                        value={formatUtil.getTel(values.bizFax)}
                      />
                    </FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow></FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>이메일</FlexTableHeader>
                    <FlexTableData>
                      <Input
                        name="bizEmail"
                        value={values.bizEmail}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.bizEmail && touched.bizEmail}
                        errorMessage={errors.bizEmail && touched.bizEmail ? errors.bizEmail : undefined}
                      />
                    </FlexTableData>
                  </FlexTableRow>
                </FlexTable>
              </Tab>
              <Tab key="3" title="기사앱 설정">
                <FlexTable title={'운송'}>
                  <FlexTableRow>
                    <FlexTableHeader>운송완료 사진 업로드</FlexTableHeader>
                    <div>
                      <FlexTableData>
                        <span className="text-sm text-gray-400">상차지</span>
                        <Tabs selectedKey={values.pickupImgUpload} onSelectionChange={key => setFieldValue('pickupImgUpload', key)}>
                          <Tab key="REQUIRED" title="필수" />
                          <Tab key="OPTIONAL" title="선택" />
                          <Tab key="DENY" title="사용안함" />
                        </Tabs>
                      </FlexTableData>
                      <FlexTableData>
                        <span className="text-sm text-gray-400">하차지</span>
                        <Tabs fullWidth selectedKey={values.deliveryImgUpload} onSelectionChange={key => setFieldValue('deliveryImgUpload', key)}>
                          <Tab key="REQUIRED" title="필수" />
                          <Tab key="OPTIONAL" title="선택" />
                          <Tab key="DENY" title="사용안함" />
                        </Tabs>
                      </FlexTableData>
                    </div>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>운송완료 거리제한</FlexTableHeader>
                    <div className="flex-1">
                      <FlexTableData>
                        <span className="text-sm text-gray-400">상차지</span>
                        <Tabs selectedKey={values.pickupDistanceYn} onSelectionChange={key => setFieldValue('pickupDistanceYn', key)}>
                          <Tab key="Y" title="사용" />
                          <Tab key="N" title="미사용" />
                        </Tabs>
                        {values.pickupDistanceYn == 'Y' && (
                          <Input name="deliveryDistanceMeter" endContent={'미터'} className="max-w-24" value={values.deliveryDistanceMeter} onChange={handleChange} type="number" min="0" />
                        )}
                      </FlexTableData>
                      <FlexTableData>
                        <span className="text-sm text-gray-400">하차지</span>
                        <Tabs selectedKey={values.deliveryDistanceYn} onSelectionChange={key => setFieldValue('deliveryDistanceYn', key)}>
                          <Tab key="Y" title="사용" />
                          <Tab key="N" title="미사용" />
                        </Tabs>
                        {values.deliveryDistanceYn == 'Y' && (
                          <Input name="pickupDistanceMeter" endContent={'미터'} className="max-w-24" value={values.pickupDistanceMeter} onChange={handleChange} type="number" min="0" />
                        )}
                      </FlexTableData>
                    </div>
                  </FlexTableRow>
                </FlexTable>
              </Tab>
              <Tab key="4" title="관리자웹 설정">
                <FlexTable title={'운송비 내역서'}>
                  <FlexTableRow>
                    <FlexTableHeader>운송비 내역서 생성</FlexTableHeader>
                    <FlexTableData>
                      <Tabs selectedKey={values.jobCostCreateYn} onSelectionChange={key => setFieldValue('jobCostCreateYn', key)}>
                        <Tab key="Y" title="자동 생성" />
                        <Tab key="N" title="수동 생성" />
                      </Tabs>
                    </FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>정산서 생성일</FlexTableHeader>
                    <FlexTableData>
                      <Input
                        className="max-w-24"
                        name="jobCostCreateDay"
                        endContent={'일'}
                        value={values.jobCostCreateDay}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="number"
                        min="1"
                        max="31"
                      />
                    </FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>운송비 내역 산정 기간</FlexTableHeader>
                    <FlexTableData>
                      <div className="flex flex-col gap-1">
                        <div className="flex items-center gap-2">
                          <Input name="jobCostStartDay" className="max-w-24" endContent={'일'} value={values.jobCostStartDay} onChange={handleChange} onBlur={handleBlur} type="number" min="1" />
                          ~
                          <Input name="jobCostEndDay" className="max-w-24" endContent={'일'} value={values.jobCostEndDay} onChange={handleChange} onBlur={handleBlur} type="number" min="1" max="31" />
                        </div>
                        <span className="text-xs text-gray-400">31일이 없는 달은 자동으로 마지막 날로 설정됩니다.</span>
                      </div>
                    </FlexTableData>
                  </FlexTableRow>
                </FlexTable>
                <FlexTable title={'간편배차'}>
                  <FlexTableRow>
                    <FlexTableHeader>간편배차 상차지기준</FlexTableHeader>
                    <FlexTableData>
                      <Tabs selectedKey={values.shipmentDirection} onSelectionChange={key => setFieldValue('shipmentDirection', key)}>
                        <Tab key="OUTBOUND" title="상차지" />
                        <Tab key="INBOUND" title="하차지" />
                      </Tabs>
                    </FlexTableData>
                  </FlexTableRow>
                </FlexTable>
              </Tab>
            </Tabs>
          </ModalBody>
          <ModalFooter>
            <Button color="default" onPress={() => handleModalClose(false)}>
              닫기
            </Button>
            <Button
              color="primary"
              onPress={() => {
                validateForm().then(validationErrors => {
                  if (Object.keys(validationErrors).length > 0) {
                    setTouched(
                      Object.keys(validationErrors).reduce((touchedObj, key) => {
                        touchedObj[key] = true;
                        return touchedObj;
                      }, {})
                    );
                    callAlert('입력한 데이터가 올바르지 않습니다.');
                  } else {
                    requestConfirm({
                      message: '생성하시겠습니까?',
                      onConfirm: handleSubmit,
                    });
                  }
                });
              }}
              isDisabled={isSubmitting}
            >
              생성하기
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <ConfirmModal isOpen={confirmOpen} title={message} onConfirm={handleConfirm} onClose={handleClose} />
      <AlertModal />
      <ModalAddress
        isOpenAddress={isOpenAddress}
        onOpenChangeAddress={onOpenChangeAddress}
        onCloseAddress={onCloseAddress}
        onOpenAddress={onOpenAddress}
        setFieldValue={setFieldValue}
        addrName={'bizAddr1'}
        zipName={'bizZipCode'}
      />
    </>
  );
};

export default ModalAdd;
