import { Api } from '@/api/index';
import ContentWrapper from '@/components/ContentWrapper';
import CustomDateRangePicker from '@/components/CustomDateRagePicker';
import LoadingBackground from '@/components/LoadingBackground';
import ConfirmModal from '@/components/Modal/ConfirmModal';
import { AlertModal, useAlertModal } from '@/components/Modal/useAlertModal';
import PaginationTable from '@/components/PaginationTable';
import { status } from '@/constants/planStatus';
import { iconMd, iconSm } from '@/constants/size';
import useConfirmModal from '@/hooks/useConfirmModal';
import useCustomSearchParams from '@/hooks/useCustomSearchParams';
import useDebounce from '@/hooks/useDebounce';
import { ExcelDownloadUtil } from '@/utils/ExcelDownloadUtil';
import { formatUtil } from '@/utils/FormatUtil';
import { CalendarDate, endOfMonth, endOfWeek, getLocalTimeZone, parseDate, startOfMonth, startOfWeek, today } from '@internationalized/date';
import { Button, Chip, getKeyValue, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Radio, RadioGroup, Select, SelectItem, Spinner, useDisclosure } from '@nextui-org/react';
import { ArrowsClockwise, Copy, Lightning, PencilLine, Trash } from '@phosphor-icons/react';
import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ModalAdd from './ModalAdd';
import ModalUpdate from './ModalUpdate';
import ModalView from './ModalView';
import { PackageSelect } from '@/components/common/filter';
import { GroupSelect } from '@/components/common/filter';

const columns = [
  { name: 'No', uid: 'no' },
  { name: '스케줄번호', uid: 'jobUnitId' },
  { name: '일정유형', uid: 'jobRepeatId' },
  { name: '운송일자', uid: 'planDate' },
  { name: '상차지', uid: 'pickupPlaceName' },
  { name: '하차지', uid: 'deliveryPlaceName' },
  { name: '운송유형', uid: 'type' },
  { name: '운송물품', uid: 'jobPlanPackageName' },
  { name: '총 단가(원)', uid: 'price' },
  { name: `복사 / 수정 / 삭제`, uid: 'button' },
  { name: '등록일', uid: 'regDt' },
];
const search = [
  { value: 'pickupPlaceNameLike', label: '상차지' },
  { value: 'deliveryPlaceNameLike', label: '하차지' },
];

const type = {
  CARGO_DELIVERY: '화물운반',
  WASTE_COLLECT: '폐기물수거',
};

const columnWidths = [50, 100, 100, 150, 100, 100, 100, 150, 150, 150, 150, 150];

const excelCol = [
  { v: 'No', t: 's' },
  { v: '스케줄번호', t: 's' },
  { v: '일정유형', t: 's' },
  { v: '운송일자', t: 's' },
  { v: '상차지', t: 's' },
  { v: '하차지', t: 's' },
  { v: '운송유형', t: 's' },
  { v: '운송물품', t: 's' },
  { v: '총 단가(원)', t: 's', s: { alignment: { horizontal: 'right' } } },
  { v: '등록일', t: 's' },
];
const List = () => {
  const { isOpen: isOpenView, onOpen: onOpenView, onOpenChange: onOpenChangeView, onClose: onCloseView } = useDisclosure();
  const { isOpen: isOpenAdd, onOpen: onOpenAdd, onOpenChange: onOpenChangeAdd, onClose: onCloseAdd } = useDisclosure();
  const { isOpen: isOpenUpdate, onOpen: onOpenUpdate, onOpenChange: onOpenChangeUpdate, onClose: onCloseUpdate } = useDisclosure();
  const { isOpen: isOpenConfirm, onOpen: onOpenConfirm, onOpenChange: onOpenChangeConfirm, onClose: onCloseConfirm } = useDisclosure();

  const { confirmOpen, message, requestConfirm, handleConfirm, handleClose } = useConfirmModal();

  const { callAlert } = useAlertModal();
  const selectedId = useRef('');
  const copyId = useRef('');
  const queryClient = useQueryClient();

  const [searchParams] = useSearchParams();

  const locale = 'kr-KR';
  const defaultDate = today(getLocalTimeZone());
  const afterOneMonth = defaultDate.add({ months: 1 });
  const calendarDate = new CalendarDate(defaultDate.year, defaultDate.month, defaultDate.day);
  const startOfCurrentWeek = startOfWeek(calendarDate, locale);
  const endOfCurrentWeek = endOfWeek(calendarDate, locale);
  const startOfCurrentMonth = startOfMonth(calendarDate, locale);
  const endOfCurrentMonth = endOfMonth(calendarDate, locale);

  const initialParams = {
    pndGroupId: searchParams.get('pndGroupId') || '',
    type: searchParams.get('type') || '',
    jobPlanPackageId: searchParams.get('jobPlanPackageId') || '',
    repeatYn: searchParams.get('repeatYn') || '',
    dateStart: searchParams.get('dateStart') || String(defaultDate).replaceAll('-', ''),
    dateEnd: searchParams.get('dateEnd') || String(afterOneMonth).replaceAll('-', ''),
    searchType: searchParams.get('searchType') || 'pickupPlaceNameLike',
    searchText: searchParams.get('searchText') || '',
  };
  const { params, setParams, queryKey, onRowsPerPageChange, onPageChange, onInputChange } = useCustomSearchParams(initialParams);
  console.log(params.jobPlanPackageId);
  const debounce = useDebounce(params.searchText, 500);

  const [textLoading, setTextLoading] = useState(false);

  const [deleteType, setDeleteType] = useState('THIS');

  const handlChangeDate = (e, type) => {
    if (type === 'today') {
      setParams(prevParams => ({
        ...prevParams,
        dateStart: String(defaultDate).replaceAll('-', ''),
        dateEnd: String(defaultDate).replaceAll('-', ''),
      }));
    } else if (type === 'week') {
      setParams(prevParams => ({
        ...prevParams,
        dateStart: String(startOfCurrentWeek).replaceAll('-', ''),
        dateEnd: String(endOfCurrentWeek).replaceAll('-', ''),
      }));
    } else if (type === 'month') {
      setParams(prevParams => ({
        ...prevParams,
        dateStart: String(startOfCurrentMonth).replaceAll('-', ''),
        dateEnd: String(endOfCurrentMonth).replaceAll('-', ''),
      }));
    }

    setParams(prevParams => ({
      ...prevParams,
      pageNo: 1,
    }));
  };

  const getParams = (pageNo, pageSize) => {
    let postParams = { pageNo: pageNo, pageSize: pageSize };
    postParams.pndGroupId = params.pndGroupId;
    postParams.jobPlanPackageId = params.jobPlanPackageId;
    postParams.type = params.type;
    postParams.repeatYn = params.repeatYn;
    postParams.pndGroupId = params.pndGroupId;
    postParams.pndGroupId = params.pndGroupId;
    postParams.dateStart = params.dateStart;
    postParams.dateEnd = params.dateEnd;
    if (params.searchText) {
      postParams[params.searchType] = params.searchText;
    }
    return Object.fromEntries(Object.entries(postParams).filter(([, value]) => value));
  };

  useEffect(() => {
    if (debounce != params.searchText) {
      setTextLoading(true);
    } else {
      setTextLoading(false);
    }
  }, [debounce, params.searchText]);

  const fetchPlanList = () => {
    const trimParams = getParams(params.pageNo, params.pageSize);
    return Api.planList(trimParams);
  };

  const reloadList = () => {
    queryClient.invalidateQueries(['planList', { ...queryKey, searchText: debounce }]);
  };

  const reloadView = () => {
    queryClient.invalidateQueries(['plan', selectedId.current]);
  };

  const delPlan = useMutation({
    mutationFn: () => Api.planDel({ jobPlanId: selectedId.current, deleteType: deleteType }),
    onSuccess: () => {
      callAlert('처리되었습니다.');
      onCloseConfirm();
      onCloseView();
      reloadList();
    },
    onError: error => {
      console.error('Error adding user', error);
    },
  });

  const handlerDelete = () => {
    delPlan.mutate();
  };

  const { data: planQuery, isSuccess } = useQuery({
    queryKey: ['plan', copyId.current],
    queryFn: () => Api.planView({ jobPlanId: copyId.current }),
    select: res => res.data.data,
    enabled: !!copyId.current,
  });

  const addPlan = useMutation({
    mutationFn: values => Api.planAdd(values),
    onSuccess: () => {
      callAlert('처리되었습니다.');
      reloadList();
    },
    onError: error => {
      console.error('Error adding user', error);
    },
  });
  const planCopy = useMutation({
    mutationFn: id => Api.planCopy({ id: id }),
    onSuccess: () => {
      callAlert('처리되었습니다.');
      reloadList();
    },
    onError: error => {
      console.error('Error adding user', error);
    },
  });

  const handlePlanCopy = id => {
    planCopy.mutate(id, {
      onSuccess: () => {
        callAlert('처리되었습니다.');
        reloadList();
      },
      onError: error => {
        console.error('Error adding user', error);
      },
    });
  };

  const handleGroupFirstLoad = firstGroupId => {
    setParams(prevParams => ({
      ...prevParams,
      pndGroupId: firstGroupId,
    }));
  };

  useEffect(() => {
    if (isSuccess && planQuery?.jobPlan && copyId.current) {
      // 데이터가 준비되었을 때만 추가 실행

      const planData = planQuery?.jobPlan;
      let data = {
        groupId: planData.groupId,
        planDate: planData.planDate,
        jobUnitList: [
          {
            pickupPlaceId: planData.jobUnitList[0].pickupPlaceId,
            deliveryPlaceId: planData.jobUnitList[0].deliveryPlaceId,
            planSort: 1,
            jobPlanPackageId: planData.jobUnitList[0].jobPlanPackageId,
            price: planData.jobUnitList[0].price,
            price2: planData.jobUnitList[0].price2,
            type: planData.jobUnitList[0].type,
          },
        ],
      };

      if (planData?.jobRepeat?.frequency) {
        data.jobRepeatPost = {
          frequency: planData.jobRepeat.frequency,
          interval: planData.jobRepeat.interval,
          monthOfYear: planData.jobRepeat.monthOfYear,
          weekOfMonth: planData.jobRepeat.weekOfMonth,
          dayOfWeek: planData.jobRepeat.dayOfWeek,
          dayOfMonth: planData.jobRepeat.dayOfMonth,
          endType: planData.jobRepeat.endType,
          endCount: planData.jobRepeat.endCount,
          endDate: planData.jobRepeat.endDate,
        };
      }
      addPlan.mutate(data, {
        onSuccess: () => {
          callAlert('처리되었습니다.');
          reloadList();
        },
        onError: error => {
          console.error('Error adding user', error);
        },
      });
      copyId.current = null;
    }
  }, [planQuery?.jobPlan, isSuccess, copyId.current]); // 의존성 배열에 planQuery.data 추가

  const {
    data: planList,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ['planList', { ...queryKey, searchText: debounce }],
    queryFn: fetchPlanList,
    placeholderData: keepPreviousData,
    select: res => res.data.data,
    enabled: !!params.pndGroupId,
  });
  console.log(planList);

  const renderCell = useCallback(
    (item, columnKey, idx) => {
      const jobUnit = item.jobUnitList.length > 0 && item.jobUnitList[0];
      switch (columnKey) {
        case 'no': {
          const rowNumber = Number(planList?.jobPlanPage?.totalElements ? planList?.jobPlanPage?.totalElements : 0) - ((Number(params.pageNo) - 1) * Number(params.pageSize) + idx);
          return <div>{rowNumber}</div>;
        }
        case 'jobUnitId':
          return <div>{jobUnit.id}</div>;
        case 'jobRepeatId':
          return (
            <div>
              {item.jobRepeatId ? (
                <div className="flex items-center gap-1 font-medium text-success-500">
                  <ArrowsClockwise weight="fill"></ArrowsClockwise>
                  <span>반복</span>
                </div>
              ) : (
                <div className="flex items-center gap-1 font-medium text-zinc-400">
                  <Lightning weight="fill" />
                  <span>일회성</span>
                </div>
              )}
            </div>
          );
        case 'pickupPlaceName':
          return <div>{jobUnit.pickupPlaceName}</div>;

        case 'deliveryPlaceName':
          return <div>{jobUnit.deliveryPlaceName}</div>;
        case 'type':
          return (
            <Chip size="sm" variant="flat" className="text-default-500">
              {type[jobUnit.type]}
            </Chip>
          );
        case 'jobPlanPackageName':
          if (jobUnit?.jobPlanPackageName) {
            return (
              <Chip size="sm" variant="flat" className="text-default-500">
                {jobUnit.jobPlanPackageName}
              </Chip>
            );
          } else {
            return '-';
          }
        case 'price':
          return <div>{formatUtil.getNumber(Number(jobUnit.price || 0) + Number(jobUnit.price2 || 0))}</div>;
        case 'button':
          return (
            <div className="flex gap-2">
              <Button
                isIconOnly
                variant="faded"
                aria-label="plus"
                size="sm"
                onClick={() => {
                  requestConfirm({
                    message: '해당 스케줄을 복사 하시겠습니까?',
                    onConfirm: () => {
                      handlePlanCopy(item.id);
                    },
                  });
                }}
              >
                <Copy className="text-zinc-400" size={iconMd} />
              </Button>
              <Button
                isIconOnly
                variant="faded"
                aria-label="plus"
                size="sm"
                onClick={() => {
                  selectedId.current = item.id;
                  onOpenUpdate();
                }}
              >
                <PencilLine className="text-zinc-400" size={iconMd} />
              </Button>
              <Button
                isIconOnly
                variant="faded"
                aria-label="delete"
                size="sm"
                onClick={() => {
                  if (item.planStatus === 'READY' || item.planStatus === 'PICKUP' || item.planStatus === 'DELIVERY') {
                    callAlert(`${status[item.planStatus]}인 스케줄은 삭제가 불가합니다. 삭제를 원하시면 배차 취소 후 다시 시도 해주세요.`);
                    return;
                  }
                  selectedId.current = item.id;
                  if (item.jobRepeatId) {
                    onOpenConfirm();
                  } else {
                    requestConfirm({
                      message: '삭제 하시겠습니까?',
                      onConfirm: () => {
                        handlerDelete();
                      },
                    });
                  }
                }}
              >
                <Trash className="text-zinc-400" size={iconMd} />
              </Button>
            </div>
          );

        case 'planDate':
          return <div>{formatUtil.getDate(getKeyValue(item, columnKey), '-')}</div>;
        case 'regDt':
          return <div>{formatUtil.getDate(getKeyValue(item, columnKey), '-')}</div>;
        default:
          return getKeyValue(item, columnKey);
      }
    },
    [planList?.jobPlanPage?.totalElements, params.pageNo]
  );

  const handlerRowClick = item => {
    onOpenView();
    selectedId.current = item.id;
  };

  const handlerAddClick = () => {
    onOpenAdd();
  };

  const fetchPlanListForExcel = () => {
    const trimParams = getParams(params.pageNo, 1000000);
    return Api.planList(trimParams);
  };

  const excelDownload = useCallback(excelData => {
    const formattedData = excelData.map((data, idx) => {
      const jobUnit = data.jobUnitList.length > 0 && data.jobUnitList[0];
      return [
        jobUnit.id,
        data.jobRepeatId ? '반복' : '일회성',
        formatUtil.getDate(data.planDate, '-'),
        jobUnit.pickupPlaceName,
        jobUnit.deliveryPlaceName,
        type[jobUnit.type],
        jobUnit.jobPlanPackageName,
        formatUtil.getNumber(Number(jobUnit.price || 0) + Number(jobUnit.price2 || 0)),
        formatUtil.getDate(data.regDt, '-'),
      ];
    });

    ExcelDownloadUtil(formattedData, '스케줄목록', '스케줄목록', excelCol, columnWidths);
  }, []);

  const handleExcelDownload = async () => {
    try {
      const result = await fetchPlanListForExcel();
      if (result.data.code === 'SUCC') {
        excelDownload(result.data.data.jobPlanPage.content);
      }
    } catch (error) {
      console.error('Excel download failed', error);
    }
  };

  return (
    <ContentWrapper>
      {(addPlan.isPending || delPlan.isPending || isFetching) && <LoadingBackground />}

      <ModalAdd isOpenAdd={isOpenAdd} onOpenChangeAdd={onOpenChangeAdd} onCloseAdd={onCloseAdd} reloadList={reloadList} params={params} setParams={setParams}></ModalAdd>

      {isOpenView && (
        <ModalView
          isOpenView={isOpenView}
          onOpenChangeView={onOpenChangeView}
          onCloseView={onCloseView}
          selectedId={selectedId.current}
          reloadList={reloadList}
          onOpenView={onOpenView}
          onOpenUpdate={onOpenUpdate}
        ></ModalView>
      )}

      <ModalUpdate
        isOpenUpdate={isOpenUpdate}
        onOpenChangeUpdate={onOpenChangeUpdate}
        onCloseUpdate={onCloseUpdate}
        size="5xl"
        selectedId={selectedId.current}
        scrollBehavior={'outside'}
        onOpenView={onOpenView}
        reloadView={reloadView}
      />

      <ConfirmModal isOpen={confirmOpen} title={message} onConfirm={handleConfirm} onClose={handleClose} />
      <AlertModal />

      <Modal isDismissable={false} isOpen={isOpenConfirm} onOpenChange={onOpenChangeConfirm} size="xl">
        <ModalContent>
          {close => (
            <>
              <ModalHeader className="flex flex-col gap-1"> 일정을 삭제할까요?</ModalHeader>
              <ModalBody>
                <RadioGroup value={deleteType} onValueChange={setDeleteType}>
                  <Radio value="THIS">이 일정만 삭제합니다.</Radio>
                  <Radio value="AFTER">이 일정과 앞으로의 일정을 삭제합니다.</Radio>
                </RadioGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="default" className="ml-auto" onPress={onCloseConfirm}>
                  닫기
                </Button>
                <Button
                  color="primary"
                  onPress={() => {
                    handlerDelete();
                  }}
                >
                  확인
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

      <div className="flex flex-col gap-4 p-4 mb-4 bg-opacity-75 border bg-default-50 border-default-100 rounded-medium">
        <div className="flex flex-wrap gap-4">
          <GroupSelect label="운반회사" selectedKey={params.pndGroupId} onChange={onInputChange} name="pndGroupId" onFirstLoad={handleGroupFirstLoad} />
          <Select
            size="sm"
            labelPlacement="outside"
            label="일정유형"
            placeholder=" "
            disallowEmptySelection
            className="max-w-48"
            selectedKeys={[String(params.repeatYn)]}
            onChange={onInputChange}
            name="repeatYn"
          >
            <SelectItem key="">전체</SelectItem>
            <SelectItem key="Y">반복</SelectItem>
            <SelectItem key="N">일회성</SelectItem>
          </Select>

          <Select size="sm" labelPlacement="outside" placeholder=" " disallowEmptySelection label="운송유형" className="max-w-48" selectedKeys={[params.type]} onChange={onInputChange} name="type">
            <SelectItem key="">전체</SelectItem>
            <SelectItem key="WASTE_COLLECT">폐기물수거</SelectItem>
            <SelectItem key="CARGO_DELIVERY">화물운반</SelectItem>
          </Select>
          <PackageSelect groupId={params.pndGroupId} name="jobPlanPackageId" selectedKey={String(params.jobPlanPackageId)} onChange={onInputChange}></PackageSelect>
          <CustomDateRangePicker
            setParams={setParams}
            label="운송일자"
            value={{
              start: parseDate(formatUtil.getDate(params.dateStart, '-')),
              end: parseDate(formatUtil.getDate(params.dateEnd, '-')),
            }}
          />
        </div>
        <div className="flex gap-4">
          <Input
            labelPlacement="outside"
            label="검색"
            startContent={
              <Select
                disallowEmptySelection
                className="w-32 shrink-0"
                classNames={{
                  base: 'h-full items-center flex-row -mx-2',
                  trigger: '!bg-transparent !shadow-none h-full min-h-min',
                  mainWrapper: 'h-full',
                }}
                selectedKeys={[params.searchType]}
                items={search}
                onChange={onInputChange}
                name="searchType"
              >
                {item => <SelectItem key={item.value}>{item.label}</SelectItem>}
              </Select>
            }
            size="sm"
            className="w-full"
            placeholder="검색어를 입력하세요"
            onChange={onInputChange}
            value={params.searchText}
            name="searchText"
            endContent={textLoading && <Spinner size="sm" color="primary" />}
          />
        </div>
      </div>

      {planList?.isLoading ? (
        <LoadingBackground />
      ) : (
        <PaginationTable
          data={{
            page: params.pageNo,
            size: params.pageSize,
            totalPages: planList?.jobPlanPage.totalPages,
            totalElements: planList?.jobPlanPage.totalElements,
            columns: columns,
            items: planList?.jobPlanPage.content,
          }}
          renderCell={renderCell}
          isFetching={isFetching}
          isLoading={isLoading}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          onRowClick={handlerRowClick}
          onAddClick={handlerAddClick}
          onExelDownload={handleExcelDownload}
        />
      )}
    </ContentWrapper>
  );
};

export default List;
