import { Api } from '@/api';
import { FlexTable, FlexTableData, FlexTableHeader, FlexTableRow } from '@/components/FlexTable';
import ConfirmModal from '@/components/Modal/ConfirmModal';
import { AlertModal, useAlertModal } from '@/components/Modal/useAlertModal';
import useConfirmModal from '@/hooks/useConfirmModal';
import { formatUtil } from '@/utils/FormatUtil';
import { parseDate } from '@internationalized/date';
import { Button, DatePicker, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Select, SelectItem, Tab, Tabs, Textarea } from '@nextui-org/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';

const ModalUpdate = ({ isOpenUpdate, onOpenChangeUpdate, onCloseUpdate, reloadList, selectedId, onOpenView }) => {
  const queryClient = useQueryClient();
  const { callAlert } = useAlertModal();
  const reloadTerms = () => {
    queryClient.invalidateQueries(['driverTerms', selectedId]);
  };

  const { confirmOpen, message, requestConfirm, handleConfirm, handleClose } = useConfirmModal();

  const groupQuery = useQuery({
    queryKey: ['groups'],
    queryFn: () => Api.groups(),
    select: res => res.data.data,
  });

  const termsQuery = useQuery({
    queryKey: ['driverTerms', selectedId],
    queryFn: () => Api.driverTermsView({ id: selectedId }),
    select: res => res.data.data,
    enabled: !!selectedId,
  });

  const { data: codeMapData } = useQuery({
    queryKey: ['codeMap', 'TERMS_TYPE'],
    queryFn: () => Api.codeMap({ cate: 'TERMS_TYPE' }),
    select: res => res.data.data,
    enabled: !!selectedId,
  });

  const termsData = termsQuery.data?.driverTerms;
  const codeMap = codeMapData?.codeMap;

  const roleQuery = useQuery({
    queryKey: ['roles'],
    queryFn: () => Api.roles(),
    select: res => res.data.data,
  });

  const updateTerms = useMutation({
    mutationFn: values => Api.driverTermsUpdate(values),
  });

  const handleClosePage = () => {
    onCloseUpdate();
  };

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, validateForm, resetForm, setValues } = useFormik({
    initialValues: {
      id: termsData?.id || '',
      pndGroupId: termsData?.pndGroupId || '',
      termsTypeCode: termsData?.termsTypeCode || '',
      termsTitle: termsData?.termsTitle || '',
      termsContent: termsData?.termsContent || '',
      termsDate: termsData?.termsDate ? parseDate(formatUtil.getDate(termsData?.termsDate, '-')) : null,
      showYn: termsData?.showYn || '',
    },
    validationSchema: Yup.object({
      termsTitle: Yup.string().required('제목을 입력하세요.'),
      termsContent: Yup.string().required('내용을 입력하세요.'),
      // termDate: Yup.string().required('날짜를 선택하세요.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      let data = { ...values };
      data.termsDate = String(data.termsDate).replaceAll('-', '');
      updateTerms.mutate(data, {
        onSettled: () => {
          setSubmitting(false);
        },
        onSuccess: () => {
          callAlert('수정되었습니다.', () => {
            onCloseUpdate();
            reloadTerms();
            onOpenView();
          });
        },
        onError: error => {
          console.error('수정 중 오류가 발생했습니다:', error);
          callAlert('수정 중 오류가 발생했습니다.');
        },
      });
    },
  });

  useEffect(() => {
    if (values.adminTel) {
      const value = values.adminTel.replace(/\D/g, '');
      setFieldValue('adminTel', formatUtil.getTel(value));
    }
  }, [values.adminTel, setFieldValue]);

  const handleRegisterClick = async () => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      requestConfirm({
        message: '수정 하시겠습니까?',
        onConfirm: handleSubmit,
      });
    } else {
      callAlert('입력한 데이터가 올바르지 않습니다.');
    }
  };

  useEffect(() => {
    if (!isOpenUpdate) {
      resetForm();
    } else if (termsQuery.data) {
      setValues({
        id: termsData?.id || '',
        pndGroupId: termsData?.pndGroupId || '',
        termsTypeCode: termsData?.termsTypeCode || '',
        termsTitle: termsData?.termsTitle || '',
        termsContent: termsData?.termsContent || '',
        termsDate: termsData?.termsDate ? parseDate(formatUtil.getDate(termsData?.termsDate, '-')) : null,
        showYn: termsData?.showYn || '',
      });
    }
  }, [isOpenUpdate, termsQuery.data, resetForm, setValues]);

  useEffect(() => {}, [termsData?.termsDate]);

  return (
    <>
      <Modal isDismissable={false} isOpen={isOpenUpdate} onOpenChange={onOpenChangeUpdate} size="5xl" scrollBehavior={'outside'}>
        <ConfirmModal isOpen={confirmOpen} title={message} onConfirm={handleConfirm} onClose={handleClose} />
        <ModalContent>
          <form
            onSubmit={event => {
              event.preventDefault();
              handleSubmit();
            }}
          >
            <ModalHeader>약관관리 수정</ModalHeader>
            <ModalBody>
              <FlexTable>
                <FlexTableRow>
                  <FlexTableHeader>운반회사</FlexTableHeader>
                  <FlexTableData>
                    <Select
                      name="pndGroupId"
                      aria-label="pndGroupId"
                      size="md"
                      isDisabled={true}
                      onChange={handleChange}
                      selectedKeys={values.pndGroupId ? [values.pndGroupId.toString()] : []}
                      disallowEmptySelection
                    >
                      {groupQuery.data?.groupList?.map(item => (
                        <SelectItem key={item.id}>{item.name}</SelectItem>
                      ))}
                    </Select>
                  </FlexTableData>
                </FlexTableRow>
                <FlexTableRow>
                  <FlexTableHeader>노출상태</FlexTableHeader>
                  <FlexTableData>
                    <Tabs
                      fullWidth
                      selectedKey={String(values.showYn)}
                      onSelectionChange={e => {
                        if (e !== values.showYn) {
                          setFieldValue('showYn', e);
                        }
                      }}
                    >
                      <Tab key="Y" title="ON" />
                      <Tab key="N" title="OFF" />
                    </Tabs>
                  </FlexTableData>
                </FlexTableRow>

                <FlexTableRow>
                  <FlexTableHeader>약관유형</FlexTableHeader>
                  <FlexTableData>
                    <Tabs
                      fullWidth
                      selectedKey={String(values.termsTypeCode)}
                      onSelectionChange={e => {
                        if (e !== values.termsTypeCode) {
                          setFieldValue('termsTypeCode', e);
                        }
                      }}
                    >
                      {codeMap && Object.keys(codeMap).map(key => <Tab key={key} title={codeMap[key]} />)}
                    </Tabs>
                  </FlexTableData>
                </FlexTableRow>
                <FlexTableRow>
                  <FlexTableHeader>제목</FlexTableHeader>
                  <FlexTableData>
                    <Input
                      name="termsTitle"
                      labelPlacement="outside"
                      placeholder="제목을 입력하세요"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.termsTitle}
                      isInvalid={touched.termsTitle && !!errors.termsTitle}
                      errorMessage={touched.termsTitle && errors.termsTitle}
                    ></Input>
                  </FlexTableData>
                </FlexTableRow>
                <FlexTableRow>
                  <FlexTableHeader>내용</FlexTableHeader>
                  <FlexTableData>
                    <Textarea
                      name="termsContent"
                      onChange={handleChange}
                      placeholder="내용을 입력해주세요"
                      value={values.termsContent}
                      onBlur={handleBlur}
                      isInvalid={touched.termsContent && !!errors.termsContent}
                      errorMessage={touched.termsContent && errors.termsContent}
                      type="text"
                    />
                  </FlexTableData>
                </FlexTableRow>
                <FlexTableRow>
                  <FlexTableHeader>약관 시작일</FlexTableHeader>
                  <FlexTableData>
                    <DatePicker
                      name="termsDate"
                      aria-label="termsDate"
                      onBlur={handleBlur}
                      className=""
                      classNames={{
                        base: "data-[type='literal']:before:content-['-'] data-[type='literal']:invisible data-[type='literal']:before:visible data-[type='literal']:last-of-type:hidden",
                      }}
                      value={values.termsDate}
                      onChange={date => setFieldValue('termsDate', date)}
                      isInvalid={touched.termsDate && !!errors.termsDate}
                      errorMessage={touched.termsDate && errors.termsDate}
                    ></DatePicker>
                  </FlexTableData>
                </FlexTableRow>
              </FlexTable>
            </ModalBody>
            <ModalFooter>
              <Button color="default" className="ml-auto" onPress={handleClosePage}>
                닫기
              </Button>
              <Button color="primary" isDisabled={!termsQuery.data} onPress={handleRegisterClick}>
                확인
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
      <AlertModal />
    </>
  );
};

export default ModalUpdate;
