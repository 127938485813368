import { getAssetPath } from '@/utils/getAssetPath';
import Privacy from '@/assets/images/privacy/privacy_1.svg?react';
import SensitiveData from '@/assets/images/privacy/sensitiveData_2.svg?react';
import HoldingPeriod from '@/assets/images/privacy/holdingPeriod_3.svg?react';
import PurposeProcessing from '@/assets/images/privacy/purposeProcessing_4.svg?react';
import ThirdPartyProvision from '@/assets/images/privacy/thirdPartyProvision_5.svg?react';
import ComplaintResolution from '@/assets/images/privacy/complaintResolution_6.svg?react';
const PrivacyPolicyContent = () => {
  return (
    <div className="docs">
      <h1>개인정보 처리방침</h1>
      <p>
        브이유에스는 정보주체의 자유와 권리 보호를 위해 「개인정보 보호법」 및 관계 법령이 정한 바를 준수하여, 적법하게 개인정보를 처리하고 안전하게 관리하고 있습니다. 이에 「개인정보 보호법」
        제30조에 따라 정보주체에게 개인정보 처리에 관한 절차 및 기준을 안내하고, 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.
      </p>
      <table className="w-full">
        <colgroup>
          <col width={'33%'} />
          <col width={'33%'} />
          <col width={'33%'} />
        </colgroup>
        <tbody>
          <tr>
            <td>
              <Privacy className="mx-auto my-4" />
            </td>
            <td>
              <SensitiveData className="mx-auto my-4" />
            </td>
            <td>
              <HoldingPeriod className="mx-auto my-4" />
            </td>
          </tr>
          <tr>
            <th>일반 개인정보 수집</th>
            <th>2개의 민감정보 수집</th>
            <th>개인정보의 보유 기간</th>
          </tr>
          <tr>
            <td>
              <PurposeProcessing className="mx-auto my-4" />
            </td>
            <td>
              <ThirdPartyProvision className="mx-auto my-4" />
            </td>
            <td>
              <ComplaintResolution className="mx-auto my-4" />
            </td>
          </tr>
          <tr>
            <th>개인정보 처리 목적</th>
            <th>개인정보의 제공</th>
            <th>고충처리부서</th>
          </tr>
        </tbody>
      </table>
      <small className='hidden'>
        ※ 기호에 마우스 커서를 대시면 세부 사항을 확인할 수 있으며, 자세한 내용은 아래의 개인정보 처리방침을 확인하시기 바랍니다. 브이유에스 개인정보 처리방침은 브이유에스에서 제공하는 서비스에
        적용합니다. 단, 특정 브이유에스 서비스에서 개별적으로 개인정보 처리방침을 운영하는 경우 그에 따릅니다.
      </small>
      <h3>1. 개인정보 수집</h3>
      <p>서비스 제공을 위해 반드시 필요한 개인정보를 수집합니다.</p>
      <p>
        서비스 제공을 위해 필요한 최소한의 정보를 필수항목으로 하고, 그 외 특화된 서비스를 제공하기 위해 필요한 정보는 선택항목으로 동의를 받고 있으며, 선택항목에 동의하지 않는 경우에도 서비스 이용
        제한은 없습니다.
      </p>
      <h4>[관리자 등록시]</h4>
      <ul>
        <li>필수 항목: 담당자 명, 소속 회사, 담당자 연락처, 담당자 이메일</li>
      </ul>
      <h4>[기사 등록시]</h4>
      <ul>
        <li>필수 항목: 기사명, 핸드폰 번호, 차량번호</li>
      </ul>
      <p>일부 서비스에서는 특화된 여러 기능을 제공하기 위해 이용자에게 동의를 받고 추가적인 개인정보를 수집할 수 있습니다.</p>
      <h4>[기사용 앱]</h4>
      <p>원활한 기사앱 사용을 위해 필요한 정보 수집에 이용자의 동의를 구하며, 이를 통해 아래와 같은 항목의 정보를 수집하게 됩니다.</p>
      <ul>
        <li>실시간 위치정보</li>
      </ul>
      <p>개인정보는 아래의 방법으로 수집합니다. 개인정보를 수집하기 위해서는 사전에 이용자에게 개인정보 수집에 대한 사실을 알리고 동의를 구한 후에 아래와 같은 방법으로 수집합니다.</p>
      <ul>
        <li>회원가입 또는 서비스 이용 과정에서 이용자가 개인정보 수집에 대해 동의하고 직접 정보를 입력</li>
        <li>제휴 또는 기업, 단체 등으로부터 개인정보를 제공받는 경우</li>
        <li>서비스 이용 상담 및 문의 과정에서 전화, 메일, 팩스, 웹페이지</li>
      </ul>
      <p>
        서비스 이용 중 단말기 정보, IP주소, 쿠키 등의 이용 환경 정보와 서비스 이용 내역 등의 정보가 자동으로 생성되어 수집될 수 있습니다. 서비스 이용 내역에는 이용자가 입력한 정보, 위치 정보 등이
        포함될 수 있습니다. 서비스 이용 내역은 이용환경 정보와 서비스 이용 내역은 서비스 이용 과정에서 자동화된 방법으로 생성되거나 이용자가 직접 입력한 정보가 송수신되면서 브이유에스 서버에 자동으로
        기록 및 수집될 수 있는 정보로 다른 정보와의 결함 여부, 처리하는 방식 등에 따라 개인정보에 해당할 수 있고, 개인정보에 해당하지 않을 수도 있습니다.
      </p>
      <h3>2. 개인정보의 처리 목적</h3>
      <p>회원제 서비스 회원 관리, 서비스 제공⋅개선, 신규 서비스 개발 등을 위해 이용합니다. </p>
      <ul>
        <li>회원 가입 의사 확인, 회원 식별⋅인증, 회원자격 유지⋅관리</li>
        <li>각종 고지⋅통지, 문의사항 또는 불만⋅고충 처리</li>
        <li>계정 도용 및 서비스 부정 이용 방지 및 제재</li>
        <li>신규 서비스 개발 및 서비스 기능 개선, 개인정보 보호를 위한 서비스 환경 구축</li>
        <li>서비스 이용 기록, 접속 빈도 및 서비스 이용 통계</li>
      </ul>
      <p>개인정보의 추가적인 이용⋅제공을 하는 경우가 있습니다.</p>
      <p>
        법령에 따라 수집 목적과 합리적으로 관련된 범위 내에서는 이용자의 동의 없이 개인정보를 이용하거나 제3자에게 제공할 수 있습니다. 이때 &apos;수집 목적의 관련성, 수집 정황 또는 처리 관행에 비추어
        개인정보의 추가적인 이용 또는 제공에 대한 예측 가능성이 있는지, 이용자 이익을 침해하는지, 가명처리 또는 암호화 등 개인정보 보호를 위해 필요한 조치를 취하였는지&apos;를 종합적으로 고려합니다.
      </p>
      <p>
        브이유에스는 수집한 개인정보를 특정 개인으로 확인할 수 없도록 가명처리하여 통계작성, 관련 연구, 공익적 기록 보존 등을 위하여 처리할 수 있습니다. 이 때 가명정보는 식별되지 않도록 추가 정보와
        분리하여 저장⋅관리하고 필요한 기술적⋅관리적 보호조치를 취합니다.
      </p>
      <p>가명정보 처리 상황이 발생하는 경우 추가로 개인정보처리방침을 통해 고지합니다.</p>
      <h3>3. 개인정보의 제3자 제공</h3>
      <ol>
        <li>브이유에스는 이용자의 별도 동의가 있거나 법령에 규정된 경우를 제외하고 이용자의 개인정보를 제3자에게 제공하지 않습니다.</li>
        <li>
          브이유에스는 이용자의 사전 동의 없이 개인정보를 제3자에게 제공하지 않습니다. 단, 서비스 제휴 등 필요한 범위내에서 제3자에게 개인정보를 제공해야 하는 경우 이용자의 동의를 받아 제공할 수
          있습니다.
        </li>
        <li>브이유에스 개인정보 제3자 제공이 발생하는 경우 추가로 개인정보처리방침을 통해 고지합니다.</li>
        <li>브이유에스는 재난, 감염병, 급박한 생명⋅신체 위험을 초래하는 사건·사고, 급박한 재산 손실 등의 긴급 상황이 발생하는 경우 정보 주체의 동의 없이 관계기관에 개인정보를 제공할 수 있습니다.</li>
      </ol>
      <h3>4. 개인정보 처리의 위탁</h3>
      <p>브이유에스는 서비스 제공을 위해 업무를 위탁하고 있지 않습니다.</p>
      <p>브이유에스는 서비스 제공을 위해 업무를 위탁하고 있지 않으나, 더 나은 서비스 제공을 위해 필요한 경우 이용자의 동의를 받아 개인정보 처리 업무 중 일부를 외부에 위탁할 수 있습니다.</p>
      <p>브이유에스 개인정보 처리의 위탁이 발생하는 경우 추가로 개인정보처리방침을 통해 고지합니다.</p>
      <h3>5. 개인정보 파기</h3>
      <p>개인정보 수집 및 이용 목적이 달성된 경우 수집한 개인정보는 지체 없이 파기합니다.</p>
      <p>
        개인정보 보유기간의 경과, 처리 목적 달성 등 개인정보 파기 사유가 발생하는 경우 개인정보의 보관 형태에 따른 파기 방법에 따라 처리합니다. 전자적 파일 형태로 기록⋅저장된 개인정보는 기록을 재생할
        수 없도록 파기하며, 종이 문서에 기록⋅저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.
      </p>
      <p>브이유에스는 1년간 서비스를 이용하지 않은 이용자의 정보를 별도로 분리 보관 또는 삭제하고 있으며, 분리 보관된 개인정보는 4년간 보관 후 지체 없이 파기합니다.</p>
      <p>이외 관계 법령에 따른 개인정보 보관 정보는 아래와 같습니다.</p>
      <table>
        <thead>
          <tr>
            <th scope="col">보존항목</th>
            <th scope="col">근거법령</th>
            <th scope="col">보존기간</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div>계약 또는 청약철회 등에 관한 기록</div>
            </td>
            <td rowSpan="4">
              <div>전자상거래 등에서의 소비자 보호에 관한 법률</div>
            </td>
            <td>
              <div>5년</div>
            </td>
          </tr>
          <tr>
            <td>
              <div>대금결제 및 재화 등의 공급에 관한 기록</div>
            </td>
            <td>
              <div>5년</div>
            </td>
          </tr>
          <tr>
            <td>
              <div>소비자의 불만 또는 분쟁처리에 관한 기록</div>
            </td>
            <td>
              <div>3년</div>
            </td>
          </tr>
          <tr>
            <td>
              <div>표시/광고에 관한 기록</div>
            </td>
            <td>
              <div>6개월</div>
            </td>
          </tr>
          <tr>
            <td>
              <div>세법이 규정하는 모든 거래에 관한 장부 및 증빙서류</div>
            </td>
            <td>
              <div>국세기본법</div>
            </td>
            <td>
              <div>5년</div>
            </td>
          </tr>
          <tr>
            <td>
              <div>전자금융 거래에 관한 기록</div>
            </td>
            <td>
              <div>전자금융거래법</div>
            </td>
            <td>
              <div>5년</div>
            </td>
          </tr>
          <tr>
            <td>
              <div>서비스 방문 기록</div>
            </td>
            <td>
              <div>통신비밀보호법</div>
            </td>
            <td>
              <div>3개월</div>
            </td>
          </tr>
          <tr>
            <td>
              <div>고객확인 및 의심되는 거래의 보고 등에 관한 기록</div>
            </td>
            <td>
              <div>특정 금융거래정보의 보고 및 이용 등에 관한 법률</div>
            </td>
            <td>
              <div>서비스 탈퇴일로부터 5년</div>
            </td>
          </tr>
          <tr>
            <td>
              <div>공인전자주소를 통한 전자문서 유통에 관한 기록</div>
            </td>
            <td>
              <div>전자문서 및 전자거래 기본법</div>
            </td>
            <td>
              <div>10년</div>
            </td>
          </tr>
        </tbody>
      </table>
      <p></p>
      <h3>6. 개인위치정보의 처리</h3>
      <ul>
        <li>
          <span>브이유에스는 위치정보의 보호 및 이용 등에 관한 법률(이하 &apos;위치정보법&apos;)에 따라 아래와 같이 개인위치정보를 처리합니다.</span>
        </li>
        <li>
          <span>서비스의 제공을 위해 개인정보를 보유할 수 있습니다.</span>
        </li>
        <li>
          <span>개인위치정보의 수집 및 이용 목적이 달성되면 지체 없이 파기합니다. </span>
        </li>
        <li>
          <span>이용자의 사전동의 없이 개인위치정보를 제3자에게 제공하지 않습니다.</span>
        </li>
        <li>
          <span>개인위치정보 처리와 관련된 자세한 내용은 브이유에스 위치정보 이용약관을 참고하시기 바랍니다.</span>
        </li>
      </ul>
      <h3>7. 이용자 법정대리인의 권리와 행사방법</h3>
      <p>이용자는 자신의 개인정보 처리에 관하여 아래와 같은 권리를 가질 수 있습니다.</p>
      <ul>
        <li>
          <span>개인정보 열람</span>
        </li>
        <li>
          <span>개인정보 정정</span>
        </li>
        <li>
          <span>개인정보 처리 정지</span>
        </li>
        <li>
          <span>개인정보 삭제 및 동의 철회/탈퇴</span>
        </li>
      </ul>
      <p>브이유에스는 이용자의 요청을 받는 경우 이를 지체 없이 처리하고, 이용자에게 결과를 통보합니다.</p>
      <h3>8. 개인정보 자동 수집 장치에 관한 사항</h3>
      <p>웹 기반 서비스 제공에 따라 쿠키를 설치 운영할 수 있습니다.</p>
      <p>
        이용자에게 편리하고 빠른 웹 기반 서비스 제공을 위해 쿠키를 사용합니다. 쿠키는 웹사이트를 운영하는 데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에 보내는 소량의 정보이며 이용자들의 PC
        컴퓨터 내의 하드디스크에 저장되기도 합니다.
      </p>
      <p>쿠키 사용 목적 </p>
      <p>개인화 서비스를 제공하기 위해서 이용자의 정보를 저장하고 불러오는 쿠키를 사용합니다.</p>
      <p>이용자가 관리자 웹사이트 서버에 접속하는 경우 서버는 이용자의 단말기에 저장되어 있는 쿠키를 읽어 이용자의 환경설정을 유지하고 맞춤화된 서비스를 제공할 수 있습니다. </p>
      <p>쿠키 수집 거부</p>
      <p>
        이용자는 쿠키 설치에 대한 선택권을 가지고 있으며, 사용하는 웹 브라우저 도구&gt;인터넷 옵션&gt;개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부할 수 있습니다. 쿠키 저장을 거부할 경우 로그인이
        필요한 일부 서비스 이용이 어려울 수 있습니다.
      </p>
      <h3>9. 개인정보의 안전성 확보 조치에 관한 사항</h3>
      <p>개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다. </p>
      <ul>
        <li>
          <span>관리적 조치: 내부 관리계획 수립·시행하고, 전담 조직 운영, 정기적 직원 교육을 실시합니다.</span>
        </li>
        <li>
          <span>기술적 조치: 개인정보 처리시스템 등의 접근권한 관리, 개인정보의 암호화, 보안프로그램 설치 및 갱신을 시행합니다.</span>
        </li>
      </ul>
      <h3>10. 개인정보 보호 책임자</h3>
      <p>브이유에스는 이용자의 개인정보 관련 문의사항 및 불만 처리 등을 위하여 개인정보 보호 책임자를 지정하고 있습니다.</p>
      <p>개인정보 보호 책임자</p>
      <ul>
        <li>
          <span>책임자 : 박범호(개인정보보호 책임자/위치정보관리책임자)</span>
        </li>
        <li>
          <span>소속 : 기술연구소</span>
        </li>
        <li>
          <span>문의 : 02-2138-0960, contact@withvus.ai</span>
        </li>
      </ul>
      <p>개인정보보호 관련 문의 및 고충 등 의견이 있는 경우 위 연락처로 접수해 주시면, 신속하게 검토하여 답변드리겠습니다.</p>
      <p>개인정보 관련 신고나 상담이 필요한 경우에는 아래 기관을 통하여 도움 받을 수 있습니다.</p>
      <p>개인정보분쟁조정위원회</p>
      <ul>
        <li>
          <span>1833-6972</span>
        </li>
        <li>
          <span>
            <a href="https://www.kopico.go.kr">https://www.kopico.go.kr</a>
          </span>
        </li>
      </ul>
      <p>개인정보침해신고센터</p>
      <ul>
        <li>
          <span>118</span>
        </li>
        <li>
          <span>
            <a href="https://privacy.kisa.or.kr">https://privacy.kisa.or.kr</a>
          </span>
        </li>
      </ul>
      <p>대검찰청</p>
      <ul>
        <li>
          <span>1301</span>
        </li>
        <li>
          <span>
            <a href="https://www.spo.go.kr">https://www.spo.go.kr</a>
          </span>
        </li>
      </ul>
      <p>경찰청</p>
      <ul>
        <li>
          <span>182</span>
        </li>
        <li>
          <span>
            <a href="https://ecrm.police.go.kr">https://ecrm.police.go.kr</a>
          </span>
        </li>
      </ul>
      <p>11. 개정 전 고지의무 </p>
      <p>서비스 또는 법령의 변경 사항을 반영하기 위한 목적 등으로 개인정보처리방침을 수정할 수 있습니다. 개인정보 처리방침 변경 최소 7일 전 변경 사항을 사전에 안내합니다.</p>
      <p>브이유에스는&nbsp;이용자의 개인정보를 소중히 여기며, 안심하고 서비스를 이용할 수 있도록 최선을 다하겠습니다.</p>
      <ul>
        <li>
          <span>공고일자: 2024년 8월 8일</span>
        </li>
        <li>
          <span>시행일자: 2024년 8월 8일 </span>
        </li>
      </ul>
      <p>시행일자 기준</p>
      <p>2024년 8월 8일</p>
    </div>
  );
};

export default PrivacyPolicyContent;
