import { Api } from '@/api';
import LoadingBackground from '@/components/LoadingBackground';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ScrollShadow,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  useDisclosure,
} from '@nextui-org/react';
import { useMutation, useQueries, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/zoom';

import { CusChip } from '@/components/common/customChip';
import DynamicIcon from '@/components/DynamicIcon';
import { FlexTable, FlexTableData, FlexTableHeader, FlexTableRow } from '@/components/FlexTable';
import { formatUtil } from '@/utils/FormatUtil';
import ModalView from './ModalView';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Zoom, Navigation, Pagination } from 'swiper/modules';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { AnimatePresence } from 'framer-motion';
import { motion } from 'framer-motion';
import { AlertModal, useAlertModal } from '@/components/Modal/useAlertModal';

const ModalWeightConfirm = ({ params, isOpenWeightConfirm, onOpenWeightConfirm, onOpenChangeWeightConfirm, onCloseWeightConfirm, reloadList }) => {
  const { isOpen: isOpenView, onOpen: onOpenView, onOpenChange: onOpenChangeView, onClose: onCloseView } = useDisclosure();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [weight, setWeight] = useState(0);
  const queryClient = useQueryClient();
  const { callAlert } = useAlertModal();

  const jobOcrTargetListQuery = useQuery({
    queryKey: ['jobOcrTargetList', { ...params, ocrTargetYn: 'Y' }],
    queryFn: () => Api.jobList({ ...params, ocrTargetYn: 'Y' }),
    enabled: !!params.pndGroupId,
    select: res =>
      res.data.data.jobUnitPage.content.map((item, idx) => {
        item.no = idx;
        return item;
      }),
  });

  const currentData = jobOcrTargetListQuery.data ? jobOcrTargetListQuery.data[currentIndex] : null;

  const currentJobWeightImageListQuery = useQuery({
    queryKey: ['currentJobWeightImageListQuery', { pndGroupId: params.pndGroupId, jobUnitIds: currentData?.id }],
    queryFn: () => Api.jobWeightImageList({ pndGroupId: params.pndGroupId, jobUnitIds: currentData?.id }),
    enabled: !!currentData,
    select: res => res.data.data.jobUnitWeightImagePage.content,
  });

  const currentJobWeightImageData = currentJobWeightImageListQuery.data ? currentJobWeightImageListQuery.data[currentImageIndex] : null;

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, validateForm, resetForm } = useFormik({
    initialValues: {
      jobUnitWeightImageId: null,
      weight: 0,
    },
    onSubmit: values => {
      console.log(values);
      confirmMutation.mutate(
        { ...values },
        {
          onError: error => {
            if (error?.response?.data?.code === 'FAIL' && error?.response?.data?.msg) {
              callAlert(error?.response?.data?.msg);
            } else {
              callAlert('검수확인 요청이 실패하였습니다.');
            }
          },
        },
      );
    },
  });

  const fetchImage = async (jobUnitId, filePath) => {
    try {
      const response = await Api.jobImage({ jobUnitId, filePath });
      return URL.createObjectURL(response);
    } catch (error) {
      throw new Error('Error fetching image');
    }
  };

  const jobImageQueries = useQueries({
    queries:
      currentJobWeightImageListQuery?.data?.map(({ filePath }) => ({
        queryKey: ['jobImage', currentData?.id, filePath],
        queryFn: () => fetchImage(currentData?.id, filePath),
        enabled: !!currentJobWeightImageListQuery.data,
        select: data => data ?? '',
      })) ?? [],
    combine: results => {
      return {
        data: results.map(result => result.data),
        pending: results.some(result => result.isPending),
      };
    },
  });

  const confirmMutation = useMutation({
    mutationFn: ({ jobUnitWeightImageId, weight }) =>
      Api.jobWeightImageConfirm({ jobUnitWeightImageId, weight }).then(async () => {
        await queryClient.invalidateQueries(['jobOcrTargetList', { ...params, ocrTargetYn: 'Y' }]);
        await queryClient.invalidateQueries(['currentJobWeightImageListQuery', { pndGroupId: params.pndGroupId, jobUnitIds: currentData?.id }]);
      }),
  });

  useEffect(() => {
    setCurrentImageIndex(0);
  }, [currentIndex]);

  useEffect(() => {
    if (currentJobWeightImageData) {
      if (currentData.ocrConfirmYn === 'Y') {
        setFieldValue('jobUnitWeightImageId', currentJobWeightImageData.id);
        setFieldValue('weight', currentData.weight);
      } else {
        setFieldValue('jobUnitWeightImageId', currentJobWeightImageData.id);
        setFieldValue('weight', currentJobWeightImageData.weightKg || '');
      }
    }
  }, [currentJobWeightImageData]);

  if (!currentData) {
    onCloseWeightConfirm();
    return null;
  }
  return jobOcrTargetListQuery.isPending ? (
    <LoadingBackground />
  ) : (
    <>
      <Modal isDismissable={false} isOpen={isOpenWeightConfirm} size="4xl" onOpenChange={onOpenChangeWeightConfirm} scrollBehavior={'outside'}>
        <ModalContent>
          <ModalHeader>
            계근표 검수하기 ({Number(currentIndex) + 1}/{jobOcrTargetListQuery.data.length})
          </ModalHeader>

          <ModalBody>
            <FlexTable title={'운송정보'}>
              <FlexTableRow>
                <FlexTableHeader>상차완료시각</FlexTableHeader>
                <FlexTableData>{formatUtil.getDatetime(currentData.pickupDt)}</FlexTableData>
                <FlexTableHeader>하차완료시각</FlexTableHeader>
                <FlexTableData>{formatUtil.getDatetime(currentData.deliveryDt)}</FlexTableData>
                <FlexTableHeader>검수자</FlexTableHeader>
                <FlexTableData>{currentData.ocrConfirmName ?? '-'}</FlexTableData>
                <FlexTableHeader>검수완료시각</FlexTableHeader>
                <FlexTableData>{formatUtil.getDatetime(currentData.ocrConfirmDt) ?? '-'}</FlexTableData>
              </FlexTableRow>
            </FlexTable>
            <div className="flex gap-4 max-h-[700px]">
              <div className="flex p-3 border-default-100 border-1 rounded-xl">
                <Table
                  isHeaderSticky
                  isCompact
                  removeWrapper
                  aria-label="Job Cost Detail Table"
                  classNames={{
                    thead: '[&>tr]:first:shadow-none',
                    base: 'overflow-scroll flex-1',
                  }}
                  color="primary"
                  selectionBehavior="replace"
                  disallowEmptySelection
                  selectionMode="single"
                  selectedKeys={[String(currentIndex)]}
                  onSelectionChange={keys => setCurrentIndex(keys.values().next().value)}
                >
                  <TableHeader>
                    <TableColumn key="no">검수상태</TableColumn>
                    <TableColumn key="jobDate">상차지</TableColumn>
                    <TableColumn key="pickupPlaceName">하차지</TableColumn>
                    <TableColumn key="deliveryPlaceName">차량번호</TableColumn>
                  </TableHeader>
                  <TableBody items={jobOcrTargetListQuery.data ?? []} emptyContent={'운송내역이 없습니다.'}>
                    {item => (
                      <TableRow key={item.no}>
                        <TableCell>
                          {item.ocrConfirmYn == 'Y' ? (
                            <CusChip size="sm" color={{ bg: 'bg-primary-500/10', text: 'text-primary-500' }}>
                              검수완료
                            </CusChip>
                          ) : (
                            <CusChip size="sm" color={{ bg: 'bg-default-500/10', text: 'text-default-500' }}>
                              검수 전
                            </CusChip>
                          )}
                        </TableCell>
                        <TableCell>{item.pickupPlaceName}</TableCell>
                        <TableCell>{item.deliveryPlaceName}</TableCell>
                        <TableCell>{item.jobPlan.vehicleNo}</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>

              <div className="flex flex-col flex-1 gap-4">
                <div className="flex flex-col flex-1 gap-2 p-3 rounded-xl bg-default-50">
                  <ScrollShadow orientation="horizontal" className="flex w-full gap-4 p-2 -m-1">
                    {!jobImageQueries?.pending && !currentJobWeightImageListQuery.isPending ? (
                      jobImageQueries?.data?.map((src, idx) => (
                        <div key={String(currentIndex) + idx} onClick={() => setCurrentImageIndex(idx)} className="relative cursor-pointer select-none">
                          <img
                            className={`pointer-events-none object-cover size-24 rounded-xl ${currentImageIndex == idx ? 'outline-2 outline-primary outline outline-offset-2' : 'opacity-70'}`}
                            src={src}
                          />
                          <span className="absolute z-20 px-1 text-xs text-white rounded-full right-2 bottom-2 bg-black/30">
                            {idx + 1}/{jobImageQueries?.data?.length}
                          </span>
                          {/* <DynamicIcon className="absolute text-xl text-danger/70 right-2 top-2" iconName={'WarningCircle'} weight={'fill'}></DynamicIcon> */}
                        </div>
                      ))
                    ) : (
                      <Skeleton className="size-24 rounded-xl" />
                    )}
                  </ScrollShadow>
                  <Swiper zoom={true} modules={[Zoom]} className="w-full mySwiper aspect-square rounded-xl bg-default-100">
                    <SwiperSlide>
                      <AnimatePresence>
                        <div className="absolute z-50 flex items-center justify-center w-full -translate-x-1/2 pointer-events-none bottom-4 left-1/2">
                          {currentJobWeightImageData?.ocrStatus == 'FAIL' && (
                            <motion.div
                              initial={{ y: '10%', opacity: 0 }}
                              animate={{ y: '0', opacity: 1 }}
                              exit={{ y: '10%', opacity: 0 }}
                              className="flex items-center gap-2 p-2 px-4 text-xs text-white rounded-full pointer-events-none bg-black/80"
                            >
                              <DynamicIcon className="text-xl shrink-0 text-danger" iconName={'WarningCircle'} weight={'duotone'}></DynamicIcon>
                              <span>
                                선택된 계근표는 문자 인식이 불가하여
                                <br />
                                실중량이 기록되지 않았습니다.
                              </span>
                            </motion.div>
                          )}
                        </div>
                      </AnimatePresence>
                      <div className="relative swiper-zoom-container">
                        <img src={jobImageQueries?.data[currentImageIndex]} />
                      </div>
                    </SwiperSlide>
                    ))
                  </Swiper>
                </div>
                <Input
                  size="lg"
                  name="weight"
                  label={'실중량'}
                  placeholder="계근표를 확인하고 실중량을 입력해주세요"
                  fullWidth
                  endContent={'kg'}
                  onChange={e => {
                    let value = e.target.value.replace(/[^\d]/g, '');
                    setFieldValue('weight', Number(value));
                  }}
                  value={formatUtil.getNumber(values.weight)}
                ></Input>
                <div className="flex gap-2">
                  <Button
                    size="lg"
                    isDisabled={confirmMutation.isPending || (currentData.ocrConfirmYn == 'Y' && currentData.weight == values.weight)}
                    onPress={handleSubmit}
                    isLoading={confirmMutation.isPending || currentJobWeightImageListQuery.isPending}
                    fullWidth
                    color="default"
                    className="bg-default-800 text-foreground-50"
                  >
                    {currentData.ocrConfirmYn == 'Y' ? (currentData.weight !== values.weight ? '변경후 저장하기' : '검수완료') : '검수확인'}
                  </Button>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
      <AlertModal />
      <ModalView isOpenView={isOpenView} onOpenChangeView={onOpenChangeView} onCloseView={onCloseView} selectedId={currentData.jobUnitId} reloadList={reloadList} onOpenView={onOpenView}></ModalView>
    </>
  );
};

export default ModalWeightConfirm;
