import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Tab, Tabs, ScrollShadow } from '@nextui-org/react';
import PrivacyPolicyContent from '../PrivacyPolicyContent';
import TermsContent from '../TermsContent';

const PrivacyAndTermsModal = ({ isOpen, onClose, onOpenChange, defaultSelectedKey }) => {
  return (
    <Modal scrollBehavior="inside" size="5xl" isOpen={isOpen} onClose={onClose} onOpenChange={onOpenChange}>
      <ModalContent>
        <ModalHeader className="flex flex-col gap-1">약관 및 정책</ModalHeader>
        <ModalBody>
          <Tabs
            defaultSelectedKey={defaultSelectedKey}
            fullWidth
            classNames={{
              panel: 'flex overflow-auto',
            }}
          >
            <Tab key="privacy" title="개인정보 처리방침">
              <ScrollShadow>
                <PrivacyPolicyContent />
              </ScrollShadow>
            </Tab>
            <Tab key="terms" title="이용약관">
              <ScrollShadow>
                <TermsContent />
              </ScrollShadow>
            </Tab>
          </Tabs>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onPress={onClose}>
            확인
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PrivacyAndTermsModal;
