import { Api } from '@/api';
import ConfirmModal from '@/components/Modal/ConfirmModal';
import { AlertModal, useAlertModal } from '@/components/Modal/useAlertModal';
import useConfirmModal from '@/hooks/useConfirmModal';
import { Button, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Tab, Tabs, Textarea } from '@nextui-org/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { FlexTable, FlexTableRow, FlexTableHeader, FlexTableData } from '@/components/FlexTable';

const ModalUpdate = ({ isOpenUpdate, onOpenChangeUpdate, onCloseUpdate, reloadList, selectedId, onOpenView }) => {
  const { callAlert } = useAlertModal();
  const queryClient = useQueryClient();

  const reloadVersion = () => {
    queryClient.invalidateQueries(['version', selectedId]);
  };

  const { confirmOpen, message, requestConfirm, handleConfirm, handleClose } = useConfirmModal();

  const versionQuery = useQuery({
    queryKey: ['version', selectedId],
    queryFn: () => Api.versionView({ id: selectedId }),
    select: res => res.data.data,
    enabled: !!selectedId && isOpenUpdate,
  });

  const versionData = versionQuery.data?.appVersion;

  const updateVersion = useMutation({
    mutationFn: values => Api.versionUpdate(values),
  });

  const handleClosePage = () => {
    //onOpenView();
    onCloseUpdate();
  };

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, validateForm, resetForm, setValues } = useFormik({
    initialValues: {
      id: versionData?.id || '',
      appName: versionData?.appName || '',
      appTypeCode: versionData?.appTypeCode || '',
      version: versionData?.version || '',
      description: versionData?.description || '',
    },
    validationSchema: Yup.object({
      appName: Yup.string().required('제목을 입력하세요.'),
      version: Yup.string().required('버전을 입력하세요.'),
      description: Yup.string().required('내용을 입력하세요.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      updateVersion.mutate(values, {
        onSettled: () => {
          setSubmitting(false);
        },
        onSuccess: () => {
          callAlert('수정되었습니다.', () => {
            onCloseUpdate();
            reloadVersion();
            onOpenView();
          });
        },
        onError: error => {
          console.error('수정 중 오류가 발생했습니다:', error);
          callAlert('수정 중 오류가 발생했습니다.');
        },
      });
    },
  });

  const handleRegisterClick = async () => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      requestConfirm({
        message: '수정 하시겠습니까?',
        onConfirm: () => {
          handleSubmit();
        },
      });
    } else {
      callAlert('입력한 데이터가 올바르지 않습니다.');
    }
  };

  useEffect(() => {
    if (!isOpenUpdate) {
      resetForm();
    } else if (versionQuery.data) {
      setValues({
        id: versionData?.id || '',
        appName: versionData?.appName || '',
        appTypeCode: versionData?.appTypeCode || '',
        version: versionData?.version || '',
        description: versionData?.description || '',
      });
    }
  }, [isOpenUpdate, versionQuery.data, resetForm, setValues]);

  return (
    <>
      <Modal isDismissable={false} isOpen={isOpenUpdate} onOpenChange={onOpenChangeUpdate} size="5xl" scrollBehavior={'outside'}>
        <ConfirmModal isOpen={confirmOpen} title={message} onConfirm={handleConfirm} onClose={handleClose} />
        <ModalContent>
          <form
            onSubmit={event => {
              event.preventDefault();
              handleSubmit();
            }}
          >
            <ModalHeader>버전관리 수정</ModalHeader>
            <ModalBody>
              <FlexTable>
                <FlexTableRow>
                  <FlexTableHeader>앱이름</FlexTableHeader>
                  <FlexTableData>
                    <Input
                      name="appName"
                      labelPlacement="outside"
                      placeholder="앱 이름을 입력하세요"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.appName}
                      isInvalid={touched.appName && !!errors.appName}
                      errorMessage={touched.appName && errors.appName}
                    ></Input>
                  </FlexTableData>
                </FlexTableRow>

                <FlexTableRow>
                  <FlexTableHeader>플랫폼</FlexTableHeader>
                  <FlexTableData>
                    <Tabs
                      fullWidth
                      selectedKey={String(values.appTypeCode)}
                      onSelectionChange={e => {
                        if (e !== values.appTypeCode) {
                          setFieldValue('appTypeCode', e);
                        }
                      }}
                    >
                      <Tab key={'AOS'} title={'Android'} />
                      <Tab key={'IOS'} title={'IOS'} />
                    </Tabs>
                  </FlexTableData>
                </FlexTableRow>

                <FlexTableRow>
                  <FlexTableHeader>버전</FlexTableHeader>
                  <FlexTableData>
                    <Input
                      name="version"
                      labelPlacement="outside"
                      placeholder="버전을 입력하세요"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.version}
                      isInvalid={touched.version && !!errors.version}
                      errorMessage={touched.version && errors.version}
                    ></Input>
                  </FlexTableData>
                </FlexTableRow>
                <FlexTableRow>
                  <FlexTableHeader>패치내용</FlexTableHeader>
                  <FlexTableData>
                    <Textarea
                      name="description"
                      onChange={handleChange}
                      placeholder="패치내용을 입력해주세요"
                      value={values.description}
                      onBlur={handleBlur}
                      isInvalid={touched.description && !!errors.description}
                      errorMessage={touched.description && errors.description}
                      type="text"
                    />
                  </FlexTableData>
                </FlexTableRow>
              </FlexTable>
            </ModalBody>
            <ModalFooter>
              <Button color="default" className="ml-auto" onPress={handleClosePage}>
                닫기
              </Button>
              <Button color="primary" isDisabled={!versionQuery.data} onPress={handleRegisterClick}>
                확인
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
      <AlertModal />
    </>
  );
};

export default ModalUpdate;
