import PrivacyAndTermsModal from '@/components/Modal/PrivacyAndTermsModal';
import { AuthContext } from '@/contexts/AuthContext';
import { Button, Checkbox, Input, Link, useDisclosure } from '@nextui-org/react';
import { Eye, EyeClosed } from '@phosphor-icons/react';
import { useMutation } from '@tanstack/react-query';
import React, { useContext, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

function LoginPage() {
  const navigate = useNavigate();
  const { login, authState } = useContext(AuthContext);

  const [cookies, setCookie, removeCookie] = useCookies(['rememberUserId']);
  const [isRemember, setIsRemember] = useState(cookies.rememberUserId ? true : false);

  const [id, setId] = useState(cookies.rememberUserId ? cookies.rememberUserId : '');
  const [pw, setPw] = useState('');

  const idRef = useRef();
  const pwRef = useRef();

  const [isVisible, setIsVisible] = React.useState(false);
  const toggleVisibility = () => setIsVisible(prev => !prev);

  const [errorMsg, setErrorMsg] = React.useState('');

  const loginMutation = useMutation({
    mutationFn: ({ id, pw }) => login(id, pw),
    onMutate: ({ id }) => {
      if (isRemember) {
        setCookie('rememberUserId', id);
      } else {
        removeCookie('rememberUserId');
      }
    },
    onSuccess: res => {
      if (res) {
        navigate('/job', { replace: true });
      }
    },

    onError: error => {
      idRef?.current?.focus();
      setPw('');
      setErrorMsg(error.response.data.msg || error.message);
    },
  });
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();

  const handleLogin = e => {
    e.preventDefault();
    loginMutation.mutate({ id, pw });
  };

  return (
    <>
      <PrivacyAndTermsModal isOpen={isOpen} onClose={onClose} onOpenChange={onOpenChange} />
      <div className="flex items-center justify-center w-full p-12 lg:w-1/2 light bg-background">
        <div className="flex flex-col mb-16 w-96">
          <form className="flex flex-col gap-2 pt-32 pb-4 border-b" onSubmit={handleLogin}>
            <img src={`${import.meta.env.VITE_APP_CONTEXT_PATH}/mango-light.svg`} className="h-16 mb-8" alt="망고체크로고" />
            <Input ref={idRef} variant="bordered" autoFocus={true} className="text-gray-700" label="아이디" value={id} onValueChange={setId} autoComplete="username" />
            <Input
              ref={pwRef}
              variant="bordered"
              type={isVisible ? 'text' : 'password'}
              label="패스워드"
              value={pw}
              onValueChange={setPw}
              autoComplete="current-password"
              className="text-gray-700"
              endContent={
                <button tabIndex={-1} className="focus:outline-none" type="button" onMouseDown={toggleVisibility} onMouseUp={toggleVisibility} onMouseLeave={() => setIsVisible(false)}>
                  {isVisible ? <Eye className="text-2xl pointer-events-none text-default-400" /> : <EyeClosed className="text-2xl pointer-events-none text-default-400" />}
                </button>
              }
            />
            <Button type="submit" color="default" size="lg" fullWidth={true} className="mt-3 text-white bg-black" isLoading={loginMutation.isPending} isDisabled={!id || !pw}>
              로그인
            </Button>
            <p className="text-sm text-danger">{errorMsg}</p>
            <Checkbox className="" color="primary" isSelected={isRemember} onValueChange={setIsRemember}>
              로그인 정보 기억하기
            </Checkbox>
          </form>
          <div className="flex flex-wrap justify-center gap-2 p-4 text-xs text-center text-gray-300">
            <div>
              <b className="font-semibold">(주)브이유에스 </b>
              대표이사 황윤익
            </div>
            <div>
              <b className="font-semibold">사업자등록번호 </b>
              743-81-02327
            </div>
            <div>
              <b className="font-semibold">주소 </b>
              서울 마포구 와우산로23길 20 가나평안빌딩 5층
            </div>
            <div>
              <b className="font-semibold">이메일 문의 </b>
              <a href="mailto:contact@withvus.ai">contact@withvus.ai</a>
            </div>
          </div>
          <div className="flex flex-wrap items-center justify-center gap-2 p-4 text-xs text-center text-gray-300">
            <Link className="cursor-pointer"  color="foreground" onPress={onOpen}>
              이용약관
            </Link>
            <div className="h-3 border-r" />
            <Link className="cursor-pointer" color="foreground" onPress={onOpen}>
              개인정보 처리방침
            </Link>
            <b className="h-3 border-r" />
            <Link href="https://withvus.ai" color="foreground" target="_blank">
              브이유에스(VUS)
            </Link>
          </div>
        </div>
      </div>
      <div className="items-end justify-end flex-1 hidden text-black lg:flex bg-zinc-50">
        <div className="flex flex-col w-4/5 h-3/4">
          <h1 className="mb-8 text-5xl font-bold">
            <span>망고와 함께라면</span>
            <span className="text-primary">.</span>
          </h1>
          <p className="mb-16 text-lg leading-relaxed text-zinc-400">
            배차를 쉽고 빠르게
            <br />
            배송상태 조회를 한눈에
            <br />
            알림톡 자동발송으로 편리하게
          </p>
          <img className="flex-1 object-cover overflow-hidden rounded-tl-xl" src={`${import.meta.env.VITE_APP_CONTEXT_PATH}/login_visual.jpg`} alt=""></img>
        </div>
      </div>
    </>
  );
}

export default LoginPage;
