import Aside from '@/components/Aside';
import { Outlet } from 'react-router-dom';

const OnlySidebarLayout = () => {
  return (
    <>
      <Aside></Aside>
      <div className="relative flex grow h-dvh">
        <Outlet></Outlet>
      </div>
    </>
  );
};

export default OnlySidebarLayout;
