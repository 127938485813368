export function expandCellRange(range) {
  const [start, end] = range.split(':');
  const startColumn = start.replace(/[0-9]/g, '');
  const startRow = parseInt(start.replace(/[^0-9]/g, ''), 10);
  const endColumn = end.replace(/[0-9]/g, '');
  const endRow = parseInt(end.replace(/[^0-9]/g, ''), 10);

  const cells = [];

  const columnToNumber = column => {
    let sum = 0;
    for (let i = 0; i < column.length; i++) {
      sum = sum * 26 + (column.charCodeAt(i) - 'A'.charCodeAt(0) + 1);
    }
    return sum;
  };

  const startColumnIndex = columnToNumber(startColumn);
  const endColumnIndex = columnToNumber(endColumn);

  for (let row = startRow; row <= endRow; row++) {
    for (let col = startColumnIndex; col <= endColumnIndex; col++) {
      let colLabel = '';
      let colIndex = col;
      while (colIndex > 0) {
        let rem = (colIndex - 1) % 26;
        colLabel = String.fromCharCode('A'.charCodeAt(0) + rem) + colLabel;
        colIndex = Math.floor((colIndex - 1) / 26);
      }
      cells.push(`${colLabel}${row}`);
    }
  }

  return cells;
}

export async function repeatStyle(worksheet, range, style) {
  const cellList = expandCellRange(range);
  if (cellList.length > 0) {
    cellList.forEach(cell => {
      const excelCell = worksheet.getCell(cell);
      Object.assign(excelCell.style, style);
    });
  }
}
