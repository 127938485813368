import {
  Pagination,
  Select,
  SelectItem,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Button,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  DropdownSection,
  Checkbox,
} from '@nextui-org/react';
import { rowsPerPage } from '@/constants/table';
import { motion } from 'framer-motion';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FileXls } from '@phosphor-icons/react';
import DynamicIcon from '@/components/DynamicIcon';

const PaginationTable = ({
  data: { page, size, totalPages, totalElements, columns, items },
  renderCell,
  isLoading,
  isFetching,
  onRowsPerPageChange,
  onPageChange,
  onAddClick,
  onRowClick,
  onExelDownload,
  type,
  extrtaBtn1,
  extrtaBtn2,
  noAdd,
  selectedKeys,
  setSelectedKeys,
  params,
  setParams,
}) => {
  const { pathname } = useLocation(); // Get the current pathname from react-router-dom
  const [sortDescriptor, setSortDescriptor] = useState({});
  const [columnSelectedKeys, setColumnSelectedKeys] = useState(() => {
    // Load initial state from localStorage or use default value
    const storedKeys = localStorage.getItem(pathname);
    return storedKeys ? new Set(JSON.parse(storedKeys)) : new Set(columns.map(item => item.uid));
  });

  useEffect(() => {
    if (params?.orderType) {
      columns.forEach(column => {
        if (column.sortable) {
          if (column.ascending === params.orderType) {
            setSortDescriptor({
              column: column.uid,
              direction: 'ascending',
            });
          }
          if (column.descending === params.orderType) {
            setSortDescriptor({
              column: column.uid,
              direction: 'descending',
            });
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    // Save columnSelectedKeys to localStorage when it changes
    localStorage.setItem(pathname, JSON.stringify(Array.from(columnSelectedKeys)));
  }, [columnSelectedKeys, pathname]);

  const handleSortChange = useCallback(
    item => {
      const columnIndex = columns.findIndex(column => column.uid === item.column);
      console.log(item, sortDescriptor, item.column !== sortDescriptor.column);

      if (!sortDescriptor.direction) {
        setParams(prevParams => ({
          ...prevParams,
          orderType: columns[columnIndex].descending,
        }));
        setSortDescriptor({
          column: item.column,
          direction: 'descending',
        });
        return;
      }

      if (item.column !== sortDescriptor.column) {
        setParams(prevParams => ({
          ...prevParams,
          orderType: columns[columnIndex][sortDescriptor.direction],
        }));
        setSortDescriptor({
          column: item.column,
          direction: sortDescriptor.direction,
        });
        return;
      }

      const newOrderType = sortDescriptor.direction === 'ascending' ? columns[columnIndex].descending : columns[columnIndex].ascending;
      setParams(prevParams => ({
        ...prevParams,
        orderType: newOrderType,
      }));
      setSortDescriptor({
        column: item.column,
        direction: sortDescriptor.direction === 'ascending' ? 'descending' : 'ascending',
      });
    },
    [columns, sortDescriptor, setParams]
  );

  const handleAddData = () => {
    onAddClick && onAddClick();
    // console.log('Add button clicked');
  };

  const handleRowClick = item => {
    onRowClick && onRowClick(item);
  };

  const handleExcelDownload = () => {
    onExelDownload && onExelDownload();
  };

  return (
    <div className="flex flex-col flex-1 space-y-3">
      <div className="flex items-center gap-2">
        <Dropdown>
          <DropdownTrigger>
            <Button variant="flat" size="sm" className="gap-1 bg-default-100">
              <DynamicIcon iconName={'GearSix'} weight={'fill'} className="text-base opacity-80"></DynamicIcon>
              <div>표 설정</div>
            </Button>
          </DropdownTrigger>
          <DropdownMenu
            topContent={
              <div className="flex flex-col gap-1 px-2 py-1.5">
                <span className="text-lg font-semibold">표 설정</span>
                <span className="text-xs text-default-400">보고 싶은 항목을 선택해주세요.</span>
              </div>
            }
            aria-label="Multiple selection example"
            variant="flat"
            closeOnSelect={false}
            disallowEmptySelection
            selectionMode="multiple"
            selectedKeys={columnSelectedKeys}
            onSelectionChange={setColumnSelectedKeys}
            items={columns}
          >
            {item => <DropdownItem key={item.uid}>{item.name}</DropdownItem>}
          </DropdownMenu>
        </Dropdown>
        <span className="text-sm text-foreground-500">총 {totalElements || 0}건</span>

        {/* {isFetching && (
          <motion.div
            className="flex"
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
            }}
            transition={{
              duration: 1,
            }}
          >
            <Spinner size="sm" color={'default'}></Spinner>
          </motion.div>
        )} */}
        <div className="mr-auto" />
        {onExelDownload && (
          <Button
            className="gap-1 bg-default-100"
            startContent={<DynamicIcon iconName={'File'} weight={'fill'} className="text-base text-success-600/80" />}
            size="sm"
            onPress={() => {
              handleExcelDownload();
            }}
            variant="flat"
          >
            엑셀 다운로드
          </Button>
        )}
        {extrtaBtn1 && extrtaBtn1}
        {extrtaBtn2 && extrtaBtn2}
        {!noAdd && (
          <Button
            color="primary"
            size="sm"
            variant="flat"
            onPress={() => {
              handleAddData();
            }}
          >
            등록
          </Button>
        )}
        <Select
          aria-label="Rows per page"
          classNames={{
            trigger: 'after:hidden border-none shadow-none !px-0 text-foreground-500 group-data-[has-value=true]:text-foreground-500',
            value: 'text-right text-foreground-400',
            selectorIcon: 'right-0',
          }}
          disallowEmptySelection
          variant={'underlined'}
          size="sm"
          handleAddData
          className="px-0 max-w-32"
          defaultSelectedKeys={[String(size)]}
          isClearable={false}
          onChange={onRowsPerPageChange}
          name="size"
          items={rowsPerPage}
        >
          {item => <SelectItem key={item.value}>{item.text}</SelectItem>}
        </Select>
      </div>
      <Table
        className="flex-1"
        removeWrapper
        selectionMode={type ? type : 'single'}
        aria-label="table"
        onRowAction={() => {
          return;
        }}
        selectedKeys={selectedKeys}
        onSelectionChange={setSelectedKeys}
        sortDescriptor={sortDescriptor}
        setSortDescriptor={setSortDescriptor}
        onSortChange={handleSortChange}
      >
        <TableHeader columns={columns.filter(item => columnSelectedKeys.has(item.uid))}>
          {column => (
            <TableColumn
              style={{
                columnButton: {
                  width: '250px',
                },
              }}
              key={column.uid}
              allowsSorting={column.sortable}
              hidden={!Array.from(columnSelectedKeys).find(e => e == column.uid)}
            >
              {column.name}
            </TableColumn>
          )}
        </TableHeader>

        <TableBody
          emptyContent={'등록된 정보가 없습니다.'}
          isLoading={isLoading} //loadingContent={<Spinner></Spinner>}
          items={items || []}
        >
          {items?.map((item, idx) => (
            <TableRow key={item.id} onClick={() => handleRowClick(item)} classNames="cursor-pointer">
              {columnKey => <TableCell>{renderCell(item, columnKey, idx)}</TableCell>}
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <div className="flex justify-center">
        {items && (
          <Pagination aria-label="Page navigation" className="items-center justify-center" variant="light" showControls color="primary" page={page} total={totalPages || 1} onChange={onPageChange} />
        )}
      </div>
    </div>
  );
};

export default PaginationTable;
