import { Api } from '@/api/index';
import ConfirmModal from '@/components/Modal/ConfirmModal';
import { AlertModal, useAlertModal } from '@/components/Modal/useAlertModal';
import useConfirmModal from '@/hooks/useConfirmModal';
import { Accordion, AccordionItem, Button, Checkbox } from '@nextui-org/react';
import { CheckCircle } from '@phosphor-icons/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Agree() {
  const navigate = useNavigate();
  const { callAlert } = useAlertModal();

  const { confirmOpen, message, requestConfirm, handleConfirm, handleClose } = useConfirmModal();

  const { data: termsList, isLoading } = useQuery({
    queryKey: ['termsList'],
    queryFn: () => Api.termsNotAgree({ adminId: 1 }),
    select: res => res.data?.data?.notAgreeTermsList || [],
  });

  const [checkedStates, setCheckedStates] = useState([]);

  useEffect(() => {
    if (!isLoading && termsList.length > 0) {
      setCheckedStates(termsList.map(() => false)); // 데이터 로드 후 초기화
    }
  }, [isLoading, termsList]); // 데이터 로드가 완료된 후 초기화

  useEffect(() => {
    callAlert('약관동의를 진행해 주세요.');
  }, []);

  const handleCheckboxChange = index => {
    setCheckedStates(prevStates => {
      const newStates = [...prevStates];
      newStates[index] = !prevStates[index]; // 상태 토글
      return newStates;
    });
  };

  const allChecked = checkedStates.length > 0 && checkedStates.every(state => state);

  const add = useMutation({
    mutationFn: data => Api.termsAgree(data),
    onSuccess: result => {
      if (result.data.code === 'SUCC') {
        callAlert(`동의되었습니다.`);
        setTimeout(() => {
          navigate('/admin');
        }, 800);
      } else {
        callAlert('동의 처리에 실패하였습니다.');
      }
    },
    onError: error => {
      console.error('Error adding user:', error);
    },
  });

  const fnTermsAgree = () => {
    let data = termsList.map(item => JSON.stringify(item.id));
    add.mutate(data);
  };

  const fnTermsDisagree = () => {
    localStorage.clear();
    window.location.href = '/';
  };

  return (
    <div>
      <div className="flex flex-col gap-4">
        <div className="relative grid px-8 overflow-x-auto shadow-md bg-content1 rounded-xl">
          <p className="flex items-center gap-2 mt-4 font-bold">
            <CheckCircle size={32} />
            약관 동의
          </p>
          <span className="mt-4 text-sm text-gray-400">필수 약관에 동의해야 망고체크 서비스를 이용하실 수 있습니다.</span>
          <div>
            <Accordion>
              {termsList &&
                termsList.length > 0 &&
                termsList.map((item, index) => (
                  <AccordionItem
                    key={item.id}
                    title={
                      <Checkbox isSelected={checkedStates[index]} onChange={() => handleCheckboxChange(index)}>
                        {item.termsTitle}
                      </Checkbox>
                    }
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.termsContent.replace(/\n/g, '<br>'),
                      }}
                    />
                  </AccordionItem>
                ))}
            </Accordion>
          </div>
        </div>
        <div className="flex flex-wrap items-center justify-end gap-4 py-4">
          <Button color="primary" variant="bordered" onClick={fnTermsDisagree}>
            취소
          </Button>
          <Button
            color="primary"
            isDisabled={!allChecked}
            isLoading={add.isPending}
            onClick={() => {
              requestConfirm({
                message: '동의하시겠습니까?',
                onConfirm: fnTermsAgree,
              });
            }}
          >
            확인
          </Button>
        </div>
      </div>
      <ConfirmModal isOpen={confirmOpen} title={message} onConfirm={handleConfirm} onClose={handleClose} />
      <AlertModal />
    </div>
  );
}

export default Agree;
