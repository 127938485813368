import { createContext, useState, useEffect } from 'react';
import { Api, setHeaderToken, removeHeaderToken } from '@/api/index';
import MenuContext from './MenuContext';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { axiosInstance } from '@/api/axiosInstance';
import toast from 'react-hot-toast';
/**
 * @typedef {Object} AuthState
 * @property {string|null} token
 * @property {string|null} refreshToken
 * @property {boolean} isAuthenticated
 */

/**
 * @typedef {Object} AuthContextType
 * @property {AuthState} authState
 * @property {(id: string, pw: string) => Promise<void>} login
 * @property {() => void} logout
 */

/** @type {React.Context<AuthContextType|undefined>} */
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    token: localStorage.getItem('token'),
    refreshToken: localStorage.getItem('refreshToken'),
    isAuthenticated: !!localStorage.getItem('token'),
  });

  const [parentMenuList, setParentMenuList] = useState(JSON.parse(localStorage.getItem('parentMenuList') || '[]'));
  const [childMenuList, setChildMenuList] = useState(JSON.parse(localStorage.getItem('childMenuList') || '[]'));

  const login = async (id, pw) => {
    try {
      const response = await Api.login({ id, pw });
      const { token, refreshToken, menuMap } = response.data.data;
      console.log(response.data.data);
      localStorage.setItem('token', token);
      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem('parentMenuList', JSON.stringify(menuMap.parentMenuList));
      localStorage.setItem('childMenuList', JSON.stringify(menuMap.childMenuList));
      setAuthState({
        token,
        refreshToken,
        isAuthenticated: true,
      });
      setHeaderToken(token);
      if (menuMap) {
        setParentMenuList(menuMap.parentMenuList || []);
        setChildMenuList(menuMap.childMenuList || []);
      }
      return response;
    } catch (error) {
      console.error('Login failed', error);
      throw error;
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('parentMenuList');
    localStorage.removeItem('childMenuList');
    setAuthState({
      token: null,
      refreshToken: null,
      isAuthenticated: false,
    });
    removeHeaderToken();
  };

  const refreshAuth = async () => {
    try {
      if (authState.refreshToken || localStorage.getItem('refreshToken')) {
        await Api.refresh({ refreshToken: authState.refreshToken || localStorage.getItem('refreshToken') }).then(res => {
          const { token, refreshToken } = res.data.data;
          localStorage.setItem('token', token);
          localStorage.setItem('refreshToken', refreshToken);
          setAuthState({
            token,
            refreshToken,
            isAuthenticated: true,
          });
        });
      } else {
        throw new Error('토큰 없음');
      }
    } catch (e) {
      toast.error('오랫동안 사용하지 않아 로그아웃되었습니다.');
      logout();
    }
  };

  createAuthRefreshInterceptor(axiosInstance, refreshAuth, {
    statusCodes: [401],
  });

  useEffect(() => {
    const token = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refreshToken');
    if (token) {
      setAuthState({
        token,
        refreshToken,
        isAuthenticated: true,
      });
      setHeaderToken(token); // Axios 헤더에 토큰 설정
    }
  }, []);

  return (
    <AuthContext.Provider value={{ authState, login, logout }}>
      <MenuContext.Provider value={{ parentMenuList, setParentMenuList, childMenuList, setChildMenuList }}>{children}</MenuContext.Provider>
    </AuthContext.Provider>
  );
};
