import { Api } from '@/api';
import { FlexTable, FlexTableData, FlexTableHeader, FlexTableRow } from '@/components/FlexTable';
import ConfirmModal from '@/components/Modal/ConfirmModal';
import { AlertModal, useAlertModal } from '@/components/Modal/useAlertModal';
import useConfirmModal from '@/hooks/useConfirmModal';
import { Button, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, useDisclosure } from '@nextui-org/react';
import { useInfiniteScroll } from '@nextui-org/use-infinite-scroll';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import DaumPostcodeEmbed from 'react-daum-postcode';
import * as Yup from 'yup';
import { formatUtil } from '../../utils/FormatUtil';
import ModalAddress from '../../components/Modal/AddressModal';

const ModalUpdate = ({ isOpenUpdate, onOpenChangeUpdate, onCloseUpdate, onOpenView, selectedId, reloadView }) => {
  const { callAlert } = useAlertModal();
  const { isOpen: isOpenPostcode, onOpen: onOpenPostcode, onOpenChange: onOpenChangePostcode, onClose: onClosePostcode } = useDisclosure();

  const { isOpen: isOpenAddress, onOpen: onOpenAddress, onOpenChange: onOpenChangeAddress, onClose: onCloseAddress } = useDisclosure();

  const { data: jobCostQuery, isPending } = useQuery({
    queryKey: ['jobCost', selectedId],
    queryFn: () => Api.jobCostView({ jobCostId: selectedId }),
    select: res => res.data.data,
    enabled: !!selectedId,
  });

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, isError } = useInfiniteQuery({
    queryKey: ['jobCostDetails', selectedId],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await Api.jobCostView({ jobCostId: selectedId, pageNo: pageParam, pageSize: 30 });
      return response.data.data;
    },
    getNextPageParam: lastPage => {
      const currentPage = lastPage.jobCostDetailPage.pageNo;
      const totalPages = lastPage.jobCostDetailPage.totalPages;
      return currentPage < totalPages ? currentPage + 1 : undefined;
    },
    select: d =>
      d.pages
        .flatMap(page => page.jobCostDetailPage.content)
        .map((item, idx) => {
          item.no = idx + 1;
          return item;
        }),
    enabled: !!selectedId,
  });
  const [loaderRef, scrollerRef] = useInfiniteScroll({ hasMore: hasNextPage, onLoadMore: fetchNextPage });

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, validateForm, resetForm, setTouched, setValues } = useFormik({
    initialValues: {
      id: '',
      bizName: '',
      bizNo: '',
      bizCeo: '',
      bizAddr1: '',
      bizAddr2: '',
      bizZipCode: '',
      bizTel: '',
      bizFax: '',
      detailList: [],
    },
    validationSchema: Yup.object({
      bizName: Yup.string().required('상호명을 입력해주세요.'),
      bizNo: Yup.string().required('등록번호를 입력해주세요.'),
      bizCeo: Yup.string().required('대표이사를 입력해주세요.'),
      //bizZipCode: Yup.string().required('주소를 선택해주세요.'),
      bizAddr1: Yup.string().required('주소를 선택해주세요.'),
      bizAddr2: Yup.string().required('상세주소를 입력해주세요.'),
      bizTel: Yup.string().required('전화번호를 입력해주세요.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      updateJobCost.mutate(values, {
        onSettled: () => {
          setSubmitting(false);
        },
        onSuccess: () => {
          callAlert('수정되었습니다.', () => {
            onCloseUpdate();
            reloadView();
          });
        },
        onError: error => {
          if (error?.response?.data?.code === 'FAIL' && error?.response?.data?.msg) {
            callAlert(error?.response?.data?.msg);
          } else {
            callAlert('수정에 실패하였습니다.');
          }
        },
      });
    },
  });

  const handleRegisterClick = async () => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      requestConfirm({
        message: '수정 하시겠습니까?',
        onConfirm: handleSubmit,
      });
    } else {
      console.log(errors);
      Object.keys(errors).map(item => {
        touched[item] = true;
      });
      callAlert('입력한 데이터가 올바르지 않습니다.');
    }
  };
  const groupsQuery = useQuery({
    queryKey: ['groups'],
    queryFn: () => Api.groups(),
    select: res => res.data.data,
  });
  const updateJobCost = useMutation({
    mutationFn: values => Api.jobCostUpdate(values),
  });

  const Postcode = () => {
    const handleComplete = data => {
      onClosePostcode();
      let fullAddress = data.address;
      let extraAddress = '';

      if (data.addressType === 'R') {
        if (data.bname !== '') {
          extraAddress += data.bname;
        }
        if (data.buildingName !== '') {
          extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
        }
        fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
      }

      setFieldValue('bizAddr1', fullAddress);
      setFieldValue('bizZipCode', data.zonecode);
    };

    return <DaumPostcodeEmbed autoClose={false} style={{ minHeight: '500px' }} onComplete={handleComplete} />;
  };

  const [detailList, setDetailList] = useState([]);
  const updateOrAddItem = (id, updatedValues) => {
    setDetailList(prevList => {
      // 해당 id를 가진 항목 찾기
      const itemIndex = prevList.findIndex(item => item.id === id);

      if (itemIndex === -1) {
        // id가 없으면 새로운 항목 추가
        return [...prevList, { id, ...updatedValues }];
      } else {
        // id가 있으면 해당 항목 값 수정
        const updatedList = [...prevList];
        updatedList[itemIndex] = { ...updatedList[itemIndex], ...updatedValues };
        return updatedList;
      }
    });
  };

  useEffect(() => {
    setFieldValue('detailList', detailList);
  }, [detailList]);

  useEffect(() => {
    resetForm();
    setDetailList([]);
    Object.keys(errors).map(item => {
      touched[item] = false;
    });
  }, [isOpenUpdate]);

  useEffect(() => {
    if (jobCostQuery) {
      setValues({
        id: selectedId,
        bizName: jobCostQuery?.jobCost?.bizName,
        bizNo: jobCostQuery?.jobCost?.bizNo,
        bizCeo: jobCostQuery?.jobCost?.bizCeo,
        bizAddr1: jobCostQuery?.jobCost?.bizAddr1,
        bizAddr2: jobCostQuery?.jobCost?.bizAddr2,
        bizZipCode: jobCostQuery?.jobCost?.bizZipCode,
        bizTel: jobCostQuery?.jobCost?.bizTel,
        bizFax: jobCostQuery?.jobCost?.bizFax,
        detailList: [],
      });
      setDetailList([]);
    }
  }, [isOpenUpdate, jobCostQuery]);

  console.log(values.detailList);
  const { confirmOpen, message, requestConfirm, handleConfirm, handleClose } = useConfirmModal();
  return (
    <>
      <Modal isDismissable={false} isOpen={isOpenUpdate} onOpenChange={onOpenChangeUpdate} size="5xl" scrollBehavior={'outside'}>
        <ModalContent>
          {close => (
            <>
              <ModalHeader>운송비 내역서 수정</ModalHeader>
              <ModalBody>
                <FlexTable title={'기본정보'} isAccordion>
                  <FlexTableRow>
                    <FlexTableHeader>운반회사</FlexTableHeader>
                    <FlexTableData>{groupsQuery.data?.groupList?.find(item => item.id == jobCostQuery?.jobCost?.pndGroupId).name || '-'}</FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>운행기간</FlexTableHeader>
                    <FlexTableData>{formatUtil.getDate(jobCostQuery?.jobCost?.startDate, '-') + ' ~ ' + formatUtil.getDate(jobCostQuery?.jobCost?.endDate, '-')}</FlexTableData>
                  </FlexTableRow>
                </FlexTable>
                <FlexTable title={'차량정보'} isAccordion>
                  <FlexTableRow>
                    <FlexTableHeader>차주명</FlexTableHeader>
                    <FlexTableData>{jobCostQuery?.jobCost?.vehicleOwner}</FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>차량목록</FlexTableHeader>
                    <FlexTableData>{jobCostQuery?.jobCost?.vehicleNoList?.map(item => item.vehicleNo).join(', ')}</FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>차주번호</FlexTableHeader>
                    <FlexTableData>{formatUtil.getMobile(jobCostQuery?.jobCost?.vehicleOwnerTel)}</FlexTableData>
                  </FlexTableRow>
                </FlexTable>
                <FlexTable title={'공급받는자'}>
                  <FlexTableRow>
                    <FlexTableHeader>상호</FlexTableHeader>
                    <FlexTableData>
                      <Input
                        name="bizName"
                        type="text"
                        placeholder="상호명을 입력해주세요"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.bizName}
                        isInvalid={touched.bizName && !!errors.bizName}
                        errorMessage={touched.bizName && errors.bizName}
                      />
                    </FlexTableData>
                    <FlexTableHeader>대표이사</FlexTableHeader>
                    <FlexTableData>
                      <Input
                        name="bizCeo"
                        type="text"
                        placeholder="대표명을 입력해주세요"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.bizCeo}
                        isInvalid={touched.bizCeo && !!errors.bizCeo}
                        errorMessage={touched.bizCeo && errors.bizCeo}
                      />
                    </FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>등록번호</FlexTableHeader>
                    <FlexTableData>
                      <Input
                        name="bizNo"
                        type="text"
                        placeholder="등록번호를 입력해주세요"
                        onBlur={handleBlur}
                        isInvalid={touched.bizNo && !!errors.bizNo}
                        errorMessage={touched.bizNo && errors.bizNo}
                        onChange={e => {
                          let value = e.target.value.replace(/[^\d]/g, '');
                          setFieldValue('bizNo', value);
                        }}
                        value={formatUtil.getBizNo(values.bizNo)}
                      />
                    </FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>
                      주소 <span className="text-rose-500">*</span>
                    </FlexTableHeader>
                    <FlexTableData className="flex-col !items-start">
                      <div className="flex gap-2">
                        <Input
                          name="bizZipCode"
                          isReadOnly
                          maxLength={16}
                          size="sm"
                          type="text"
                          placeholder="우편번호(자동입력)"
                          onChange={handleChange}
                          value={values.bizZipCode}
                          isInvalid={touched.zipcode && !!errors.zipcode}
                          errorMessage={touched.zipcode && errors.zipcode}
                        />
                        <Button color="primary" size="sm" variant="flat" style={{ marginRight: '8px' }} onPress={onOpenAddress}>
                          주소찾기
                        </Button>
                      </div>
                      <Input
                        name="bizAddr1"
                        maxLength={16}
                        isReadOnly
                        size="sm"
                        type="text"
                        placeholder="도로명 주소(자동입력)"
                        onChange={handleChange}
                        value={values.bizAddr1}
                        isInvalid={touched.bizAddr1 && !!errors.bizAddr1}
                        errorMessage={touched.bizAddr1 && errors.bizAddr1}
                      />
                      <Input
                        name="bizAddr2"
                        maxLength={16}
                        size="sm"
                        type="text"
                        placeholder="상세주소를 입력해주세요."
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.bizAddr2}
                        isInvalid={touched.bizAddr2 && !!errors.bizAddr2}
                        errorMessage={touched.bizAddr2 && errors.bizAddr2}
                      />
                    </FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>전화</FlexTableHeader>
                    <FlexTableData>
                      <Input
                        name="bizTel"
                        type="text"
                        placeholder="전화번호를 입력해주세요"
                        onBlur={handleBlur}
                        isInvalid={touched.bizTel && !!errors.bizTel}
                        errorMessage={touched.bizTel && errors.bizTel}
                        onChange={e => {
                          let value = e.target.value.replace(/[^\d]/g, '');
                          setFieldValue('bizTel', value);
                        }}
                        value={formatUtil.getTel(values.bizTel)}
                      />
                    </FlexTableData>
                    <FlexTableHeader>팩스</FlexTableHeader>
                    <FlexTableData>
                      <Input
                        name="bizFax"
                        type="text"
                        placeholder="팩스번호를 입력해주세요"
                        onBlur={handleBlur}
                        isInvalid={touched.bizFax && !!errors.bizFax}
                        errorMessage={touched.bizFax && errors.bizFax}
                        onChange={e => {
                          let value = e.target.value.replace(/[^\d]/g, '');
                          setFieldValue('bizFax', value);
                        }}
                        value={formatUtil.getTel(values.bizFax)}
                      />
                    </FlexTableData>
                  </FlexTableRow>
                </FlexTable>
                <FlexTable>
                  <FlexTableHeader className="justify-between !text-base max-h-12 !text-default-800">
                    <div>합계(VAT포함)</div>
                    <div>{formatUtil.getNumber(jobCostQuery.jobCost.totalPrice + jobCostQuery.jobCost.totalVat || 0)}원</div>
                  </FlexTableHeader>
                </FlexTable>
                {/* 테이블 영역 */}
                <div>
                  <div className="mb-2 text-sm font-medium text-default-600">{`운송내역 (${jobCostQuery.jobCostDetailPage.totalElements}건)`}</div>
                  <Table
                    isHeaderSticky
                    isCompact
                    baseRef={scrollerRef}
                    removeWrapper
                    aria-label="Job Cost Detail Table"
                    classNames={{
                      thead: '[&>tr]:first:shadow-none',
                      base: 'max-h-[250px] overflow-scroll',
                      // th: 'first:border-s last:border-e border-y',
                    }}
                    bottomContent={hasNextPage ? <div className="flex w-full" ref={loaderRef}></div> : null}
                  >
                    <TableHeader>
                      <TableColumn key="no">No</TableColumn>
                      <TableColumn key="vehicleNo">차량번호</TableColumn>
                      <TableColumn key="jobDate">운송일</TableColumn>
                      <TableColumn key="pickupPlaceName">상차지</TableColumn>
                      <TableColumn key="deliveryPlaceName">하차지</TableColumn>
                      <TableColumn key="weight">실중량(Kg)</TableColumn>
                      <TableColumn key="price">공급가액(원)</TableColumn>
                      <TableColumn key="vat">새액(원)</TableColumn>
                      <TableColumn key="etc">비고</TableColumn>
                    </TableHeader>
                    <TableBody items={data ?? []} emptyContent={'운송내역이 없습니다.'}>
                      {item => (
                        <TableRow key={item.id}>
                          <TableCell>{item.no}</TableCell>
                          <TableCell>{item.vehicleNo}</TableCell>
                          <TableCell>{formatUtil.getDate(item.jobDate, '-')}</TableCell>
                          <TableCell>{item.pickupPlaceName}</TableCell>
                          <TableCell>{item.deliveryPlaceName}</TableCell>
                          <TableCell>
                            <Input size="sm" className="max-w-24" defaultValue={item.weight} type="text" onChange={e => updateOrAddItem(item.id, { weight: e.target.value })}></Input>
                          </TableCell>
                          <TableCell>
                            <Input size="sm" className="max-w-24" defaultValue={item.price} type="text" onChange={e => updateOrAddItem(item.id, { price: e.target.value })}></Input>
                          </TableCell>
                          <TableCell>{formatUtil.getNumber(item.vat)}</TableCell>
                          <TableCell>
                            <Input size="sm" className="max-w-24" defaultValue={item.etc} type="text" onChange={e => updateOrAddItem(item.id, { etc: e.target.value })}></Input>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="default" onPress={onCloseUpdate}>
                  닫기
                </Button>
                <Button isLoading={updateJobCost.isLoading} color="primary" disabled={isSubmitting} onPress={handleRegisterClick}>
                  수정
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
      <ConfirmModal isOpen={confirmOpen} title={message} onConfirm={handleConfirm} onClose={handleClose} />
      <AlertModal />
      <Modal isDismissable={false} isOpen={isOpenPostcode} onOpenChange={onOpenChangePostcode} size="lg">
        <ModalContent>
          <ModalHeader className="flex flex-col gap-1">주소찾기</ModalHeader>
          <Postcode />
        </ModalContent>
      </Modal>
      <ModalAddress
        addrName={'bizAddr1'}
        zipName={'bizZipCode'}
        isOpenAddress={isOpenAddress}
        onOpenChangeAddress={onOpenChangeAddress}
        onCloseAddress={onCloseAddress}
        onOpenAddress={onOpenAddress}
        setFieldValue={setFieldValue}
      ></ModalAddress>
    </>
  );
};

export default ModalUpdate;
