//배차 상태값
export const status = {
  NOT_READY: '미정',
  READY: '배차완료',
  PICKUP: '상차완료',
  DELIVERY: '하차완료',
  COMPLETE: '구매확정',
  DELIVERY_FAIL: '하차실패',
  PICKUP_FAIL: '상차실패',
  SCHEDULE_CANCEL: '스케줄캔슬',
  NEW: '신규',
  CANCEL: '배차취소',
};

//배차 히스토리 상태값
export const historyStatus = {
  NOT_READY: '미정',
  READY: '배차완료',
  PICKUP: '상차완료',
  DELIVERY: '하차완료',
  COMPLETE: '구매확정',
  DELIVERY_FAIL: '하차실패',
  PICKUP_FAIL: '상차실패',
  SCHEDULE_CANCEL: '스케줄캔슬',
  NEW: '신규',
  CANCEL: '배차취소',
};

//운송내역 객체리스트
export const jobPlanStatusList = [
  {
    value: 'READY',
    label: '배차완료',
  },
  {
    value: 'PICKUP',
    label: '상차완료',
  },
  {
    value: 'DELIVERY',
    label: '하차완료',
  },
  {
    value: 'DELIVERY_FAIL',
    label: '하차실패',
  },
  {
    value: 'PICKUP_FAIL',
    label: '상차실패',
  },
  {
    value: 'COMPLETE',
    label: '구매확정',
  },
];

//배차관련 아이콘
export const statusIcon = {
  NOT_READY: 'ClockUser',
  READY: 'CheckCircle',
  PICKUP: 'ArrowFatLinesUp',
  DELIVERY: 'ArrowFatLinesDown',
  COMPLETE: 'CheckCircle',
  FAIL: 'XCircle',
  DELIVERY_FAIL: 'XCircle',
  PICKUP_FAIL: 'XCircle',
  SCHEDULE_CANCEL: 'CalendarX',
  NEW: 'CalendarPlus',
  CANCEL: 'CalendarX',
};
//배차관련 색상
export const statusColor = {
  NOT_READY: 'text-gray-500',
  READY: 'text-sky-500',
  PICKUP: 'text-lime-500',
  DELIVERY: 'text-orange-500',
  COMPLETE: 'text-blue-500',
  FAIL: 'text-rose-500',
  DELIVERY_FAIL: 'text-rose-500',
  PICKUP_FAIL: 'text-rose-500',
  SCHEDULE_CANCEL: 'text-rose-500',
  NEW: 'text-sky-500',
  CANCEL: 'text-rose-500',
};

//chip 색상
export const statusColorName = {
  NEW: {
    bg: 'bg-sky-100',
    text: 'text-sky-500',
  },
  READY: {
    bg: 'bg-sky-100',
    text: 'text-sky-500',
  },
  NOT_READY: {
    bg: 'bg-orange-100',
    text: 'text-orange-500',
  },
  PICKUP: {
    bg: 'bg-lime-100',
    text: 'text-lime-500',
  },
  DELIVERY: {
    bg: 'bg-orange-100',
    text: 'text-orange-500',
  },
  COMPLETE: {
    bg: 'bg-blue-100',
    text: 'text-blue-500',
  },
  FAIL: {
    bg: 'bg-rose-100',
    text: 'text-rose-500',
  },
  DELIVERY_FAIL: {
    bg: 'bg-rose-100',
    text: 'text-rose-500',
  },
  CANCEL: {
    bg: 'bg-rose-100',
    text: 'text-rose-500',
  },
  PICKUP_FAIL: {
    bg: 'bg-rose-100',
    text: 'text-rose-500',
  },
  SCHEDULE_CANCEL: {
    bg: 'bg-rose-100',
    text: 'text-rose-500',
  },
};

//간편배차
export const easyPlanLogStatus = {
  NOT_READY: '추가',
  READY: '배차',
  CANCEL: '배차취소',
  SCHEDULE_CANCEL: '취소',
};
export const easyPlanLogIconList = {
  NOT_READY: 'CalendarPlus',
  READY: 'Truck',
  CANCEL: 'Truck',
  SCHEDULE_CANCEL: 'CalendarX',
};
export const easyPlanLogStatusColor = {
  NOT_READY: {
    bg: 'bg-blue-50',
    text: 'text-blue-500',
  },
  READY: {
    bg: 'bg-blue-50',
    text: 'text-blue-500',
  },
  CANCEL: {
    bg: 'bg-rose-50',
    text: 'text-rose-500',
  },
  SCHEDULE_CANCEL: {
    bg: 'bg-rose-50',
    text: 'text-rose-500',
  },
};
