import React, { useState } from 'react';

const useConfirmModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState({});

  const requestConfirm = ({ message, onConfirm, onCancel }) => {
    setOptions({ message, onConfirm, onCancel });
    setIsOpen(true);
  };

  const handleConfirm = () => {
    options.onConfirm();
    setIsOpen(false);
  };

  const handleClose = () => {
    options.onCancel && options.onCancel();
    setIsOpen(false);
  };

  return {
    confirmOpen: isOpen,
    message: options.message,
    requestConfirm,
    handleConfirm,
    handleClose,
  };
};

export default useConfirmModal;
