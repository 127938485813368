import { Api } from '@/api';
import LoadingBackground from '@/components/LoadingBackground';
import ConfirmModal from '@/components/Modal/ConfirmModal';
import { AlertModal, useAlertModal } from '@/components/Modal/useAlertModal';
import useConfirmModal from '@/hooks/useConfirmModal';
import { formatUtil } from '@/utils/FormatUtil';
import { Button, Chip, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ScrollShadow, Select, SelectItem, Skeleton } from '@nextui-org/react';
import { useMutation, useQueries, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { statusColor, status as constantsStatus, statusIcon } from '@/constants/planStatus';
import useCodeMap from '@/hooks/useCodeMap';
import { FlexTable, FlexTableRow, FlexTableHeader, FlexTableData } from '@/components/FlexTable';
import DynamicIcon from '@/components/DynamicIcon';
import { DownloadSimple } from '@phosphor-icons/react';

const status = [
  {
    value: 'READY',
    label: '배차완료',
  },

  {
    value: 'PICKUP',
    label: '상차완료',
  },
  {
    value: 'DELIVERY',
    label: '하차완료',
  },

  {
    value: 'DELIVERY_FAIL',
    label: '하차실패',
  },
  {
    value: 'PICKUP_FAIL',
    label: '상차실패',
  },
  {
    value: 'COMPLETE',
    label: '구매확정',
  },
];

const type = {
  CARGO_DELIVERY: '화물운반',
  WASTE_COLLECT: '폐기물수거',
};

const ModalView = ({ isOpenView, onOpenView, onOpenChangeView, onCloseView, selectedId, reloadList }) => {
  const { callAlert } = useAlertModal();
  const [oneButtonFlag, setOneButtonFlag] = useState(false);
  const queryClient = useQueryClient();
  const { data: vehicleTypeMap } = useCodeMap('VEHICLE_FLAG');

  const { data: jobQuery, isPending } = useQuery({
    queryKey: ['job', selectedId],
    queryFn: () => Api.jobView({ jobUnitId: selectedId }),
    select: res => res.data.data,
    enabled: !!selectedId,
  });

  const [price, setPrice] = useState(0);
  const [price2, setPrice2] = useState(0);
  const [weight, setWeight] = useState(0);
  const [memo, setMemo] = useState('');

  const [planStatus, setPlanStatus] = useState(null);
  const [imageSrcs, setImageSrcs] = useState([]);
  const [isTouched, setIsTouched] = useState(false);

  const updateJob = useMutation({
    mutationFn: data => {
      return Api.jobUpdate(data);
    },
    onSuccess: result => {
      if (result.data.code === 'SUCC') {
        callAlert('처리되었습니다.');
        queryClient.invalidateQueries(['job', selectedId]);
        reloadList();
      } else if (result.data.code === 'FAIL') {
        callAlert(result.data.msg);
      } else {
        callAlert('처리에 실패하였습니다.');
      }
    },
    onError: error => {
      console.error('Error adding user', error);
    },
  });

  let job = jobQuery?.jobUnit;
  let jobHistoryList = jobQuery?.jobPlanStatusHistoryList;
  let jobHistory = jobHistoryList && jobHistoryList.length > 0 ? [...jobHistoryList].reverse() : [];

  const jobPlan = jobQuery?.jobUnit ? jobQuery?.jobUnit?.jobPlan : {};
  let deliveryImagePathList = jobQuery?.jobUnit?.deliveryImagePathList || [];
  let pickupImagePathList = jobQuery?.jobUnit?.pickupImagePathList || [];

  const imgList = useMemo(() => [...deliveryImagePathList, ...pickupImagePathList], [deliveryImagePathList, pickupImagePathList], [selectedId]);

  const [isSelectDisabled, setIsSelectDisabled] = useState(false);

  useEffect(() => {
    if (job?.price != '' || job?.price != null) {
      setPrice(job?.price || 0);
    }
    if (job?.price2 != '' || job?.price2 != null) {
      setPrice2(job?.price2 || 0);
    }
    if (job?.planStatus) {
      setPlanStatus(job.planStatus);
    }
    if (job?.weight != '' || job?.weight != null) {
      setWeight(job?.weight);
    }
    setIsTouched(false);
  }, [job, isOpenView]);

  const { confirmOpen, message, requestConfirm, handleConfirm, handleClose } = useConfirmModal();

  const handlerUpdatePage = () => {
    let data = { id: selectedId, price: price, price2: price2, weight: weight, memo: memo };
    if (job?.planStatus !== planStatus) {
      data.planStatus = planStatus;
    }
    updateJob.mutate(data);
  };

  const handleDownload = (src, index) => {
    const link = document.createElement('a');
    link.href = src;
    link.download = `JobImage_${index + 1}.png`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const fetchImage = async (jobUnitId, filePath) => {
    try {
      const response = await Api.jobImage({ jobUnitId, filePath });
      return URL.createObjectURL(response);
    } catch (error) {
      throw new Error('Error fetching image');
    }
  };
  const jobImageQueries = useQueries({
    queries: imgList.map(filePath => ({
      queryKey: ['jobImage', selectedId, filePath],
      queryFn: () => fetchImage(selectedId, filePath),
      enabled: !!selectedId,
      select: data => data ?? '',
    })),
    combine: results => {
      return {
        data: results.map(result => result.data),
        pending: results.some(result => result.isPending),
      };
    },
  });

  useEffect(() => {
    if (selectedId && isOpenView) {
      if (imgList?.length > 0) {
        const fetchImages = async () => {
          try {
            const imagePromises = imgList.map(filePath => Api.jobImage({ jobUnitId: selectedId, filePath }).then(blob => URL.createObjectURL(blob)));
            const imageUrls = await Promise.all(imagePromises);
            setImageSrcs(imageUrls);
          } catch (error) {
            console.error('Error fetching images:', error);
          }
        };
        fetchImages();
      } else if (imageSrcs.length !== 0) {
        setImageSrcs([]);
      }
    }
  }, [selectedId, imgList, isOpenView]);

  useEffect(() => {
    setIsSelectDisabled(job?.planStatus === 'COMPLETE');
  }, [job, isOpenView]);
  return (
    <>
      <>
        {isPending && isOpenView && <LoadingBackground />}
        {!isPending && (
          <Modal isDismissable={false} isOpen={isOpenView} onOpenChange={onOpenChangeView} size="5xl" scrollBehavior={'outside'}>
            <ModalContent>
              {close => (
                <>
                  <ModalHeader>운송내역</ModalHeader>
                  <ModalBody>
                    <FlexTable title={'기본정보'}>
                      <FlexTableRow>
                        <FlexTableHeader>등록일</FlexTableHeader>
                        <FlexTableData>{formatUtil.getDate(job?.regDt, '-')}</FlexTableData>
                        <FlexTableHeader>수정일</FlexTableHeader>
                        <FlexTableData>{formatUtil.getDate(job?.modDt, '-')}</FlexTableData>
                        <FlexTableHeader>작성자</FlexTableHeader>
                        <FlexTableData>{job?.regName}</FlexTableData>
                      </FlexTableRow>
                      <FlexTableRow>
                        <FlexTableHeader>스케줄번호</FlexTableHeader>
                        <FlexTableData>{job?.id}</FlexTableData>
                        <FlexTableHeader>운송일</FlexTableHeader>
                        <FlexTableData>{formatUtil.getDate(jobPlan?.planDate, '-')}</FlexTableData>
                      </FlexTableRow>
                      <FlexTableRow>
                        <FlexTableHeader>운송유형</FlexTableHeader>
                        <FlexTableData>
                          <Chip size="sm" variant="flat" className="text-default-500">
                            {type[job?.type]}
                          </Chip>
                        </FlexTableData>
                        <FlexTableHeader>운송물품</FlexTableHeader>
                        <FlexTableData>
                          {job?.jobPlanPackageName ? (
                            <Chip size="sm" variant="flat" className="text-default-500">
                              {job?.jobPlanPackageName}
                            </Chip>
                          ) : (
                            '-'
                          )}
                        </FlexTableData>
                      </FlexTableRow>
                      <FlexTableRow>
                        <FlexTableHeader>상차지</FlexTableHeader>
                        <FlexTableData>
                          <div>
                            {job?.pickupPlaceName}{' '}
                            <span>
                              {job?.pickupPlaceAddress1}
                              {job?.pickupPlaceAddress2}
                            </span>
                          </div>
                        </FlexTableData>
                        <FlexTableHeader>현장 담당자</FlexTableHeader>
                        <FlexTableData>
                          {job?.pickupPlaceMangerName} <span>{formatUtil.getMobile(job?.pickupPlaceMangerTel)}</span>
                        </FlexTableData>
                      </FlexTableRow>
                      <FlexTableRow>
                        <FlexTableHeader>하차지</FlexTableHeader>
                        <FlexTableData>
                          <div>
                            {job?.deliveryPlaceName}{' '}
                            <span>
                              {job?.deliveryPlaceAddress1}
                              {job?.deliveryPlaceAddress2}
                            </span>
                          </div>
                        </FlexTableData>
                        <FlexTableHeader>현장 담당자</FlexTableHeader>
                        <FlexTableData>
                          {job?.deliveryPlaceMangerName}
                          <span>{formatUtil.getMobile(job?.deliveryPlaceMangerTel)}</span>
                        </FlexTableData>
                      </FlexTableRow>
                      <FlexTableRow>
                        <FlexTableHeader>단가</FlexTableHeader>
                        <FlexTableData>
                          <div>
                            <Input
                              name="price"
                              size="sm"
                              placeholder="단가를 입력하세요"
                              value={formatUtil.getNumber(price)}
                              onChange={e => {
                                const value = e.target.value.replace(/[^\d]/g, '');
                                setPrice(value);
                                setIsTouched(true);
                              }}
                              base
                              endContent={'원'}
                            ></Input>
                          </div>
                        </FlexTableData>
                        <FlexTableHeader>기타금액</FlexTableHeader>
                        <FlexTableData>
                          <div>
                            <Input
                              name="price2"
                              size="sm"
                              placeholder="기타금액을 입력하세요"
                              value={formatUtil.getNumber(price2)}
                              onChange={e => {
                                const value = e.target.value.replace(/[^\d]/g, '');
                                setPrice2(value);
                                setIsTouched(true);
                              }}
                              base
                              endContent={'원'}
                            ></Input>
                          </div>
                        </FlexTableData>
                        <FlexTableHeader>총 단가</FlexTableHeader>
                        <FlexTableData>
                          <div>{formatUtil.getNumber(Number(price || 0) + Number(price2 || 0))} 원</div>
                        </FlexTableData>
                      </FlexTableRow>
                      <FlexTableRow>
                        <FlexTableHeader>무게</FlexTableHeader>
                        <FlexTableData>
                          <div>
                            <Input
                              name="weight"
                              size="sm"
                              className={'max-w-[200px]'}
                              placeholder="무게를 입력하세요"
                              value={formatUtil.getNumber(weight)}
                              onChange={e => {
                                const value = e.target.value.replace(/[^\d]/g, '');
                                setWeight(value);
                                setIsTouched(true);
                              }}
                              endContent={'kg'}
                            ></Input>
                          </div>
                        </FlexTableData>
                      </FlexTableRow>
                      <FlexTableRow>
                        <FlexTableHeader>특이사항</FlexTableHeader>
                        <FlexTableData>
                          <div>
                            <Input
                              name="memo"
                              size="sm"
                              className={'min-w-[500px]'}
                              placeholder="특이사항을 입력하세요"
                              value={job.memo}
                              onChange={e => {
                                setMemo(e.target.value);
                                setIsTouched(true);
                              }}
                            ></Input>
                          </div>
                        </FlexTableData>
                      </FlexTableRow>
                      <FlexTableRow>
                        <FlexTableHeader>계근표</FlexTableHeader>
                        <FlexTableData>
                          {imgList.length > 0 ? (
                            <ScrollShadow orientation="horizontal" className="flex flex-row gap-4">
                              {!jobImageQueries.pending ? (
                                jobImageQueries.data.map((src, index) => (
                                  <div key={index} className="relative flex items-center justify-center overflow-hidden bg-default-100 h-96 min-w-96 max-w-96 group rounded-2xl">
                                    <img className="object-contain w-full h-full" src={src} alt={`Job Image ${index + 1}`} />
                                    <div className="absolute bottom-0 z-0 flex items-center justify-between w-full h-20 p-4 bg-gradient-to-t from-10% from-black/75 to-black/0">
                                      <div className="px-2 text-base text-white">
                                        {index + 1} / {jobImageQueries.data.length}
                                      </div>
                                      <Button isIconOnly className="mb-1" size="lg" color="default" variant="light" onPress={() => handleDownload(src, index)}>
                                        <DownloadSimple className="text-2xl text-white"></DownloadSimple>
                                      </Button>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <Skeleton className="min-w-96 min-h-96 rounded-2xl"></Skeleton>
                              )}
                            </ScrollShadow>
                          ) : (
                            <div className="text-default-400">등록된 계근표가 없습니다.</div>
                          )}
                        </FlexTableData>
                      </FlexTableRow>
                    </FlexTable>
                    <FlexTable title={'기사 및 차량정보'}>
                      <FlexTableRow>
                        <FlexTableHeader>기사</FlexTableHeader>
                        <FlexTableData>{jobPlan?.driverName ? jobPlan?.driverName : '-'}</FlexTableData>
                        <FlexTableHeader>기사 전화번호</FlexTableHeader>
                        <FlexTableData>{jobPlan?.driverTel ? formatUtil.getMobile(jobPlan?.driverTel) : '-'}</FlexTableData>
                      </FlexTableRow>
                      <FlexTableRow>
                        <FlexTableHeader>차주</FlexTableHeader>
                        <FlexTableData>{jobPlan?.vehicleOwner ? jobPlan?.vehicleOwner : '-'}</FlexTableData>
                        <FlexTableHeader>차주 전화번호</FlexTableHeader>
                        <FlexTableData>{jobPlan?.vehicleOwnerTel ? formatUtil.getMobile(jobPlan?.vehicleOwnerTel) : '-'}</FlexTableData>
                      </FlexTableRow>
                      <FlexTableRow>
                        <FlexTableHeader>차량번호</FlexTableHeader>
                        <FlexTableData>
                          {jobPlan?.vehicleNo ? jobPlan.vehicleNo : '-'}
                          {jobPlan?.vehicleFlag && (
                            <Chip size="sm" variant="flat" className="ml-2 text-zinc-400">
                              {vehicleTypeMap.codeMap[jobPlan?.vehicleFlag]}
                            </Chip>
                          )}
                        </FlexTableData>
                      </FlexTableRow>
                    </FlexTable>
                    <FlexTable title={'상태'}>
                      <FlexTableRow>
                        <FlexTableHeader>운송상태</FlexTableHeader>
                        <FlexTableData>
                          <Select
                            size="sm"
                            labelPlacement="outside"
                            placeholder=" "
                            //label="요일"
                            disallowEmptySelection
                            className="max-w-48"
                            selectedKeys={[planStatus]}
                            items={status}
                            isDisabled={isSelectDisabled}
                            onChange={e => {
                              setPlanStatus(e.target.value);
                              setIsTouched(true);
                            }}
                            name="status"
                          >
                            {item => <SelectItem key={item.value}>{item?.label}</SelectItem>}
                          </Select>
                        </FlexTableData>
                      </FlexTableRow>
                      <FlexTableRow>
                        <FlexTableHeader>운송내역</FlexTableHeader>
                        <FlexTableData>
                          <div className="flex flex-col w-full">
                            {jobHistory?.length > 0
                              ? jobHistory.map((j, idx) => {
                                  return (
                                    <div key={idx} className="flex items-center gap-4 [&:not(:last-child)]:pb-1 [&:not(:first-child)]:pt-1 [&:not(:last-child)]:border-b border-default-100">
                                      <div className={`flex items-center gap-1 text-sm`}>
                                        <DynamicIcon className={statusColor[j.planStauts]} iconName={statusIcon[j.planStauts]} weight={'fill'}></DynamicIcon>
                                        <span className={statusColor[j.planStauts]}>{constantsStatus[j.planStauts]}</span>
                                      </div>
                                      <div className="text-default-400">{formatUtil.getDatetime(j.regDt, '-')}</div>
                                      <div className="text-default-400">{j.regName}</div>
                                    </div>
                                  );
                                })
                              : '등록된 정보가 없습니다.'}
                          </div>
                        </FlexTableData>
                      </FlexTableRow>
                    </FlexTable>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="default" onPress={onCloseView}>
                      닫기
                    </Button>
                    {isTouched && (
                      <Button
                        color="primary"
                        onPress={() => {
                          requestConfirm({
                            message: '수정하시겠습니까?',
                            onConfirm: handlerUpdatePage,
                          });
                        }}
                      >
                        변경사항 저장
                      </Button>
                    )}
                  </ModalFooter>
                </>
              )}
            </ModalContent>
          </Modal>
        )}

        <ConfirmModal isOpen={confirmOpen} title={message} onConfirm={handleConfirm} onClose={handleClose} oneButton={oneButtonFlag} />
        <AlertModal />
      </>
    </>
  );
};

export default ModalView;
