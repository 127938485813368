import { Api } from '@/api';
import { formatUtil } from '@/utils/FormatUtil';
import { Button, Modal, ModalHeader, ModalContent, ModalFooter, ModalBody, useDisclosure } from '@nextui-org/react';

import { useMutation, useQuery } from '@tanstack/react-query';
import ModalUpdate from './ModalUpdate';
import useConfirmModal from '@/hooks/useConfirmModal';
import ConfirmModal from '@/components/Modal/ConfirmModal';
import { useAlertModal, AlertModal } from '@/components/Modal/useAlertModal';
import LoadingBackground from '@/components/LoadingBackground';
import { FlexTable, FlexTableRow, FlexTableHeader, FlexTableData } from '@/components/FlexTable';

const ModalView = ({ isOpenView, onOpenView, onOpenChangeView, onCloseView, selectedId, reloadList }) => {
  const { callAlert } = useAlertModal();

  const termsQuery = useQuery({
    queryKey: ['driverTerms', selectedId],
    queryFn: () => Api.driverTermsView({ id: selectedId }),
    select: res => res.data.data,
    enabled: !!selectedId,
  });

  const { data: codeMapData } = useQuery({
    queryKey: ['codeMap', 'TERMS_TYPE'],
    queryFn: () => Api.codeMap({ cate: 'TERMS_TYPE' }),
    select: res => res.data.data,
    enabled: !!selectedId,
  });

  const { confirmOpen, message, requestConfirm, handleConfirm, handleClose } = useConfirmModal();

  const { isOpen: isOpenUpdate, onOpen: onOpenUpdate, onOpenChange: onOpenChangeUpdate, onClose: onCloseUpdate } = useDisclosure();

  const handlerUpdatePage = () => {
    onCloseView();
    onOpenUpdate();
  };

  const groupQuery = useQuery({
    queryKey: ['groups'],
    queryFn: () => Api.groups(),
    select: res => res.data.data,
  });

  const delTerms = useMutation({
    mutationFn: id => Api.driverTermsDel({ id, delYn: 'Y' }),
    onSuccess: data => {
      callAlert('처리되었습니다.', () => {
        onCloseView();
        reloadList();
      });
    },
    onError: error => {
      console.error('Error adding user', error);
    },
  });

  const handlerDelete = id => {
    delTerms.mutate(id);
  };

  let terms = termsQuery?.data?.driverTerms;
  let codeMap = codeMapData?.codeMap;

  return (
    <>
      {termsQuery.isPending && isOpenView && <LoadingBackground />}
      <Modal isDismissable={false} isOpen={isOpenView} onOpenChange={onOpenChangeView} size="5xl" scrollBehavior={'outside'}>
        <ModalContent>
          {close => (
            <>
              <ModalHeader>약관관리 상세</ModalHeader>
              <ModalBody>
                <FlexTable>
                  <FlexTableRow>
                    <FlexTableHeader>등록일</FlexTableHeader>
                    <FlexTableData>{formatUtil.getDate(terms?.regDt, '-')}</FlexTableData>
                    <FlexTableHeader>수정일</FlexTableHeader>
                    <FlexTableData>{formatUtil.getDate(terms?.modDt, '-')}</FlexTableData>
                    <FlexTableHeader>작성자</FlexTableHeader>
                    <FlexTableData>{terms?.regName}</FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>운반회사</FlexTableHeader>
                    <FlexTableData>
                      {groupQuery.data?.groupList?.map(item => {
                        return terms?.pndGroupId == item.id && item.name;
                      })}
                    </FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>노출상태</FlexTableHeader>
                    <FlexTableData>{terms?.showYn}</FlexTableData>
                  </FlexTableRow>

                  <FlexTableRow>
                    <FlexTableHeader>약관유형</FlexTableHeader>
                    <FlexTableData>{codeMap ? codeMap[terms?.termsTypeCode] : ''}</FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>제목</FlexTableHeader>
                    <FlexTableData>{terms?.termsTitle}</FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>내용</FlexTableHeader>
                    <FlexTableData>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: terms?.termsContent.replace(/\n/g, '<br>'),
                        }}
                      ></div>
                    </FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>약관 시작일</FlexTableHeader>
                    <FlexTableData>{formatUtil.getDate(terms?.regDt, '-')}</FlexTableData>
                  </FlexTableRow>
                </FlexTable>
              </ModalBody>
              <ModalFooter>
                {/* <Button
                  color="danger"
                  className="mr-auto"
                  isLoading={delTerms.isPending}
                  onPressEnd={() => {
                    requestConfirm({
                      message: '삭제하시겠습니까??',
                      onConfirm: () => {
                        handlerDelete(terms?.id);
                      },
                    });
                  }}
                >
                  삭제
                </Button> */}
                <Button color="default" onPress={onCloseView}>
                  닫기
                </Button>
                <Button color="primary" onPress={handlerUpdatePage}>
                  수정
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

      <ConfirmModal isOpen={confirmOpen} title={message} onConfirm={handleConfirm} onClose={handleClose} />
      <ModalUpdate
        isOpenUpdate={isOpenUpdate}
        onOpenChangeUpdate={onOpenChangeUpdate}
        onCloseUpdate={onCloseUpdate}
        size="5xl"
        selectedId={selectedId}
        scrollBehavior={'outside'}
        onOpenView={onOpenView}
      />
      <AlertModal />
    </>
  );
};

export default ModalView;
