import { Api } from '@/api';
import ConfirmModal from '@/components/Modal/ConfirmModal';
import { useAlertModal } from '@/components/Modal/useAlertModal';
import useConfirmModal from '@/hooks/useConfirmModal';
import { Button, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from '@nextui-org/react';
import { useMutation } from '@tanstack/react-query';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FlexTable, FlexTableRow, FlexTableHeader, FlexTableData } from '@/components/FlexTable';
import { useEffect } from 'react';

const ModalPwUpdate = ({ isOpen, onOpenChange, selectedId, onClose }) => {
  const { confirmOpen, message, requestConfirm, handleConfirm, handleClose } = useConfirmModal();
  const { callAlert } = useAlertModal();

  const updatePwAdmin = useMutation({
    mutationFn: values => Api.adminPwChange(values),
  });

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, validateForm, resetForm } = useFormik({
    initialValues: {
      loginPw: '',
      loginPwCheck: '',
    },
    validationSchema: Yup.object({
      loginPw: Yup.string()
        .required('비밀번호를 입력하세요.')
        .matches(/^.*(?=^.{6,30}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&+=]).*$/, '6~30 자리 / 영어 대문자, 소문자, 숫자, 특수문자 중 3개 이상 조합 '),
      loginPwCheck: Yup.string()
        .oneOf([Yup.ref('loginPw'), null], '비밀번호가 일치하지 않습니다.')
        .required('비밀번호 확인을 입력하세요.'),
    }),

    onSubmit: (values, { setSubmitting }) => {
      const { loginPw } = values;
      updatePwAdmin.mutate(
        { id: selectedId, loginPw },
        {
          onSettled: () => {
            setSubmitting(false);
          },
          onSuccess: () => {
            callAlert('변경되었습니다.', () => {
              onClose();
            });
          },
          onError: error => {
            if (error?.response?.data?.code === 'FAIL' && error?.response?.data?.msg) {
              callAlert(error?.response?.data?.msg);
            } else {
              callAlert('비밀번호 변경에 실패하였습니다.');
            }
          },
        }
      );
    },
  });

  useEffect(() => {
    resetForm();
    Object.keys(errors).map(item => {
      touched[item] = false;
    });
  }, [isOpen]);

  const handleUpdatePwConfirm = async () => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      requestConfirm({
        message: '비밀번호를 변경 하시겠습니까?',
        onConfirm: handleSubmit,
      });
    } else {
      Object.keys(errors).map(item => {
        touched[item] = true;
      });
      callAlert('입력한 데이터가 올바르지 않습니다.');
    }
  };
  return (
    <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
      <ConfirmModal isOpen={confirmOpen} title={message} onConfirm={handleConfirm} onClose={handleClose} />

      <ModalContent>
        {onClose => (
          <>
            <ModalHeader className="flex flex-col gap-1">비밀번호 변경</ModalHeader>
            <ModalBody>
              <FlexTable>
                <FlexTableRow>
                  <FlexTableHeader>비밀번호</FlexTableHeader>
                  <FlexTableData>
                    <Input
                      name="loginPw"
                      maxLength={30}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.loginPw && !!errors.loginPw}
                      errorMessage={touched.loginPw && errors.loginPw}
                      type="password"
                      autoComplete="new-password"
                    />
                  </FlexTableData>
                </FlexTableRow>
                <FlexTableRow>
                  <FlexTableHeader>비밀번호 확인</FlexTableHeader>
                  <FlexTableData>
                    <Input
                      name="loginPwCheck"
                      maxLength={30}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.loginPwCheck && !!errors.loginPwCheck}
                      errorMessage={touched.loginPwCheck && errors.loginPwCheck}
                      type="password"
                      autoComplete="new-password"
                    />
                  </FlexTableData>
                </FlexTableRow>
              </FlexTable>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" variant="bordered" style={{ marginRight: '8px' }} onPress={onClose}>
                취소
              </Button>
              <Button color="primary" onPressEnd={handleUpdatePwConfirm}>
                확인
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default ModalPwUpdate;
