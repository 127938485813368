import { Api } from '@/api';
import ConfirmModal from '@/components/Modal/ConfirmModal';
import { AlertModal, useAlertModal } from '@/components/Modal/useAlertModal';
import useConfirmModal from '@/hooks/useConfirmModal';
import { formatUtil } from '@/utils/FormatUtil';
import { Button, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Select, SelectItem, Tab, Tabs } from '@nextui-org/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { FlexTable, FlexTableRow, FlexTableHeader, FlexTableData } from '@/components/FlexTable';

const ModalUpdate = ({ isOpenUpdate, onOpenChangeUpdate, onCloseUpdate, reloadList, selectedId, onOpenView }) => {
  const { confirmOpen, message, requestConfirm, handleConfirm, handleClose } = useConfirmModal();

  const { callAlert } = useAlertModal();
  const queryClient = useQueryClient();

  const reload = () => {
    queryClient.invalidateQueries(['driver', selectedId]);
  };

  const driverQuery = useQuery({
    queryKey: ['driver', selectedId],
    queryFn: () => Api.driverView({ driverId: selectedId }),
    select: res => res.data.data,
    enabled: !!selectedId && isOpenUpdate,
  });

  const driverData = driverQuery?.data?.driver;
  const groupQuery = useQuery({
    queryKey: ['groups'],
    queryFn: () => Api.groups(),
    select: res => res.data.data,
  });

  const addUpdate = useMutation({
    mutationFn: values => Api.driverUpdate(values),
  });

  const { values, setValues, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, validateForm, resetForm } = useFormik({
    initialValues: {
      id: driverData?.id || '',
      groupId: driverData?.groupId || '',
      useYn: driverData?.useYn || '',
      name: driverData?.name || '',
      tel: driverData?.tel || '',
      vehicleId: driverData?.vehicleId || '-1',
      company: driverData?.company || '',
      memo: driverData?.memo || '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('기사명을 입력하세요.'),
      tel: Yup.string()
        .matches(/^\d{2,3}-\d{3,4}-\d{4}$/, '전화번호 형식이 잘못되었습니다.')
        .required('전화번호를 입력하세요.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      addUpdate.mutate(values, {
        onSettled: () => {
          setSubmitting(false);
        },
        onSuccess: () => {
          callAlert('수정 되었습니다.', () => {
            onCloseUpdate();
            reload();
            onOpenView();
          });
        },
        onError: error => {
          if (error?.response?.data?.code === 'FAIL' && error?.response?.data?.msg) {
            callAlert(error?.response?.data?.msg);
          } else {
            callAlert('수정에 실패하였습니다.');
          }
        },
      });
    },
  });

  let vehicleList = useQuery({
    queryKey: ['vehicleList', driverData?.groupId],
    queryFn: () => Api.vehicleList({ pndGroupId: driverData?.groupId }),
    select: res => res.data.data,
    enabled: !!driverData?.groupId,
  });

  useEffect(() => {
    if (values.tel) {
      const value = values.tel.replace(/\D/g, '');
      setFieldValue('tel', formatUtil.getTel(value));
    }
  }, [values.tel, setFieldValue]);

  const handleRegisterClick = async () => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      requestConfirm({
        message: '수정 하시겠습니까?',
        onConfirm: handleSubmit,
      });
    } else {
      Object.keys(errors).map(item => {
        touched[item] = true;
      });
      callAlert('입력한 데이터가 올바르지 않습니다.');
    }
  };

  useEffect(() => {
    resetForm();
  }, [isOpenUpdate]);

  useEffect(() => {
    if (groupQuery.data?.groupList) {
      setFieldValue('pndGroupId', String(groupQuery.data.groupList[0].id));
    }
  }, [groupQuery.data]);

  useEffect(() => {
    if (!isOpenUpdate) {
      resetForm();
    } else if (driverData) {
      setValues({
        id: driverData?.id || '',
        groupId: driverData?.groupId || '',
        useYn: driverData?.useYn || '',
        name: driverData?.name || '',
        tel: driverData?.tel || '',
        vehicleId: driverData?.vehicleId || '-1',
        company: driverData?.company || '',
        memo: driverData?.memo || '',
      });
    }
  }, [isOpenUpdate, driverData, resetForm]);

  useEffect(() => {
    if (driverData?.vehicleId) {
      setFieldValue('vehicleId', String(driverData?.vehicleId));
    }
  }, [driverData?.vehicleId]);

  return (
    <>
      <Modal isDismissable={false} isOpen={isOpenUpdate} onOpenChange={onOpenChangeUpdate} size="5xl" scrollBehavior={'outside'}>
        <ConfirmModal isOpen={confirmOpen} title={message} onConfirm={handleConfirm} onClose={handleClose} />
        <ModalContent>
          <form
            onSubmit={event => {
              event.preventDefault();
              handleSubmit();
            }}
          >
            <ModalHeader>기사 수정</ModalHeader>
            <ModalBody>
              <FlexTable>
                <FlexTableRow>
                  <FlexTableHeader>운반회사</FlexTableHeader>
                  <FlexTableData>{driverData?.groupName}</FlexTableData>
                </FlexTableRow>
                <FlexTableRow>
                  <FlexTableHeader>활성화</FlexTableHeader>
                  <FlexTableData>
                    <Tabs
                      fullWidth
                      selectedKey={values.useYn}
                      onSelectionChange={e => {
                        if (e !== values.useYn) {
                          setFieldValue('useYn', e);
                        }
                      }}
                    >
                      <Tab key={'Y'} title="활성" />
                      <Tab key={'N'} title="비활성" />
                    </Tabs>
                  </FlexTableData>
                </FlexTableRow>

                <FlexTableRow>
                  <FlexTableHeader>소속</FlexTableHeader>
                  <FlexTableData>
                    <Input name="company" labelPlacement="outside" placeholder="소속을 입력하세요" value={values.company} onChange={handleChange} onBlur={handleBlur}></Input>
                  </FlexTableData>
                </FlexTableRow>

                <FlexTableRow>
                  <FlexTableHeader>
                    기사명<span className="text-rose-500">*</span>
                  </FlexTableHeader>
                  <FlexTableData>
                    <Input
                      name="name"
                      labelPlacement="outside"
                      placeholder="기사명을 입력하세요"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.name && !!errors.name}
                      errorMessage={touched.name && errors.name}
                    ></Input>
                  </FlexTableData>
                </FlexTableRow>

                <FlexTableRow>
                  <FlexTableHeader>
                    기사 전화번호<span className="text-rose-500">*</span>
                  </FlexTableHeader>
                  <FlexTableData>
                    <Input
                      name="tel"
                      labelPlacement="outside"
                      placeholder="010-0000-0000"
                      value={values.tel}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxLength={13}
                      isInvalid={touched.tel && !!errors.tel}
                      errorMessage={touched.tel && errors.tel}
                    ></Input>
                  </FlexTableData>
                </FlexTableRow>
                <FlexTableRow>
                  <FlexTableHeader>차량번호</FlexTableHeader>
                  <FlexTableData>
                    <Select name="vehicleId" size="md" onChange={handleChange} disallowEmptySelection selectedKeys={[String(values.vehicleId ? values.vehicleId : '')]}>
                      <SelectItem key={'-1'}>{'미배정'}</SelectItem>
                      {vehicleList?.data?.vehiclePage?.content?.map(item => (
                        <SelectItem key={item.id}>{item.vehicleNo}</SelectItem>
                      ))}
                    </Select>
                  </FlexTableData>
                </FlexTableRow>
                <FlexTableRow>
                  <FlexTableHeader>메모</FlexTableHeader>
                  <FlexTableData>
                    <Input
                      name="memo"
                      labelPlacement="outside"
                      placeholder="메모를 입력하세요."
                      value={values.memo}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.memo && !!errors.memo}
                      errorMessage={touched.memo && errors.memo}
                    ></Input>
                  </FlexTableData>
                </FlexTableRow>
              </FlexTable>
            </ModalBody>
            <ModalFooter></ModalFooter>
            <ModalFooter>
              <Button color="default" className="ml-auto" onPress={onCloseUpdate}>
                닫기
              </Button>
              <Button isLoading={addUpdate.isLoading} color="primary" disabled={isSubmitting} onPress={handleRegisterClick}>
                확인
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
      <AlertModal />
    </>
  );
};

export default ModalUpdate;
