const TermsContent = () => {
  return (
    <div className="docs">
      <h1>
        <span>이용약관</span>
      </h1>
      <h3>
        <span>제1조 목적</span>
      </h3>
      <p>
        <span>본 약관은 (주)브이유에스(이하 회사)와 회사의 서비스(이하에서 정의함)를 이용하는 자간의 관계와 권리 의무 및 책임 사항, 기타 서비스 이용에 관한 제반 사항을 정함을 목적으로 합니다.</span>
      </p>
      <h3>
        <span>제2조 용어의 정의</span>
      </h3>
      <p>
        <span>본 약관에서 사용하는 용어의 의미는 다음 각 호와 같으며, 여기에서 정의되지 않은 약관상 용어의 의미는 본 약관에서 달리 정의하지 않는 한 관련 법령 및 일반적인 거래관행에 따릅니다.</span>
      </p>
      <ol start="1">
        <li>
          <span>
            &apos;서비스&apos; : 회사가 사용자에게 &apos;사이트&apos;와 &apos;모바일 어플리케이션&apos; 등을 통해 제공하는 업무와 이에 수반되는 제반 작업을 의미합니다. 회사는 경영 정책상 또는 안정적인
            서비스 제공을 위한 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 &apos;서비스&apos;를 변경할 수 있으며 변경된 내용은 제3조의 방법으로 공지합니다.
          </span>
        </li>
        <li>
          <span>&apos;사용자&apos; : 회사의 서비스를 이용하는 고객을 의미합니다.</span>
        </li>
        <li>
          <span>&apos;운송 회사&apos; : 화물자동차를 통해 폐기물, 재활용 원료 등 화물의 운송을 목적으로 망고를 사용하는 회원을 말합니다.</span>
        </li>
        <li>
          <span>&apos;계정&apos; : 사용자가 서비스 이용 전 회사로부터 발급 받은 계정으로서, 사용자 계정을 총칭하는 의미를 가집니다.</span>
        </li>
        <li>
          <span>&apos;사이트&apos; : 회사가 사용자에게 서비스를 제공하기 위해 운영하는 망고 웹사이트(</span>
          <span>
            <a href="https://mango.withvus.ai/op/">https://mango.withvus.ai/op/</a>
          </span>
          <span>)를 의미합니다.</span>
        </li>
        <li>
          <span>&apos;모바일 어플리케이션&apos; : 회사가 사용자에게 서비스를 제공하기 위해 운영하는 모바일 어플리케이션을 의미합니다. </span>
        </li>
      </ol>
      <h3>
        <span>제3조 약관의 효력 및 변경</span>
      </h3>
      <ol start="1">
        <li>
          <span>본 약관의 내용은 망고 화면에 게시하거나 기타의 방법으로 회원에게 공시하고, 본 약관에 동의한 회원에게 그 효력이 있습니다. </span>
        </li>
        <li>
          <span>
            회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 서비스 초기 화면에 그 개정약관의 적용일자 7일 전부터 적용일자 전일까지 공지합니다. 다만, 회원에게 불리한 약관의 개정의
            경우에는 30일 전부터 적용일자 전일까지 공지하며, 공지 외에 일정기간 서비스 내 전자우편, 로그인시 동의창 등의 전자적 수단을 통해 따로 명확히 통지하도록 합니다.
          </span>
        </li>
        <li>
          <span>제2항에 따라 공지된 적용 일자 이후에 서면으로 명시적 거부 의사를 표시하지 않으면 변경된 약관을 승인한 것으로 간주합니다.</span>
        </li>
        <li>
          <span>
            사용자가 변경된 약관에 동의하지 않는 경우 회원탈퇴를 통해 서비스 이용을 해지할 수 있습니다. 또한, 제2항의 조치에도 불구하고 약관의 개정 사실을 알지 못함으로써 발생하는 사용자의 피해에
            대하여 회사는 책임을 지지 않습니다.
          </span>
        </li>
        <li>
          <span>본 약관은 사용자가 본 약관에 동의한 날로부터 탈퇴시까지 적용하는 것을 원칙으로 합니다. 단, 본 약관의 일부 조항은 탈퇴 후에도 유효하게 적용될 수 있습니다.</span>
        </li>
      </ol>
      <h3>
        <span>제4조 회원가입 및 서비스 이용 제한</span>
      </h3>
      <ol start="1">
        <li>
          <span>이용계약은 이용자가 약관의 내용에 대하여 동의를 한 다음 회사가 제시하는 양식과 절차에 따라 이용신청을 하고 회사가 이러한 신청에 대하여 승낙함으로써 체결됩니다.</span>
        </li>
        <li>
          <span>
            회사는 본 약관에 동의하고 회원 정보를 사실대로 기재한 가입신청자의 가입 신청을 승낙함을 원칙으로 합니다. 다음 각 호에 해당하는 신청에 대해서는 회사가 승낙을 하지 않거나 사후에 이용계약을
            해지할 수 있습니다.
          </span>
          <ul>
            <li>
              <span>가입신청자가 본 약관에 의하여 이전에 사용자 자격을 상실한 적이 있는 경우 (단, 회사의 사용자 재가입 승낙을 얻은 경우에는 예외로 합니다.)</span>
            </li>
            <li>
              <span>실명이 아니거나 타인의 명의를 이용한 경우</span>
            </li>
            <li>
              <span>허위 정보를 기재하거나, 회사가 제시하는 내용을 기재하지 않은 경우</span>
            </li>
            <li>
              <span>만 14세 미만의 신청자인 경우</span>
            </li>
            <li>
              <span>사용자가 서비스의 정상적인 제공을 저해하거나 다른 사용자의 서비스 이용에 지장을 줄 것으로 예상되는 경우</span>
            </li>
            <li>
              <span>사용자의 귀책사유로 인하여 승인이 불가하거나 기타 규정한 제반 사항을 위반하여 신청하는 경우</span>
            </li>
            <li>
              <span>기타 회사가 관련법령 등을 기준으로 하여 명백하게 사회질서 및 미풍양속에 반할 우려가 있다고 판단하는 경우</span>
            </li>
          </ul>
        </li>

        <li>
          <span>회사는 다음에 해당하는 상황에서 회원가입심사를 지연할 수 있습니다.</span>
          <ul>
            <li>
              <span>회사의 설비에 여유가 없거나 기술적 장애가 있는 경우</span>
            </li>
            <li>
              <span>서비스 상의 장애 또는 서비스 이용요금 결제수단의 장애가 발생한 경우</span>
            </li>
            <li>
              <span>기타 회사가 재정적, 기술적으로 필요하다고 인정하는 경우</span>
            </li>
          </ul>
        </li>
        <li>
          <span>회사는 사용자의 종류에 따라 전문기관을 통한 실명확인 및 본인인증을 요청하거나 증빙자료를 추가로 요청할 수 있습니다.</span>
        </li>
        <li>
          <span>사용자가 제공한 정보는 본인의 실제 정보로 간주되며 정보가 허위이거나 현재의 사실과 일치하지 않을 경우에 발생하는 모든 문제에 대해 회사는 책임지지 않습니다.</span>
        </li>
        <li>
          <span>회사와 이용자가 서비스 이용에 관하여 별도 계약을 체결한 경우, 해당 별도 계약이 본 약관에 우선하여 적용됩니다.</span>
        </li>
      </ol>
      <h3>
        <span>제5조 개인정보 보호 및 이용</span>
      </h3>
      <p>
        <span>
          회사는 “개인정보 보호법” 등 관계 법령이 정하는 바에 따라 사용자의 개인정보를 보호하기 위해 노력합니다. 사용자의 개인정보 수집 및 이용에 대해서는 관련 법령, 본 약관, 개인정보 처리방침이
          적용됩니다.
        </span>
      </p>
      <p>
        <span>사용자는 원활한 서비스 이용을 위하여 사용자가 제공받는 서비스와 직접적으로 관련 있는 다음의 사용자에게 아래 열거한 개인정보를 공유할 수 있습니다.</span>
      </p>
      <ul>
        <li>
          <span>제공받는 사용자: 운송회사 관리자</span>
        </li>
        <li>
          <span>공유되는 개인정보: 기사 정보 (성명, 휴대폰 번호, 차량번호) 이동 경로, 이동 거리, 이동 시간, 각 방문지 출발 및 도착시간, 상차 완료 시각, 하차 완료 시각</span>
        </li>
      </ul>
      <p>
        <span>
          회사는 서비스의 원활한 제공을 위하여 관련 법령에 따라 개인정보를 수집 ∙ 이용하며, 개인정보 보호 관련 법령에 따라 안전하게 관리합니다. 개인정보 보호 및 이용에 관련된 상세한 사항은 개인정보
          처리방침에서 확인하실 수 있습니다.
        </span>
      </p>
      <h3>
        <span>제6조 회원 계정</span>
      </h3>
      <ol start="1">
        <li>
          <span>최초 계정의 경우, 본 약관 제4조에서 정한 절차에 따라 사용자가 서비스 이용 신청 후 회사에서 이를 승낙함으로써 생성되며, 회사와 사용 계약을 맺은 모든 기능에 접근할 수 있습니다.</span>
        </li>
        <li>
          <span>
            사용자는 최초로 발급받은 계정을 이용해 새로운 계정을 생성하여 시스템상의 기능을 사용할 수 있습니다. 단, 회사의 내규에 따라 생성 개수가 제한될 수 있으며, 생성된 계정의 개수에 따라 추가
            비용이 발생할 수 있습니다.
          </span>
        </li>
        <li>
          <span>관리자 계정은 로그인 후 노출되는 이용약관 및 개인정보 처리방침 동의를 완료함으로써 사용이 가능합니다.</span>
        </li>
      </ol>
      <h3>
        <span>제7조 회원 정보의 변경</span>
      </h3>
      <p>
        <span>
          사용자는 계정 생성시 작성한 정보나 서비스 이용 과정에서 회사에 제공한 정보가 변경되었을 경우 즉시 사용자 정보를 변경하여야 하며, 변경사항을 수정하지 않아 발생한 모든 불이익에 대하여 회사는
          책임지지 않습니다.
        </span>
      </p>
      <h3>
        <span>제8조 사용자 의무 및 책임</span>
      </h3>
      <ol start="1">
        <li>
          <span>회원으로 가입된 모든 사용자는 본 약관의 규정, 이용 안내와 관련하여 회사가 공지한 사항을 준수할 의무가 있습니다.</span>
        </li>
        <li>
          <span>
            사용자가 생성하거나 제공한 개인정보 및 사용자데이터에 대한 책임은 전적으로 고객(사용자)에게 있습니다. 사이트 또는 시스템 상에 등록된 정보에 변경이 있는 경우, 사용자는 해당 정보를 바로
            업데이트해야 합니다.
          </span>
        </li>
        <li>
          <span>
            사용자는 회사의 사전 서면 동의 없이 본 계약과 관련하여 취득한 기술상, 업무상 및 영업상의 비밀 및 제반 정보를 제3자에게 누설하거나 본 계약의 이행을 위한 목적 이외의 용도로 사용하여서는 안
            됩니다.
          </span>
        </li>
        <li>
          <span>
            사용자는 본 서비스의 이용에 있어서 본 약관 등 회사가 제공하는 각종 정보 및 주의 사항 등을 확인하고 본 서비스의 내용 및 구조를 충분히 이해한 후 자신의 판단과 책임으로 이용해야 합니다.
          </span>
        </li>
        <li>
          <span>사용자는 아이디와 비밀번호에 관한 관리 책임이 있습니다. 사용자는 자신의 아이디 및 비밀번호를 제3자에게 제공, 공개하거나 제3자가 이용하도록 해서는 안 됩니다.</span>
          <ul>
            <li>
              <span>
                사용자가 자신의 아이디 및 비밀번호 유출 또는 제3자 사용에 대해 인지하고도 비밀번호를 변경하지 않은 경우 또는 이와 같은 사정을 회사에 통지하지 않거나 회사의 조치에 응하지 않은 경우 등
                사용자가 본 조의 의무를 준수하지 않아 발생하는 모든 손실이나 손해 등 불이익에 대한 책임은 사용자에게 있습니다.
              </span>
            </li>
            <li>
              <span>사용자의 아이디와 비밀번호가 외부에 누출 되었다고 의심되는 경우, 회사는 계정 잠금 등 보호조치를 취할 수 있으며 사용자의 이용이 제한될 수 있습니다.</span>
            </li>
          </ul>
        </li>
      </ol>

      <h3>
        <span>제9조 회사의 의무 및 책임</span>
      </h3>
      <ol start="1">
        <li>
          <span>회사는 관련 법령을 준수하고, 이 약관이 정하는 권리의 행사와 의무의 이행을 신의에 따라 성실하게 합니다. </span>
        </li>
        <li>
          <span>
            회사는 회원에 대한 계속적이고 안정적인 서비스의 제공을 위하여 서비스 개선 작업 중 설비에 장애가 생기거나 데이터 등이 멸실된 때에는 천재지변, 비상사태, 해결이 곤란한 기술적 결함 등 부득이한
            사유가 없는 한 지체 없이 이를 수리 또는 복구하도록 최선의 노력을 다합니다.
          </span>
        </li>
        <li>
          <span>
            회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보 보호를 위해 보안시스템을 갖추어야 하며 개인정보 처리방침을 공시하고 준수합니다. 회사는 관련 법령, 본 약관, 위치기반서비스 이용약관
            및 개인정보 처리방침에서 정한 경우를 제외하고는 회원의 개인정보가 제3자에게 공개 또는 제공되지 않도록 합니다.
          </span>
        </li>
      </ol>
      <h3>
        <span>제10조</span>
        <span>&nbsp;</span>
        <span>휴면 계정</span>
      </h3>
      <p>
        <span>1. 회사는 1년 이상 로그인하지 않는 사용자의 개인정보를 보호하기 위해 사용자 계정을 휴면계정으로 분류하고 서비스 이용 계약을 해지할 수 있습니다.</span>
      </p>
      <p>
        <span>
          2. 회사는 휴면계정으로 분류되기 30일 전까지 개인정보가 파기되거나 분리되어 저장된다는 사실과 기간 만료일 및 해당 개인정보의 항목을 이메일, 서면, 전화, 또는 유사한 방법 중 하나의 방법으로
          사용자에게 알리도록 합니다. 회사는 사용자가 이를 확인하지 아니함으로 인하여 발생하는 손해에 대해서 책임지지 않습니다.
        </span>
      </p>
      <p>
        <span>3. 회원탈퇴 전 휴면 계정을 이용하여 로그인하는 경우, 회원가입 당시 입력한 정보 확인 후 바로 사용자 계정으로 전환됩니다.</span>
      </p>
      <h3>
        <span>제11조 서비스 제한조치</span>
      </h3>
      <p>
        <span>회사는 다음 각 호의 경우 해당 사용자에 대하여 서비스 제공을 중단할 수 있습니다.</span>
      </p>
      <ol>
        <li>
          <span>사용자가 서비스를 이용함에 있어서 본 약관, 개인정보 처리방침, 관련 법령을 위반한 경우</span>
        </li>
        <li>
          <span>사용자가 서비스를 이용함에 있어서 제3자의 권리나 영업비밀을 침해한 경우</span>
        </li>
        <li>
          <span>사용자가 서비스를 이용함에 있어서 건전한 사회질서를 위반하거나, 미풍양속, 공공질서에 반하는 행위를 하는 경우</span>
        </li>
        <li>
          <span>
            사용자가 회사의 시스템, 서버 등에 과부하를 발생시키거나 자동화 프로그램 등을 통해 기계적인 접근을 하거나, 침입 또는 침입을 시도하는 등 회사의 시스템 및 서버에 부당하게 접근하거나 그러한
            시도를 하는 경우
          </span>
        </li>
        <li>
          <span>사용자가 회사가 명확하게 공지한 운영정책이나 공지사항을 위반하는 경우</span>
        </li>
      </ol>
      <h3>
        <span>제12조 계약 해지</span>
      </h3>
      <ol start="1">
        <li>
          <span>사용자는 이용계약을 해지 하고자 할 때 본인이 직접 홈페이지를 통하여 신청하거나 이메일, 전화 등의 방법을 통하여 회사에 신청하여야 합니다.</span>
        </li>
        <li>
          <span>회사는 전 1항의 규정에 의하여 해지신청이 접수되면 즉시 이용계약을 해지합니다. 단, 별도의 채권, 채무 관계가 있을 경우에는 그러하지 아니합니다.</span>
        </li>
        <li>
          <span>
            회사는 이용자가 다음 각 호에 해당할 경우에는 이용자의 동의 없이 이용계약을 해지할 수 있으며 그 사실을 이용자에게 통지합니다. 다만 회사가 긴급하게 해지할 필요가 있다고 인정하는 경우나
            이용자의 귀책사유로 인하여 통지할 수 없는 경우에는 지체없이 사후 통지로 대체할 수 있습니다.
          </span>
          <ul>
            <li>
              <span>이용자가 본 약관을 위반하고 회사가 안내한 일정기간 이내에 위반 내용을 해소하지 않은 경우</span>
            </li>
            <li>
              <span>회사의 서비스 제공 목적 외의 용도로 서비스를 이용하거나 제3자에게 임의로 서비스를 임대한 경우</span>
            </li>
            <li>
              <span>회사의 이용 요금 등의 납입청구에 대하여 이용자가 이용요금을 체납할 경우</span>
            </li>
          </ul>
        </li>
      </ol>
      <h3>
        <span>제13조 손해배상</span>
      </h3>
      <p>
        <span>
          회사와 이용자는 서비스 이용과 관련하여 고의 또는 과실로 상대방에게 손해를 끼친 경우, 그 손해를 배상하여야 합니다. 단, 회사는 무료로 제공하는 서비스의 이용과 관련하여 개인정보 취급방침에서
          정하는 내용에 위반하지 않는 한 어떠한 손해에 대해서도 책임을 지지 않습니다.
        </span>
      </p>
      <h3>
        <span>제14조 면책</span>
      </h3>
      <ol start="1">
        <li>
          <span>회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.</span>
        </li>
        <li>
          <span>회사는 사용자의 귀책사유로 인한 서비스 이용의 장애에 대하여 책임을 지지 않습니다.</span>
        </li>
        <li>
          <span>
            사용자 또는 회사의 이용계약 해지 등으로 인하여 사용자와 회사의 이용계약이 종료되는 경우, 관계 법령, 본 약관, 위치기반서비스 이용약관 및 개인정보 처리방침에 따라 회사가 사용자정보(개인정보
            및 사용자 데이터를 포함하며, 이하 동일함)를 보유하는 경우를 제외하고는 회사는 보다 나은 서비스 환경 등을 위하여 해지 즉시 사용자의 가입정보 및 관련된 모든 데이터를 삭제할 수 있으며 회사는
            이용계약 종료 이후 사용자의 가입정보 및 관련 데이터 삭제로 인한 책임을 부담하지 않습니다.
          </span>
        </li>
        <li>
          <span>
            회사는 기간통신 사업자가 전기통신서비스를 중지하거나 정상적으로 제공하지 아니하여 사용자에게 손해가 발생한 경우에 대해서 회사의 고의 또는 중대한 과실이 없는 한 책임이 면제됩니다.
          </span>
        </li>
        <li>
          <span>
            회사는 사전에 공지된 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 서비스가 중지되거나 장애가 발생한 경우에 대해서 회사의 고의 또는 중대한 과실이 없는 한 책임이 면제됩니다.
          </span>
        </li>
        <li>
          <span>회사는 사용자의 컴퓨터 환경으로 인하여 발생하는 제반 문제 또는 회사의 고의 또는 중대한 과실이 없는 네트워크 환경으로 인하여 발생하는 문제에 대해서 책임이 면제됩니다.</span>
        </li>
        <li>
          <span>
            회사는 사용자 또는 제3자가 서비스 내 또는 사이트 상에 게시 또는 전송한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 대해서는 회사의 고의 또는 중대한 과실이 없는 한 책임이 면제됩니다.
          </span>
        </li>
        <li>
          <span>회사는 사용자 상호간 또는 사용자와 제3자간에 서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없으며 이로 인한 손해를 배상할 책임도 없습니다.</span>
        </li>
        <li>
          <span>
            회사는 사용자의 컴퓨터 오류에 의한 손해가 발생한 경우 또는 신상정보 및 전자우편주소를 부정확하게 기재하거나 기재하지 아니하여 손해가 발생한 경우에 대하여 회사의 고의 또는 중대한 과실이
            없는 한 책임이 면제됩니다.
          </span>
        </li>
        <li>
          <span>
            회사는 관련 법령, 정부 정책 등에 따라 서비스 또는 사용자에 따라 서비스 이용시간 등을 제한할 수 있으며, 이러한 제한사항 및 제한에 따라 발생하는 서비스 이용 관련 제반사항에 대해서는 책임이
            면제됩니다.
          </span>
        </li>
      </ol>
      <h3>
        <span>제15조 분쟁 조정</span>
      </h3>
      <ol start="1">
        <li>
          <span>본 약관은 대한민국법령에 의하여 규정되고 이행됩니다.</span>
        </li>
        <li>
          <span>본 약관 및 서비스 이용과 관련하여 회사와 이용자 간에 발생된 분쟁에 대해서는 민사소송법 상의 회사의 본사소재지를 관할하는 법원을 합의관할로 합니다.</span>
        </li>
        <li>
          <span>해외에 주소나 거소가 있는 이용자의 경우 회사와 이용자간 발생한 분쟁에 관한 소송은 전항에도 불구하고 대한민국 서울중앙지방법원을 관할법원으로 합니다.</span>
        </li>
      </ol>
      <p>
        <span>본 약관은 2024년 8월 8일부터 적용됩니다.</span>
      </p>
    </div>
  );
};

export default TermsContent;
