import { Api } from '@/api';
import LoadingBackground from '@/components/LoadingBackground';
import ConfirmModal from '@/components/Modal/ConfirmModal';
import { AlertModal, useAlertModal } from '@/components/Modal/useAlertModal';
import useConfirmModal from '@/hooks/useConfirmModal';
import { formatUtil } from '@/utils/FormatUtil';
import { Button, Chip, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Select, SelectItem, useDisclosure, Link } from '@nextui-org/react';
import { useMutation, useQueries, useQuery } from '@tanstack/react-query';
import { FlexTable, FlexTableRow, FlexTableHeader, FlexTableData } from '@/components/FlexTable';
import { FileText } from '@phosphor-icons/react';

const ModalView = ({ isOpenView, onOpenView, onOpenChangeView, onCloseView, selectedId, reloadList, onOpenUpdate }) => {
  const { callAlert } = useAlertModal();

  const groupQuery = useQuery({
    queryKey: ['groups'],
    queryFn: () => Api.groups(),
    select: res => res.data.data,
  });

  const {
    data: placeQuery,
    isLoading,
    isPending,
  } = useQuery({
    queryKey: ['place', selectedId],
    queryFn: () => Api.placeView({ placeId: selectedId }),
    select: res => res.data.data,
    enabled: !!selectedId && isOpenView,
  });

  let place = placeQuery?.place;
  const { confirmOpen, message, requestConfirm, handleConfirm, handleClose } = useConfirmModal();

  const handleDownloadFile = async (placeId, filePath, fileName) => {
    try {
      const response = await Api.placeFile({ placeId, filePath });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(new Blob([response.data]));
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      throw new Error('Error fetching image');
    }
  };

  const handlerUpdatePage = () => {
    onCloseView();
    onOpenUpdate();
  };

  const delPlace = useMutation({
    mutationFn: id => Api.placeDel({ placeId: Number(id) }),
    onSuccess: () => {
      onCloseView();
      callAlert('처리되었습니다.', () => {
        reloadList();
      });
    },
    onError: error => {
      if (error.response.data.code == 'FAIL' && error.response.data.msg) {
        callAlert(error.response.data.msg);
      }
      console.error('Error adding user', error);
    },
  });

  const handlerDelete = id => {
    delPlace.mutate(id);
  };

  return (
    <>
      <>
        {isPending && isOpenView && <LoadingBackground />}
        <Modal isDismissable={false} isOpen={isOpenView} onOpenChange={onOpenChangeView} size="3xl" scrollBehavior={'outside'}>
          <ModalContent>
            {close => (
              <>
                <ModalHeader>방문지 정보</ModalHeader>
                <ModalBody>
                  <FlexTable title={'기본정보'}>
                    <FlexTableRow>
                      <FlexTableHeader>등록일</FlexTableHeader>
                      <FlexTableData>{formatUtil.getDate(place?.regDt, '-')}</FlexTableData>
                      <FlexTableHeader>수정일</FlexTableHeader>
                      <FlexTableData>{formatUtil.getDate(place?.modDt, '-')}</FlexTableData>
                      <FlexTableHeader>작성자</FlexTableHeader>
                      <FlexTableData>{place?.regName}</FlexTableData>
                    </FlexTableRow>
                    <FlexTableRow>
                      <FlexTableHeader>운반회사</FlexTableHeader>
                      <FlexTableData>
                        <Select name="pndGroupId" isDisabled={true} aria-label="pndGroupId" size="md" disallowEmptySelection selectedKeys={[String(place?.groupId)]}>
                          {groupQuery.data?.groupList?.map(item => (
                            <SelectItem key={item.id}>{item.name}</SelectItem>
                          ))}
                        </Select>
                      </FlexTableData>
                    </FlexTableRow>
                    <FlexTableRow>
                      <FlexTableHeader>활성화</FlexTableHeader>
                      <FlexTableData>
                        <Chip size="sm" variant="flat" color={place?.useYn == 'Y' ? 'success' : 'default'}>
                          {place?.useYn == 'Y' ? '활성' : '비활성'}
                        </Chip>
                      </FlexTableData>
                    </FlexTableRow>
                    <FlexTableRow>
                      <FlexTableHeader>방문지명</FlexTableHeader>
                      <FlexTableData>{place?.name}</FlexTableData>
                    </FlexTableRow>
                    <FlexTableRow>
                      <FlexTableHeader>주소</FlexTableHeader>
                      <FlexTableData>
                        <div>
                          <div>
                            <span>(우)</span>
                            <span>{place?.zipcode}</span>
                          </div>
                          <div>
                            {place?.address1} {place?.address2}
                          </div>
                        </div>
                      </FlexTableData>
                    </FlexTableRow>
                    <FlexTableRow>
                      <FlexTableHeader>대표 전화번호</FlexTableHeader>
                      <FlexTableData>{place?.mainTel ? formatUtil.getMobile(place?.mainTel) : '-'}</FlexTableData>
                      <FlexTableHeader>대표 이메일</FlexTableHeader>
                      <FlexTableData>{place?.mainEmail || '-'}</FlexTableData>
                    </FlexTableRow>
                    <FlexTableRow>
                      <FlexTableHeader>문서</FlexTableHeader>
                      <FlexTableData>
                        {place?.fileName1 || place?.fileName2 || place?.fileName3 ? (
                          <div className="flex flex-wrap gap-2">
                            {place?.fileName1 && (
                              <Button
                                variant="flat"
                                className="h-auto min-w-0 min-h-0 gap-1 p-1 px-2"
                                startContent={<FileText weight="fill" className="text-default-500" />}
                                radius="full"
                                onPress={() => handleDownloadFile(place?.id, place?.filePath1, place?.fileName1)}
                              >
                                {place?.fileName1}
                              </Button>
                            )}
                            {place?.fileName2 && (
                              <Button
                                variant="flat"
                                className="h-auto min-w-0 min-h-0 gap-1 p-1 px-2"
                                startContent={<FileText weight="fill" className="text-default-500" />}
                                radius="full"
                                onPress={() => handleDownloadFile(place?.id, place?.filePath2, place?.fileName2)}
                              >
                                {place?.fileName2}
                              </Button>
                            )}
                            {place?.fileName3 && (
                              <Button
                                variant="flat"
                                className="h-auto min-w-0 min-h-0 gap-1 p-1 px-2"
                                startContent={<FileText weight="fill" className="text-default-500" />}
                                radius="full"
                                onPress={() => handleDownloadFile(place?.id, place?.filePath3, place?.fileName3)}
                              >
                                {place?.fileName3}
                              </Button>
                            )}
                          </div>
                        ) : (
                          '-'
                        )}
                      </FlexTableData>
                    </FlexTableRow>
                    <FlexTableRow>
                      <FlexTableHeader>메모</FlexTableHeader>
                      <FlexTableData>{place?.memo || '-'}</FlexTableData>
                    </FlexTableRow>
                  </FlexTable>
                  <div>담당자 정보</div>
                  <FlexTable>
                    <FlexTableRow>
                      <FlexTableHeader>담당자</FlexTableHeader>
                      <FlexTableData>{place?.managerName ? place?.managerName : '-'}</FlexTableData>
                      <FlexTableHeader>담당자 전화번호</FlexTableHeader>
                      <FlexTableData>{place?.managerTel ? formatUtil.getMobile(place?.managerTel) : '-'}</FlexTableData>
                    </FlexTableRow>
                    {(place?.managerName2 || place?.managerTel2) && (
                      <FlexTableRow>
                        <FlexTableHeader>담당자 2</FlexTableHeader>
                        <FlexTableData>{place?.managerName2 ? place?.managerName2 : '-'}</FlexTableData>
                        <FlexTableHeader>담당자 전화번호</FlexTableHeader>
                        <FlexTableData>{place?.managerTel2 ? formatUtil.getMobile(place?.managerTel2) : '-'}</FlexTableData>
                      </FlexTableRow>
                    )}
                    {(place?.managerName3 || place?.managerTel3) && (
                      <FlexTableRow>
                        <FlexTableHeader>담당자 3</FlexTableHeader>
                        <FlexTableData>{place?.managerName3 ? place?.managerName3 : '-'}</FlexTableData>
                        <FlexTableHeader>담당자 전화번호</FlexTableHeader>
                        <FlexTableData>{place?.managerTel3 ? formatUtil.getMobile(place?.managerTel3) : '-'}</FlexTableData>
                      </FlexTableRow>
                    )}
                    <FlexTableRow>
                      <FlexTableHeader>알림톡 전화번호</FlexTableHeader>
                      <FlexTableData>{place?.mobile ? formatUtil.getMobile(place?.mobile) : '-'}</FlexTableData>
                    </FlexTableRow>
                  </FlexTable>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="danger"
                    className="mr-auto"
                    isLoading={isLoading}
                    isDisabled={isLoading}
                    onPressEnd={() => {
                      requestConfirm({
                        message: '삭제하시겠습니까??',
                        onConfirm: () => {
                          handlerDelete(selectedId);
                        },
                      });
                    }}
                  >
                    삭제
                  </Button>
                  <Button color="default" onPress={onCloseView}>
                    닫기
                  </Button>
                  <Button color="primary" onPress={handlerUpdatePage}>
                    수정
                  </Button>
                </ModalFooter>
              </>
            )}
          </ModalContent>
        </Modal>
        <ConfirmModal isOpen={confirmOpen} title={message} onConfirm={handleConfirm} onClose={handleClose} />
        <AlertModal />
      </>
    </>
  );
};

export default ModalView;
