import { Api } from '@/api';
import ConfirmModal from '@/components/Modal/ConfirmModal';
import { AlertModal, useAlertModal } from '@/components/Modal/useAlertModal';
import useConfirmModal from '@/hooks/useConfirmModal';
import { formatUtil } from '@/utils/FormatUtil';
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from '@nextui-org/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';

const ModalUpdate = ({ isOpenUpdate, onOpenChangeUpdate, onCloseUpdate, selectedId }) => {
  const queryClient = useQueryClient();

  const reloadTerms = () => {
    queryClient.invalidateQueries(['driverTerms', selectedId]);
  };

  const { confirmOpen, message, requestConfirm, handleConfirm, handleClose } = useConfirmModal();

  const { callAlert } = useAlertModal();

  const menuQuery = useQuery({
    queryKey: ['menu'],
    queryFn: Api.menu,
    select: res => res.data.data.menuMap,
  });

  const termsQuery = useQuery({
    queryKey: ['driverTerms', selectedId],
    queryFn: () => Api.driverTermsView({ id: selectedId }),
    select: res => res.data.data,
    enabled: !!selectedId,
  });

  const termsData = termsQuery.data?.driverTerms;
  const codeMap = termsQuery.data?.codeMap;

  const updateTerms = useMutation({
    mutationFn: values => Api.driverTermsUpdate(values),
  });

  const handleBackPage = () => {
    onCloseUpdate();
  };

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, validateForm, resetForm, setValues } = useFormik({
    initialValues: {
      id: termsData?.id || '',
      pndGroupId: termsData?.pndGroupId || '',
      termsTypeCode: termsData?.termsTypeCode || '',
      termsTitle: termsData?.termsTitle || '',
      termsContent: termsData?.termsContent || '',
      termDate: termsData?.termDate || '',
      showYn: termsData?.showYn || '',
    },
    validationSchema: Yup.object({
      termsTitle: Yup.string().required('제목을 입력하세요.'),
      termsContent: Yup.string().required('내용을 입력하세요.'),
      // termDate: Yup.string().required('날짜를 선택하세요.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      updateTerms.mutate(values, {
        onSettled: () => {
          setSubmitting(false);
        },
        onSuccess: () => {
          callAlert('수정되었습니다.', () => {
            onCloseUpdate();
            reloadTerms();
          });
        },
        onError: error => {
          console.error('수정 중 오류가 발생했습니다:', error);
          callAlert('수정 중 오류가 발생했습니다.');
        },
      });
    },
  });

  useEffect(() => {
    if (values.adminTel) {
      const value = values.adminTel.replace(/\D/g, '');
      setFieldValue('adminTel', formatUtil.getTel(value));
    }
  }, [values.adminTel, setFieldValue]);

  const handleRegisterClick = async () => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      requestConfirm({
        message: '수정 하시겠습니까?',
        onConfirm: handleSubmit,
      });
    } else {
      callAlert('입력한 데이터가 올바르지 않습니다.');
    }
  };

  useEffect(() => {
    if (!isOpenUpdate) {
      resetForm();
    } else if (termsQuery.data) {
      setValues({
        id: termsData?.id || '',
        pndGroupId: termsData?.pndGroupId || '',
        termsTypeCode: termsData?.termsTypeCode || '',
        termsTitle: termsData?.termsTitle || '',
        termsContent: termsData?.termsContent || '',
        termDate: termsData?.termDate || '',
        showYn: termsData?.showYn || '',
      });
    }
  }, [isOpenUpdate, termsQuery.data, resetForm, setValues]);

  return (
    <>
      <Modal isDismissable={false} isOpen={isOpenUpdate} onOpenChange={onOpenChangeUpdate} size="5xl" scrollBehavior={'outside'}>
        <ConfirmModal isOpen={confirmOpen} title={message} onConfirm={handleConfirm} onClose={handleClose} />(
        <ModalContent>
          <form
            onSubmit={event => {
              event.preventDefault();
              handleSubmit();
            }}
          >
            <ModalHeader>약관관리 수정</ModalHeader>
            <ModalBody></ModalBody>
            <ModalFooter>
              <Button color="primary" isDisabled={!termsQuery.data} onPress={handleRegisterClick}>
                확인
              </Button>
              <Button color="default" className="ml-auto" onPress={handleBackPage}>
                뒤로가기
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
      <AlertModal />
    </>
  );
};

export default ModalUpdate;
