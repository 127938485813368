import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import MainLayout from '@/layout/MainLayout';
import OnlyFooterLayout from '@/layout/OnlyFooterLayout';
import LoginPage from '@/pages/LoginPage';
import DriverList from '@/pages/Driver/List';
import VehicleList from '@/pages/Vehicle/List';
import AdminList from '@/pages/Admin/List';
import TermsList from '@/pages/Terms/List';
import VersionList from '@/pages/Version/List';
import RoleList from '@/pages/Role/List';
import PlaceList from '@/pages/Place/List';
import ProtectedRoute from './ProtectedRoute';
import TermsAgree from '@/pages/Terms/Agree';
import PlanList from '@/pages/Plan/List';
import ScheduleList from '@/pages/Schedule/List';
import JobList from '@/pages/Job/List';
import EasyPlanList from '@/pages/EasyPlan/List';
import Dashboard from '@/pages/Dashboard/View';
import Map from '@/pages/Map/View';
import JobCostList from '@/pages/JobCost/List';
import GroupList from '@/pages/Group/List';

import { AlertModalProvider } from '@/components/Modal/useAlertModal';
import ErrorPage from '@/pages/ErrorPage';
import OnlySidebarLayout from '@/layout/OnlySidebarLayout';

export default function Router() {
  return (
    <AlertModalProvider>
      <Routes>
        <Route element={<ProtectedRoute Layout={MainLayout} />}>
          <Route path="/" element={<Navigate to="/plan" replace={true} />}></Route>
          <Route path="/dashboard" element={<Dashboard />}></Route>
          <Route path="/terms" element={<TermsList />}></Route>
          <Route path="/version" element={<VersionList />}></Route>
          <Route path="/driver" element={<DriverList />}></Route>
          <Route path="/admin" element={<AdminList />}></Route>
          <Route path="/roles" element={<RoleList />}></Route>
          <Route path="/vehicle" element={<VehicleList />}></Route>
          <Route path="/place" element={<PlaceList />}></Route>
          <Route path="/termsAgree" element={<TermsAgree />}></Route>
          <Route path="/schedule" element={<ScheduleList />}></Route>
          <Route path="/plan" element={<PlanList />}></Route>
          <Route path="/EasyPlan" element={<EasyPlanList />}></Route>
          <Route path="/job" element={<JobList />}></Route>
          <Route path="/jobCost" element={<JobCostList />}></Route>
          <Route path="/group" element={<GroupList />}></Route>
          <Route path="/group" element={<GroupList />}></Route>
        </Route>
        <Route element={<ProtectedRoute Layout={OnlySidebarLayout} />}>
          <Route path="/map" element={<Map />}></Route>
        </Route>
        <Route element={<OnlyFooterLayout />}>
          <Route path="/login" element={<LoginPage />}></Route>
        </Route>
        <Route path="/*" element={<ErrorPage />}></Route>
      </Routes>
    </AlertModalProvider>
  );
}
