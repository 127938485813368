import { Api } from '@/api';
import LoadingBackground from '@/components/LoadingBackground';
import ConfirmModal from '@/components/Modal/ConfirmModal';
import { AlertModal, useAlertModal } from '@/components/Modal/useAlertModal';
import useCodeMap from '@/hooks/useCodeMap';
import useConfirmModal from '@/hooks/useConfirmModal';
import { useInput } from '@/hooks/useInput';
import { formatUtil } from '@/utils/FormatUtil';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  SelectItem,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  getKeyValue,
  useDisclosure,
} from '@nextui-org/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import ModalVehcleAdd from './ModalVehcleAdd';

const columns = [
  { name: '차량번호', uid: 'vehicleNo' },
  { name: '기사', uid: 'name' },
  { name: '기사전화번호', uid: 'tel' },
  { name: '차량구분', uid: 'vehicleType' },
  { name: '차량소속', uid: 'company' },
  { name: '최근방문지(상차지-하차지)', uid: 'recently' },
];

const filter = [
  { label: '차량번호', key: 'vehilceNoLike' },
  { label: '기사', key: 'driverNameLike' },
  { label: '기사전화번호', key: 'driverTelLike' },
  { label: '최근상차지', key: 'lastPickupPlaceNameLike' },
  { label: '최근하차지', key: 'lastDeliveryPlaceNameLike' },
];

const ModalDirectDriver = ({ isOpenDirect, onOpenChangeDirect, onCloseDirect, selectedId, planDate, toPlaceId, handleAddVehicle, groupId }) => {
  const { isOpen: isOpenAdd, onOpen: onOpenAdd, onOpenChange: onOpenChangeAdd, onClose: onCloseAdd } = useDisclosure();

  const queryClient = useQueryClient();

  const { data: vehicleFlagMap } = useCodeMap('VEHICLE_FLAG');
  const { data: vehicleTypeMap } = useCodeMap('VEHICLE_TYPE');

  const { callAlert } = useAlertModal();
  const { confirmOpen, message, requestConfirm, handleConfirm, handleClose } = useConfirmModal();

  const [selectedKey, setSelectedKey] = useState(new Set([]));

  const [searchTypeInput, searchTypeChange, searchTypeSubmit, searchTypeReset] = useInput('vehilceNoLike');
  const [searchTextInput, searchTextChange, searchTextSubmit, searchTextReset] = useInput('');

  let param = { planDate: planDate, deliveryPlaceId: toPlaceId, orderType: 10 };
  if (searchTypeInput && searchTextInput) {
    param[searchTypeInput] = searchTextInput;
  }
  const { data: planDrivers, isLoading } = useQuery({
    queryKey: ['planDrivers', { param }],
    queryFn: () => {
      return Api.planDrivers(param);
    },
    select: res => res.data.data,
    enabled: !!toPlaceId && !!isOpenDirect && !!vehicleTypeMap,
  });

  const reloadDriver = () => {
    queryClient.invalidateQueries(['planDrivers', param]);
  };

  //const debounce = useDebounce(params.searchText, 500);

  const planDirect = useMutation({
    mutationFn: values => Api.planDirect(values),
  });

  const renderCell = useCallback((item, columnKey) => {
    const jobPlanDeliveryLastSum = item.jobPlanDeliveryLastSum;
    switch (columnKey) {
      case 'tel':
        return <div>{formatUtil.getMobile(item.tel)}</div>;
      case 'price':
        return <div>{formatUtil.getNumber(jobPlanDeliveryLastSum.price)}</div>;
      case 'vehicleFlag':
        return <div>{vehicleFlagMap?.codeMap[item.vehicleFlag]}</div>;
      case 'vehicleType':
        return <div>{vehicleFlagMap?.codeMap[item.vehicleType] ? vehicleFlagMap?.codeMap[item.vehicleType] : '-'}</div>;
      case 'company':
        return <div>{item?.company ? item.company : '-'}</div>;
      case 'recently': {
        let picup = item?.lastPickupPlaceName ? item?.lastPickupPlaceName : '';
        let delivery = item?.lastDeliveryPlaceName ? item?.lastDeliveryPlaceName : '';
        return <div>{picup + ' - ' + delivery}</div>;
      }
      default:
        return getKeyValue(item, columnKey);
    }
  }, []);

  useEffect(() => {
    setSelectedKey(new Set([]));
    searchTypeChange({ target: { value: 'vehilceNoLike' } });
    searchTextReset();
  }, [isOpenDirect]);

  return (
    <>
      <Modal isDismissable={false} isOpen={isOpenDirect} onOpenChange={onOpenChangeDirect} size="5xl" scrollBehavior={'outside'}>
        <ConfirmModal isOpen={confirmOpen} title={message} onConfirm={handleConfirm} onClose={handleClose} />
        <ModalContent>
          <ModalHeader>직접 기사 배정</ModalHeader>
          <ModalBody>
            <div className={'text-zinc-400 text-sm'}>최근 30일 운행 현황 정보를 확인하고 배차하세요. 최근 30일 운행이 집계된 내역입니다. 기사는 1명만 배정 가능합니다.</div>
            <div className="flex gap-4">
              <Select
                size="sm"
                labelPlacement="outside"
                label="검색"
                disallowEmptySelection
                className="max-w-48"
                selectedKeys={[searchTypeInput ? searchTypeInput : 'vehilceNoLike']}
                items={filter}
                onChange={searchTypeChange}
                name="searchType"
              >
                {item => <SelectItem key={item.value}>{item.label}</SelectItem>}
              </Select>
              <Input labelPlacement="outside" label=" " size="sm" className="w-full" placeholder="검색어를 입력하세요" value={searchTextInput} onChange={searchTextChange} name="searchText" />
            </div>
            <Table isHeaderSticky color={'primary'} aria-label="content" selectionMode="single" selectedKeys={selectedKey} onSelectionChange={setSelectedKey}>
              <TableHeader columns={columns}>
                {column => (
                  <TableColumn key={column.uid} allowsSorting={column.sortable}>
                    {column.name}
                  </TableColumn>
                )}
              </TableHeader>
              <TableBody emptyContent={'등록된 정보가 없습니다.'} items={planDrivers?.driverList ? planDrivers?.driverList : []} isLoading={isLoading} loadingContent={<Spinner></Spinner>}>
                {item => <TableRow key={item.id}>{columnKey => <TableCell>{renderCell(item, columnKey)}</TableCell>}</TableRow>}
              </TableBody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <Button color="default" className="ml-auto" onPress={onCloseDirect}>
              닫기
            </Button>
            <Button
              color="primary"
              isDisabled={!planDrivers?.driverList}
              onPress={() => {
                onOpenAdd();
              }}
            >
              차량추가하기
            </Button>
            <Button
              color="primary"
              isDisabled={!planDrivers?.driverList}
              onPress={() => {
                if (!selectedKey?.currentKey) {
                  callAlert('배차할 기사를 선택하세요.');
                  return;
                }
                const selectedItem = planDrivers?.driverList.find(item => {
                  return String(item.id) === selectedKey?.currentKey;
                });

                let { vehicleId, vehicleNo, id, vehicleFlag, vehicleCompany, name, tel } = selectedItem;
                handleAddVehicle(vehicleId, selectedId, vehicleNo, id, vehicleFlagMap?.codeMap[vehicleFlag], vehicleCompany, name, tel);
                onCloseDirect();
              }}
            >
              배차하기
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {planDirect.isPending && <LoadingBackground />}
      <AlertModal />
      <ModalVehcleAdd isOpenAdd={isOpenAdd} onOpenChangeAdd={onOpenChangeAdd} onCloseAdd={onCloseAdd} reloadList={reloadDriver} groupId={groupId}></ModalVehcleAdd>
    </>
  );
};

export default ModalDirectDriver;
