import { Api } from '@/api/index';
import ContentWrapper from '@/components/ContentWrapper';
import LoadingBackground from '@/components/LoadingBackground';
import PaginationTable from '@/components/PaginationTable';
import useCustomSearchParams from '@/hooks/useCustomSearchParams';
import VersionView from '@/pages/Version/ModalView';
import { formatUtil } from '@/utils/FormatUtil';
import { getKeyValue, useDisclosure } from '@nextui-org/react';
import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useRef } from 'react';
import VersionAdd from './ModalAdd';
import ModalUpdate from './ModalUpdate';

const columns = [
  { name: 'No', uid: 'no' },
  { name: '앱이름', uid: 'appName' },
  { name: '플랫폼', uid: 'appTypeCode' },
  { name: '버전', uid: 'version' },
  { name: '패치내용', uid: 'description' },
  { name: '등록일시', uid: 'regDt' },
];

const List = () => {
  const { isOpen: isOpenView, onOpen: onOpenView, onOpenChange: onOpenChangeView, onClose: onCloseView } = useDisclosure();
  const { isOpen: isOpenAdd, onOpen: onOpenAdd, onOpenChange: onOpenChangeAdd, onClose: onCloseAdd } = useDisclosure();
  const { isOpen: isOpenUpdate, onOpen: onOpenUpdate, onOpenChange: onOpenChangeUpdate, onClose: onCloseUpdate } = useDisclosure();

  const selectedId = useRef('');
  const queryClient = useQueryClient();

  const initialParams = {
    // pndGroupId: searchParams.get('pndGroupId') || '',
  };

  const { params, queryKey, onRowsPerPageChange, onPageChange } = useCustomSearchParams(initialParams);

  const fetchVersionList = () => {
    const postParams = { pageNo: params.pageNo, pageSize: params.pageSize };
    const trimParams = Object.fromEntries(Object.entries(postParams).filter(([, value]) => value));
    return Api.versionList(trimParams);
  };

  const reloadList = () => {
    queryClient.invalidateQueries(['versionList', { ...queryKey }]);
  };

  const listQuery = useQuery({
    queryKey: ['versionList', { ...queryKey }],
    queryFn: fetchVersionList,
    placeholderData: keepPreviousData,
    select: res => res.data.data,
  });

  const renderCell = useCallback(
    (item, columnKey, idx) => {
      switch (columnKey) {
        case 'no': {
          const rowNumber = Number(listQuery?.data?.appVersionPage?.totalElements ? listQuery.data.appVersionPage.totalElements : 0) - ((Number(params.pageNo) - 1) * Number(params.pageSize) + idx);
          return <div>{rowNumber}</div>;
        }
        case 'regDt': {
          return <div>{formatUtil.getDate(getKeyValue(item, columnKey), '-')}</div>;
        }
        default: {
          return getKeyValue(item, columnKey);
        }
      }
    },
    [listQuery?.data?.appVersionPage?.totalElements, params.pageNo, params.pageSize]
  );

  const handlerRowClick = item => {
    onOpenView();
    selectedId.current = item.id;
  };

  const handlerAddClick = () => {
    onOpenAdd();
  };

  return (
    <ContentWrapper>
      {listQuery.isFetching && <LoadingBackground />}
      <VersionAdd isOpenAdd={isOpenAdd} onOpenChangeAdd={onOpenChangeAdd} onCloseAdd={onCloseAdd} reloadList={reloadList}></VersionAdd>
      {isOpenView && (
        <VersionView
          isOpenView={isOpenView}
          onOpenChangeView={onOpenChangeView}
          onCloseView={onCloseView}
          selectedId={selectedId.current}
          reloadList={reloadList}
          onOpenView={onOpenView}
          onOpenUpdate={onOpenUpdate}
        ></VersionView>
      )}

      <ModalUpdate
        isOpenUpdate={isOpenUpdate}
        onOpenChangeUpdate={onOpenChangeUpdate}
        onCloseUpdate={onCloseUpdate}
        size="5xl"
        selectedId={selectedId.current}
        scrollBehavior={'outside'}
        onOpenView={onOpenView}
      />

      <PaginationTable
        data={{
          page: params.pageNo,
          size: params.pageSize,
          totalPages: listQuery?.data?.appVersionPage?.totalPages,
          totalElements: listQuery?.data?.appVersionPage?.totalElements,
          columns: columns,
          items: listQuery?.data?.appVersionPage?.content,
        }}
        renderCell={renderCell}
        isFetching={listQuery.isFetching}
        isLoading={listQuery.isLoading}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        onRowClick={handlerRowClick}
        onAddClick={handlerAddClick}
      />
    </ContentWrapper>
  );
};

export default List;
