import { Api } from '@/api';
import ConfirmModal from '@/components/Modal/ConfirmModal';
import useConfirmModal from '@/hooks/useConfirmModal';
import { Button, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Select, SelectItem, Tab, Tabs } from '@nextui-org/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { AlertModal, useAlertModal } from '@/components/Modal/useAlertModal';

import { formatUtil } from '@/utils/FormatUtil';

const ModalAdd = ({ isOpenAdd, onOpenChangeAdd, onCloseAdd, reloadList }) => {
  const { callAlert } = useAlertModal();
  const { confirmOpen, message, requestConfirm, handleConfirm, handleClose } = useConfirmModal();

  const groupQuery = useQuery({
    queryKey: ['groups'],
    queryFn: () => Api.groups(),
    select: res => res.data.data,
  });

  const addDriver = useMutation({
    mutationFn: values => Api.driverAdd(values),
  });

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, validateForm, resetForm } = useFormik({
    initialValues: {
      useYn: '',
      pndGroupId: '',
      vehicleId: '-1',
      name: '',
      tel: '',
      company: '',
      memo: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('기사명을 입력하세요.'),
      tel: Yup.string()
        .matches(/^\d{2,3}-\d{3,4}-\d{4}$/, '전화번호 형식이 잘못되었습니다.')
        .required('전화번호를 입력하세요.'),
      //vehicleId: Yup.string().required('차량을 선택하세요.'),
      // company: Yup.string().required('소속을 입력하세요.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      addDriver.mutate(values, {
        onSettled: () => {
          setSubmitting(false);
        },
        onSuccess: () => {
          callAlert('등록되었습니다.', () => {
            onCloseAdd();
            reloadList();
          });
        },
        onError: error => {
          if (error?.response?.data?.code === 'FAIL' && error?.response?.data?.msg) {
            callAlert(error?.response?.data?.msg);
          } else {
            callAlert('등록에 실패하였습니다.');
          }
        },
      });
    },
  });

  let groupId = values?.pndGroupId ? values?.pndGroupId : groupQuery.data?.groupList[0].id;

  let vehicleList = useQuery({
    queryKey: ['vehicleList', groupId],
    queryFn: () => Api.vehicleList({ pndGroupId: groupId }),
    select: res => res.data.data,
    enabled: !!groupId,
  });

  useEffect(() => {
    if (values.tel) {
      const value = values.tel.replace(/\D/g, '');
      setFieldValue('tel', formatUtil.getTel(value));
    }
  }, [values.tel, setFieldValue]);

  const handleRegisterClick = async () => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      requestConfirm({
        message: '등록 하시겠습니까?',
        onConfirm: handleSubmit,
      });
    } else {
      Object.keys(errors).map(item => {
        touched[item] = true;
      });
      callAlert('입력한 데이터가 올바르지 않습니다.');
    }
  };

  useEffect(() => {
    resetForm();
    Object.keys(errors).map(item => {
      touched[item] = false;
    });
  }, [isOpenAdd]);

  const handleSelectVehicle = e => {
    setFieldValue('vehicleId', String(e.target.value));
  };

  useEffect(() => {
    if (groupQuery.data?.groupList && isOpenAdd) {
      setFieldValue('pndGroupId', String(groupQuery.data.groupList[0].id));
    }
  }, [groupQuery?.data, isOpenAdd]);

  return (
    <>
      <Modal isDismissable={false} isOpen={isOpenAdd} onOpenChange={onOpenChangeAdd} size="5xl" scrollBehavior={'outside'}>
        <ConfirmModal isOpen={confirmOpen} title={message} onConfirm={handleConfirm} onClose={handleClose} />
        <ModalContent>
          <form
            onSubmit={event => {
              event.preventDefault();
              handleSubmit();
            }}
          >
            <ModalHeader>기사 등록</ModalHeader>
            <ModalBody>
              <div className="flex flex-col space-y-4">
                <div className="">
                  <div className="mb-2 text-sm">운반회사</div>
                  <Select name="pndGroupId" aria-label="pndGroupId" size="md" onChange={handleChange} disallowEmptySelection selectedKeys={[String(values.pndGroupId)]}>
                    {groupQuery.data?.groupList?.map(item => (
                      <SelectItem key={item.id}>{item.name}</SelectItem>
                    ))}
                  </Select>
                </div>

                <div>
                  <div className="mb-2 text-sm">
                    활성화<span className="text-rose-500">*</span>
                  </div>
                  <Tabs
                    fullWidth
                    selectedKey={values.useYn}
                    onSelectionChange={e => {
                      if (e !== values.useYn) {
                        setFieldValue('useYn', e);
                      }
                    }}
                  >
                    <Tab key={'Y'} title="활성" />
                    <Tab key={'N'} title="비활성" />
                  </Tabs>
                </div>

                <div className="">
                  <div className="mb-2 text-sm">소속</div>
                  <Input
                    name="company"
                    labelPlacement="outside"
                    placeholder="소속을 입력하세요"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    // isInvalid={touched.company && !!errors.company}
                    // errorMessage={touched.company && errors.company}
                  ></Input>
                </div>

                <div className="">
                  <div className="mb-2 text-sm">기사명</div>
                  <Input
                    name="name"
                    labelPlacement="outside"
                    placeholder="기사명을 입력하세요"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.name && !!errors.name}
                    errorMessage={touched.name && errors.name}
                  ></Input>
                </div>
                <div className="">
                  <div className="mb-2 text-sm">기사 전화번호</div>
                  <Input
                    name="tel"
                    labelPlacement="outside"
                    placeholder="010-0000-0000"
                    value={values.tel}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    maxLength={13}
                    isInvalid={touched.tel && !!errors.tel}
                    errorMessage={touched.tel && errors.tel}
                  ></Input>
                </div>

                <div className="">
                  <div className="mb-2 text-sm">차량번호</div>
                  <Select name="vehicleId" size="md" aria-label="vehicleId" onChange={handleSelectVehicle} disallowEmptySelection selectedKeys={[String(values?.vehicleId)]}>
                    <SelectItem key={'-1'}>{'미배정'}</SelectItem>
                    {vehicleList?.data?.vehiclePage?.content?.map(item => (
                      <SelectItem key={item.id}>{item.vehicleNo}</SelectItem>
                    ))}
                  </Select>
                </div>
                <div className="">
                  <div className="mb-2 text-sm">메모</div>
                  <Input
                    name="memo"
                    labelPlacement="outside"
                    placeholder="메모를 입력하세요."
                    value={values.memo}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.memo && !!errors.memo}
                    errorMessage={touched.memo && errors.memo}
                  ></Input>
                </div>
              </div>
            </ModalBody>
            <ModalFooter></ModalFooter>
            <ModalFooter>
              <Button color="default" className="ml-auto" onPress={onCloseAdd}>
                닫기
              </Button>
              <Button isLoading={addDriver.isLoading} color="primary" disabled={isSubmitting} onPress={handleRegisterClick}>
                등록
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
      <AlertModal />
    </>
  );
};

export default ModalAdd;
