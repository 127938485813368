import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Api } from '@/api';

function useCodeMap(typeName) {
  return useQuery({
    queryKey: ['codeMap', typeName],
    queryFn: () => Api.codeMap({ cate: typeName }),
    select: res => res.data.data,
  });
}

export default useCodeMap;
