import { Api } from '@/api';
import { CusChip } from '@/components/common/customChip';
import DynamicIcon from '@/components/DynamicIcon';
import LoadingBackground from '@/components/LoadingBackground';
import useDebounce from '@/hooks/useDebounce';
import { Button, Input, Listbox, ListboxItem, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from '@nextui-org/react';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

const ModalAddress = ({ isOpenAddress, onOpenChangeAddress, onCloseAddress, setFieldValue, addrName, zipName }) => {
  const [query, setQuery] = useState('');
  const debounceQuery = useDebounce(query, 500);

  const {
    data: addressQuery,
    isPending,
    isLoading,
  } = useQuery({
    queryKey: ['address', query],
    queryFn: () => Api.placeAddress({ query: query }),
    select: res => res.data.data.addressList,
    enabled: isOpenAddress && !!debounceQuery,
  });

  let combinedAddressList = addressQuery;

  const onInputChange = e => {
    setQuery(e.target.value);
  };

  const handleAddrClick = (name, zoneNo = '', x, y) => {
    if (name) {
      setFieldValue(addrName, name);
    }
    setFieldValue(zipName, zoneNo);
    if (x) setFieldValue('x', x);
    if (y) setFieldValue('y', y);
    onCloseAddress();
  };

  function HighlightText({ text, searchTerm }) {
    const regex = new RegExp(searchTerm.split('').join('\\s*'), 'i');
    const match = text.match(regex);

    if (!match) {
      return <span>{text}</span>;
    }

    const index = match.index;
    const matchText = match[0];
    const beforeText = text.substring(0, index);
    const afterText = text.substring(index + matchText.length);

    return (
      <span>
        {beforeText}
        <span className="text-sky-500">{matchText}</span>
        {afterText}
      </span>
    );
  }

  useEffect(() => {
    combinedAddressList = [];
    setQuery('');
  }, [isOpenAddress]);

  return (
    <>
      {isLoading && isPending && isOpenAddress && <LoadingBackground />}
      <Modal isDismissable={false} isOpen={isOpenAddress} onOpenChange={onOpenChangeAddress} size="3xl" scrollBehavior={'outside'}>
        <ModalContent>
          {close => (
            <>
              <ModalHeader>주소찾기</ModalHeader>
              <ModalBody>
                <div>
                  <Input
                    name="company"
                    labelPlacement="outside"
                    placeholder="예) 판교역로 166, 분당 주공, 백현동 532"
                    onChange={onInputChange}
                    endContent={<DynamicIcon iconName={'MagnifyingGlass'} weight={'light'} className="text-default-600"></DynamicIcon>}
                    autocomplete="off"
                  ></Input>
                </div>
                <div className="max-h-[500px] overflow-auto">
                  <Listbox className="mt-1" variant="light" disallowEmptySelection>
                    {combinedAddressList?.length > 0 ? (
                      combinedAddressList.map((item, idx) => {
                        return (
                          <ListboxItem key={idx} hideSelectedIcon={true} showDivider={true} shouldHighlightOnFocus={true}>
                            <div className={'flex items-center justify-between'}>
                              <div className="flex justify-center w-1/6 mr-2 text-rose-500">
                                {item?.roadAddress?.zoneNo ? item?.roadAddress?.zoneNo : item?.address?.zoneNo ? item?.address?.zoneNo : '우편번호 없음'}
                              </div>
                              <div className="w-full ">
                                <div
                                  className="p-1 rounded-lg hover:bg-gray-200"
                                  onClick={() => {
                                    if (item?.roadAddress?.addressName) {
                                      handleAddrClick(item?.roadAddress?.addressName, item.roadAddress?.zoneNo, item.roadAddress.x, item.roadAddress.y);
                                    }
                                  }}
                                >
                                  <CusChip color={{ bg: 'bg-blue-50', text: 'text-blue-500' }}>도로명</CusChip>
                                  <span> {item?.roadAddress?.addressName ? <HighlightText text={item.roadAddress?.addressName} searchTerm={query} /> : '-'}</span>
                                </div>
                                <div
                                  className="p-1 rounded-lg hover:bg-gray-200"
                                  onClick={() => {
                                    if (item?.address?.addressName) {
                                      handleAddrClick(item?.address?.addressName, item.address?.zoneNo, item.address.x, item.address.y);
                                    }
                                  }}
                                >
                                  <CusChip color={{ bg: 'bg-gray-50', text: 'text-gray-500' }}>지번</CusChip>
                                  <span> {item?.address?.addressName ? <HighlightText text={item.address?.addressName} searchTerm={query} /> : '-'}</span>
                                </div>
                              </div>
                            </div>
                          </ListboxItem>
                        );
                      })
                    ) : (
                      <ListboxItem className="text-default-400">{query.length > 0 ? '등록된 정보가 없습니다.' : ''}</ListboxItem>
                    )}
                  </Listbox>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="default" onPress={onCloseAddress}>
                  닫기
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalAddress;
