import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx-js-style';

const EXCEL_FILE_EXTENSION = '.xlsx';
const EXCEL_FILE_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

export const ExcelDownloadUtil = (data, sheetName, fileName, columns, columnWidths) => {
  const header = columns.map(col => col.v);
  const bodyData = data.map((row, idx) => [idx + 1, ...row]);

  const ws = XLSX.utils.aoa_to_sheet([header]);

  //const ws = XLSX.utils.aoa_to_sheet([columns]);

  bodyData.forEach((row, idx) => {
    XLSX.utils.sheet_add_aoa(ws, [row], { origin: -1 });

    row.forEach((cell, colIdx) => {
      const cellAddress = XLSX.utils.encode_cell({ r: idx + 1, c: colIdx });
      if (!ws[cellAddress]) ws[cellAddress] = {};
      if (columns[colIdx] && columns[colIdx].s) {
        ws[cellAddress].s = columns[colIdx].s;
      }
    });
  });

  // 컬럼 너비 설정
  ws['!cols'] = columnWidths.map(width => ({ wpx: width }));

  // 워크북 생성
  const wb = { Sheets: { [sheetName]: ws }, SheetNames: [sheetName] };

  // 엑셀 파일 생성 및 다운로드
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const excelFile = new Blob([excelBuffer], { type: EXCEL_FILE_TYPE });
  FileSaver.saveAs(excelFile, fileName + EXCEL_FILE_EXTENSION);
};
