import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { format, parse, parseISO } from 'date-fns';
import DynamicIcon from './DynamicIcon'; // Assuming you have a dynamic icon component
import { Api } from '@/api'; // Assuming API import
import { easyPlanLogStatusColor, easyPlanLogIconList, easyPlanLogStatus } from '@/constants/planStatus';
import { formatUtil } from '@/utils/FormatUtil';
import { Button, Input } from '@nextui-org/react';
import useConfirmModal from '@/hooks/useConfirmModal';
import ConfirmModal from '@/components/Modal/ConfirmModal';
import { useTheme } from 'next-themes';

const MemoCommentItem = ({ comment }) => {
  const { confirmOpen, message, requestConfirm, handleConfirm, handleClose } = useConfirmModal();

  const queryClient = useQueryClient();
  const [isEditing, setIsEditing] = useState(false);
  const [commentText, setCommentText] = useState(comment.content);
  const myinfoQuery = useQuery({
    queryKey: ['myinfo'],
    queryFn: Api.myinfo,
    select: res => res.data.data.admin,
  });

  const reloadQuery = () => {
    queryClient.invalidateQueries(['easyPlanLogList']);
  };
  const modMutation = useMutation({
    mutationFn: Api.easyPlanLogCommentMod,
    onSuccess: () => {
      reloadQuery();
      setIsEditing(false);
    },
    onError: error => {
      console.error('Failed to edit comment:', error);
    },
  });
  const delMutation = useMutation({
    mutationFn: Api.easyPlanLogCommentDel,
    onSuccess: () => {
      reloadQuery();
    },
    onError: error => {
      console.error('Failed to delete comment:', error);
    },
  });
  const handleEditCancle = () => {
    setIsEditing(false);
    setCommentText(comment.content);
  };
  const handleEditComment = () => {
    requestConfirm({
      message: '메모를 수정 하시겠습니까?',
      onConfirm: () =>
        modMutation.mutate({
          placeWeekChangeLogCommentId: comment.id,
          content: commentText,
        }),
    });
  };
  const handleDeleteComment = () => {
    requestConfirm({
      message: '메모를 삭제 하시겠습니까?',
      onConfirm: () =>
        delMutation.mutate({
          placeWeekChangeLogCommentId: comment.id,
        }),
    });
  };

  return (
    <>
      <div key={comment.id} className="flex items-center gap-2 p-3 ml-10 rounded-xl bg-default-100">
        <div>
          <DynamicIcon iconName={'ChatTeardropDots'} weight={'fill'} className="text-default-600"></DynamicIcon>
        </div>
        {!isEditing ? (
          <>
            <div className="flex gap-2 text-sm">
              <span className="text-default-600">{comment.content}</span>
              <span className="text-default-400">{comment.regName}</span>
              <span className="text-default-400">{formatUtil.getDatetime(comment.regDt)}</span>
            </div>
            {comment.regId == myinfoQuery?.data?.id && (
              <div className="flex items-center gap-2 ml-auto">
                <Button variant="light" isIconOnly onPress={() => setIsEditing(true)} className="w-6 h-6 min-w-6 min-h-6 text-default-600">
                  <DynamicIcon iconName={'PencilSimpleLine'} weight={'fill'}></DynamicIcon>
                </Button>
                <Button variant="light" isIconOnly onPress={handleDeleteComment} className="w-6 h-6 min-w-6 min-h-6 text-default-600">
                  <DynamicIcon iconName={'X'} weight={'bold'}></DynamicIcon>
                </Button>
              </div>
            )}
          </>
        ) : (
          <>
            <div className="flex flex-1 gap-2 text-sm">
              <Input
                placeholder="메모를 작성해주세요. (최대 300자)"
                autoFocus
                classNames={{ inputWrapper: 'shadow-none !bg-background !border-default-300/40 border-2' }}
                value={commentText}
                onChange={e => setCommentText(e.target.value)}
              ></Input>
              <Button isIconOnly onPress={handleEditCancle}>
                취소
              </Button>
              <Button isIconOnly className="bg-default-800 text-background" onPress={handleEditComment}>
                수정
              </Button>
            </div>
          </>
        )}
      </div>
      <ConfirmModal isOpen={confirmOpen} title={message} onConfirm={handleConfirm} onClose={handleClose} />
    </>
  );
};

const MemoItem = ({ item }) => {
  const queryClient = useQueryClient();
  const [isOpenMemo, setIsOpenMemo] = useState(false);
  const [comment, setComment] = useState('');
  const { theme, setTheme } = useTheme();

  const reloadQuery = () => {
    queryClient.invalidateQueries(['easyPlanLogList']);
  };
  // Mutation for adding a new comment
  const addMutation = useMutation({
    mutationFn: Api.easyPlanLogCommentAdd,
    onSuccess: () => {
      setComment(''); // Clear the input after success
      setIsOpenMemo(false); // Close the memo input
      reloadQuery();
    },
    onError: error => {
      console.error('Failed to add comment:', error);
    },
  });

  // Handle adding a new comment
  const handleAddComment = () => {
    addMutation.mutate({
      placeWeekChangeLogId: item.id, // Assuming you pass the log ID
      content: comment, // Comment content
    });
  };

  return (
    <div className="flex flex-col gap-2 mb-4">
      {/* Action Log */}
      <div className={`flex items-center justify-between rounded-md  ${theme === 'light' ? 'hover:bg-gray-100' : 'hover:bg-gray-400'}`}>
        <div className="flex items-center gap-2">
          <div className={`w-6 h-6 flex items-center justify-center rounded-full ${easyPlanLogStatusColor[item.planStauts]?.bg}`}>
            <DynamicIcon weight="fill" className={`${easyPlanLogStatusColor[item.planStauts]?.text}`} iconName={easyPlanLogIconList[item.planStauts]} />
          </div>
          <div>
            <span className="font-semibold">{item.regName}</span>
            <span>님께서 {format(parseISO(item.planDate), 'MM월 dd일')} </span>
            <span className="font-semibold">
              {item.fromPlaceName} → {item.toPlaceName}
            </span>
            <span> 스케줄을</span>
            <span className="font-semibold"> {easyPlanLogStatus[item.planStauts]}</span>
            <span>했습니다.</span>
          </div>
        </div>
        <div className="text-sm text-gray-500">{}</div>
        <Button onPress={() => setIsOpenMemo(!isOpenMemo)} size="sm" variant="flat" startContent={<DynamicIcon iconName={'Note'} />}>
          메모작성
        </Button>
      </div>
      <div className="flex flex-col gap-2">
        {item.commentList && item.commentList.length > 0 && item.commentList.map((comment, index) => <MemoCommentItem key={comment.id} comment={comment} />)}

        {isOpenMemo && (
          <div key={comment.id} className="flex items-center gap-2 p-3 ml-10 rounded-xl bg-default-100">
            <div>
              <DynamicIcon iconName={'ChatTeardropDots'} weight={'fill'} className="text-default-600"></DynamicIcon>
            </div>
            <div className="flex flex-1 gap-2 text-sm">
              <Input
                placeholder="메모를 작성해주세요. (최대 300자)"
                autoFocus
                classNames={{ inputWrapper: 'shadow-none !bg-background !border-default-300/40 border-2' }}
                value={comment}
                onChange={e => setComment(e.target.value)}
              ></Input>
              <Button isIconOnly onPress={() => setIsOpenMemo(false)}>
                취소
              </Button>
              <Button isIconOnly className="bg-default-800 text-background" onPress={handleAddComment}>
                등록
              </Button>
            </div>
          </div>
        )}

        {/* Comments List */}
      </div>
    </div>
  );
};

export default MemoItem;
