import { Api } from '@/api/index';
import { GroupSelect } from '@/components/common/filter';
import ContentWrapper from '@/components/ContentWrapper';
import LoadingBackground from '@/components/LoadingBackground';
import PaginationTable from '@/components/PaginationTable';
import useCustomSearchParams from '@/hooks/useCustomSearchParams';
import { formatUtil } from '@/utils/FormatUtil';
import { Chip, getKeyValue, Input, Select, SelectItem, useDisclosure } from '@nextui-org/react';
import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import ModalAdd from './ModalAdd';
import ModalView from './ModalView';
import useCodeMap from '@/hooks/useCodeMap';
import DynamicSelect from '@/components/DynamicSelect';
import useDebounce from '@/hooks/useDebounce';

const columns = [
  { name: 'No', uid: 'no' },
  { name: '계약상태', uid: 'useYn' },
  { name: '상호', uid: 'name' },
  { name: '사업자형태', uid: 'groupTypeName' },
  { name: '대표자명', uid: 'bizCeo' },
  { name: '사업자등록번호', uid: 'bizNo' },
  { name: '대표 전화번호', uid: 'bizTel' },
  { name: '등록일자', uid: 'regDt' },
];
const search = [
  { value: 'bizName', label: '상호' },
  { value: 'bizCeo', label: '대표자명' },
];
const List = () => {
  const { isOpen: isOpenView, onOpen: onOpenView, onOpenChange: onOpenChangeView, onClose: onCloseView } = useDisclosure();
  const { isOpen: isOpenAdd, onOpen: onOpenAdd, onOpenChange: onOpenChangeAdd, onClose: onCloseAdd } = useDisclosure();

  const selectedId = useRef('');
  const queryClient = useQueryClient();

  const [searchParams, setSearchParams] = useSearchParams();

  const initialParams = {
    useYn: searchParams.get('useYn') || '',
    groupTypeCd: searchParams.get('groupTypeCd') || '',
    searchType: searchParams.get('searchType') || 'bizName',
    searchText: searchParams.get('searchText') || '',
  };

  const { params, setParams, queryKey, onRowsPerPageChange, onPageChange, onInputChange } = useCustomSearchParams(initialParams);
  const debounce = useDebounce(params.searchText, 500);

  const getParams = () => {
    const { searchType, searchText, ...rest } = params;
    const postParams = { ...rest };
    if (debounce) {
      postParams[searchType] = debounce;
    }
    return Object.fromEntries(Object.entries(postParams).filter(([, value]) => value));
  };

  const reloadList = () => {
    queryClient.invalidateQueries(['groupList', { ...getParams(params) }]);
  };

  const listQuery = useQuery({
    queryKey: ['groupList', { ...getParams(params) }],
    queryFn: () => Api.groupList(getParams(params)),
    // placeholderData: keepPreviousData,
    select: res => res.data.data.groupPage,
  });
  const groupTypeQuery = useCodeMap('GROUP_TYPE');

  const renderCell = useCallback(
    (item, columnKey, idx) => {
      switch (columnKey) {
        case 'no': {
          const rowNumber = Number(listQuery?.data?.totalElements ?? 0) - ((Number(params.pageNo) - 1) * Number(params.pageSize) + idx);
          return <div>{rowNumber}</div>;
        }
        case 'useYn':
          return item.useYn === 'Y' ? (
            <Chip size="sm" variant="flat" color={'success'}>
              활성화
            </Chip>
          ) : (
            <Chip size="sm" variant="flat" color={'default'}>
              비활성화
            </Chip>
          );
        case 'groupTypeName':
          return <div>{item?.meta?.groupTypeName ?? '-'}</div>;
        case 'bizCeo':
          return <div>{formatUtil.getMaskName(item?.meta?.bizCeo) ?? '-'}</div>;
        case 'bizNo':
          return <div>{formatUtil.getMaskBizNo(item?.meta?.bizNo) ?? '-'}</div>;
        case 'bizTel':
          return <div>{formatUtil.getMaskTel(item?.meta?.bizTel) ?? '-'}</div>;
        case 'regDt':
          return <div>{formatUtil.getDate(getKeyValue(item, columnKey), '-')}</div>;
        default:
          return getKeyValue(item, columnKey);
      }
    },
    [listQuery?.data?.totalElements, params.pageNo]
  );

  const handlerRowClick = item => {
    onOpenView();
    selectedId.current = item.id;
  };

  const handlerAddClick = () => {
    onOpenAdd();
  };

  {
    groupTypeQuery.isSuccess &&
      Object.entries(groupTypeQuery.data.codeMap).map(([key, value]) => {
        console.log(key, value);
      });
  }
  return (
    <ContentWrapper>
      {listQuery.isFetching && <LoadingBackground />}
      <ModalView isOpenView={isOpenView} onCloseView={onCloseView} selectedId={selectedId.current} reloadList={reloadList}></ModalView>
      <ModalAdd isOpenAdd={isOpenAdd} onCloseAdd={onCloseAdd} reloadList={reloadList} />
      <div className="flex flex-col gap-4 p-4 mb-4 bg-opacity-75 border bg-default-50 border-default-100 rounded-medium">
        <div className="flex gap-4">
          <Select size="sm" className="max-w-48" labelPlacement="outside" disallowEmptySelection label="계약상태" selectedKeys={[params.useYn]} onChange={onInputChange} name="useYn">
            <SelectItem key="">전체</SelectItem>
            <SelectItem key="Y">사용</SelectItem>
            <SelectItem key="N">미사용</SelectItem>
          </Select>
          <DynamicSelect name="groupTypeCd" label="사업자 형태" selectedKey={params.groupTypeCd} query={groupTypeQuery} dataSelector={data => data.codeMap} onChange={onInputChange} isAll={true} />
        </div>

        <Input
          labelPlacement="outside"
          label="검색"
          size="sm"
          className="w-full"
          placeholder="검색어를 입력하세요"
          onChange={onInputChange}
          value={params.searchText}
          name="searchText"
          startContent={
            <Select
              disallowEmptySelection
              className="w-32 shrink-0"
              classNames={{
                base: 'h-full items-center flex-row -mx-2',
                trigger: '!bg-transparent !shadow-none h-full min-h-min',
                mainWrapper: 'h-full',
              }}
              selectedKeys={[params.searchType]}
              items={search}
              onChange={onInputChange}
              name="searchType"
            >
              {item => <SelectItem key={item.value}>{item.label}</SelectItem>}
            </Select>
          }
        />
      </div>
      <PaginationTable
        data={{
          page: params.pageNo,
          size: params.pageSize,
          totalPages: listQuery?.data?.totalPages,
          totalElements: listQuery?.data?.totalElements,
          columns: columns,
          items: listQuery?.data?.content,
        }}
        renderCell={renderCell}
        isFetching={listQuery.isFetching}
        isLoading={listQuery.isLoading}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        onRowClick={handlerRowClick}
        onAddClick={handlerAddClick}
      />
    </ContentWrapper>
  );
};

export default List;
