import { Api } from '@/api';
import ConfirmModal from '@/components/Modal/ConfirmModal';
import { AlertModal, useAlertModal } from '@/components/Modal/useAlertModal';
import useConfirmModal from '@/hooks/useConfirmModal';
import { formatUtil } from '@/utils/FormatUtil';
import { Button, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Select, SelectItem, Tab, Tabs } from '@nextui-org/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import useCodeMap from '@/hooks/useCodeMap';
import { FlexTable, FlexTableRow, FlexTableHeader, FlexTableData } from '@/components/FlexTable';

const ModalUpdate = ({ isOpenUpdate, onOpenChangeUpdate, onCloseUpdate, reloadList, selectedId, onOpenView }) => {
  const { callAlert } = useAlertModal();
  const queryClient = useQueryClient();
  const [driverPhone, setDriverPhone] = useState('');

  const reload = () => {
    queryClient.invalidateQueries(['vehicle', selectedId]);
  };

  const { confirmOpen, message, requestConfirm, handleConfirm, handleClose } = useConfirmModal();

  const { data: vehicleFlagMap } = useCodeMap('VEHICLE_FLAG');
  const { data: vehicleTypeMap } = useCodeMap('VEHICLE_TYPE');

  const vehicleQuery = useQuery({
    queryKey: ['vehicle', selectedId],
    queryFn: () => Api.vehicleView({ vehicleId: selectedId }),
    select: res => res.data.data,
    enabled: !!selectedId && isOpenUpdate,
  });

  const vehicleData = vehicleQuery.data?.vehicle;

  const roleQuery = useQuery({
    queryKey: ['roles'],
    queryFn: () => Api.roles(),
    select: res => res.data.data,
  });

  const updateVehicle = useMutation({
    mutationFn: values => Api.vehicleUpdate(values),
  });

  // 기사 선택 시 전화번호 설정
  const handleDriverChange = e => {
    setFieldValue('driverId', String(e.target.value));
    const selectedDriver = driverDtList.find(driver => {
      return String(driver.id) === e.target.value;
    });
    setDriverPhone(formatUtil.getTel(selectedDriver?.tel ? selectedDriver.tel : ''));
  };

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, validateForm, resetForm, setValues } = useFormik({
    initialValues: {
      id: vehicleData?.id || '',
      pndGroupId: vehicleData?.pndGroupId || '',
      vehicleFlag: vehicleData?.vehicleFlag || '',
      vehicleNo: vehicleData?.vehicleNo || '',
      name: vehicleData?.name || '',
      company: vehicleData?.company || '',
      type: vehicleData?.type || '',
      driverId: vehicleData?.driverId || '-1',
      owner: vehicleData?.owner || '',
      ownerTel: vehicleData?.ownerTel || '',
      useYn: vehicleData?.useYn || '',
      memo: vehicleData?.memo || '',
    },
    validationSchema: Yup.object({
      vehicleNo: Yup.string().required('차량번호를 입력하세요.'),
      //name: Yup.string().required('차량이름을 입력하세요.'),
      // owner: Yup.string().required('치주명을 입력하세요.'),
      // ownerTel: Yup.string()
      //   .required('차주 전화번호를 입력하세요.')
      //   .matches(/^\d{2,3}-\d{3,4}-\d{4}$/, '전화번호 형식이 잘못되었습니다.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      const { loginPwCheck, ...restValues } = values; // loginPwCheck 값을 제외
      updateVehicle.mutate(restValues, {
        onSettled: () => {
          setSubmitting(false);
        },
        onSuccess: () => {
          callAlert('수정되었습니다.', () => {
            onCloseUpdate();
            reload();
            onOpenView();
          });
        },
        onError: error => {
          if (error?.response?.data?.code === 'FAIL' && error?.response?.data?.msg) {
            callAlert(error?.response?.data?.msg);
          } else {
            callAlert('등록에 실패하였습니다.');
          }
        },
      });
    },
  });

  const handleRegisterClick = async () => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      requestConfirm({
        message: '수정 하시겠습니까?',
        onConfirm: handleSubmit,
      });
    } else {
      Object.keys(errors).map(item => {
        touched[item] = true;
      });
      callAlert('입력한 데이터가 올바르지 않습니다.');
    }
  };

  const driverList = useQuery({
    queryKey: ['driverList', vehicleData?.groupId],
    queryFn: () => {
      return Api.driverList({ pndGroupId: vehicleData?.groupId });
    },
    enabled: !!vehicleData?.groupId,
    select: res => res.data.data,
  });

  let driverDtList = driverList?.data?.driverPage?.content;

  useEffect(() => {
    if (values.ownerTel) {
      const value = values.ownerTel.replace(/\D/g, '');
      setFieldValue('ownerTel', formatUtil.getTel(value));
    }
  }, [values.ownerTel]);

  useEffect(() => {
    if (!isOpenUpdate) {
      resetForm();
    } else if (vehicleQuery.data) {
      setValues({
        id: vehicleData?.id || '',
        pndGroupId: vehicleData?.pndGroupId || '',
        vehicleFlag: vehicleData?.vehicleFlag || '',
        vehicleNo: vehicleData?.vehicleNo || '',
        name: vehicleData?.name || '',
        company: vehicleData?.company || '',
        type: vehicleData?.type || '',
        driverId: vehicleData?.driverId || '-1',
        owner: vehicleData?.owner || '',
        ownerTel: vehicleData?.ownerTel || '',
        useYn: vehicleData?.useYn || '',
        memo: vehicleData?.memo || '',
      });
    }
  }, [isOpenUpdate, vehicleQuery.data, resetForm, setValues]);

  useEffect(() => {
    if (vehicleData?.driverId) {
      setFieldValue('driverId', String(vehicleData?.driverId));
      setDriverPhone(formatUtil.getTel(vehicleData.driverTel));
    } else {
      setDriverPhone('');
    }
  }, [vehicleData?.driverId]);

  return (
    <>
      <Modal isDismissable={false} isOpen={isOpenUpdate} onOpenChange={onOpenChangeUpdate} size="5xl" scrollBehavior={'outside'}>
        <ConfirmModal isOpen={confirmOpen} title={message} onConfirm={handleConfirm} onClose={handleClose} />
        {roleQuery.data?.adminRoleList && (
          <ModalContent>
            <form
              onSubmit={event => {
                event.preventDefault();
                handleSubmit();
              }}
            >
              <ModalHeader>차량 수정</ModalHeader>
              <ModalBody>
                <FlexTable title={'기본정보'}>
                  <FlexTableRow>
                    <FlexTableHeader>운반회사</FlexTableHeader>
                    <FlexTableData>{vehicleData?.groupName}</FlexTableData>
                  </FlexTableRow>

                  <FlexTableRow>
                    <FlexTableHeader>
                      활성화<span className="text-rose-500">*</span>
                    </FlexTableHeader>
                    <FlexTableData>
                      <Tabs
                        fullWidth
                        selectedKey={values.useYn}
                        onSelectionChange={e => {
                          if (e !== values.useYn) {
                            setFieldValue('useYn', e);
                          }
                        }}
                      >
                        <Tab key={'Y'} title={'활성'} />
                        <Tab key={'N'} title={'비활성'} />
                      </Tabs>
                    </FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>
                      차량구분<span className="text-rose-500">*</span>
                    </FlexTableHeader>
                    <FlexTableData>
                      <Tabs
                        fullWidth
                        selectedKey={String(values.vehicleFlag)}
                        onSelectionChange={e => {
                          if (e !== values.vehicleFlag) {
                            setFieldValue('vehicleFlag', e);
                          }
                        }}
                      >
                        {vehicleFlagMap?.codeMap && Object.keys(vehicleFlagMap?.codeMap).map(key => <Tab key={key} title={vehicleFlagMap?.codeMap[key]} />)}
                      </Tabs>
                    </FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>
                      차량번호<span className="text-rose-500">*</span>
                    </FlexTableHeader>
                    <FlexTableData>
                      <Input
                        name="vehicleNo"
                        labelPlacement="outside"
                        placeholder="차량번호를 입력하세요"
                        value={values.vehicleNo}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.vehicleNo && !!errors.vehicleNo}
                        errorMessage={touched.vehicleNo && errors.vehicleNo}
                      ></Input>
                    </FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>차량이름</FlexTableHeader>
                    <FlexTableData>
                      <Input
                        name="name"
                        labelPlacement="outside"
                        placeholder="차량이름을 입력하세요"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                        isInvalid={touched.name && !!errors.name}
                        errorMessage={touched.name && errors.name}
                      ></Input>
                    </FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>차량소속</FlexTableHeader>
                    <FlexTableData>
                      <Input
                        name="company"
                        labelPlacement="outside"
                        placeholder="차량소속명을 입력하세요"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.company}
                        isInvalid={touched.company && !!errors.company}
                        errorMessage={touched.company && errors.company}
                      ></Input>
                    </FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>
                      차량유형<span className="text-rose-500">*</span>
                    </FlexTableHeader>
                    <FlexTableData>
                      <Tabs
                        fullWidth
                        selectedKey={String(values.type)}
                        onSelectionChange={e => {
                          if (e !== values.type) {
                            setFieldValue('type', e);
                          }
                        }}
                      >
                        {vehicleTypeMap?.codeMap && Object.keys(vehicleTypeMap?.codeMap).map(key => <Tab key={key} title={vehicleTypeMap?.codeMap[key]} />)}
                      </Tabs>
                    </FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>메모</FlexTableHeader>
                    <FlexTableData>
                      <Input name="memo" labelPlacement="outside" value={values.memo} placeholder="메모를 입력해 주세요." onChange={handleChange} onBlur={handleBlur}></Input>
                    </FlexTableData>
                  </FlexTableRow>
                </FlexTable>

                <FlexTable title={'기사 및 차주 정보'}>
                  <FlexTableRow>
                    <FlexTableHeader>기사명</FlexTableHeader>
                    <FlexTableData>
                      <Select name="driverId" aria-labelledby="driverId" size="md" onChange={handleDriverChange} defaultSelectedKeys={[String(values?.driverId)]} disallowEmptySelection>
                        <SelectItem key={'-1'}>{'미배정'}</SelectItem>
                        {driverDtList?.map(item => (
                          <SelectItem key={item.id}>{item.name}</SelectItem>
                        ))}
                      </Select>
                    </FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>기사 전화번호</FlexTableHeader>
                    <FlexTableData>
                      <Input name="name" value={driverPhone} labelPlacement="outside" placeholder="기사 선택시 자동 입력됩니다." readOnly></Input>
                    </FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>차주명</FlexTableHeader>
                    <FlexTableData>
                      <Input
                        name="owner"
                        labelPlacement="outside"
                        placeholder="차주명을 입력하세요"
                        value={values.owner}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.owner && !!errors.owner}
                        errorMessage={touched.owner && errors.owner}
                      ></Input>
                    </FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>차주 전화번호</FlexTableHeader>
                    <FlexTableData>
                      <Input
                        name="ownerTel"
                        labelPlacement="outside"
                        placeholder="차주 전화번호를  입력하세요"
                        value={values.ownerTel}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.ownerTel && !!errors.ownerTel}
                        errorMessage={touched.ownerTel && errors.ownerTel}
                        maxLength={13}
                      ></Input>
                    </FlexTableData>
                  </FlexTableRow>
                </FlexTable>
              </ModalBody>
              <ModalFooter>
                <Button color="default" className="ml-auto" onPress={onCloseUpdate}>
                  닫기
                </Button>
                <Button color="primary" isDisabled={!vehicleQuery.data} disabled={isSubmitting} onPress={handleRegisterClick}>
                  확인
                </Button>
              </ModalFooter>
            </form>
          </ModalContent>
        )}
      </Modal>
      <AlertModal />
    </>
  );
};

export default ModalUpdate;
