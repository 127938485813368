import { TRANSITION_EASINGS } from '@nextui-org/framer-transitions';

export default {
  variants: {
    enter: {
      scale: 1,
      y: 'var(--slide-enter)',
      opacity: 1,
      transition: {
        scale: {
          duration: 0.4,
          ease: TRANSITION_EASINGS.ease,
        },
        opacity: {
          duration: 0.4,
          ease: TRANSITION_EASINGS.ease,
        },
        y: {
          type: 'spring',
          bounce: 0,
          duration: 0.6,
        },
      },
    },
    exit: {
      scale: 1.03, // NextUI default 1.03
      y: 'var(--slide-exit)',
      opacity: 0,
      transition: {
        duration: 0.3,
        ease: TRANSITION_EASINGS.ease,
      },
    },
  },
};
