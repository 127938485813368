import { Api } from '@/api/index';
import LoadingBackground from '@/components/LoadingBackground';
import ConfirmModal from '@/components/Modal/ConfirmModal';
import { useAlertModal } from '@/components/Modal/useAlertModal';
import { GroupSelect, PlaceSelect } from '@/components/common/filter';
import useConfirmModal from '@/hooks/useConfirmModal';
import useCustomSearchParams from '@/hooks/useCustomSearchParams';
import { formatUtil } from '@/utils/FormatUtil';
import { CalendarDate, endOfWeek, getLocalTimeZone, startOfWeek, today } from '@internationalized/date';
import { Checkbox, Button, Input, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, useDisclosure, Tabs, Tab, Skeleton, Spinner, ScrollShadow } from '@nextui-org/react';
import { CaretLeft, CaretRight, GreaterThan, LessThan, MapPin } from '@phosphor-icons/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ModalDispatch from './ModalDispatch.jsx';
import ModalPlace from './ModalPlace.jsx';
import MemoItem from '@/components/MemoItem.jsx';

let placeMap = {
  fromPlaceId: '상차지',
  toPlaceId: '하차지',
};
let shipmentDirectionMap = {
  OUTBOUND: 'fromPlaceId',
  INBOUND: 'toPlaceId',
};

const List = () => {
  const locale = 'kr-KR';
  const { isOpen: isOpenView, onOpen: onOpenView, onOpenChange: onOpenChangeView, onClose: onCloseView } = useDisclosure();
  const { isOpen: isOpenDispatch, onOpen: onOpenDispatch, onOpenChange: onOpenChangeDispatch, onClose: onCloseDispatch } = useDisclosure();
  const { confirmOpen, message, requestConfirm, handleConfirm, handleClose } = useConfirmModal();
  const [refetchParams, setRefetchParams] = useState(null);
  let [week, setWeek] = useState(0);
  const [inputData, setInputData] = useState({});

  const [compare, setCompare] = useState(false);

  let defaultDate = today(getLocalTimeZone());
  const afterXWeek = defaultDate.add({ weeks: week });
  const selectedId = useRef('');
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const { callAlert } = useAlertModal();
  const calendarDate = new CalendarDate(afterXWeek.year, afterXWeek.month, afterXWeek.day);
  let startOfCurrentWeek = startOfWeek(calendarDate, locale);
  startOfCurrentWeek = startOfCurrentWeek.add({ days: 1 });
  let endOfCurrentWeek = endOfWeek(calendarDate, locale);
  endOfCurrentWeek = endOfCurrentWeek.add({ days: 1 });

  const startOfCurrentWeekAdd1 = startOfCurrentWeek.add({ days: 1 });
  const startOfCurrentWeekAdd2 = startOfCurrentWeek.add({ days: 2 });
  const startOfCurrentWeekAdd3 = startOfCurrentWeek.add({ days: 3 });
  const startOfCurrentWeekAdd4 = startOfCurrentWeek.add({ days: 4 });
  const startOfCurrentWeekAdd5 = startOfCurrentWeek.add({ days: 5 });

  const weekDayNumber = formatUtil.getWeekNumber(calendarDate.toString().replaceAll('-', ''));

  const initialParams = {
    pndGroupId: searchParams.get('pndGroupId') || '',
    week: searchParams.get('week') || weekDayNumber,
    year: searchParams.get('year') || calendarDate.toString().substring(0, 4),
    placeType: searchParams.get('placeType') || 'fromPlaceId',
  };
  initialParams[initialParams.placeType] = searchParams.get(initialParams.placeType);

  if (initialParams.placeType == 'fromPlaceId') {
    initialParams.shipmentDirection = 'OUTBOUND';
    delete initialParams.toPlaceId; // 반대 기준 파라미터는 삭제
  } else {
    //initialParams.toPlaceId = searchParams.get('toPlaceId') || '';
    initialParams.shipmentDirection = 'INBOUND';
    delete initialParams.fromPlaceId; // 반대 기준 파라미터는 삭제
  }

  const { data: groupList } = useQuery({
    queryKey: ['groups'],
    queryFn: () => Api.groups(),
    select: res => res.data.data.groupList,
  });

  const { params, setParams, removeParam, queryKey, onRowsPerPageChange, onPageChange, onInputChange } = useCustomSearchParams(initialParams);

  const [placeStatus, setPlaceStatus] = useState(initialParams.placeType);
  const [pairPlace, setPairPlace] = useState(initialParams.placeType == 'fromPlaceId' ? 'toPlaceId' : 'fromPlaceId');

  const getDay = stdater => {
    let dayClass = 'text-zinc-400';
    let thatDay = formatUtil.getDayOfWeek(stdater.replaceAll('-', ''));
    if (thatDay === '토') {
      dayClass = 'text-blue-400';
    } else if (thatDay === '일') {
      dayClass = 'text-red-400';
    }
    let date = `${formatUtil.getExtractDay(stdater)}(${formatUtil.getDayOfWeek(stdater.replaceAll('-', ''))})`;
    if (defaultDate.toString() == stdater) {
      return (
        <div className="flex justify-center">
          <div className="px-4 py-1 text-white rounded-full bg-primary-400">{date}</div>
        </div>
      );
    }
    return <div className={dayClass}>{date}</div>;
  };

  const mon = startOfCurrentWeek.toString().replaceAll('-', '');
  const tue = startOfCurrentWeekAdd1.toString().replaceAll('-', '');
  const wed = startOfCurrentWeekAdd2.toString().replaceAll('-', '');
  const thu = startOfCurrentWeekAdd3.toString().replaceAll('-', '');
  const fri = startOfCurrentWeekAdd4.toString().replaceAll('-', '');
  const sat = startOfCurrentWeekAdd5.toString().replaceAll('-', '');
  const sun = endOfCurrentWeek.toString().replaceAll('-', '');

  const columns = [
    { name: placeStatus == 'fromPlaceId' ? '하차지' : '상차지', uid: pairPlace, align: 'left' },
    { name: '메모', uid: 'memo' },
    { name: getDay(startOfCurrentWeek.toString()), uid: mon },
    { name: getDay(startOfCurrentWeekAdd1.toString()), uid: tue },
    { name: getDay(startOfCurrentWeekAdd2.toString()), uid: wed },
    { name: getDay(startOfCurrentWeekAdd3.toString()), uid: thu },
    { name: getDay(startOfCurrentWeekAdd4.toString()), uid: fri },
    { name: getDay(startOfCurrentWeekAdd5.toString()), uid: sat },
    { name: getDay(endOfCurrentWeek.toString()), uid: sun },
  ];

  //운반회사 최초로딩시
  const handleGroupFirstLoad = firstGroupId => {
    setParams(prevParams => ({
      ...prevParams,
      pndGroupId: firstGroupId,
    }));

    //회사별 방향세팅
    const currGroupItem = groupList?.find(item => {
      return item.id == firstGroupId;
    });
    if (currGroupItem?.meta?.shipmentDirection && shipmentDirectionMap[currGroupItem?.meta?.shipmentDirection] != placeStatus) {
      setPlaceStatus(shipmentDirectionMap[currGroupItem?.meta?.shipmentDirection]);
      setPairPlace(placeStatus);
      setParams(prevParams => {
        let data = {
          ...prevParams,
        };
        data.shipmentDirection = currGroupItem?.meta?.shipmentDirection;
        return data;
      });
    }
  };

  const handleDeliveryPlaceFirstLoad = placeId => {
    setParams(prevParams => {
      let data = {
        ...prevParams,
      };
      data[placeStatus] = placeId;
      return data;
    });
  };
  const queryKeyParams = {
    groupId: params.pndGroupId,
    week: params.week,
    year: params.year,
  };

  const handleChangeGroup = e => {
    removeParam('fromPlaceId');
    removeParam('toPlaceId');
    onInputChange(e);

    //회사별 방향세팅
    const currGroupItem = groupList?.find(item => {
      return item.id == e.target.value;
    });
    if (currGroupItem?.meta?.shipmentDirection && shipmentDirectionMap[currGroupItem?.meta?.shipmentDirection] != placeStatus) {
      setPlaceStatus(shipmentDirectionMap[currGroupItem?.meta?.shipmentDirection]);
      setPairPlace(placeStatus);
      setParams(prevParams => {
        let data = {
          ...prevParams,
        };
        data.shipmentDirection = currGroupItem?.meta?.shipmentDirection;
        return data;
      });
    }
  };

  // placeStatus에 따라 fromPlaceId나 toPlaceId를 설정
  if (placeStatus === 'fromPlaceId') {
    queryKeyParams.fromPlaceId = params.fromPlaceId;
    queryKeyParams.shipmentDirection = 'OUTBOUND';
    delete queryKeyParams.toPlaceId;
  } else if (placeStatus === 'toPlaceId') {
    queryKeyParams.toPlaceId = params.toPlaceId;
    queryKeyParams.shipmentDirection = 'INBOUND';
    delete queryKeyParams.fromPlaceId;
  }

  const reloadList = () => {
    queryClient.invalidateQueries(['easyPlanList', queryKeyParams]);
  };

  const { data: placeListDt } = useQuery({
    queryKey: ['placeList', { pndGroupId: params.pndGroupId, pageSize: 1000 }],
    queryFn: () => {
      return Api.placeList({ pndGroupId: params.pndGroupId, pageSize: 1000 });
    },
    select: res => res.data.data,
    enabled: !!params.pndGroupId,
  });

  let placeListInfo = placeListDt?.placePage?.content;
  let placeNameObj = {};

  placeListInfo?.forEach(item => {
    placeNameObj[item.id] = item.name;
  });

  const placeCheck = () => {
    if (placeStatus === 'fromPlaceId') {
      return params.fromPlaceId;
    } else if (placeStatus === 'toPlaceId') {
      return params.toPlaceId;
    }
  };

  let { groupId, ...rest } = queryKeyParams;

  const { data: easyPlanList, isFetching } = useQuery({
    queryKey: ['easyPlanList', queryKeyParams],
    queryFn: () => {
      return Api.easyPlanList(queryKeyParams);
    },
    enabled: !!params.pndGroupId && placeListInfo?.length > 0 && !!placeCheck(),
    select: res => res.data.data,
  });

  const easyPlanLogList = useQuery({
    queryKey: ['easyPlanLogList', queryKeyParams],
    queryFn: () => {
      return Api.easyPlanLogList(queryKeyParams);
    },
    enabled: !!params.pndGroupId && placeListInfo?.length > 0 && !!placeCheck(),
    select: res =>
      res.data.data.changeLogPage.content.filter(item => item.planStauts === 'READY' || item.planStauts === 'NOT_READY' || item.planStauts === 'SCHEDULE_CANCEL' || item.planStauts === 'CANCEL'),
  });

  console.log(easyPlanLogList.data);

  const { data: easyPlanSnapShotList } = useQuery({
    queryKey: ['easyPlanSnapShotList', { ...rest, pndGroupId: groupId }],
    queryFn: () => {
      return Api.easyPlanSnapShotList({ ...rest, pndGroupId: groupId });
    },
    enabled: !!params.pndGroupId && placeListInfo?.length > 0 && easyPlanList?.confirmYn === 'Y',
    select: res => res.data.data,
  });

  const snapShotMap = easyPlanSnapShotList?.snapshotList?.reduce((acc, item) => {
    //상차_하차_날짜 객체생성
    const key = `${item.fromPlaceId}_${item.toPlaceId}_${item.date}`;
    acc[key] = item.count;
    return acc;
  }, {});

  const weekList = easyPlanList?.placeWeekList ?? [];
  const sortedWeekList = [...weekList].sort((a, b) => {
    return placeNameObj[a[pairPlace]].localeCompare(placeNameObj[b[pairPlace]]);
  });
  const jobList = easyPlanList?.jobUnitSumList;

  let sumObj = {};
  let mainPickUpObj = {};

  jobList?.forEach(item => {
    let type = pairPlace == 'toPlaceId' ? 'deliveryPlaceId' : 'pickupPlaceId';
    if (!mainPickUpObj[item[type]]) {
      mainPickUpObj[item[type]] = {};
    }
    mainPickUpObj[item[type]][item.planDate] = item;

    if (item?.planDate) {
      if (!sumObj[item?.planDate]) {
        sumObj[item?.planDate] = {
          scheduleCount: 0,
          dispatchCount: 0,
        };
      }
      sumObj[item?.planDate].scheduleCount += item.scheduleCount;
      sumObj[item?.planDate].dispatchCount += item.dispatchCount;
    }
  });

  const confirmNBottom = [
    { name: '합계', uid: 'sum ', align: 'left' },
    { name: '', uid: 'memo' },

    { name: sumObj[mon] ? sumObj[mon].scheduleCount : '0', uid: mon },
    { name: sumObj[tue] ? sumObj[tue].scheduleCount : '0', uid: tue },
    { name: sumObj[wed] ? sumObj[wed].scheduleCount : '0', uid: wed },
    { name: sumObj[thu] ? sumObj[thu].scheduleCount : '0', uid: thu },
    { name: sumObj[fri] ? sumObj[fri].scheduleCount : '0', uid: fri },
    { name: sumObj[sat] ? sumObj[sat].scheduleCount : '0', uid: sat },
    { name: sumObj[sun] ? sumObj[sun].scheduleCount : '0', uid: sun },
  ];

  const confirmYBottom = [
    { name: '합계', uid: 'sum ', align: 'left' },
    { name: '', uid: 'memo' },
    ...[mon, tue, wed, thu, fri, sat, sun].map(dateKey => {
      const scheduleCount = sumObj[dateKey]?.scheduleCount || 0;
      const dispatchCount = sumObj[dateKey]?.dispatchCount || 0;
      let diffText = '';
      if (snapShotMap) {
        const previousCount = Object.keys(snapShotMap).reduce((acc, key) => {
          if (key.endsWith(`_${dateKey}`)) {
            acc += snapShotMap[key];
          }
          return acc;
        }, 0);

        const diff = scheduleCount - previousCount;

        if (diff > 0) {
          diffText = <span className="text-danger-500">(+{diff})</span>;
        } else if (diff < 0) {
          diffText = <span className="text-sky-500">({diff})</span>;
        }
      }
      return {
        name: (
          <>
            {dispatchCount}/{scheduleCount} {compare && diffText}
          </>
        ),
        uid: dateKey,
      };
    }),
  ];

  const handleInputChange = (date, pId, value) => {
    value = value.replace(/[^0-9]/g, ''); // 숫자 외의 모든 문자를 제거
    setInputData(prevData => {
      const newData = { ...prevData };
      if (!newData[date]) {
        newData[date] = {};
      }
      newData[date][pId] = value; // 공백이 아니라 빈 문자열로 설정
      return newData;
    });
  };

  const confirmEasySchedule = useMutation({
    mutationFn: values => Api.confirmEasySchedule(values),
  });

  const handleConfirmSchedule = () => {
    if (Object.keys(inputData).length !== 0) {
      callAlert('데이터를 먼저 저장해 주세요.');
      return;
    }
    let data = {
      pndGroupId: params.pndGroupId,
      year: params.year,
      week: params.week,
    };

    if (placeStatus === 'fromPlaceId') {
      data.fromPlaceId = params[placeStatus];
      data.shipmentDirection = 'OUTBOUND';
    } else if (placeStatus === 'toPlaceId') {
      data.toPlaceId = params[placeStatus];
      data.shipmentDirection = 'INBOUND';
    }

    confirmEasySchedule.mutate(data, {
      onSuccess: () => {
        callAlert('확정 되었습니다.', () => {
          reloadList();
        });
      },
      onError: error => {
        if (error?.response?.data?.code === 'FAIL' && error?.response?.data?.msg) {
          callAlert(error?.response?.data?.msg);
        } else {
          callAlert('확정에 실패하였습니다.');
        }
      },
    });
  };

  const handleSetDispatch = async data => {
    setRefetchParams(data); // 파라미터 설정
  };

  const addEasySchedule = useMutation({
    mutationFn: values => Api.addEasySchedule(values),
  });
  const handleSaveSchedule = () => {
    let insertList = [];

    Object.keys(inputData).map(item => {
      let planDate = item;
      Object.keys(inputData[planDate]).map(id => {
        let count = inputData[planDate][id];
        if (count) {
          let insertData = {
            planDate: planDate,
            pickupPlaceId: id,
            count: count,
          };

          if (pairPlace === 'fromPlaceId') {
            insertData.pickupPlaceId = id;
          } else if (pairPlace === 'toPlaceId') {
            insertData.deliveryPlaceId = id;
          }
          insertList.push(insertData);
        }
      });
    });

    if (insertList.length == 0) {
      callAlert('변경된 데이터가 없습니다.');
      return;
    }

    let data = {
      pndGroupId: params.pndGroupId,
      year: params.year,
      week: params.week,
      itemList: insertList,
      shipmentDirection: params.shipmentDirection,
    };

    if (placeStatus === 'fromPlaceId') {
      data.pickupPlaceId = params.fromPlaceId;
    } else if (placeStatus === 'toPlaceId') {
      data.deliveryPlaceId = params.toPlaceId;
    }

    addEasySchedule.mutate(data, {
      onSuccess: () => {
        setInputData({});
        callAlert('저장 되었습니다.', () => {
          reloadList();
        });
      },
      onError: error => {
        if (error?.response?.data?.code === 'FAIL' && error?.response?.data?.msg) {
          callAlert(error?.response?.data?.msg);
        } else {
          callAlert('저장에 실패하였습니다.');
        }
      },
    });
  };

  const renderCell = (item, columnKey, idx) => {
    let planObj = mainPickUpObj[item[pairPlace]];
    let planDates = planObj ? Object.keys(planObj) : [];
    let data = { date: columnKey, pickupId: item.fromPlaceId, deliveryId: item.toPlaceId };

    if (easyPlanList.confirmYn === 'Y') {
      if (columnKey === 'fromPlaceId') {
        return placeNameObj[item.fromPlaceId];
      } else if (columnKey === 'toPlaceId') {
        return placeNameObj[item.toPlaceId];
      } else if (columnKey === 'memo') {
        return item.memo ? item.memo : <span className="text-default-400">없음</span>;
      } else if (planDates.includes(columnKey)) {
        // 운반회사, 상차지, 하차지, 날짜
        let bgColor = planObj[columnKey]?.dispatchCount == planObj[columnKey]?.scheduleCount ? 'bg-default-100 group-hover/cell:bg-default-200' : 'bg-danger-100 group-hover/cell:bg-danger-200';
        let thisItem = planObj[columnKey];
        let key = `${thisItem.pickupPlaceId}_${thisItem.deliveryPlaceId}_${thisItem.planDate}`;
        let count = snapShotMap?.[key] ?? 0;
        let detail = (
          <div
            className={`flex justify-center gap-1 cursor-pointer group/cell`}
            onClick={() => {
              handleSetDispatch(data);
            }}
          >
            <div className={`flex justify-center ${bgColor} w-8 items-center h-8 rounded-2xl`} type="text">
              {planObj[columnKey]?.dispatchCount ? planObj[columnKey]?.dispatchCount : '0'}
            </div>
            <div className="flex items-center h-8">/</div>
            <div className="flex items-center justify-center h-8 min-w-8 max-w-fit bg-default-100 group-hover/cell:bg-default-200 rounded-2xl" type="text">
              {planObj[columnKey]?.scheduleCount ? planObj[columnKey]?.scheduleCount : '0'}
              {compare && planObj[columnKey]?.scheduleCount > count ? <span className="text-danger-500">(+{planObj[columnKey]?.scheduleCount - count})</span> : ''}
              {compare && planObj[columnKey]?.scheduleCount < count ? <span className="text-sky-500">(-{count - planObj[columnKey]?.scheduleCount})</span> : ''}
            </div>
          </div>
        );
        return detail;
      } else {
        // let thisItem = planObj[columnKey];

        let key = `${item.fromPlaceId}_${item.toPlaceId}_${columnKey}`;
        let count = snapShotMap?.[key] ?? 0;
        return (
          <div
            className="flex justify-center gap-1 cursor-pointer group/cell"
            onClick={() => {
              handleSetDispatch(data);
            }}
          >
            <div className="flex gap-1 text-default-300">
              <div className="flex items-center justify-center w-8 h-8 bg-default-100 group-hover/cell:bg-default-200 rounded-2xl" type="text">
                0
              </div>
              <div className="flex items-center h-8">/</div>
              <div className="flex items-center justify-center w-8 h-8 bg-default-100 group-hover/cell:bg-default-200 rounded-2xl" type="text">
                0{compare && count > 0 && <span className="text-sky-500">(-{count})</span>}
              </div>
            </div>
          </div>
        );
      }
    } else {
      if (columnKey === 'fromPlaceId') {
        return placeNameObj[item.fromPlaceId];
      } else if (columnKey === 'toPlaceId') {
        return placeNameObj[item.toPlaceId];
      } else if (columnKey === 'memo') {
        return item.memo ? item.memo : <span className="text-default-400">없음</span>;
      } else if (planDates.includes(columnKey)) {
        let inputDt = inputData[columnKey]?.[item[pairPlace]] && inputData[columnKey]?.[item[pairPlace]];

        console.log('d:' + planObj[columnKey]?.scheduleCount);
        console.log('v:' + inputDt);

        return (
          <div className="flex justify-center">
            <Input
              size=""
              className="w-10"
              classNames={{ input: ['text-center'] }}
              value={inputDt ?? planObj[columnKey]?.scheduleCount}
              type="text"
              maxLength={2}
              onChange={e => {
                handleInputChange(columnKey, item[pairPlace], e.target.value);
              }}
            />
          </div>
        );
      } else {
        const inputDt = inputData[columnKey]?.[item[pairPlace]] && inputData[columnKey]?.[item[pairPlace]];
        return (
          <div className="flex justify-center">
            <Input
              size=""
              className="w-10"
              classNames={{ input: ['text-center'] }}
              value={inputDt ?? 0}
              type="text"
              maxLength={2}
              onChange={e => {
                handleInputChange(columnKey, item[pairPlace], e.target.value);
              }}
            />
          </div>
        );
      }
    }
  };

  useEffect(() => {
    if (refetchParams) {
      onOpenDispatch();
    }
  }, [refetchParams]);

  useEffect(() => {
    // placeStatus가 변경될 때마다 실행
    let contentList = placeListDt?.placePage?.content || [];
    if (placeListDt?.placePage?.content?.length > 0) {
      const sortedPlaceList = [...contentList].sort((a, b) => {
        return a.name.localeCompare(b.name);
      });

      handleDeliveryPlaceFirstLoad(params[placeStatus] ? params[placeStatus] : sortedPlaceList[0].id);
    }
  }, [placeStatus, placeListDt]);

  return (
    <div className="min-w-[1024px]">
      {isFetching && <LoadingBackground />}
      <ModalPlace
        isOpenView={isOpenView}
        onOpenChangeView={onOpenChangeView}
        onCloseView={onCloseView}
        selectedId={selectedId.current}
        reloadList={reloadList}
        onOpenView={onOpenView}
        groupId={params.pndGroupId}
        year={params.year}
        week={params.week}
        placeId={params[placeStatus]}
        placeNameObj={placeNameObj}
        placeStatus={placeStatus}
      ></ModalPlace>

      <ModalDispatch
        isOpenDispatch={isOpenDispatch}
        onOpenChangeDispatch={onOpenChangeDispatch}
        onCloseDispatch={onCloseDispatch}
        selectedId={selectedId.current}
        reloadList={reloadList}
        onOpenDispatch={onOpenDispatch}
        groupId={params.pndGroupId}
        year={params.year}
        week={params.week}
        planDate={refetchParams?.date}
        fromPlaceId={refetchParams?.pickupId}
        toPlaceId={refetchParams?.deliveryId}
        placeNameObj={placeNameObj}
        refetchParams={refetchParams}
        placeStatus={placeStatus}
        shipmentDirection={params.shipmentDirection}
      ></ModalDispatch>

      <div className="mb-2 bg-opacity-75 border-b bg-default-50 border-default-100 min-w-[500px]">
        <div className="">
          <div className="flex flex-wrap justify-between gap-4 p-4 mb-2 rounded-medium">
            <div className={'flex gap-4'}>
              <GroupSelect label="운반회사" selectedKey={params.pndGroupId} onChange={handleChangeGroup} name="pndGroupId" onFirstLoad={handleGroupFirstLoad} />
              <PlaceSelect
                label={placeMap[placeStatus]}
                selectedKey={params[placeStatus] ? params[placeStatus] : ''}
                onChange={onInputChange}
                name={placeStatus}
                groupId={params.pndGroupId}
                noAll={true}
              />
            </div>
            <div>
              <Tabs
                name="placeType"
                className="mt-6"
                size="sm"
                selectedKey={placeStatus}
                onSelectionChange={e => {
                  setPlaceStatus(e);
                  if (e == 'fromPlaceId') {
                    setPairPlace('toPlaceId');
                    removeParam('toPlaceId');

                    setParams(prevParams => {
                      let data = {
                        ...prevParams,
                      };
                      data.shipmentDirection = 'OUTBOUND';
                      return data;
                    });
                  } else {
                    setPairPlace('fromPlaceId');
                    removeParam('fromPlaceId');
                    setParams(prevParams => {
                      let data = {
                        ...prevParams,
                      };
                      data.shipmentDirection = 'INBOUND';
                      return data;
                    });
                  }

                  let data = {
                    target: {
                      name: 'placeType',
                      value: e,
                    },
                  };

                  onInputChange(data);
                }}
              >
                <Tab key="fromPlaceId" title="상차지기준" />
                <Tab key="toPlaceId" title="하차지기준" />
              </Tabs>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-2 ">
        <div className="flex items-center justify-between gap-1 text-lg font-medium w-72">
          <Button
            isIconOnly
            variant="light"
            radius="full"
            size="sm"
            onClick={() => {
              setWeek(week - 1);
              setParams(prevParams => ({
                ...prevParams,
                week: weekDayNumber - 1,
              }));
              setParams(prevParams => ({
                ...prevParams,
                year: formatUtil.getYearOfWeek(calendarDate.toString().substring(0, 4), weekDayNumber - 1),
              }));
              setInputData({});
            }}
          >
            <CaretLeft className="text-lg" weight="bold" />
          </Button>
          <div className="flex items-center gap-1">
            <span>{formatUtil.getKorMonthDate(startOfCurrentWeek.toString())}</span>
            <span>-</span>
            <span>{formatUtil.getKorMonthDate(endOfCurrentWeek.toString())}</span>
            {week == 0 && (
              <span className="w-1.5 h-1.5 mb-3 ml-1 relative rounded-full bg-success-400 before:content-[''] before:absolute before:w-1.5 before:h-1.5 before:bg-success-400 before:rounded-full before:animate-ping "></span>
            )}
          </div>
          <Button
            isIconOnly
            variant="light"
            size="sm"
            radius="full"
            onClick={() => {
              setWeek(week + 1);
              setParams(prevParams => ({
                ...prevParams,
                week: weekDayNumber + 1,
              }));
              setParams(prevParams => ({
                ...prevParams,
                year: formatUtil.getYearOfWeek(calendarDate.toString().substring(0, 4), weekDayNumber + 1),
              }));
              setInputData({});
            }}
          >
            <CaretRight className="text-lg" weight="bold" />
          </Button>
        </div>
        <div className="flex items-center gap-4">
          <div>
            <Checkbox
              defaultSelected
              radius="full"
              isSelected={compare}
              onValueChange={e => {
                setCompare(e);
              }}
            >
              <span className="text-zinc-500">확정 스케줄과 비교하기</span>
            </Checkbox>
          </div>
          <Button
            color="primary"
            variant="flat"
            onClick={() => {
              onOpenView();
            }}
            size="sm"
            startContent={<MapPin weight="fill" />}
          >
            방문지 관리
          </Button>
        </div>
      </div>
      <Table
        aria-label=""
        removeWrapper
        classNames={{
          td: 'h-14',
        }}
      >
        <TableHeader columns={columns}>
          {column => (
            <TableColumn width={'100px'} key={column.uid} align={column.align || 'center'}>
              {column.name}
            </TableColumn>
          )}
        </TableHeader>

        <TableBody emptyContent={'등록된 정보가 없습니다.'}>
          {sortedWeekList?.length > 0 && sortedWeekList?.map((item, idx) => <TableRow key={item.id}>{columnKey => <TableCell>{renderCell(item, columnKey, idx)}</TableCell>}</TableRow>)}
        </TableBody>
      </Table>

      <Table aria-label="" className="w-full h-auto py-2" removeWrapper>
        <TableHeader columns={easyPlanList?.confirmYn === 'Y' ? confirmYBottom : confirmNBottom}>
          {column => (
            <TableColumn key={column.uid} align={column.align || 'center'} width={'100px'}>
              {column.name}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody />
      </Table>
      {easyPlanList?.confirmYn === 'N' && (
        <div className="flex justify-end gap-2">
          <Button
            color="primary"
            variant="bordered"
            onPress={() => {
              requestConfirm({
                message: '저장 하시겠습니까?',
                onConfirm: handleSaveSchedule,
              });
            }}
          >
            저장
          </Button>

          <Button
            color="primary"
            onPress={() => {
              requestConfirm({
                message: '확정 하시겠습니까?',
                onConfirm: handleConfirmSchedule,
              });
            }}
          >
            확정
          </Button>
        </div>
      )}
      <ConfirmModal isOpen={confirmOpen} title={message} onConfirm={handleConfirm} onClose={handleClose} />

      {easyPlanList?.confirmYn === 'Y' && (
        <>
          <div className="flex gap-1 mb-2 text-lg font-medium">
            <span>{formatUtil.getKorMonthDate(startOfCurrentWeek.toString())}</span>
            <span>-</span>
            <span>{formatUtil.getKorMonthDate(endOfCurrentWeek.toString())}</span>
            <span>스케줄 수정내역</span>
          </div>
          <ScrollShadow className="p-4 border border-default-200 rounded-xl ">
            {easyPlanLogList?.data && easyPlanLogList?.data?.length > 0 ? (
              easyPlanLogList.data.map(item => {
                return <MemoItem key={item.id} item={item}></MemoItem>;
              })
            ) : (
              <div className="flex items-center justify-center w-full h-24 text-default-400">변경이력이 없습니다.</div>
            )}
          </ScrollShadow>
        </>
      )}
    </div>
  );
};

export default List;
