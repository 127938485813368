import { Api } from '@/api';
import ConfirmModal from '@/components/Modal/ConfirmModal';
import { AlertModal, useAlertModal } from '@/components/Modal/useAlertModal';
import useConfirmModal from '@/hooks/useConfirmModal';
import { formatUtil } from '@/utils/FormatUtil';
import {
  Button,
  Chip,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  getKeyValue,
  useDisclosure,
} from '@nextui-org/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';

import LoadingBackground from '@/components/LoadingBackground';
import { CusChip } from '@/components/common/customChip';
import { status, statusColorName } from '@/constants/planStatus';
import { iconMd } from '@/constants/size';
import useCodeMap from '@/hooks/useCodeMap';
import { CalendarPlus, MagnifyingGlass, MinusCircle, Trash } from '@phosphor-icons/react';
import ModalDirectDriver from './ModalDirectDriver';

const columns = [
  {
    label: '스케줄번호',
    key: 'no',
  },
  {
    label: '상태',
    key: 'status',
  },
  {
    label: '기사검색',
    key: 'findDriver',
  },
  {
    label: '차량번호',
    key: 'vehicleNo',
  },
  {
    label: '기사',
    key: 'driverName',
  },
  {
    label: '기사 전화번호',
    key: 'driverTel',
  },
  {
    label: '운송 안내사항',
    key: 'guide',
  },
  {
    label: '기사 배정 취소',
    key: 'dispatchCancel',
  },
  {
    label: '스케줄 취소',
    key: 'scheduleCancel',
  },
];

const ModalDispatch = ({
  isOpenDispatch,
  onOpenDispatch,
  planDate,
  onOpenChangeDispatch,
  onCloseDispatch,
  selectedId,
  fromPlaceId,
  reloadList,
  groupId,
  toPlaceId,
  placeNameObj,
  refetchParams,
  shipmentDirection,
}) => {
  const queryClient = useQueryClient();
  const [newPlanList, setNewPlanList] = useState([]);
  const [planList, setPlanList] = useState([]);
  const { data: vehicleFlagMap } = useCodeMap('VEHICLE_FLAG');

  const lastRowRef = useRef(null);

  const { isOpen: isOpenVehicle, onOpen: onOpenVehicle, onOpenChange: onOpenChangeVehicle, onClose: onCloseVehicle } = useDisclosure();
  const { isOpen: isOpenDirect, onOpen: onOpenDirect, onOpenChange: onOpenChangeDirect, onClose: onCloseDirect } = useDisclosure();

  const { callAlert } = useAlertModal();
  const [oneButtonFlag, setOneButtonFlag] = useState(false);

  let selectedScheduleId = useRef('');

  const reloadDispatch = () => {
    queryClient.invalidateQueries(['planList', refetchParams]);
  };

  const { data: planDataList, isPending } = useQuery({
    queryKey: ['planList', refetchParams],
    queryFn: param => {
      const { date } = refetchParams;
      return Api.jobList({ pndGroupId: groupId, dateStart: date, dateEnd: date, pickupPlaceId: refetchParams.pickupId, deliveryPlaceId: refetchParams.deliveryId });
    },
    enabled: !!isOpenDispatch && !!refetchParams?.date && !!refetchParams?.pickupId,
    select: res => res.data.data,
  });

  const updatePlanList = useMutation({
    mutationFn: values => Api.updatePlanList(values),
  });

  const savePlanList = () => {
    const data = {
      pndGrouId: groupId,
      shipmentDirection: shipmentDirection,
    };

    let addList = [];
    let modList = [];
    let dispatchList = [];
    let cancelList = [];
    let deleteList = [];
    let notReadyList = [];

    newPlanList.forEach(item => {
      let data = {
        planDate: planDate,
        pickupPlaceId: fromPlaceId,
        deliveryPlaceId: toPlaceId,
        driverId: item.driverId,
        vehicleId: item.vehicleId,
        guide: item.guide,
      };
      addList.push(data);
    });

    planList.forEach(item => {
      if (item.dispatch) {
        let planData = planDataList?.jobUnitPage?.content.find(dt => {
          return dt.jobPlan?.id === item.id;
        });

        let data = {
          jobPlanId: item?.id,
          jobUnitId: planData.id,
          driverId: item.driverId,
          vehicleId: item.vehicleId,
          guide: item.guide,
        };
        dispatchList.push(data);
      }
      if (item.notReady) {
        let data = {
          jobPlanId: item?.id,
          driverId: item?.driverId ? item?.driverId : item.driverId,
        };
        notReadyList.push(data);
      }
      if (item.cancel) {
        let data = {
          jobPlanId: item?.id,
          driverId: item?.driverId ? item?.driverId : item.driverId,
        };
        cancelList.push(data);
      }
      if (item.delete) {
        let data = {
          jobPlanId: item?.id,
          driverId: item?.driverId ? item?.driverId : item.driverId,
        };
        deleteList.push(data);
      }
      if (item.guide && item.planStatus !== 'NEW') {
        let planData = planDataList?.jobUnitPage?.content.find(dt => {
          return dt.jobPlan?.id === item.id;
        });
        let data = {
          jobUnitId: planData.id,
          guide: item.guide,
        };
        modList.push(data);
      }
    });
    data.addList = addList;
    data.modList = modList;
    data.dispatchList = dispatchList;
    data.cancelList = cancelList;
    data.notReadyList = notReadyList;
    data.deleteList = deleteList;
    if (addList.length == 0 && modList.length == 0 && dispatchList.length == 0 && cancelList.length == 0 && notReadyList.length == 0 && deleteList.length == 0) {
      callAlert('변경된 내용이 없습니다.');
      return;
    }
    updatePlanList.mutate(data, {
      onSuccess: () => {
        callAlert('처리 되었습니다.', () => {
          setNewPlanList([]);
          reloadDispatch();
        });
      },
      onError: error => {
        if (error?.response?.data?.code === 'FAIL' && error?.response?.data?.msg) {
          callAlert(error?.response?.data?.msg);
        } else {
          callAlert('등록에 실패하였습니다.');
        }
      },
    });
  };

  const { confirmOpen, message, requestConfirm, handleConfirm, handleClose } = useConfirmModal();

  const handleAddVehicle = (vId, sId, vNo, drvierId, fName, company, driverName, driverTel) => {
    if (String(sId).startsWith('new')) {
      setNewPlanList(prevList =>
        prevList.map(item =>
          item.id === sId ? { ...item, vehicleId: vId, vehicleNo: vNo, company: company, vehicleFlagName: fName, driverId: drvierId, driverName: driverName, driverTel: driverTel } : item
        )
      );
    } else {
      setPlanList(prevList =>
        prevList.map(item =>
          item.id === sId
            ? { ...item, vehicleId: vId, vehicleNo: vNo, company: company, vehicleFlagName: fName, driverId: drvierId, driverName: driverName, driverTel: driverTel, dispatch: true }
            : item
        )
      );
    }
  };
  useEffect(() => {
    setNewPlanList([]);
  }, [isOpenDispatch]);

  useEffect(() => {
    setNewPlanList([]);
    if (planDataList?.jobUnitPage?.content) {
      const jobPlanList =
        planDataList?.jobUnitPage?.content?.map(item => {
          const { jobPlan, guide } = item;
          return { ...jobPlan, guide };
        }) ?? [];
      console.log(jobPlanList);
      setPlanList(jobPlanList);
    }
  }, [planDataList?.jobUnitPage?.content, isOpenDispatch]);

  const renderCell = (item, columnKey, idx) => {
    switch (columnKey) {
      case 'no': {
        let sNo = '';
        if (item.planStatus === 'NEW') {
          sNo = 'NEW';
        } else {
          let plan = planDataList?.jobUnitPage?.content.find(dt => {
            return dt.jobPlan.id == item.id;
          });
          sNo = plan?.id;
        }
        return <div>{sNo}</div>;
      }
      case 'status':
        return <CusChip color={statusColorName[item.planStatus]}>{status[item.planStatus]}</CusChip>;

      case 'findDriver': {
        let vNo = (
          <div className="flex items-center w-full">
            <Button
              color={item.planStatus != 'NOT_READY' && item.planStatus != 'NEW' ? 'default' : 'primary'}
              className={item.planStatus != 'NOT_READY' && item.planStatus != 'NEW' ? 'opacity-25' : 'opacity-100'}
              style={{ width: '100px' }}
              onClick={() => {
                if (item.planStatus != 'NOT_READY' && item.planStatus != 'NEW') {
                  return;
                }
                selectedScheduleId.current = item.id;
                onOpenDirect();
              }}
            >
              <MagnifyingGlass />
              기사검색
            </Button>
          </div>
        );
        return vNo;
      }
      case 'vehicleNo': {
        let vNo = <div className="flex items-center w-full">{item.vehicleNo ? item.vehicleNo : '-'}</div>;
        return vNo;
      }
      case 'driverName': {
        let driverName = <div className="flex items-center w-full">{item.driverName ? item.driverName : '-'}</div>;
        return driverName;
      }
      case 'driverTel': {
        let driverTel = <div className="flex items-center w-full">{item.driverTel ? formatUtil.getMobile(item.driverTel) : '-'}</div>;
        return driverTel;
      }
      case 'guide': {
        return (
          <Input
            name="guide"
            placeholder="최대 50자까지 입력 가능합니다."
            maxLength={50}
            value={item.guide || ''}
            onChange={e => {
              const { value } = e.target;
              if (String(item.id).startsWith('new')) {
                setNewPlanList(prevList => prevList.map(dt => (dt.id === item.id ? { ...dt, guide: value } : dt)));
              } else {
                setPlanList(prevList => prevList.map(dt => (dt.id === item.id ? { ...dt, guide: value } : dt)));
              }
            }}
          />
        );
      }
      case 'dispatchCancel': {
        let dispatchContent = '';

        if (item.planStatus === 'READY' && !item?.notReady) {
          dispatchContent = (
            <div
              className="flex gap-1"
              onClick={() => {
                setPlanList(prevList => prevList.map(dt => (dt.id === item.id ? { ...dt, notReady: true } : dt)));
              }}
            >
              <span className="text-base text-rose-500 ">기사 배정취소</span> <MinusCircle className="text-rose-500" size={iconMd} />
            </div>
          );
        } else if (item.planStatus === 'NEW' && item.vehicleNo != '') {
          dispatchContent = (
            <div
              className="flex gap-1"
              onClick={() => {
                setNewPlanList(prevList => prevList.map(dt => (dt.id === item.id ? { ...dt, vehicleId: '', vehicleNo: '', company: '', vehicleFlagName: '' } : dt)));
              }}
            >
              <span className="text-base text-rose-500">기사 배정취소</span> <MinusCircle className="text-rose-500" size={iconMd} />
            </div>
          );
        } else if (item.planStatus === 'NOT_READY') {
          dispatchContent = (
            <div className="flex gap-1 opacity-30">
              <span className="text-base text-rose-500">기사 배정취소</span>
              <MinusCircle className="text-rose-500" size={iconMd} />
            </div>
          );
        } else {
          dispatchContent = (
            <div className="flex gap-1 opacity-30">
              <span className="text-base text-rose-500">기사 배정취소</span>
              <MinusCircle className="text-rose-500" size={iconMd} />
            </div>
          );
        }

        return (
          <div className="max-w-[300px] flex items-center gap-2 cursor-pointer" type="text">
            {dispatchContent}
          </div>
        );
      }

      case 'scheduleCancel': {
        let scheduleCancel = (
          <div className="max-w-[300px] flex items-center gap-2 cursor-pointer" type="text">
            {(item.planStatus === 'READY' || item.planStatus === 'NOT_READY' || item.planStatus === 'NEW') && !item.cancel && !item.delete ? (
              <div
                className="flex gap-1"
                onClick={() => {
                  if (item.planStatus === 'NEW') {
                    setNewPlanList(prevItems => {
                      let filterList = prevItems.filter(i => i.id !== item.id);
                      return filterList.map((item, idx) => ({
                        ...item,
                        id: 'new' + idx,
                      }));
                    });
                  } else {
                    if (item.planStatus === 'READY') {
                      setPlanList(prevList => prevList.map(dt => (dt.id === item.id ? { ...dt, cancel: true } : dt)));
                    } else if (item.planStatus === 'NOT_READY') {
                      setPlanList(prevList => prevList.map(dt => (dt.id === item.id ? { ...dt, delete: true } : dt)));
                    }
                  }
                }}
              >
                <span className="text-base text-zinc-500">스케줄 취소</span> <Trash className="text-zinc-500" size={iconMd} />
              </div>
            ) : (
              <div className="flex gap-1 opacity-30">
                <span className="text-base text-zinc-500">스케줄 취소</span> <Trash className="text-zinc-500" size={iconMd} />
              </div>
            )}
          </div>
        );
        return scheduleCancel;
      }

      default:
        return getKeyValue(item, columnKey);
    }
  };

  return (
    <>
      {isPending && isOpenDispatch && <LoadingBackground />}
      <Modal isDismissable={false} isOpen={isOpenDispatch} onOpenChange={onOpenChangeDispatch} className={'max-w-[80%]'}>
        <ModalContent className="w-full">
          {close => (
            <>
              <ModalHeader>배차 상세 정보 ({formatUtil.getDate(planDate, '-')})</ModalHeader>
              <ModalBody>
                <div className="flex gap-4 ">
                  <div className="flex items-center gap-2">
                    <Chip size="sm" variant="flat" color={'default'}>
                      상차지
                    </Chip>
                    {placeNameObj[fromPlaceId]}
                  </div>
                  {'->'}
                  <div className="flex items-center gap-2">
                    <Chip size="sm" variant="flat" color={'default'}>
                      하차지
                    </Chip>
                    {placeNameObj[toPlaceId]}
                  </div>
                </div>
                <div className="flex justify-end mb-4"></div>
                <div className="text-xs">
                  *<span className="font-bold">기사 배정 취소 및 추가</span> 시 기사에게 배차가 취소 및 추가 되었다는 알림톡이 발송됩니다.
                </div>
                <Table
                  aria-label=""
                  classNames={{
                    base: 'max-h-[520px] ',
                    table: 'overflow-scroll',
                  }}
                >
                  <TableHeader columns={columns}>{column => <TableColumn key={column.key}>{column.label}</TableColumn>}</TableHeader>

                  <TableBody emptyContent={'등록된 정보가 없습니다.'}>
                    {planList?.length > 0 && planList?.map((item, idx) => <TableRow key={item.id}>{columnKey => <TableCell>{renderCell(item, columnKey, idx)}</TableCell>}</TableRow>)}
                    {newPlanList?.map((item, idx) => (
                      <TableRow key={item.id}>{columnKey => <TableCell>{renderCell(item, columnKey, idx)}</TableCell>}</TableRow>
                    ))}
                  </TableBody>
                </Table>
              </ModalBody>

              <ModalFooter>
                <Button
                  color="primary"
                  variant="light"
                  className="mr-auto text-lg"
                  startContent={<CalendarPlus />}
                  onClick={() => {
                    lastRowRef.current?.scrollIntoView({ behavior: 'smooth' });
                    let data = {
                      id: 'new' + newPlanList.length,
                      planStatus: 'NEW',
                      vehicleNo: '',
                      vehicleId: '',
                      driverId: '',
                      company: '',
                      vehicleFlagName: '',
                    };
                    setNewPlanList([...newPlanList, data]);
                  }}
                >
                  스케줄 추가
                </Button>

                <Button
                  color="primary"
                  onPress={() => {
                    requestConfirm({
                      message: '저장 하시겠습니까?',
                      onConfirm: savePlanList,
                    });
                  }}
                >
                  저장
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
      <ConfirmModal isOpen={confirmOpen} title={message} onConfirm={handleConfirm} onClose={handleClose} oneButton={oneButtonFlag} />
      <AlertModal />
      <ModalDirectDriver
        isOpenDirect={isOpenDirect}
        onOpenChangeDirect={onOpenChangeDirect}
        onCloseDirect={onCloseDirect}
        reloadList={reloadList}
        selectedDeliveryName={placeNameObj[toPlaceId]}
        planDate={planDate}
        toPlaceId={toPlaceId}
        handleAddVehicle={handleAddVehicle}
        selectedId={selectedScheduleId.current}
        groupId={groupId}
      ></ModalDirectDriver>
    </>
  );
};

export default ModalDispatch;
