import { colorUtil } from '@/utils/ColorUtil';
import { HourglassMedium, Note, Truck } from '@phosphor-icons/react';
import { useTheme } from 'next-themes';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { AmbientLight, DirectionalLight, FlyToInterpolator, LightingEffect, WebMercatorViewport } from '@deck.gl/core';
import { IconLayer, PathLayer } from '@deck.gl/layers';
import { ScenegraphLayer } from '@deck.gl/mesh-layers';
import DeckGL from '@deck.gl/react';
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Map } from 'react-map-gl';
import TruckBoxGlbUrl from '@/assets/glb/truck_box.glb?url';
import TruckCoreGlbUrl from '@/assets/glb/truck_core.glb?url';
window.deck.log.level = 0;
import { calculateBearing } from '@/utils/CoordUtils';
import { isEqual } from 'lodash';
import { Button } from '@nextui-org/react';
import { getMarker } from '@/utils/markerUtil';

// Mapbox Access Token 설정
const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1IjoibHVjYXNrciIsImEiOiJjbHNtbzBkM2EwcW5sMmtwNjVwcTNtNGJsIn0.H4DMdjoFxA-KWUwEYMBbwQ';
const MAPBOX_STYLE = 'mapbox://styles/lucaskr/clglzn9w8001201rcbxy51oof';
const initialViewState = {
  latitude: 35.9,
  longitude: 128,
  zoom: 6.5,
  bearing: 0,
  pitch: 0,
  altitude: 1.5,
  maxZoom: 20,
  minZoom: 0,
  maxPitch: 60,
  minPitch: 0,
  normalize: true,
  position: [0, 0, 0],
};

const initialController = {
  doubleClickZoom: false,
  touchRotate: false,
};
let isHovering = false;

/**
 * VusMap 컴포넌트는 지도 데이터를 시각화합니다.
 *
 * @component
 * @param {Object[]} data - 지도에 표시할 객체의 배열입니다. 각 객체는 특정 위치와 관련된 데이터를 포함해야 합니다.
 * @param {React.Ref} ref - 컴포넌트에 접근하기 위한 ref 객체입니다.
 * @returns {JSX.Element} 지도를 표시하는 React 요소를 반환합니다.
 */
const VusMap = forwardRef(({ data, onVehicleClick, groupId, visibleVehicleIdSet }, ref) => {
  const { theme } = useTheme();
  const mapbox_style = theme === 'dark' ? 'mapbox://styles/mapbox/dark-v11' : MAPBOX_STYLE;
  const [viewState, setViewState] = useState(initialViewState);
  const tooltipRef = useRef(null);
  const [tooltipInfo, setTooltipInfo] = useState(null);

  /**
   * id
   * tooltipInfo
   * title
   * subtitle
   * content
   * list : {title, content}
   */

  const handleSetTooltip = useCallback(info => {
    setTooltipInfo(currentTooltipInfo => (currentTooltipInfo?.id === info.id ? null : info));
  }, []);

  const validData = useMemo(() => {
    return Array.isArray(data) ? data : [];
  }, [data]);

  const layers = useMemo(() => {
    const pathLayerArray = [];
    const vehicleCoreLayerArray = [];
    const vehicleBoxLayerArray = [];
    const markerLayerArray = [];
    validData.forEach(vehicle => {
      const rgbArray = colorUtil.hashStringToRGB(String(vehicle.vehicleNo));
      const hash = colorUtil.hashStringToColor(String(vehicle.vehicleNo));
      const visible = visibleVehicleIdSet.size === 0 || visibleVehicleIdSet.has(vehicle.id);
      if (vehicle.path) {
        const optimizedPath = vehicle.path.filter((value, index, array) => {
          if (index === 0) return true;
          return !isEqual(value, array[index - 1]);
        });
        const truckBearing = calculateBearing(vehicle.path[vehicle.path.length - 2], vehicle.path[vehicle.path.length - 1]);
        const truckProps = {
          data: [{}],
          pickable: true,
          _lighting: 'pbr',
          sizeMaxPixels: 20,
          sizeMinPixels: 10,
          getOrientation: [0, truckBearing, 90],
          getPosition: optimizedPath[optimizedPath.length - 1],
          sizeScale: 1,
          visible: visible,
          onClick: e => {
            onVehicleClick(vehicle);
            handleSetTooltip({
              id: `vehicle-${vehicle.id}`,
              title: vehicle.vehicleNo,
              x: vehicle.path[vehicle.path.length - 1][0],
              y: vehicle.path[vehicle.path.length - 1][1],
              subTitle: vehicle.driverName,
            });
          },
          transitions: {
            sizeScale: 3000,
          },
        };
        pathLayerArray.push(
          new PathLayer({
            id: `pathLayer-${vehicle.id}`,
            data: [
              {
                color: rgbArray,
                path: vehicle.path,
              },
            ],
            getPath: d => d.path,
            getColor: d => d.color,
            getWidth: 8,
            capRounded: true,
            jointRounded: true,
            visible: visible,
            widthMaxPixels: 8,
            widthMinPixels: 8,
          }),
        );
        vehicleCoreLayerArray.push(
          new ScenegraphLayer({
            id: `truckCoreLayer-${vehicle.id}`,
            ...truckProps,
            scenegraph: TruckCoreGlbUrl,
          }),
        );
        vehicleBoxLayerArray.push(
          new ScenegraphLayer({
            id: `truckBoxLayer-${vehicle.id}`,
            ...truckProps,
            scenegraph: TruckBoxGlbUrl,
            getColor: rgbArray,
          }),
        );
      }
      if (vehicle.history) {
        vehicle.history.forEach(jobUnit => {
          const markerLayerId = `marker-${vehicle.id}-${jobUnit.id}-${jobUnit.jobUnitId}-${jobUnit.status}`;
          markerLayerArray.push(
            new IconLayer({
              id: markerLayerId,
              visible: visible,
              onClick: () => {
                handleSetTooltip({
                  id: markerLayerId,
                  title: jobUnit.name,
                  subTitle: jobUnit.type === 'PICKUP' ? '상차지' : '하차지',
                  content: jobUnit.address1,
                  x: jobUnit.x,
                  y: jobUnit.y,
                });
              },
              pickable: true,
              data: [
                {
                  x: jobUnit.x,
                  y: jobUnit.y,
                },
              ],
              getIcon: d => ({
                url: getMarker(jobUnit.status, hash),
                x: 0,
                y: 0,
                width: 430,
                height: 380,
                anchorY: 380,
              }),
              getPosition: d => [d.x, d.y],
              getSize: () => 32,
              loadOptions: {
                imagebitmap: {
                  resizeWidth: 430,
                  resizeHeight: 380,
                  premultiplyAlpha: 'premultiply',
                },
              },
              parameters: {
                blendFunc: [1, 771], // GL.ONE, GL.ONE_MINUS_SRC_ALPHA
              },
              textureParameters: {
                [10240]: 9728,
                [10241]: 9985,
                [10242]: 33071,
                [10243]: 33071,
              },
            }),
          );
        });
      }
    });

    return [...pathLayerArray, ...vehicleCoreLayerArray, ...vehicleBoxLayerArray, ...markerLayerArray];
  }, [handleSetTooltip, onVehicleClick, validData, visibleVehicleIdSet]);

  const onViewStateChange = useCallback(({ viewState: newView }) => {
    setViewState(newView);
  }, []);

  useEffect(() => {
    if (tooltipInfo !== null) {
      const [newPosX, newPosY] = new WebMercatorViewport(viewState).project([tooltipInfo.x, tooltipInfo.y]);
      if (tooltipRef.current) {
        tooltipRef.current.style.left = `${newPosX}px`;
        tooltipRef.current.style.top = `${newPosY}px`;
      }
    }
  }, [viewState, tooltipInfo]);

  useEffect(() => {
    setTooltipInfo(null);
  }, [groupId, visibleVehicleIdSet]);

  const mapRefCallback = useCallback(ref => {
    if (ref !== null) {
      const map = ref;
      const language = new MapboxLanguage();
      map.addControl(language);
    }
  }, []);

  const flyToCenter = coordinatesList => {
    const centerLongitude = (Math.min(...coordinatesList.map(coord => coord[0])) + Math.max(...coordinatesList.map(coord => coord[0]))) / 2;
    const centerLatitude = (Math.min(...coordinatesList.map(coord => coord[1])) + Math.max(...coordinatesList.map(coord => coord[1]))) / 2;
    //최적의 줌 레벨 계산

    if (coordinatesList.length === 1) {
      flyToCoordinates(centerLongitude, centerLatitude);
      return;
    }
    const bounds = coordinatesList.reduce(
      (acc, coord) => {
        return {
          minLng: Math.min(acc.minLng, coord[0]),
          minLat: Math.min(acc.minLat, coord[1]),
          maxLng: Math.max(acc.maxLng, coord[0]),
          maxLat: Math.max(acc.maxLat, coord[1]),
        };
      },
      {
        minLng: Infinity,
        minLat: Infinity,
        maxLng: -Infinity,
        maxLat: -Infinity,
      },
    );
    try {
      const zoom = new WebMercatorViewport(viewState).fitBounds(
        [
          [bounds.minLng, bounds.minLat],
          [bounds.maxLng, bounds.maxLat],
        ],
        {
          padding: 200,
          width: window.innerWidth,
          height: window.innerHeight,
        },
      ).zoom;
      flyToCoordinates(centerLongitude, centerLatitude, zoom);
    } catch (e) {
      flyToCoordinates(centerLongitude, centerLatitude);
    }
  };

  const flyToCoordinates = useCallback((longitude, latitude, zoom) => {
    console.log('map', longitude, latitude);

    setViewState(prev => {
      return {
        ...prev,
        longitude,
        latitude,
        transitionDuration: 1000,
        transitionInterpolator: new FlyToInterpolator(),
        pitch: 0,
        zoom: zoom ? zoom : prev.zoom,
        transitionEasing: t => 1 - Math.pow(1 - t, 5),
      };
    });
  }, []);

  const redraw = useCallback(() => {
    setViewState({ ...viewState });
  }, [viewState]);

  useImperativeHandle(ref, () => ({
    redraw,
    setViewState,
    onViewStateChange,
    setTooltipInfo,
    flyToCoordinates,
    flyToCenter,
  }));
  const ambientLight = new AmbientLight({
    color: [255, 255, 255],
    intensity: 3.5,
  });
  const directionalLight = new DirectionalLight({
    color: [128, 128, 128],
    intensity: 8.0,
    direction: [50, 100, 100],
  });
  const bottomLight = new DirectionalLight({
    color: [0, 0, 255],
    intensity: 1.0,
    direction: [100, 0, 100],
  });
  const lightingEffect = new LightingEffect({ ambientLight, directionalLight, bottomLight });
  return (
    <>
      <DeckGL
        width="100%"
        height="100%"
        onHover={({ object }) => (isHovering = Boolean(object))}
        getCursor={({ isDragging }) => (isDragging ? 'grabbing' : isHovering ? 'pointer' : 'grab')}
        useDevicePixels={2}
        doubleClickZoom={false}
        effects={[lightingEffect]}
        initialViewState={viewState}
        controller={initialController}
        onViewStateChange={onViewStateChange}
        layers={layers}
      >
        <Map ref={mapRefCallback} preserveDrawingBuffer={false} styleDiffing={false} reuseMaps={true} mapboxAccessToken={MAPBOX_ACCESS_TOKEN} mapStyle={mapbox_style} locale={'ko-KR'} />

        {tooltipInfo && (
          // address, itemQty, itemQtyUnit, jobDuration
          <div
            ref={tooltipRef}
            className="absolute -translate-x-1/2 -translate-y-[calc(100%+50px)] bg-background rounded-xl p-3 min-w-48 max-w-48 bg-opacity-60 backdrop-blur before:content-[''] before:absolute before:w-0 before:h-0 before:-translate-x-1/2 before:left-1/2 before:top-full before:border-8 before:border-transparent before:border-t-background/60 before:backdrop-blur"
          >
            {tooltipInfo.subTitle && <div className="text-xs font-medium text-foreground-500">{tooltipInfo.subTitle}</div>}
            {tooltipInfo.title && <div className="text-base font-bold">{tooltipInfo.title}</div>}
            {tooltipInfo.content && <div className="mt-1 text-xs text-foreground-800">{tooltipInfo.content}</div>}
            {/* <Button fullWidth size="sm" className="mt-2">
              상세보기
            </Button> */}
          </div>
        )}
      </DeckGL>
    </>
  );
});
VusMap.displayName = 'VusMap';

export default VusMap;
