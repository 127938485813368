import { Api } from '@/api';
import { formatUtil } from '@/utils/FormatUtil';
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, useDisclosure } from '@nextui-org/react';

import LoadingBackground from '@/components/LoadingBackground';
import ConfirmModal from '@/components/Modal/ConfirmModal';
import { AlertModal, useAlertModal } from '@/components/Modal/useAlertModal';
import useConfirmModal from '@/hooks/useConfirmModal';
import { useMutation, useQuery } from '@tanstack/react-query';
import { FlexTable, FlexTableRow, FlexTableHeader, FlexTableData } from '@/components/FlexTable';

const ModalView = ({ isOpenView, onOpenView, onOpenChangeView, onCloseView, selectedId, reloadList, onOpenUpdate }) => {
  const { callAlert } = useAlertModal();

  const adminQuery = useQuery({
    queryKey: ['admin', selectedId],
    queryFn: () => Api.adminView({ id: selectedId }),
    select: res => res.data.data,
    enabled: !!selectedId && isOpenView,
  });

  const { confirmOpen, message, requestConfirm, handleConfirm, handleClose } = useConfirmModal();

  const handlerUpdatePage = () => {
    onCloseView();
    onOpenUpdate();
  };

  const groupQuery = useQuery({
    queryKey: ['groups'],
    queryFn: () => Api.groups(),
    select: res => res.data.data,
  });
  const roleQuery = useQuery({
    queryKey: ['roles'],
    queryFn: () => Api.roles(),
    select: res => res.data.data,
  });

  const delAdmin = useMutation({
    mutationFn: id => Api.adminDel({ id, delYn: 'Y' }),
    onSuccess: data => {
      onCloseView();
      callAlert('처리되었습니다.', () => {
        reloadList();
      });
    },
    onError: error => {
      console.error('Error adding user', error);
    },
  });

  const handlerDelete = id => {
    delAdmin.mutate(id);
  };

  return (
    <>
      {adminQuery.isPending && isOpenView && <LoadingBackground />}
      <Modal isDismissable={false} isOpen={isOpenView} onOpenChange={onOpenChangeView} size="5xl" scrollBehavior={'outside'}>
        <ModalContent>
          {close => (
            <>
              <ModalHeader>계정관리 상세</ModalHeader>
              <ModalBody>
                <FlexTable>
                  <FlexTableRow>
                    <FlexTableHeader>등록일</FlexTableHeader>
                    <FlexTableData>{formatUtil.getDate(adminQuery?.data?.admin?.regDt, '-')}</FlexTableData>
                    <FlexTableHeader>수정일</FlexTableHeader>
                    <FlexTableData>{formatUtil.getDate(adminQuery?.data?.admin?.modDt, '-')}</FlexTableData>
                    <FlexTableHeader>작성자</FlexTableHeader>
                    <FlexTableData>{adminQuery?.data?.admin?.regName}</FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>운반회사</FlexTableHeader>
                    <FlexTableData>
                      {groupQuery.data?.groupList?.map(item => {
                        return adminQuery?.data?.admin?.pndGroupId == item.id && item.name;
                      })}
                    </FlexTableData>
                  </FlexTableRow>

                  <FlexTableRow>
                    <FlexTableHeader>아이디</FlexTableHeader>
                    <FlexTableData>{adminQuery?.data?.admin?.loginId}</FlexTableData>
                  </FlexTableRow>

                  <FlexTableRow>
                    <FlexTableHeader>구분</FlexTableHeader>
                    <FlexTableData>
                      {roleQuery.data?.adminRoleList?.map(item => {
                        return adminQuery?.data?.admin?.adminRoleId == item.id && item.roleName;
                      })}
                    </FlexTableData>
                  </FlexTableRow>

                  <FlexTableRow>
                    <FlexTableHeader>이름</FlexTableHeader>
                    <FlexTableData>{adminQuery?.data?.admin?.adminName}</FlexTableData>
                  </FlexTableRow>

                  <FlexTableRow>
                    <FlexTableHeader>전화번호</FlexTableHeader>
                    <FlexTableData>{formatUtil.getTel(adminQuery?.data?.admin?.adminTelDec)}</FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>이메일</FlexTableHeader>
                    <FlexTableData>{adminQuery?.data?.admin?.adminEmailDec}</FlexTableData>
                  </FlexTableRow>
                </FlexTable>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="danger"
                  className="mr-auto"
                  isLoading={delAdmin.isPending}
                  onPressEnd={() => {
                    requestConfirm({
                      message: '삭제하시겠습니까??',
                      onConfirm: () => {
                        handlerDelete(adminQuery?.data?.admin?.id);
                      },
                    });
                  }}
                >
                  삭제
                </Button>
                <Button color="default" onPress={onCloseView}>
                  닫기
                </Button>
                <Button color="primary" onPress={handlerUpdatePage}>
                  수정
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
      <ConfirmModal isOpen={confirmOpen} title={message} onConfirm={handleConfirm} onClose={handleClose} />
      <AlertModal />
    </>
  );
};

export default ModalView;
