import React from 'react';
import { Spinner } from '@nextui-org/react';

const LoadingBackground = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center  bg-opacity-50 z-[10000]">
      <Spinner size="xl" />
    </div>
  );
};

export default LoadingBackground;
