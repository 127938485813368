import { Select, SelectItem } from '@nextui-org/react';

const DynamicSelect = ({
  query,
  dataSelector,
  label,
  placeholder = '옵션을 선택해주세요',
  onChange,
  params,
  name,
  selectedKey,
  size = 'sm',
  className = 'max-w-48',
  labelPlacement = 'outside',
  disallowEmptySelection = true,
  isAll = true,
  isInvalid = false,
  errorMessage = '',
}) => {
  return (
    <Select
      isLoading={query.isLoading}
      isDisabled={query.isLoading}
      size={size}
      className={className}
      labelPlacement={labelPlacement}
      disallowEmptySelection={disallowEmptySelection}
      label={label}
      selectedKeys={selectedKey && [selectedKey]}
      onChange={onChange}
      name={name}
      placeholder={placeholder}
      isInvalid={isInvalid}
      errorMessage={errorMessage}
    >
      {isAll && <SelectItem key="">전체</SelectItem>}
      {query.isSuccess && Object.entries(dataSelector(query.data)).map(([key, value]) => <SelectItem key={key}>{value}</SelectItem>)}
    </Select>
  );
};

export default DynamicSelect;
