import { Api } from '@/api/index';
import { GroupSelect } from '@/components/common/filter';
import ContentWrapper from '@/components/ContentWrapper';
import LoadingBackground from '@/components/LoadingBackground';
import PaginationTable from '@/components/PaginationTable';
import useCustomSearchParams from '@/hooks/useCustomSearchParams';
import { formatUtil } from '@/utils/FormatUtil';
import { Chip, getKeyValue, useDisclosure } from '@nextui-org/react';
import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import ModalAdd from './ModalAdd';
import ModalView from './ModalView';

const columns = [
  { name: 'No', uid: 'no' },
  { name: '회사명', uid: 'pndGroupId' },
  { name: '제목', uid: 'termsTitle' },
  { name: '상태', uid: 'showYn' },
  { name: '등록일자', uid: 'regDt' },
];

const List = () => {
  const { isOpen: isOpenView, onOpen: onOpenView, onOpenChange: onOpenChangeView, onClose: onCloseView } = useDisclosure();
  const { isOpen: isOpenAdd, onOpen: onOpenAdd, onOpenChange: onOpenChangeAdd, onClose: onCloseAdd } = useDisclosure();

  const selectedId = useRef('');
  const queryClient = useQueryClient();

  const [searchParams, setSearchParams] = useSearchParams();

  const initialParams = {
    pndGroupId: searchParams.get('pndGroupId') || '',
  };

  const { params, setParams, queryKey, onRowsPerPageChange, onPageChange, onInputChange } = useCustomSearchParams(initialParams);

  const fetchTermsList = () => {
    const postParams = { pageNo: params.pageNo, pageSize: params.pageSize };
    postParams.useYn = params.useYn;
    postParams.pndGroupId = params.pndGroupId;
    if (params.searchText) {
      postParams[params.searchType] = params.searchText;
    }
    const trimParams = Object.fromEntries(Object.entries(postParams).filter(([, value]) => value));
    return Api.driverTermsList(trimParams);
  };

  const reloadList = () => {
    queryClient.invalidateQueries(['termsList', { ...queryKey }]);
  };

  const listQuery = useQuery({
    queryKey: ['termsList', { ...queryKey }],
    queryFn: fetchTermsList,
    placeholderData: keepPreviousData,
    select: res => res.data.data,
  });

  const handleGroupFirstLoad = firstGroupId => {
    setParams(prevParams => ({
      ...prevParams,
      pndGroupId: firstGroupId,
    }));
  };

  useEffect(() => {}, [params.pageNo, params.pageSize]);

  const renderCell = useCallback(
    (item, columnKey, idx) => {
      switch (columnKey) {
        case 'no': {
          const rowNumber =
            Number(listQuery?.data?.driverTermsPage?.totalElements ? listQuery?.data?.driverTermsPage?.totalElements : 0) - ((Number(params.pageNo) - 1) * Number(params.pageSize) + idx);
          return <div>{rowNumber}</div>;
        }
        case 'showYn':
          return item.showYn === 'Y' ? (
            <Chip size="sm" variant="flat" color={'success'}>
              ON
            </Chip>
          ) : (
            <Chip size="sm" variant="flat" color={'default'}>
              OFF
            </Chip>
          );
        case 'regDt':
          return <div>{formatUtil.getDate(getKeyValue(item, columnKey), '-')}</div>;
        default:
          return getKeyValue(item, columnKey);
      }
    },
    [listQuery?.data?.driverTermsPage?.totalElements, params.pageNo]
  );

  const handlerRowClick = item => {
    onOpenView();
    selectedId.current = item.id;
  };

  const handlerAddClick = () => {
    onOpenAdd();
  };

  return (
    <ContentWrapper>
      {listQuery.isFetching && <LoadingBackground />}
      <ModalAdd isOpenAdd={isOpenAdd} onOpenChangeAdd={onOpenChangeAdd} onCloseAdd={onCloseAdd} reloadList={reloadList}></ModalAdd>

      <ModalView isOpenView={isOpenView} onOpenChangeView={onOpenChangeView} onCloseView={onCloseView} selectedId={selectedId.current} reloadList={reloadList} onOpenView={onOpenView}></ModalView>

      <div className="sticky flex flex-col gap-1 p-4 mb-2 bg-opacity-75 border-b bg-default-50 border-default-100 rounded-medium">
        <GroupSelect label="운반회사" selectedKey={params.pndGroupId} onChange={onInputChange} name="pndGroupId" onFirstLoad={handleGroupFirstLoad} />
      </div>

      <PaginationTable
        data={{
          page: params.pageNo,
          size: params.pageSize,
          totalPages: listQuery?.data?.driverTermsPage?.totalPages,
          totalElements: listQuery?.data?.driverTermsPage?.totalElements,
          columns: columns,
          items: listQuery?.data?.driverTermsPage?.content,
        }}
        renderCell={renderCell}
        isFetching={listQuery.isFetching}
        isLoading={listQuery.isLoading}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        onRowClick={handlerRowClick}
        onAddClick={handlerAddClick}
      />
    </ContentWrapper>
  );
};

export default List;
