export const colorUtil = {};

colorUtil.hashStringToColor = str => {
  function hashCode(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  }

  function intToRGB(i) {
    const c = (i & 0x00ffffff).toString(16).toUpperCase();

    return '00000'.substring(0, 6 - c.length) + c;
  }

  const hash = hashCode(str);
  const color = intToRGB(hash);

  return `#${color}`;
};
colorUtil.hashStringToRGB = str => {
  function hashCode(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  }

  function intToRGB(i) {
    const red = (i >> 16) & 255; // 빨간색 값 추출
    const green = (i >> 8) & 255; // 초록색 값 추출
    const blue = i & 255; // 파란색 값 추출
    return [red, green, blue];
  }

  const hash = hashCode(str);
  const rgbArray = intToRGB(hash);

  return rgbArray;
};
colorUtil.getContrastingColor = hexColor => {
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);

  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  return luminance > 0.5 ? '#000000' : '#FFFFFF';
};

colorUtil.getDarkModeColor = hexColor => {
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);

  // Adjust the color to be darker
  const darken = color => Math.max(0, color - 50);

  const darkR = darken(r);
  const darkG = darken(g);
  const darkB = darken(b);

  return `#${((1 << 24) + (darkR << 16) + (darkG << 8) + darkB).toString(16).slice(1).toUpperCase()}`;
};
