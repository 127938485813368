import { Api } from '@/api';
import LoadingBackground from '@/components/LoadingBackground';
import { AlertModal } from '@/components/Modal/useAlertModal';
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ScrollShadow, Skeleton } from '@nextui-org/react';
import { DownloadSimple } from '@phosphor-icons/react';
import { useQueries, useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Zoom, Navigation, Pagination } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/zoom';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const ModalImageView = ({ isOpenView, onOpenView, onOpenChangeView, onCloseView, selectedId, reloadList }) => {
  const [imageSrcs, setImageSrcs] = useState([]);
  const { data: jobQuery, isPending } = useQuery({
    queryKey: ['job', selectedId],
    queryFn: () => Api.jobView({ jobUnitId: selectedId }),
    select: res => res.data.data,
    enabled: !!selectedId,
  });

  let deliveryImagePathList = jobQuery?.jobUnit?.deliveryImagePathList || [];
  let pickupImagePathList = jobQuery?.jobUnit?.pickupImagePathList || [];

  const imgList = useMemo(() => [...deliveryImagePathList, ...pickupImagePathList], [deliveryImagePathList, pickupImagePathList], [selectedId]);

  const handleDownload = (src, index) => {
    const link = document.createElement('a');
    link.href = src;
    link.download = `JobImage_${index + 1}.png`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const fetchImage = async (jobUnitId, filePath) => {
    try {
      const response = await Api.jobImage({ jobUnitId, filePath });
      return URL.createObjectURL(response);
    } catch (error) {
      throw new Error('Error fetching image');
    }
  };
  const jobImageQueries = useQueries({
    queries: imgList.map(filePath => ({
      queryKey: ['jobImage', selectedId, filePath],
      queryFn: () => fetchImage(selectedId, filePath),
      enabled: !!selectedId,
      select: data => data ?? '',
    })),
    combine: results => {
      return {
        data: results.map(result => result.data),
        pending: results.some(result => result.isPending),
      };
    },
  });

  useEffect(() => {
    if (selectedId && isOpenView) {
      if (imgList?.length > 0) {
        const fetchImages = async () => {
          try {
            const imagePromises = imgList.map(filePath => Api.jobImage({ jobUnitId: selectedId, filePath }).then(blob => URL.createObjectURL(blob)));
            const imageUrls = await Promise.all(imagePromises);
            setImageSrcs(imageUrls);
          } catch (error) {
            console.error('Error fetching images:', error);
          }
        };
        fetchImages();
      } else if (imageSrcs.length !== 0) {
        setImageSrcs([]);
      }
    }
  }, [selectedId, imgList, isOpenView]);

  return (
    <>
      <>
        {isPending && isOpenView && <LoadingBackground />}
        {!isPending && (
          <Modal isDismissable={false} isOpen={isOpenView} size="xl" onOpenChange={onOpenChangeView} scrollBehavior={'outside'}>
            <ModalContent>
              <ModalHeader>운송내역</ModalHeader>
              <ModalBody>
                <Swiper
                  style={{
                    '--swiper-navigation-color': '#fff',
                    '--swiper-pagination-color': '#fff',
                  }}
                  zoom={true}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Zoom, Pagination]}
                  className="mySwiper w-full h-[500px] rounded-xl bg-default-100"
                >
                  {!jobImageQueries.pending ? (
                    jobImageQueries.data.map((src, index) => (
                      <SwiperSlide key={index}>
                        <div className="relative swiper-zoom-container">
                          <img src={src} alt={`Job Image ${index + 1}`} />
                        </div>
                        <div className="absolute bottom-0 z-0 flex items-center justify-between w-full h-20 p-4 bg-gradient-to-t from-10% from-black/50 to-black/0">
                          <div className="px-2 text-base text-white">
                            {index + 1} / {jobImageQueries.data.length}
                          </div>
                          <Button isIconOnly className="mb-1" size="lg" color="default" variant="light" onPress={() => handleDownload(src, index)}>
                            <DownloadSimple className="text-2xl text-white"></DownloadSimple>
                          </Button>
                        </div>
                      </SwiperSlide>
                    ))
                  ) : (
                    <Skeleton className="min-w-96 min-h-96 rounded-2xl"></Skeleton>
                  )}
                </Swiper>
              </ModalBody>
              <ModalFooter></ModalFooter>
            </ModalContent>
          </Modal>
        )}
        <AlertModal />
      </>
    </>
  );
};

export default ModalImageView;
