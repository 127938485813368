export const iconXs = 16;
export const iconSm = 20;
export const iconMd = 24;
export const iconLg = 28;

//tailwind
export const fontSizeXs = 'text-xs';
export const fontSizeSm = 'text-sm';
export const fontSizeMd = 'text-base';
export const fontSizeLg = 'text-lg';
