import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { refreshAuth } from './refresh-auth';

const BASE_URL = import.meta.env.VITE_APP_BACKEND_HOST;
const CONTENT_TYPE = 'application/json;charset=UTF-8';
const TIMEOUT = 20000;

const initializeAxios = () => {
  const token = localStorage.getItem('token');
  const instance = axios.create({
    baseURL: BASE_URL,
    timeout: TIMEOUT,
    headers: {
      'content-type': CONTENT_TYPE,
      accept: 'application/json,',
      Authorization: token ? `Bearer ${token}` : '',
    },
  });
  return instance;
};

export const axiosInstance = initializeAxios();

export const setHeaderToken = token => {
  axiosInstance.defaults.headers.Authorization = `Bearer ${token}`;
};

export const removeHeaderToken = () => {
  delete axiosInstance.defaults.headers.Authorization;
};

export const getHeaderToken = () => {
  return axiosInstance.defaults.headers.Authorization;
};

let navigateFunction = null;

function getAccessToken() {
  return localStorage.getItem('token');
}

axiosInstance.setNavigate = navigate => {
  navigateFunction = navigate;
};

axiosInstance.interceptors.response.use(
  response => response,
  error => handleResponseError(error)
);

axiosInstance.interceptors.request.use(request => {
  request.headers['Authorization'] = `Bearer ${getAccessToken()}`;
  return request;
});
// 에러 처리 로직을 함수로 분리
const handleResponseError = async error => {
  const status = error.response.status;
  const code = error.response?.data?.code;
  if (status === 400 && code === 'FAIL' && error.response?.data?.msg) {
    return Promise.reject(error);
  } else if (status === 403 || code === 'NOT_AGREE') {
    if (navigateFunction) {
      navigateFunction('/termsAgree');
    }
  } else if ([404].includes(status)) {
    console.log(`${status} error`);
    // navigateFunction('/ErrorPage', {
    //   state: {
    //     status,
    //   },
    // });
    return new Promise(() => {});
    //return Promise.reject(error);
  }
  // else if ([400].includes(status)) {
  // }

  return Promise.reject(error);
};
