import { Api } from '@/api';
import ConfirmModal from '@/components/Modal/ConfirmModal';
import { AlertModal, useAlertModal } from '@/components/Modal/useAlertModal';
import useConfirmModal from '@/hooks/useConfirmModal';
import { formatUtil } from '@/utils/FormatUtil';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Tabs,
  getKeyValue,
  useDisclosure,
  Select,
  SelectItem,
  Chip,
} from '@nextui-org/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useState, useMemo } from 'react';

import { iconMd } from '@/constants/size';
import { MapPin, Trash } from '@phosphor-icons/react';
import ModalFindPlace from './ModalFindPlace';

const ModalPlace = ({ isOpenView, onOpenView, onOpenChangeView, onCloseView, selectedId, reloadList, groupId, year, week, placeId, placeNameObj, placeStatus }) => {
  const columns = [
    {
      label: 'no',
      key: 'no',
    },
    {
      label: placeStatus == 'toPlaceId' ? '상차지' : '하차지',
      key: placeStatus == 'toPlaceId' ? 'fromPlaceId' : 'toPlaceId',
    },
    {
      label: '단가',
      key: 'jobUnitPrice',
    },
    {
      label: '기타금액',
      key: 'jobUnitPrice2',
    },
    {
      label: '총 단가',
      key: 'allPrice',
    },
    {
      label: '운송유형',
      key: 'jobUnitType',
    },
    {
      label: '운반물품',
      key: 'jobPlanPackageId',
    },
    {
      label: '메모',
      key: 'memo',
    },
    {
      label: '삭제',
      key: 'delete',
    },
  ];

  let filterType = '';
  if (placeStatus === 'fromPlaceId') {
    filterType = 'toPlaceId';
  } else {
    filterType = 'fromPlaceId';
  }

  const [placeList, setPlaceList] = useState([]);
  const [deleteList, setDeleteList] = useState([]);

  const [selectedKeys, setSelectedKeys] = useState(new Set([]));
  const { isOpen: isOpenPlace, onOpen: onOpenPlace, onOpenChange: onOpenChangePlace, onClose: onClosePlace } = useDisclosure();

  const { callAlert } = useAlertModal();
  const [oneButtonFlag, setOneButtonFlag] = useState(false);

  const param = {
    groupId: groupId,
    week: week,
    year: year,
  };

  // placeStatus에 따라 fromPlaceId나 toPlaceId를 설정
  if (placeStatus === 'fromPlaceId') {
    param.fromPlaceId = placeId;
    param.shipmentDirection = 'OUTBOUND';
    delete param.toPlaceId;
  } else if (placeStatus === 'toPlaceId') {
    param.toPlaceId = placeId;
    param.shipmentDirection = 'INBOUND';
    delete param.fromPlaceId;
  }

  const { data: weekList } = useQuery({
    queryKey: ['easyPlanList', param],
    queryFn: () => {
      return Api.easyPlanList(param);
    },
    enabled: !!groupId && isOpenView && !!placeId,

    select: res => {
      return res.data?.data?.placeWeekList?.sort((a, b) => {
        return placeNameObj[a.fromPlaceId] < placeNameObj[b.fromPlaceId] ? -1 : placeNameObj[a.fromPlaceId] > placeNameObj[b.fromPlaceId] ? 1 : 0;
      });
    },
  });

  const { data: placeListDt } = useQuery({
    queryKey: ['placeList', { pndGroupId: groupId }],
    queryFn: () => {
      return Api.placeList({ pndGroupId: groupId, pageSize: 1000 });
    },
    select: res => res.data.data,
    enabled: !!groupId,
  });

  const { data: packagesDt } = useQuery({
    queryKey: ['packages', groupId],
    queryFn: () => {
      return Api.packages({ pndGroupId: groupId });
    },
    select: res => res.data.data,
    enabled: !!groupId && isOpenView,
  });

  let placeListInfo = placeListDt?.placePage?.content?.filter(item => item.id != placeId);
  useEffect(() => {
    setPlaceList([]);
    if (weekList?.length > 0) {
      weekList.forEach(item => {
        let data = {
          //fromPlaceId: item.fromPlaceId,
          jobUnitPrice: item.jobUnitPrice ? item.jobUnitPrice : 0,
          jobUnitPrice2: item.jobUnitPrice2 ? item.jobUnitPrice2 : 0,
          jobUnitType: item.jobUnitType,
          jobPlanPackageId: String(item.jobPlanPackageId) || (packagesDt?.jobPlanPackageList?.length > 0 ? String(packagesDt.jobPlanPackageList[0].id) : ''),
          memo: item.memo,
        };
        if (placeStatus === 'fromPlaceId') {
          data.toPlaceId = item.toPlaceId;
        } else {
          data.fromPlaceId = item.fromPlaceId;
        }
        setPlaceList(prevList => [...prevList, data]);
      });
    } else {
      if (placeListInfo?.length > 0) {
        placeListInfo.forEach(item => {
          let data = {
            new: true,
            //fromPlaceId: item.id,
            jobUnitPrice: 0,
            jobUnitPrice2: 0,
            jobUnitType: 'CARGO_DELIVERY',
            jobPlanPackageId: packagesDt?.jobPlanPackageList?.length > 0 ? String(packagesDt.jobPlanPackageList[0].id) : '', // 첫 번째 패키지를 기본 선택
            memo: '',
          };
          if (placeStatus === 'fromPlaceId') {
            data.toPlaceId = item.id;
          } else {
            data.fromPlaceId = item.id;
          }
          setPlaceList(prevList => [...prevList, data]);
        });
      }
    }
  }, [weekList, isOpenView, packagesDt]);

  const { data: planQuery, isPending } = useQuery({
    queryKey: ['plan', selectedId],
    queryFn: () => Api.planView({ jobPlanId: selectedId }),
    select: res => res.data.data,
    enabled: !!selectedId,
  });

  const addEasyPlan = useMutation({
    mutationFn: values => Api.addEasyPlan(values),
  });

  const handleRegistPlace = () => {
    const filteredPlaceList = placeList.map(item => {
      const { new: newProp, fromPlaceId, toPlaceId, ...rest } = item;
      return {
        placeId: fromPlaceId || toPlaceId,
        ...rest,
      };
    });
    const data = {
      pndGroupId: groupId,
      year: year,
      week: week,
      placeList: filteredPlaceList,
    };

    if (placeStatus === 'fromPlaceId') {
      data.fromPlaceId = placeId;
      data.shipmentDirection = 'OUTBOUND';
    } else {
      data.toPlaceId = placeId;
      data.shipmentDirection = 'INBOUND';
    }

    if (deleteList.length > 0) {
      data.placeIdListForDelete = deleteList;
    }
    addEasyPlan.mutate(data, {
      onSuccess: () => {
        callAlert('처리 되었습니다.', () => {
          onCloseView();
          reloadList();
        });
      },
      onError: error => {
        if (error?.response?.data?.code === 'FAIL' && error?.response?.data?.msg) {
          callAlert(error?.response?.data?.msg);
        } else {
          callAlert('처리에 실패하였습니다.');
        }
      },
    });
  };

  let plan = planQuery?.jobPlan;

  const [planStatus, setPlanStatus] = useState('');

  const handleAddPlace = dataList => {
    dataList.forEach(item => {
      const data = {
        jobUnitPrice: 0,
        jobUnitPrice2: 0,
        jobUnitType: 'CARGO_DELIVERY',
        memo: '',
        jobPlanPackageId: packagesDt?.jobPlanPackageList?.length > 0 ? String(packagesDt.jobPlanPackageList[0].id) : '', // 첫 번째 패키지를 기본 선택
        new: true,
      };
      if (placeStatus === 'fromPlaceId') {
        data.toPlaceId = item;
      } else {
        data.fromPlaceId = item;
      }
      setPlaceList(prevList => [...prevList, data]);
    });
  };

  const handleDeletePlace = obj => {
    if (!obj.new) {
      setDeleteList([...deleteList, obj[filterType]]);
    }
    setPlaceList(prevList => prevList.filter(item => item[filterType] !== obj[filterType]));
  };

  const handleJobUnitPriceChange = (id, value) => {
    setPlaceList(prevList => prevList.map(item => (item[filterType] === id ? { ...item, jobUnitPrice: value } : item)));
  };

  const handleJobUnitPriceChange2 = (id, value) => {
    setPlaceList(prevList => prevList.map(item => (item[filterType] === id ? { ...item, jobUnitPrice2: value } : item)));
  };

  const handleJobUnitTypeChange = (id, value) => {
    setPlaceList(prevList => prevList.map(item => (item[filterType] === id ? { ...item, jobUnitType: value } : item)));
  };

  const handleJobPackagesChange = (id, value) => {
    setPlaceList(prevList => prevList.map(item => (item[filterType] === id ? { ...item, jobPlanPackageId: value } : item)));
  };

  const handleMemoChange = (id, value) => {
    setPlaceList(prevList => prevList.map(item => (item[filterType] === id ? { ...item, memo: value } : item)));
  };

  useEffect(() => {
    if (plan?.planStatus) {
      setPlanStatus(plan.planStatus);
    }
  }, [plan]);

  const { confirmOpen, message, requestConfirm, handleConfirm, handleClose } = useConfirmModal();

  const renderCell = useCallback(
    (item, columnKey, idx) => {
      switch (columnKey) {
        case 'no':
          return <div>{Number(idx) + 1}</div>;

        case 'fromPlaceId':
          return <div className={'max-w-[130px] min-w-[80px]'}>{placeNameObj[item.fromPlaceId]}</div>;
        case 'toPlaceId':
          return <div className={'max-w-[130px] min-w-[80px]'}>{placeNameObj[item.toPlaceId]}</div>;

        case 'jobUnitPrice': {
          const jobUnitPrice = (
            <Input
              classNames={{ input: ['text-right'] }}
              className="max-w-[130px] min-w-[110px]"
              value={formatUtil.getNumber(item.jobUnitPrice)}
              aria-label="jobUnitPrice"
              type="text"
              onChange={e => handleJobUnitPriceChange(item[filterType], e.target.value.replace(/[^0-9]/g, ''))}
              endContent={<span style={{ whiteSpace: 'nowrap' }}>원/회</span>}
            />
          );

          return jobUnitPrice;
        }
        case 'jobUnitPrice2': {
          const jobUnitPrice2 = (
            <Input
              classNames={{ input: ['text-right'] }}
              className="max-w-[130px] min-w-[110px]"
              value={formatUtil.getNumber(item.jobUnitPrice2)}
              aria-label="jobUnitPrice2"
              type="text"
              onChange={e => handleJobUnitPriceChange2(item[filterType], e.target.value.replace(/[^0-9]/g, ''))}
              endContent={<span style={{ whiteSpace: 'nowrap' }}>원/회</span>}
            />
          );

          return jobUnitPrice2;
        }
        case 'allPrice': {
          const allPrice = (
            <Input
              classNames={{ input: ['text-right'] }}
              className="max-w-[130px] min-w-[110px]"
              value={formatUtil.getNumber(Number(item.jobUnitPrice || 0) + Number(item.jobUnitPrice2 || 0))}
              aria-label="allPrice"
              type="text"
              placeholder="자동입력"
              isDisabled={true}
              endContent={<span style={{ whiteSpace: 'nowrap' }}>원/회</span>}
            />
          );

          return allPrice;
        }

        case 'jobUnitType':
          return (
            <Select
              //size="sm"
              aria-label="jobUnitType"
              disallowEmptySelection
              className="min-w-[120px]"
              selectedKeys={[item.jobUnitType]}
              onChange={e => {
                handleJobUnitTypeChange(item[filterType], e.target.value);
              }}
              name="jobUnitType"
            >
              <SelectItem key="CARGO_DELIVERY">화물 운반</SelectItem>
              <SelectItem key="WASTE_COLLECT">폐기물 수거</SelectItem>
            </Select>
          );

        case 'jobPlanPackageId':
          return (
            <Select
              aria-label="jobPlanPackageId"
              disallowEmptySelection
              className="min-w-[120px]"
              selectedKeys={[item.jobPlanPackageId.toString()]}
              onChange={e => {
                handleJobPackagesChange(item[filterType], e.target.value);
              }}
              name="jobPlanPackageId"
            >
              {packagesDt?.jobPlanPackageList?.map(item => (
                <SelectItem key={item.id}>{item.name}</SelectItem>
              ))}
            </Select>
          );

        case 'memo': {
          const memo = (
            <Input
              className="min-w-[130px]"
              value={item.memo}
              type="text"
              onKeyDown={e => {
                if (e.key === 'Tab' && e.nativeEvent.isComposing) {
                  e.stopPropagation();
                  e.preventDefault();
                }
              }}
              onInput={e => handleMemoChange(item[filterType], e.target.value)}
              aria-label="memo"
            />
          );
          return memo;
        }

        case 'delete':
          return (
            <Button
              isIconOnly
              variant="faded"
              aria-label="delete"
              size="sm"
              onClick={() => {
                handleDeletePlace(item);
              }}
            >
              <Trash className="text-zinc-400" size={iconMd} />
            </Button>
          );
        default:
          return getKeyValue(item, columnKey);
      }
    },
    [placeList, packagesDt]
  );

  const sortedPlaceList = useMemo(() => {
    // Create a shallow copy of the placeList and sort it
    return [...placeList].sort((a, b) => {
      const nameA = placeNameObj[a[filterType]] || '';
      const nameB = placeNameObj[b[filterType]] || '';
      return nameA.localeCompare(nameB);
    });
  }, [placeList, placeNameObj, filterType]);

  useEffect(() => {
    setDeleteList([]);
  }, [isOpenView]);

  let name = placeListDt?.placePage?.content.find(item => {
    return item.id == placeId;
  })?.name;

  return (
    <>
      <Modal isDismissable={false} isOpen={isOpenView} onOpenChange={onOpenChangeView} className={'min-w-[80vw] '} scrollBehavior={'outside'} aria-label="placeInfoModal">
        <ModalContent>
          {close => (
            <>
              <ModalHeader>방문지 관리</ModalHeader>
              <ModalBody>
                <div className="flex justify-between">
                  <div className="flex items-center gap-2">
                    <Chip size="sm" variant="flat" color={'default'}>
                      {placeStatus == 'toPlaceId' ? '하차지' : '상차지'}
                    </Chip>
                    {name}
                  </div>
                  <Button
                    color="primary"
                    aria-label="add Place Button"
                    startContent={<MapPin />}
                    onPress={() => {
                      onOpenPlace();
                    }}
                  >
                    방문지 추가
                  </Button>
                </div>
                <Table aria-label="">
                  <TableHeader columns={columns}>{column => <TableColumn key={column.key}>{column.label}</TableColumn>}</TableHeader>

                  <TableBody emptyContent={'등록된 정보가 없습니다.'}>
                    {sortedPlaceList.length > 0 && sortedPlaceList?.map((item, idx) => <TableRow key={idx}>{columnKey => <TableCell>{renderCell(item, columnKey, idx)}</TableCell>}</TableRow>)}
                  </TableBody>
                </Table>
              </ModalBody>
              <ModalFooter>
                <Button color="default" onPress={onCloseView} aria-label="close">
                  닫기
                </Button>

                <Button
                  color="primary"
                  aria-label="save"
                  onPress={() => {
                    requestConfirm({
                      message: '방문지를 저장 하시겠습니까?',
                      onConfirm: handleRegistPlace,
                    });
                  }}
                >
                  저장
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

      <ModalFindPlace
        groupId={groupId}
        isOpenPlace={isOpenPlace}
        onOpenPlace={onOpenPlace}
        onOpenChangePlace={onOpenChangePlace}
        onClosePlace={onClosePlace}
        selectedKeys={selectedKeys}
        setSelectedKeys={setSelectedKeys}
        handleAddPlace={handleAddPlace}
        toPlaceId={placeId}
        registeredIdList={placeList.map(item => {
          return item[filterType];
        })}
      />
      <ConfirmModal isOpen={confirmOpen} title={message} onConfirm={handleConfirm} onClose={handleClose} oneButton={oneButtonFlag} />

      <AlertModal />
    </>
  );
};

export default ModalPlace;
