import { Chip } from '@nextui-org/react';

export function CusChip({ color = { bg: '', text: '' }, children, size = 'md' }) {
  return (
    <Chip
      size={size}
      classNames={{
        base: color?.bg,
        content: color?.text,
      }}
    >
      {children}
    </Chip>
  );
  //}
}
