export const getMarker = (status, color) => {
  let svg = '';
  if (status == 'SUCC') {
    svg = `<svg xmlns="http://www.w3.org/2000/svg" width="43" height="38" viewBox="0 0 43 38" fill="none">
            <path fill="${color}" d="M23.8209 33.8979C22.7999 35.7026 20.2001 35.7026 19.1791 33.8979L10.5873 18.7125C5.85827 10.3542 11.8966 1.09555e-06 21.5 1.93511e-06C31.1035 2.77467e-06 37.1417 10.3542 32.4127 18.7125L23.8209 33.8979Z"/>
            <path fill="white" d="M21.5 6.40625C20.2948 6.40625 19.1166 6.76364 18.1145 7.43323C17.1124 8.10282 16.3313 9.05454 15.8701 10.168C15.4089 11.2815 15.2882 12.5068 15.5233 13.6888C15.7585 14.8709 16.3388 15.9567 17.1911 16.8089C18.0433 17.6612 19.1291 18.2415 20.3112 18.4767C21.4932 18.7118 22.7185 18.5911 23.832 18.1299C24.9455 17.6687 25.8972 16.8876 26.5668 15.8855C27.2364 14.8834 27.5938 13.7052 27.5938 12.5C27.592 10.8844 26.9495 9.33538 25.8071 8.19295C24.6646 7.05052 23.1156 6.40796 21.5 6.40625ZM24.1754 11.4254L20.8941 14.7066C20.8506 14.7502 20.7989 14.7848 20.742 14.8084C20.6851 14.832 20.6241 14.8441 20.5625 14.8441C20.5009 14.8441 20.4399 14.832 20.383 14.8084C20.3261 14.7848 20.2744 14.7502 20.2309 14.7066L18.8246 13.3004C18.7367 13.2124 18.6872 13.0931 18.6872 12.9688C18.6872 12.8444 18.7367 12.7251 18.8246 12.6371C18.9126 12.5492 19.0319 12.4997 19.1563 12.4997C19.2806 12.4997 19.3999 12.5492 19.4879 12.6371L20.5625 13.7123L23.5121 10.7621C23.5557 10.7186 23.6074 10.684 23.6643 10.6604C23.7212 10.6369 23.7822 10.6247 23.8438 10.6247C23.9053 10.6247 23.9663 10.6369 24.0232 10.6604C24.0801 10.684 24.1318 10.7186 24.1754 10.7621C24.2189 10.8057 24.2535 10.8574 24.2771 10.9143C24.3006 10.9712 24.3128 11.0322 24.3128 11.0938C24.3128 11.1553 24.3006 11.2163 24.2771 11.2732C24.2535 11.3301 24.2189 11.3818 24.1754 11.4254Z" />
            </svg>`;
  } else if (status == 'FAIL') {
    svg = `<svg xmlns="http://www.w3.org/2000/svg" width="43" height="38" viewBox="0 0 43 38" fill="none">
            <path d="M23.8209 33.8979C22.7999 35.7026 20.2001 35.7026 19.1791 33.8979L10.5873 18.7125C5.85827 10.3542 11.8966 1.09555e-06 21.5 1.93511e-06C31.1035 2.77467e-06 37.1417 10.3542 32.4127 18.7125L23.8209 33.8979Z" fill="#F43F5E"/>
            <path d="M21.5 6.40625C20.2948 6.40625 19.1166 6.76364 18.1145 7.43323C17.1124 8.10282 16.3313 9.05454 15.8701 10.168C15.4089 11.2815 15.2882 12.5068 15.5233 13.6888C15.7585 14.8709 16.3388 15.9567 17.1911 16.8089C18.0433 17.6612 19.1291 18.2415 20.3112 18.4767C21.4932 18.7118 22.7185 18.5911 23.832 18.1299C24.9455 17.6687 25.8972 16.8876 26.5668 15.8855C27.2364 14.8834 27.5938 13.7052 27.5938 12.5C27.592 10.8844 26.9495 9.33538 25.8071 8.19295C24.6646 7.05052 23.1156 6.40796 21.5 6.40625ZM23.7066 14.0434C23.7502 14.0869 23.7847 14.1386 23.8083 14.1955C23.8319 14.2524 23.844 14.3134 23.844 14.375C23.844 14.4366 23.8319 14.4976 23.8083 14.5545C23.7847 14.6114 23.7502 14.6631 23.7066 14.7066C23.6631 14.7502 23.6114 14.7847 23.5545 14.8083C23.4976 14.8319 23.4366 14.844 23.375 14.844C23.3134 14.844 23.2524 14.8319 23.1955 14.8083C23.1386 14.7847 23.0869 14.7502 23.0434 14.7066L21.5 13.1627L19.9566 14.7066C19.9131 14.7502 19.8614 14.7847 19.8045 14.8083C19.7476 14.8319 19.6866 14.844 19.625 14.844C19.5634 14.844 19.5024 14.8319 19.4455 14.8083C19.3886 14.7847 19.3369 14.7502 19.2934 14.7066C19.2498 14.6631 19.2153 14.6114 19.1917 14.5545C19.1681 14.4976 19.156 14.4366 19.156 14.375C19.156 14.3134 19.1681 14.2524 19.1917 14.1955C19.2153 14.1386 19.2498 14.0869 19.2934 14.0434L20.8373 12.5L19.2934 10.9566C19.2054 10.8687 19.156 10.7494 19.156 10.625C19.156 10.5006 19.2054 10.3813 19.2934 10.2934C19.3813 10.2054 19.5006 10.156 19.625 10.156C19.7494 10.156 19.8687 10.2054 19.9566 10.2934L21.5 11.8373L23.0434 10.2934C23.0869 10.2498 23.1386 10.2153 23.1955 10.1917C23.2524 10.1681 23.3134 10.156 23.375 10.156C23.4366 10.156 23.4976 10.1681 23.5545 10.1917C23.6114 10.2153 23.6631 10.2498 23.7066 10.2934C23.7502 10.3369 23.7847 10.3886 23.8083 10.4455C23.8319 10.5024 23.844 10.5634 23.844 10.625C23.844 10.6866 23.8319 10.7476 23.8083 10.8045C23.7847 10.8614 23.7502 10.9131 23.7066 10.9566L22.1627 12.5L23.7066 14.0434Z" fill="white"/>
            </svg>`;
  } else {
    svg = `<svg xmlns="http://www.w3.org/2000/svg" width="43" height="38" viewBox="0 0 43 38" fill="none">
            <path d="M23.8209 33.8979C22.7999 35.7026 20.2001 35.7026 19.1791 33.8979L10.5873 18.7125C5.85827 10.3542 11.8966 1.09555e-06 21.5 1.93511e-06C31.1035 2.77467e-06 37.1417 10.3542 32.4127 18.7125L23.8209 33.8979Z" fill="#94A3B8"/>
            <path d="M23.5313 12C23.5313 12.3029 23.4414 12.5989 23.2732 12.8507C23.1049 13.1025 22.8658 13.2988 22.586 13.4147C22.3062 13.5306 21.9983 13.5609 21.7013 13.5018C21.4042 13.4427 21.1314 13.2969 20.9172 13.0828C20.7031 12.8686 20.5573 12.5958 20.4982 12.2987C20.4391 12.0017 20.4694 11.6938 20.5853 11.414C20.7012 11.1342 20.8975 10.8951 21.1493 10.7268C21.4011 10.5586 21.6971 10.4688 22 10.4688C22.4061 10.4688 22.7956 10.6301 23.0828 10.9172C23.3699 11.2044 23.5313 11.5939 23.5313 12ZM17.625 10.4688C17.3221 10.4688 17.0261 10.5586 16.7743 10.7268C16.5225 10.8951 16.3262 11.1342 16.2103 11.414C16.0944 11.6938 16.0641 12.0017 16.1232 12.2987C16.1823 12.5958 16.3281 12.8686 16.5422 13.0828C16.7564 13.2969 17.0292 13.4427 17.3263 13.5018C17.6233 13.5609 17.9312 13.5306 18.211 13.4147C18.4908 13.2988 18.7299 13.1025 18.8982 12.8507C19.0664 12.5989 19.1563 12.3029 19.1563 12C19.1563 11.5939 18.9949 11.2044 18.7078 10.9172C18.4206 10.6301 18.0311 10.4688 17.625 10.4688ZM26.375 10.4688C26.0721 10.4688 25.7761 10.5586 25.5243 10.7268C25.2725 10.8951 25.0762 11.1342 24.9603 11.414C24.8444 11.6938 24.8141 12.0017 24.8732 12.2987C24.9323 12.5958 25.0781 12.8686 25.2922 13.0828C25.5064 13.2969 25.7792 13.4427 26.0763 13.5018C26.3733 13.5609 26.6812 13.5306 26.961 13.4147C27.2408 13.2988 27.4799 13.1025 27.6482 12.8507C27.8164 12.5989 27.9063 12.3029 27.9063 12C27.9063 11.7989 27.8666 11.5998 27.7897 11.414C27.7127 11.2282 27.5999 11.0594 27.4578 10.9172C27.3156 10.7751 27.1468 10.6623 26.961 10.5853C26.7752 10.5084 26.5761 10.4688 26.375 10.4688Z" fill="#334155"/>
            </svg>`;
  }
  return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svg)}`;
};
