import { Api } from '@/api';
import { FlexTable, FlexTableData, FlexTableHeader, FlexTableRow } from '@/components/FlexTable';
import LoadingBackground from '@/components/LoadingBackground';
import ConfirmModal from '@/components/Modal/ConfirmModal';
import { AlertModal, useAlertModal } from '@/components/Modal/useAlertModal';
import { status } from '@/constants/planStatus';
import useConfirmModal from '@/hooks/useConfirmModal';
import { formatUtil } from '@/utils/FormatUtil';
import { Button, Chip, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from '@nextui-org/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

const type = {
  CARGO_DELIVERY: '화물운반',
  WASTE_COLLECT: '폐기물수거',
};

const ModalView = ({ isOpenView, onOpenView, onOpenChangeView, onCloseView, selectedId, reloadList }) => {
  const { confirmOpen, message, requestConfirm, handleConfirm, handleClose } = useConfirmModal();

  const { callAlert } = useAlertModal();
  const [oneButtonFlag, setOneButtonFlag] = useState(false);

  const { data: planQuery, isPending } = useQuery({
    queryKey: ['plan', selectedId],
    queryFn: () => Api.planView({ jobPlanId: selectedId }),
    select: res => res.data.data,
    enabled: !!selectedId,
  });

  const cancelPlan = useMutation({
    mutationFn: data => {
      return Api.planCancel(data);
    },
    onSuccess: result => {
      if (result.data.code === 'SUCC') {
        callAlert('처리되었습니다.');
        reloadList();
      } else if (result.data.code === 'FAIL') {
        callAlert(result.data.msg);
      } else {
        callAlert('처리에 실패하였습니다.');
      }
    },
    onError: error => {
      console.error('Error adding user', error);
    },
  });

  let plan = planQuery?.jobPlan;
  let jobUnit = planQuery?.jobPlan?.jobUnitList.length > 0 && planQuery?.jobPlan?.jobUnitList[0];

  const [planStatus, setPlanStatus] = useState('');

  useEffect(() => {
    if (plan?.planStatus) {
      setPlanStatus(plan.planStatus);
    }
  }, [plan]);

  const handlerUpdatePage = () => {
    setOneButtonFlag(false);
    cancelPlan.mutate({ id: selectedId });
  };

  return (
    <>
      <>
        {isPending && isOpenView && <LoadingBackground />}
        <Modal isDismissable={false} isOpen={isOpenView} onOpenChange={onOpenChangeView} size="5xl" scrollBehavior={'outside'}>
          <ModalContent>
            {close => (
              <>
                <ModalHeader>배차 정보</ModalHeader>
                <ModalBody>
                  <FlexTable title={'기본정보'}>
                    <FlexTableRow>
                      <FlexTableHeader>등록일</FlexTableHeader>
                      <FlexTableData>{formatUtil.getDate(plan?.regDt, '-')}</FlexTableData>
                      <FlexTableHeader>수정일</FlexTableHeader>
                      <FlexTableData>{formatUtil.getDate(plan?.modDt, '-')}</FlexTableData>
                      <FlexTableHeader>작성자</FlexTableHeader>
                      <FlexTableData>{plan?.regName}</FlexTableData>
                    </FlexTableRow>
                    <FlexTableRow>
                      <FlexTableHeader>운반회사</FlexTableHeader>
                      <FlexTableData>{plan?.groupName}</FlexTableData>
                    </FlexTableRow>
                    <FlexTableRow>
                      <FlexTableHeader>스케줄번호</FlexTableHeader>
                      <FlexTableData>{jobUnit.id}</FlexTableData>
                      <FlexTableHeader>운송일</FlexTableHeader>
                      <FlexTableData>{formatUtil.getDate(plan?.regDt, '-')}</FlexTableData>
                    </FlexTableRow>
                    <FlexTableRow>
                      <FlexTableHeader>운송유형</FlexTableHeader>
                      <FlexTableData>
                        <Chip size="sm" variant="flat" className="text-zinc-400">
                          {type[jobUnit.type]}
                        </Chip>
                      </FlexTableData>
                      <FlexTableHeader>운송물품</FlexTableHeader>
                      <FlexTableData>
                        {jobUnit?.jobPlanPackageName ? (
                          <Chip size="sm" variant="flat" className="text-default-500">
                            {jobUnit?.jobPlanPackageName}
                          </Chip>
                        ) : (
                          '-'
                        )}
                      </FlexTableData>
                    </FlexTableRow>
                    <FlexTableRow>
                      <FlexTableHeader>상차지</FlexTableHeader>
                      <FlexTableData>
                        {jobUnit.pickupPlaceName}

                        <div className="text-xs text-zinc-400">
                          <Chip size="sm" variant="flat" color={'default'} className="mr-2">
                            {jobUnit.pickupPlaceZipCode}
                          </Chip>
                          {jobUnit.pickupPlaceAddress1} {jobUnit.pickupPlaceAddress2}
                        </div>
                      </FlexTableData>
                      <FlexTableHeader>담당자</FlexTableHeader>
                      <FlexTableData>
                        {jobUnit.pickupPlaceMangerName}
                        {<span className="pl-2 text-xs text-zinc-400">{formatUtil.getMobile(jobUnit.pickupPlaceMangerTel)}</span>}
                      </FlexTableData>
                    </FlexTableRow>
                    <FlexTableRow>
                      <FlexTableHeader>하차지</FlexTableHeader>
                      <FlexTableData>
                        {jobUnit.deliveryPlaceName}
                        {
                          <div className="text-xs text-zinc-400">
                            <Chip size="sm" variant="flat" color={'default'} className="mr-2">
                              {jobUnit.deliveryPlaceZipCode}
                            </Chip>
                            {jobUnit.deliveryPlaceAddress1} {jobUnit.deliveryPlaceAddress2}
                          </div>
                        }
                      </FlexTableData>
                      <FlexTableHeader>담당자</FlexTableHeader>
                      <FlexTableData>
                        {jobUnit.deliveryPlaceMangerName}
                        {<span className="pl-2 text-xs text-zinc-400">{formatUtil.getMobile(jobUnit.deliveryPlaceMangerTel)}</span>}
                      </FlexTableData>
                    </FlexTableRow>
                    <FlexTableRow>
                      <FlexTableHeader>단가</FlexTableHeader>
                      <FlexTableData>
                        <div className="flex items-center gap-1">
                          <span className="text-default-400">단가</span>
                          <span>{formatUtil.getNumber(jobUnit?.price)}원</span>
                        </div>
                        <div className="flex items-center gap-1">
                          <span className="text-default-400">기타금액</span>
                          <span>{formatUtil.getNumber(jobUnit?.price2)}원</span>
                        </div>

                        <div className="flex items-center gap-1">
                          <span className="text-default-400">총 단가</span>
                          <span className="font-medium">{formatUtil.getNumber(Number(jobUnit.price || 0) + Number(jobUnit.price2 || 0))}원</span>
                        </div>
                      </FlexTableData>
                    </FlexTableRow>
                    <FlexTableRow>
                      <FlexTableHeader>운송 안내사항</FlexTableHeader>
                      <FlexTableData>{jobUnit.guide ?? '-'}</FlexTableData>
                    </FlexTableRow>
                  </FlexTable>
                  <FlexTable title={'배차 정보'}>
                    <FlexTableRow>
                      <FlexTableHeader>배차상태</FlexTableHeader>
                      <FlexTableData>
                        <div className="flex items-center gap-2">
                          <div className={planStatus === 'NOT_READY' ? 'text-orange-500' : 'text-sky-500'}>{planStatus === 'NOT_READY' ? '미정' : '배차완료'}</div>
                          {planStatus !== 'NOT_READY' && planStatus !== 'READY' && <div className="text-xs text-zinc-400">({status[planStatus]} 상태)</div>}
                          {planStatus !== 'NOT_READY' && planStatus !== 'READY' && <div className="text-xs text-zinc-400">운송이 시작된 후에는 배차상태를 변경할 수 없습니다.</div>}
                        </div>
                      </FlexTableData>
                    </FlexTableRow>
                    <FlexTableRow>
                      <FlexTableHeader>배차 완료일</FlexTableHeader>
                      <FlexTableData>{formatUtil.getDate(plan?.planDate, '-')}</FlexTableData>
                    </FlexTableRow>
                    <FlexTableRow>
                      <FlexTableHeader>기사</FlexTableHeader>
                      <FlexTableData>{plan?.driverName ? plan?.driverName : '-'}</FlexTableData>
                      <FlexTableHeader>차량번호</FlexTableHeader>
                      <FlexTableData>{plan?.vehicleNo ? plan?.vehicleNo : '-'}</FlexTableData>
                    </FlexTableRow>
                    <FlexTableRow>
                      <FlexTableHeader>기사 전화번호</FlexTableHeader>
                      <FlexTableData>{plan?.driverTel ? formatUtil.getTel(plan?.driverTel) : '-'}</FlexTableData>
                    </FlexTableRow>
                  </FlexTable>
                </ModalBody>
                <ModalFooter>
                  <Button color="default" onPress={onCloseView}>
                    닫기
                  </Button>
                  {planStatus === 'READY' && (
                    <Button
                      color="primary"
                      onPress={() => {
                        requestConfirm({
                          message: '수정하시겠습니까?',
                          onConfirm: handlerUpdatePage,
                        });
                      }}
                    >
                      배차취소
                    </Button>
                  )}
                </ModalFooter>
              </>
            )}
          </ModalContent>
        </Modal>
        <ConfirmModal isOpen={confirmOpen} title={message} onConfirm={handleConfirm} onClose={handleClose} oneButton={oneButtonFlag} />
        <AlertModal />
      </>
      {cancelPlan.isPending && <LoadingBackground />}
    </>
  );
};

export default ModalView;
