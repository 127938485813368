import { Api } from '@/api';
import { AlertModal, useAlertModal } from '@/components/Modal/useAlertModal';
import { Button, Modal, ModalBody, ModalContent, ModalFooter } from '@nextui-org/react';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';

const ModalSmart = ({ isOpenSmart, onOpenChangeSmart, onCloseSmart, reloadList, selectedKey }) => {
  //팝업타입 do, doing, done
  const { callAlert } = useAlertModal();
  const [popupType, setPopupType] = useState('do');

  const planSmart = useMutation({
    mutationFn: values => Api.planSmart(values),
  });

  const registPlanSmart = () => {
    setPopupType('doing');
    let data = { ids: [...selectedKey].join() };
    planSmart.mutate(data, {
      onSuccess: () => {
        setPopupType('done');
        reloadList();
      },
      onError: error => {
        if (error?.response?.data?.code === 'FAIL' && error?.response?.data?.msg) {
          callAlert(error?.response?.data?.msg);
        } else {
          callAlert('배차 실패하였습니다.');
        }
      },
    });
  };

  return (
    <>
      <Modal isDismissable={false} isOpen={isOpenSmart} onOpenChange={onOpenChangeSmart} size="1xl">
        {popupType === 'do' && (
          <ModalContent>
            <ModalBody>
              <div className="mt-10 flex flex-col items-start gap-4">
                <div>
                  <img className="w-10" src={`${import.meta.env.VITE_APP_CONTEXT_PATH}/smile.gif`} alt="망고체크 로고" />
                </div>
                <div className="relative grid w-full   overflow-x-auto   rounded-xl text-xl font-semibold">
                  {selectedKey.size ? selectedKey.size : selectedKey.length} 건에 대해 스마트배차를 하시겠습니까?
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <ModalFooter>
                <Button color="default" className="ml-auto" onPress={onCloseSmart}>
                  닫기
                </Button>
                <Button color="primary" disabled={''} onPress={registPlanSmart}>
                  스마트 배차
                </Button>
              </ModalFooter>
            </ModalFooter>
          </ModalContent>
        )}

        {popupType === 'doing' && (
          <ModalContent>
            <ModalBody>
              <div className="mt-10 flex flex-col items-start gap-4 h-48">
                <div>
                  <img className="w-10" src={`${import.meta.env.VITE_APP_CONTEXT_PATH}/eye.gif`} alt="망고체크 로고" />
                </div>
                <div className="relative grid w-full   overflow-x-auto   rounded-xl text-xl font-semibold">스마트 배차를 진행하고 있어요.</div>
              </div>
            </ModalBody>
          </ModalContent>
        )}

        {popupType === 'done' && (
          <ModalContent>
            <ModalBody>
              <div className="mt-10 flex flex-col items-start">
                <div>
                  <img className="w-10" src={`${import.meta.env.VITE_APP_CONTEXT_PATH}/clap.gif`} alt="망고체크 로고" />
                </div>
                <div className="text-xl font-semibold pt-4">스마트 배차를 완료했어요.</div>
              </div>
            </ModalBody>
            <ModalFooter>
              <ModalFooter>
                <Button color="default" className="ml-auto" onPress={onCloseSmart}>
                  닫기
                </Button>
              </ModalFooter>
            </ModalFooter>
          </ModalContent>
        )}
      </Modal>
      <AlertModal />
    </>
  );
};

export default ModalSmart;
