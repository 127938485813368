import { formatUtil } from '@/utils/FormatUtil';
import { CalendarDate, getLocalTimeZone, today } from '@internationalized/date';
import { parseDate, startOfWeek, endOfWeek, startOfMonth, endOfMonth } from '@internationalized/date';
import { Button, ButtonGroup, Tab, Tabs } from '@nextui-org/react';
import { useCallback } from 'react';

import { DateRangePicker } from '@nextui-org/react';
import { useState } from 'react';

const CustomDateRangePicker = ({ label, setParams, value, dateKey = { start: 'dateStart', end: 'dateEnd' } }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <DateRangePicker
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      CalendarBottomContent={<CalendarBottomContent dateKey={dateKey} setParams={setParams} setIsOpen={setIsOpen}></CalendarBottomContent>}
      size="sm"
      labelPlacement="outside"
      className="max-w-max"
      label={label}
      visibleMonths={2}
      value={value}
      onChange={e => {
        setParams(prevParams => ({
          ...prevParams,
          [dateKey.start]: String(e.start).replaceAll('-', ''),
          [dateKey.end]: String(e.end).replaceAll('-', ''),
          page: 1,
        }));
      }}
    />
  );
};

const CalendarBottomContent = ({ setParams, setIsOpen, dateKey }) => {
  if (!setParams) {
    console.error('setParams is not provided.');
    return null; // Or some fallback UI
  }
  const setCalendarDate = (startDate, endDate) => {
    setParams(prevParams => ({
      ...prevParams,
      [dateKey.start]: String(startDate).replaceAll('-', ''),
      [dateKey.end]: String(endDate).replaceAll('-', ''),
    }));
    setIsOpen(false);
  };

  const locale = 'kr-KR';
  const currendDate = today();

  //지난달
  const startOfBeforeMonth = startOfMonth(currendDate.subtract({ months: 1 }), locale);
  const endOfBeforeMonth = endOfMonth(currendDate.subtract({ months: 1 }), locale);

  //지난주
  const startOfBeforeWeek = startOfWeek(currendDate.subtract({ weeks: 1 }), locale);
  const endOfBeforeWeek = endOfWeek(currendDate.subtract({ weeks: 1 }), locale);

  //이번주
  const startOfCurrentWeek = startOfWeek(currendDate, locale);
  const endOfCurrentWeek = endOfWeek(currendDate, locale);

  //다음주
  const startOfAfterWeek = startOfWeek(currendDate.add({ weeks: 1 }), locale);
  const endOfAfterWeek = endOfWeek(currendDate.add({ weeks: 1 }), locale);

  //이번달
  const startOfCurrentMonth = startOfMonth(currendDate, locale);
  const endOfCurrentMonth = endOfMonth(currendDate, locale);

  //다음달
  const startOfAfterMonth = startOfMonth(currendDate.add({ months: 1 }), locale);
  const endOfAfterMonth = endOfMonth(currendDate.add({ months: 1 }), locale);

  return (
    <div className="px-3 pb-3">
      <ButtonGroup fullWidth radius="full" size="sm" variant="flat">
        <Button onPress={() => setCalendarDate(startOfBeforeMonth, endOfBeforeMonth)}>지난달</Button>
        <Button onPress={() => setCalendarDate(startOfBeforeWeek, endOfBeforeWeek)}>지난주</Button>
        <Button onPress={() => setCalendarDate(currendDate, currendDate)}>오늘</Button>
        <Button onPress={() => setCalendarDate(startOfCurrentWeek, endOfCurrentWeek)}>이번주</Button>
        <Button onPress={() => setCalendarDate(startOfCurrentMonth, endOfCurrentMonth)}>이번달</Button>
        <Button onPress={() => setCalendarDate(startOfAfterWeek, endOfAfterWeek)}>다음주</Button>
        <Button onPress={() => setCalendarDate(startOfAfterMonth, endOfAfterMonth)}>다음달</Button>
      </ButtonGroup>
    </div>
  );
};

export default CustomDateRangePicker;
