import { Api } from '@/api';
import LoadingBackground from '@/components/LoadingBackground';
import ConfirmModal from '@/components/Modal/ConfirmModal';
import { AlertModal, useAlertModal } from '@/components/Modal/useAlertModal';
import useConfirmModal from '@/hooks/useConfirmModal';
import { formatUtil } from '@/utils/FormatUtil';
import { Button, Chip, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, useDisclosure } from '@nextui-org/react';
import { useMutation, useQuery } from '@tanstack/react-query';

import { FlexTable, FlexTableRow, FlexTableHeader, FlexTableData } from '@/components/FlexTable';

const ModalView = ({ isOpenView, onOpenView, onOpenChangeView, onCloseView, selectedId, reloadList, onOpenUpdate }) => {
  const { callAlert } = useAlertModal();
  const driverQuery = useQuery({
    queryKey: ['driver', selectedId],
    queryFn: () => Api.driverView({ driverId: selectedId }),
    select: res => res.data.data,
    enabled: !!selectedId && isOpenView,
  });

  let driverData = driverQuery?.data?.driver;

  const { confirmOpen, message, requestConfirm, handleConfirm, handleClose } = useConfirmModal();

  const handlerUpdatePage = () => {
    onCloseView();
    onOpenUpdate();
  };

  const delDriver = useMutation({
    mutationFn: id => {
      return Api.driverDel({ driverId: id });
    },
    onSuccess: data => {
      onCloseView();
      callAlert('처리되었습니다.', () => {
        reloadList();
      });
    },
    onError: error => {
      if (error.response.data.code == 'FAIL' && error.response.data.msg) {
        callAlert(error.response.data.msg);
      }
    },
  });

  const handlerDelete = id => {
    delDriver.mutate(id);
  };

  return (
    <>
      {driverQuery.isPending && isOpenView && <LoadingBackground />}
      <Modal isDismissable={false} isOpen={isOpenView} onOpenChange={onOpenChangeView} size="5xl" scrollBehavior={'outside'}>
        <ModalContent>
          {close => (
            <>
              <ModalHeader>기사 조회</ModalHeader>
              <ModalBody>
                <FlexTable>
                  <FlexTableRow>
                    <FlexTableHeader>등록일</FlexTableHeader>
                    <FlexTableData>{formatUtil.getDate(driverData?.regDt, '-')}</FlexTableData>
                    <FlexTableHeader>수정일</FlexTableHeader>
                    <FlexTableData>{formatUtil.getDate(driverData?.modDt, '-')}</FlexTableData>
                    <FlexTableHeader>작성자</FlexTableHeader>
                    <FlexTableData>{driverData?.regName}</FlexTableData>
                  </FlexTableRow>

                  <FlexTableRow>
                    <FlexTableHeader>운반회사</FlexTableHeader>
                    <FlexTableData>{driverData?.groupName}</FlexTableData>
                  </FlexTableRow>

                  <FlexTableRow>
                    <FlexTableHeader>활성화</FlexTableHeader>
                    <FlexTableData>
                      <Chip size="sm" variant="flat" color={driverData?.useYn == 'Y' ? 'success' : 'default'}>
                        {driverData?.useYn == 'Y' ? '활성' : '비활성'}
                      </Chip>
                    </FlexTableData>
                  </FlexTableRow>

                  <FlexTableRow>
                    <FlexTableHeader>소속</FlexTableHeader>
                    <FlexTableData>{driverData?.company ? driverData?.company : '-'}</FlexTableData>
                  </FlexTableRow>

                  <FlexTableRow>
                    <FlexTableHeader>기사명</FlexTableHeader>
                    <FlexTableData>{driverData?.name ? driverData?.name : '-'}</FlexTableData>
                  </FlexTableRow>

                  <FlexTableRow>
                    <FlexTableHeader>기사 전화번호</FlexTableHeader>
                    <FlexTableData>{formatUtil.getTel(driverData?.tel)}</FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>차량번호</FlexTableHeader>
                    <FlexTableData>{driverData?.vehicleNo ? driverData?.vehicleNo : '-'}</FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>메모</FlexTableHeader>
                    <FlexTableData>{driverData?.memo ? driverData?.memo : '-'}</FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>등록일</FlexTableHeader>
                    <FlexTableData>{formatUtil.getDate(driverData?.regDt, '-')}</FlexTableData>
                  </FlexTableRow>
                </FlexTable>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="danger"
                  className="mr-auto"
                  isLoading={delDriver.isPending}
                  onPressEnd={() => {
                    requestConfirm({
                      message: '삭제하시겠습니까??',
                      onConfirm: () => {
                        handlerDelete(driverData?.id);
                      },
                    });
                  }}
                >
                  삭제
                </Button>
                <Button color="default" onPress={onCloseView}>
                  닫기
                </Button>
                <Button color="primary" onPress={handlerUpdatePage}>
                  수정
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

      <ConfirmModal isOpen={confirmOpen} title={message} onConfirm={handleConfirm} onClose={handleClose} />
      <AlertModal />
    </>
  );
};

export default ModalView;
