import { Api } from '@/api';
import { FlexTable, FlexTableData, FlexTableHeader, FlexTableRow } from '@/components/FlexTable';
import ConfirmModal from '@/components/Modal/ConfirmModal';
import { AlertModal, useAlertModal } from '@/components/Modal/useAlertModal';
import useConfirmModal from '@/hooks/useConfirmModal';
import { parseDate } from '@internationalized/date';
import { Button, DateRangePicker, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Select, SelectItem, useDisclosure } from '@nextui-org/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import DaumPostcodeEmbed from 'react-daum-postcode';
import * as Yup from 'yup';
import ModalAddress from '@/components/Modal/AddressModal';
import { formatUtil } from '@/utils/FormatUtil';

const ModalAdd = ({ isOpenAdd, onOpenAdd, onOpenChangeAdd, onCloseAdd, selectedId, reloadList }) => {
  const { callAlert } = useAlertModal();
  const { isOpen: isOpenPostcode, onOpen: onOpenPostcode, onOpenChange: onOpenChangePostcode, onClose: onClosePostcode } = useDisclosure();

  const { isOpen: isOpenAddress, onOpen: onOpenAddress, onOpenChange: onOpenChangeAddress, onClose: onCloseAddress } = useDisclosure();

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, validateForm, resetForm, setTouched, setValues } = useFormik({
    initialValues: {
      pndGroupId: '',
      vehicleOwner: '',
      vehicleIdList: new Array(0),
      vehicleOwnerTel: '',
      startDate: '',
      endDate: '',
      bizName: '',
      bizNo: '',
      bizCeo: '',
      bizAddr1: '',
      bizAddr2: '',
      bizZipCode: '',
      bizTel: '',
      bizFax: '',
    },
    validationSchema: Yup.object({
      pndGroupId: Yup.string().required('운반회사를 선택해주세요.'),
      vehicleOwner: Yup.string().required('차주를 선택해주세요.'),
      vehicleIdList: Yup.array()
        .of(Yup.string().required('차량 ID가 필요합니다.')) // 각 요소가 문자열인 배열
        .min(1, '차량을 한개 이상 선택해주세요.'), // 최소 하나 이상의 아이템
      vehicleOwnerTel: Yup.string().required('차주 전화번호를 입력해주세요.'),
      startDate: Yup.string().required('기간을 선택해주세요.'),
      endDate: Yup.string().required('기간을 선택해주세요.'),
      bizName: Yup.string().required('상호명을 입력해주세요.'),
      bizNo: Yup.string().required('등록번호를 입력해주세요.'),
      bizCeo: Yup.string().required('대표이사를 입력해주세요.'),
      // bizZipCode: Yup.string().required('주소를 선택해주세요.'),
      bizAddr1: Yup.string().required('주소를 선택해주세요.'),
      bizAddr2: Yup.string().required('상세주소를 입력해주세요.'),
      bizTel: Yup.string().required('전화번호를 입력해주세요.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      addJobCost.mutate(values, {
        onSettled: () => {
          setSubmitting(false);
        },
        onSuccess: () => {
          callAlert('등록되었습니다.', () => {
            onCloseAdd();
            reloadList();
          });
        },
        onError: error => {
          if (error?.response?.data?.code === 'FAIL' && error?.response?.data?.msg) {
            callAlert(error?.response?.data?.msg);
          } else {
            callAlert('등록에 실패하였습니다.');
          }
        },
      });
    },
  });

  const handleRegisterClick = async () => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      requestConfirm({
        message: '등록 하시겠습니까?',
        onConfirm: handleSubmit,
      });
    } else {
      console.log(errors);
      Object.keys(errors).map(item => {
        touched[item] = true;
      });
      callAlert('입력한 데이터가 올바르지 않습니다.');
    }
  };

  const groupsQuery = useQuery({
    queryKey: ['groups'],
    queryFn: () => Api.groups(),
    select: res => res.data.data,
  });

  let groupId = values?.pndGroupId ? values?.pndGroupId : groupsQuery.data?.groupList[0].id;

  const vehicleOwnersQuery = useQuery({
    queryKey: ['vehicleOwners', groupId],
    queryFn: () => Api.vehicleOwnerList({ pndGroupId: Number(groupId) }),
    enabled: !!groupId,
    select: res => res.data.data,
  });
  let owner = values?.vehicleOwner ? values?.vehicleOwner : vehicleOwnersQuery.data?.ownerList[0];

  const vehicleListQuery = useQuery({
    queryKey: ['vehicleList', { groupId, owner }],
    queryFn: () => {
      return Api.vehicleList({ pndGroupId: groupId, useYn: 'Y', owner });
    },
    select: res => res.data.data,
    enabled: !!groupId && !!owner,
  });

  const addJobCost = useMutation({
    mutationFn: values => Api.jobCostAdd(values),
  });

  const Postcode = () => {
    const handleComplete = data => {
      onClosePostcode();
      let fullAddress = data.address;
      let extraAddress = '';

      if (data.addressType === 'R') {
        if (data.bname !== '') {
          extraAddress += data.bname;
        }
        if (data.buildingName !== '') {
          extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
        }
        fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
      }

      console.log(fullAddress); // e.g. '서울 성동구 왕십리로2길 20 (성수동1가)'
      setFieldValue('bizAddr1', fullAddress);
      setFieldValue('bizZipCode', data.zonecode);
    };

    return <DaumPostcodeEmbed autoClose={false} style={{ minHeight: '500px' }} onComplete={handleComplete} />;
  };

  useEffect(() => {
    resetForm();
    Object.keys(errors).map(item => {
      touched[item] = false;
    });
  }, [isOpenAdd]);

  useEffect(() => {
    if (groupsQuery.data?.groupList && isOpenAdd) {
      setFieldValue('pndGroupId', String(groupsQuery.data.groupList[0].id));
    }
  }, [groupsQuery?.data, isOpenAdd]);

  useEffect(() => {
    //운반회사 변경시 차량관련정보 초기화 및 기본정보 불러오기
    const selectedPndGroup = groupsQuery.data?.groupList?.find(item => item.id == values.pndGroupId) || null;
    if (selectedPndGroup) {
      setValues({
        ...values,

        //차량관련정보 초기화
        vehicleOwner: '',
        vehicleIdList: new Array(0),
        vehicleOwnerTel: '',

        //운반회사 기본정보 불러오기
        bizAddr1: selectedPndGroup?.meta?.bizAddr1 || '',
        bizAddr2: selectedPndGroup?.meta?.bizAddr2 || '',
        bizCeo: selectedPndGroup?.meta?.bizCeo || '',
        bizFax: selectedPndGroup?.meta?.bizFax || '',
        bizName: selectedPndGroup?.meta?.bizName || '',
        bizNo: selectedPndGroup?.meta?.bizNo || '',
        bizTel: selectedPndGroup?.meta?.bizTel || '',
        bizZipCode: selectedPndGroup?.meta?.bizZipCode || '',
      });
    }
  }, [values.pndGroupId]);

  useEffect(() => {
    //차주 변경시 차량관련 정보 초기화 하기
    setValues({
      ...values,
      vehicleIdList: new Array(0),
      vehicleOwnerTel: vehicleListQuery.data?.vehiclePage?.content[0]?.ownerTel || '',
    });
  }, [values.vehicleOwner]);

  const { confirmOpen, message, requestConfirm, handleConfirm, handleClose } = useConfirmModal();
  return (
    <>
      <Modal isDismissable={false} isOpen={isOpenAdd} onOpenChange={onOpenChangeAdd} size="5xl" scrollBehavior={'outside'}>
        <ModalContent>
          {close => (
            <>
              <ModalHeader>운송비 내역서 생성</ModalHeader>
              <ModalBody>
                <FlexTable title={'기본정보'}>
                  <FlexTableRow>
                    <FlexTableHeader>운반회사</FlexTableHeader>
                    <FlexTableData>
                      <Select name="pndGroupId" aria-label="pndGroupId" size="md" onChange={handleChange} disallowEmptySelection selectedKeys={[String(values.pndGroupId)]}>
                        {groupsQuery.data?.groupList?.map(item => (
                          <SelectItem key={item.id}>{item.name}</SelectItem>
                        ))}
                      </Select>
                    </FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>
                      운행기간<span className="text-rose-500">*</span>
                    </FlexTableHeader>
                    <FlexTableData>
                      <DateRangePicker
                        labelPlacement="outside"
                        className="max-w-max"
                        visibleMonths={2}
                        value={{
                          start: values.startDate ? parseDate(values.startDate.slice(0, 4) + '-' + values.startDate.slice(4, 6) + '-' + values.startDate.slice(6, 8)) : null,
                          end: values.endDate ? parseDate(values.endDate.slice(0, 4) + '-' + values.endDate.slice(4, 6) + '-' + values.endDate.slice(6, 8)) : null,
                        }}
                        onChange={e => {
                          setFieldValue('startDate', String(e.start).replaceAll('-', ''));
                          setFieldValue('endDate', String(e.end).replaceAll('-', ''));
                        }}
                        isInvalid={touched.startDate && !!errors.startDate && touched.endDate && !!errors.endDate}
                        errorMessage={touched.startDate && errors.startDate && touched.endDate && errors.endDate}
                      />
                    </FlexTableData>
                  </FlexTableRow>
                </FlexTable>
                <FlexTable title={'차량정보'}>
                  <FlexTableRow>
                    <FlexTableHeader>
                      차주명<span className="text-rose-500">*</span>
                    </FlexTableHeader>
                    <FlexTableData>
                      <Select
                        name="vehicleOwner"
                        disabledKeys={['-1']}
                        placeholder="차주를 선택해주세요"
                        aria-label="pndGroupId"
                        size="md"
                        onChange={handleChange}
                        selectedKeys={[String(values.vehicleOwner)]}
                        isInvalid={touched.vehicleOwner && !!errors.vehicleOwner}
                        errorMessage={touched.vehicleOwner && errors.vehicleOwner}
                      >
                        {vehicleOwnersQuery.data?.ownerList?.length == 0 && <SelectItem>차주 목록이 비어있습니다.</SelectItem>}
                        {vehicleOwnersQuery.data?.ownerList?.map((item, idx) => (
                          <SelectItem key={item}>{item}</SelectItem>
                        ))}
                      </Select>
                    </FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>
                      차량목록<span className="text-rose-500">*</span>
                    </FlexTableHeader>
                    <FlexTableData>
                      <Select
                        name="vehicleIdList"
                        placeholder="차량을 선택하세요"
                        isDisabled={!values.vehicleOwner}
                        aria-label="vehicleIdList"
                        size="md"
                        disabledKeys={['-1']}
                        onSelectionChange={keys => {
                          setFieldValue(
                            'vehicleIdList',
                            [...keys].filter(item => item !== '')
                          );
                        }}
                        selectionMode="multiple"
                        selectedKeys={values.vehicleIdList}
                        isInvalid={touched.vehicleIdList && !!errors.vehicleIdList}
                        errorMessage={touched.vehicleIdList && errors.vehicleIdList}
                      >
                        {(vehicleListQuery.data?.vehiclePage?.content?.length || 0) == 0 && <SelectItem key={-1}>차량 목록이 비어있습니다.</SelectItem>}
                        {vehicleListQuery.data?.vehiclePage?.content?.map((item, idx) => (
                          <SelectItem key={item.id}>{item.vehicleNo}</SelectItem>
                        ))}
                      </Select>
                    </FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>
                      차주번호<span className="text-rose-500">*</span>
                    </FlexTableHeader>
                    <FlexTableData>
                      <Input
                        name="vehicleOwnerTel"
                        type="text"
                        placeholder="차주번호를 입력해주세요"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.vehicleOwnerTel}
                        isInvalid={touched.vehicleOwnerTel && !!errors.vehicleOwnerTel}
                        errorMessage={touched.vehicleOwnerTel && errors.vehicleOwnerTel}
                      />
                    </FlexTableData>
                  </FlexTableRow>
                </FlexTable>
                <FlexTable title={'공급받는자'}>
                  <FlexTableRow>
                    <FlexTableHeader>
                      상호<span className="text-rose-500">*</span>
                    </FlexTableHeader>
                    <FlexTableData>
                      <Input
                        name="bizName"
                        type="text"
                        placeholder="상호명을 입력해주세요"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.bizName}
                        isInvalid={touched.bizName && !!errors.bizName}
                        errorMessage={touched.bizName && errors.bizName}
                      />
                    </FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>
                      대표이사<span className="text-rose-500">*</span>
                    </FlexTableHeader>
                    <FlexTableData>
                      <Input
                        name="bizCeo"
                        type="text"
                        placeholder="대표명을 입력해주세요"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.bizCeo}
                        isInvalid={touched.bizCeo && !!errors.bizCeo}
                        errorMessage={touched.bizCeo && errors.bizCeo}
                      />
                    </FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>
                      등록번호<span className="text-rose-500">*</span>
                    </FlexTableHeader>
                    <FlexTableData>
                      <Input
                        name="bizNo"
                        type="text"
                        placeholder="등록번호를 입력해주세요"
                        onBlur={handleBlur}
                        isInvalid={touched.bizNo && !!errors.bizNo}
                        errorMessage={touched.bizNo && errors.bizNo}
                        onChange={e => {
                          let value = e.target.value.replace(/[^\d]/g, '');
                          setFieldValue('bizNo', value);
                        }}
                        value={formatUtil.getBizNo(values.bizNo)}
                      />
                    </FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>
                      주소 <span className="text-rose-500">*</span>
                    </FlexTableHeader>
                    <FlexTableData className="flex-col !items-start">
                      <div className="flex gap-2">
                        <Input
                          name="bizZipCode"
                          isReadOnly
                          maxLength={16}
                          size="sm"
                          type="text"
                          placeholder="우편번호(자동입력)"
                          onChange={handleChange}
                          value={values.bizZipCode}
                          isInvalid={touched.bizZipCode && !!errors.bizZipCode}
                          errorMessage={touched.bizZipCode && errors.bizZipCode}
                        />
                        <Button color="primary" size="sm" variant="flat" style={{ marginRight: '8px' }} onPress={onOpenAddress}>
                          주소찾기
                        </Button>
                      </div>
                      <Input
                        name="bizAddr1"
                        maxLength={16}
                        isReadOnly
                        size="sm"
                        type="text"
                        placeholder="도로명 주소(자동입력)"
                        onChange={handleChange}
                        value={values.bizAddr1}
                        isInvalid={touched.bizAddr1 && !!errors.bizAddr1}
                        errorMessage={touched.bizAddr1 && errors.bizAddr1}
                      />
                      <Input
                        name="bizAddr2"
                        maxLength={16}
                        size="sm"
                        type="text"
                        placeholder="상세주소를 입력해주세요."
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.bizAddr2}
                        isInvalid={touched.bizAddr2 && !!errors.bizAddr2}
                        errorMessage={touched.bizAddr2 && errors.bizAddr2}
                      />
                    </FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>
                      전화<span className="text-rose-500">*</span>
                    </FlexTableHeader>
                    <FlexTableData>
                      <Input
                        name="bizTel"
                        type="text"
                        placeholder="전화번호를 입력해주세요"
                        onBlur={handleBlur}
                        isInvalid={touched.bizTel && !!errors.bizTel}
                        errorMessage={touched.bizTel && errors.bizTel}
                        onChange={e => {
                          let value = e.target.value.replace(/[^\d]/g, '');
                          setFieldValue('bizTel', value);
                        }}
                        value={formatUtil.getTel(values.bizTel)}
                      />
                    </FlexTableData>
                    <FlexTableHeader>팩스</FlexTableHeader>
                    <FlexTableData>
                      <Input
                        name="bizFax"
                        type="text"
                        placeholder="팩스번호를 입력해주세요"
                        onBlur={handleBlur}
                        isInvalid={touched.bizFax && !!errors.bizFax}
                        errorMessage={touched.bizFax && errors.bizFax}
                        onChange={e => {
                          let value = e.target.value.replace(/[^\d]/g, '');
                          setFieldValue('bizFax', value);
                        }}
                        value={formatUtil.getTel(values.bizFax)}
                      />
                    </FlexTableData>
                  </FlexTableRow>
                </FlexTable>
              </ModalBody>
              <ModalFooter>
                <Button color="default" onPress={onCloseAdd}>
                  닫기
                </Button>
                <Button isLoading={addJobCost.isLoading} color="primary" disabled={isSubmitting} onPress={handleRegisterClick}>
                  등록
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
      <ConfirmModal isOpen={confirmOpen} title={message} onConfirm={handleConfirm} onClose={handleClose} />
      <AlertModal />
      <Modal isDismissable={false} isOpen={isOpenPostcode} onOpenChange={onOpenChangePostcode} size="lg">
        <ModalContent>
          <ModalHeader className="flex flex-col gap-1">주소찾기</ModalHeader>
          <Postcode />
        </ModalContent>
      </Modal>

      <ModalAddress
        isOpenAddress={isOpenAddress}
        onOpenChangeAddress={onOpenChangeAddress}
        onCloseAddress={onCloseAddress}
        onOpenAddress={onOpenAddress}
        setFieldValue={setFieldValue}
        addrName={'bizAddr1'}
        zipName={'bizZipCode'}
      ></ModalAddress>
    </>
  );
};

export default ModalAdd;
