import { Api } from '@/api';
import useForm from '@/hooks/useForm';
import { Button, Input, Modal, ModalHeader, Tab, Tabs, ModalContent, ModalFooter, ModalBody, Select, SelectItem, Chip, useDisclosure } from '@nextui-org/react';
import { Envelope, IdentificationBadge, Key, Phone, User, BuildingOffice, UserPlus } from '@phosphor-icons/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import useConfirmModal from '@/hooks/useConfirmModal';
import ConfirmModal from '@/components/Modal/ConfirmModal';
import { useAlertModal, AlertModal } from '@/components/Modal/useAlertModal';
import { formatUtil } from '@/utils/FormatUtil';
import DaumPostcodeEmbed from 'react-daum-postcode';
import { FlexTable, FlexTableRow, FlexTableHeader, FlexTableData } from '@/components/FlexTable';
import CustomFileInput from '@/components/CustomFileInput';
import ModalAddress from '../../components/Modal/AddressModal';

const ModalUpdate = ({ isOpenUpdate, onOpenChangeUpdate, onCloseUpdate, selectedId, onOpenView }) => {
  const queryClient = useQueryClient();
  const { callAlert } = useAlertModal();
  const reloadView = () => {
    queryClient.invalidateQueries(['vehicle', selectedId]);
  };

  const { isOpen: isOpenAddress, onOpen: onOpenAddress, onOpenChange: onOpenChangeAddress, onClose: onCloseAddress } = useDisclosure();

  const { confirmOpen, message, requestConfirm, handleConfirm, handleClose } = useConfirmModal();
  const { isOpen: isOpenPostcode, onOpen: onOpenPostcode, onOpenChange: onOpenChangePostcode, onClose: onClosePostcode } = useDisclosure();
  const { data: placeQuery, isLoading } = useQuery({
    queryKey: ['place', selectedId],
    queryFn: () => Api.placeView({ placeId: selectedId }),
    select: res => res.data.data,
    enabled: !!selectedId,
  });

  let place = placeQuery?.place;

  const groupQuery = useQuery({
    queryKey: ['groups'],
    queryFn: () => Api.groups(),
    select: res => res.data.data,
  });

  const roleQuery = useQuery({
    queryKey: ['roles'],
    queryFn: () => Api.roles(),
    select: res => res.data.data,
  });
  const filesUpload = useMutation({
    mutationFn: ({ placeId, uploadFiles }) => Api.placeFilesUpload({ placeId, uploadFiles }),
  });
  const updatePlace = useMutation({
    mutationFn: values => Api.placeUpdate(values),
  });

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, validateForm, resetForm, setValues } = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: selectedId || '',
      pndGroupId: place?.groupId || '',
      name: place?.name || '',
      mainTel: place?.mainTel || '',
      mainEmail: place?.mainEmail || '',
      address1: place?.address1 || '',
      address2: place?.address2 || '',
      zipcode: place?.zipcode || '',
      managerTel: place?.managerTel || '',
      managerName: place?.managerName || '',
      managerTel2: place?.managerTel2 || '',
      managerName2: place?.managerName2 || '',
      managerTel3: place?.managerTel3 || '',
      managerName3: place?.managerName3 || '',
      memo: place?.memo || '',
      useYn: place?.useYn || '',
      mobile: place?.mobile || '',
      file1: place?.fileName1 || null,
      file2: place?.fileName2 || null,
      file3: place?.fileName3 || null,
      x: place?.x || '',
      y: place?.y || '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('방문지명을 입력하세요.'),
      // zipcode: Yup.string().required('주소를 선택해주세요.'),
      address1: Yup.string().required('주소를 선택해주세요.'),
      address2: Yup.string().required('상세주소를 입력해주세요.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      const { file1, file2, file3, ...rest } = values;
      rest.managerTel = rest.managerTel.replaceAll('-', '');
      rest.delFile1 = !!(file1 !== place?.fileName1) || file1 instanceof File;
      rest.delFile2 = !!(file2 !== place?.fileName2) || file2 instanceof File;
      rest.delFile3 = !!(file3 !== place?.fileName3) || file2 instanceof File;

      const uploadFiles = [file1, file2, file3].filter(file => file instanceof File);
      console.log('파일 체크');

      updatePlace.mutate(rest, {
        onSettled: () => {
          setSubmitting(false);
        },
        onSuccess: () => {
          if (uploadFiles.length > 0) {
            filesUpload.mutate(
              { placeId: place.id, uploadFiles },
              {
                onSuccess: () => {
                  callAlert('등록되었습니다.', () => {
                    onCloseUpdate();
                    onOpenView();
                    reloadView();
                  });
                },
                onError: uploadError => {
                  callAlert(`파일 업로드 실패: ${uploadError.message}`);
                },
              }
            );
          } else {
            callAlert('등록되었습니다.', () => {
              onCloseUpdate();
              onOpenView();
              reloadView();
            });
          }
        },
        onError: error => {
          if (error?.response?.data?.code === 'FAIL' && error?.response?.data?.msg) {
            callAlert(error?.response?.data?.msg);
          } else {
            callAlert('수정에 실패하였습니다.');
          }
        },
      });
    },
  });
  console.log(values);
  const handleUpdateClick = async () => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      requestConfirm({
        message: '수정 하시겠습니까?',
        onConfirm: handleSubmit,
      });
    } else {
      callAlert('입력한 데이터가 올바르지 않습니다.');
    }
  };

  useEffect(() => {
    if (values.managerTel) {
      const value = values.managerTel.replace(/\D/g, '');
      setFieldValue('managerTel', formatUtil.getTel(value));
    }
    if (values.mobile) {
      const value = values.mobile.replace(/\D/g, '');
      setFieldValue('mobile', formatUtil.getTel(value));
    }
  }, [values.managerTel, values.mobile, setFieldValue]);

  useEffect(() => {
    resetForm();
  }, [isOpenUpdate, place, resetForm]);

  const Postcode = () => {
    const handleComplete = data => {
      let fullAddress = data.address;
      let extraAddress = '';

      if (data.addressType === 'R') {
        if (data.bname !== '') {
          extraAddress += data.bname;
        }
        if (data.buildingName !== '') {
          extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
        }
        fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
      }

      console.log(fullAddress); // e.g. '서울 성동구 왕십리로2길 20 (성수동1가)'
      setFieldValue('address1', fullAddress);
      setFieldValue('zipcode', data.zonecode);
      onClosePostcode();
    };

    return <DaumPostcodeEmbed onComplete={handleComplete} />;
  };

  return (
    <>
      <Modal isDismissable={false} isOpen={isOpenUpdate} onOpenChange={onOpenChangeUpdate} size="3xl" scrollBehavior={'outside'}>
        <ConfirmModal isOpen={confirmOpen} title={message} onConfirm={handleConfirm} onClose={handleClose} />
        {roleQuery.data?.adminRoleList && (
          <ModalContent>
            <form
              onSubmit={event => {
                event.preventDefault();
                handleSubmit();
              }}
            >
              <ModalHeader>방문지 수정</ModalHeader>
              <ModalBody>
                <FlexTable title={'기본정보'}>
                  <FlexTableRow>
                    <FlexTableHeader>운반회사</FlexTableHeader>
                    <FlexTableData>
                      <Select name="pndGroupId" aria-label="pndGroupId" isDisabled={true} size="md" onChange={handleChange} disallowEmptySelection selectedKeys={[String(values.pndGroupId)]}>
                        {groupQuery.data?.groupList?.map(item => (
                          <SelectItem key={item.id}>{item.name}</SelectItem>
                        ))}
                      </Select>
                    </FlexTableData>
                  </FlexTableRow>

                  <FlexTableRow>
                    <FlexTableHeader>
                      활성화 <span className="text-rose-500">*</span>
                    </FlexTableHeader>
                    <FlexTableData>
                      <Tabs
                        fullWidth
                        selectedKey={values.useYn}
                        onSelectionChange={e => {
                          if (e !== values.useYn) {
                            setFieldValue('useYn', e);
                          }
                        }}
                      >
                        <Tab key={'Y'} title={'활성'} />
                        <Tab key={'N'} title={'비활성'} />
                      </Tabs>
                    </FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>
                      방문지명 <span className="text-rose-500">*</span>
                    </FlexTableHeader>
                    <FlexTableData>
                      <Input
                        name="name"
                        labelPlacement="outside"
                        placeholder="방문지명을 입력하세요"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                        isInvalid={touched.name && !!errors.name}
                        errorMessage={touched.name && errors.name}
                      ></Input>
                    </FlexTableData>
                  </FlexTableRow>

                  <FlexTableRow>
                    <FlexTableHeader>
                      주소 <span className="text-rose-500">*</span>
                    </FlexTableHeader>
                    <FlexTableData className="flex-col !items-start">
                      <div className="flex gap-2">
                        <Input
                          name="zipcode"
                          isReadOnly
                          maxLength={16}
                          size="sm"
                          type="text"
                          placeholder="우편번호(자동입력)"
                          onChange={handleChange}
                          value={values.zipcode}
                          // isInvalid={touched.zipcode && !!errors.zipcode}
                          // errorMessage={touched.zipcode && errors.zipcode}
                        />
                        <Button color="primary" size="sm" variant="flat" style={{ marginRight: '8px' }} onPress={onOpenAddress}>
                          주소찾기
                        </Button>
                        <div className="flex flex-col text-xs font-thin text-slate-100">
                          <span>X:{values.x}</span>
                          <span>Y:{values.y}</span>
                        </div>
                      </div>
                      <Input
                        name="address1"
                        maxLength={16}
                        isReadOnly
                        size="sm"
                        type="text"
                        placeholder="도로명 주소(자동입력)"
                        onChange={handleChange}
                        value={values.address1}
                        isInvalid={touched.address1 && !!errors.address1}
                        errorMessage={touched.address1 && errors.address1}
                      />
                      <Input
                        name="address2"
                        maxLength={16}
                        size="sm"
                        type="text"
                        placeholder="상세주소를 입력해주세요."
                        onChange={handleChange}
                        value={values.address2}
                        onBlur={handleBlur}
                        isInvalid={touched.address2 && !!errors.address2}
                        errorMessage={touched.address2 && errors.address2}
                      />
                    </FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>대표 전화번호</FlexTableHeader>
                    <FlexTableData>
                      <Input
                        name="mainTel"
                        maxLength={13}
                        type="text"
                        placeholder="대표 전화번호를 입력해주세요."
                        value={values.mainTel}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.mainTel && !!errors.mainTel}
                        errorMessage={touched.mainTel && errors.mainTel}
                      />
                    </FlexTableData>
                    <FlexTableHeader>대표 이메일</FlexTableHeader>
                    <FlexTableData>
                      <Input
                        name="mainEmail"
                        maxLength={320}
                        type="text"
                        placeholder="대표 이메일을 입력해주세요."
                        value={values.mainEmail}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.mainEmail && !!errors.mainEmail}
                        errorMessage={touched.mainEmail && errors.mainEmail}
                      />
                    </FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>문서</FlexTableHeader>
                    <FlexTableData>
                      <div className="flex flex-col flex-1 gap-2">
                        <CustomFileInput size={'sm'} initFileName={place?.fileName1} name="file1" onChange={e => setFieldValue('file1', e?.target.files[0] || null)}></CustomFileInput>
                        <CustomFileInput size={'sm'} initFileName={place?.fileName2} name="file2" onChange={e => setFieldValue('file2', e?.target.files[0] || null)}></CustomFileInput>
                        <CustomFileInput size={'sm'} initFileName={place?.fileName3} name="file3" onChange={e => setFieldValue('file3', e?.target.files[0] || null)}></CustomFileInput>
                      </div>
                    </FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>메모</FlexTableHeader>
                    <FlexTableData>
                      <Input
                        name="memo"
                        labelPlacement="outside"
                        placeholder="메모를 입력하세요"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.memo}
                        isInvalid={touched.memo && !!errors.memo}
                        errorMessage={touched.memo && errors.memo}
                      ></Input>
                    </FlexTableData>
                  </FlexTableRow>
                </FlexTable>
                <FlexTable title={'담당자 정보'}>
                  <FlexTableRow>
                    <FlexTableHeader>담당자</FlexTableHeader>
                    <FlexTableData>
                      <Input
                        name="managerName"
                        labelPlacement="outside"
                        placeholder=" 담당자명을 입력하세요"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.managerName}
                        isInvalid={touched.managerName && !!errors.managerName}
                        errorMessage={touched.managerName && errors.managerName}
                      ></Input>
                    </FlexTableData>
                    <FlexTableHeader>담당자 전화번호</FlexTableHeader>
                    <FlexTableData>
                      <Input
                        name="managerTel"
                        labelPlacement="outside"
                        placeholder="담당자 전화번호를 입력하세요"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.managerTel}
                        isInvalid={touched.managerTel && !!errors.managerTel}
                        errorMessage={touched.managerTel && errors.managerTel}
                        maxLength={13}
                      ></Input>
                    </FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>담당자 2</FlexTableHeader>
                    <FlexTableData>
                      <Input
                        name="managerName2"
                        labelPlacement="outside"
                        placeholder=" 담당자명을 입력하세요"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.managerName2}
                        isInvalid={touched.managerName2 && !!errors.managerName2}
                        errorMessage={touched.managerName2 && errors.managerName2}
                      ></Input>
                    </FlexTableData>
                    <FlexTableHeader>담당자 2 전화번호</FlexTableHeader>
                    <FlexTableData>
                      <Input
                        name="managerTel2"
                        labelPlacement="outside"
                        placeholder="담당자 전화번호를 입력하세요"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.managerTel2}
                        isInvalid={touched.managerTel2 && !!errors.managerTel2}
                        errorMessage={touched.managerTel2 && errors.managerTel2}
                        maxLength={13}
                      ></Input>
                    </FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>담당자 3</FlexTableHeader>
                    <FlexTableData>
                      <Input
                        name="managerName3"
                        labelPlacement="outside"
                        placeholder=" 담당자명을 입력하세요"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.managerName3}
                        isInvalid={touched.managerName3 && !!errors.managerName3}
                        errorMessage={touched.managerName3 && errors.managerName3}
                      ></Input>
                    </FlexTableData>
                    <FlexTableHeader>담당자 3 전화번호</FlexTableHeader>
                    <FlexTableData>
                      <Input
                        name="managerTel3"
                        labelPlacement="outside"
                        placeholder="담당자 전화번호를 입력하세요"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.managerTel3}
                        isInvalid={touched.managerTel3 && !!errors.managerTel3}
                        errorMessage={touched.managerTel3 && errors.managerTel3}
                        maxLength={13}
                      ></Input>
                    </FlexTableData>
                  </FlexTableRow>
                  <FlexTableRow>
                    <FlexTableHeader>알림톡 전화번호</FlexTableHeader>
                    <FlexTableData>
                      <Input
                        name="mobile"
                        labelPlacement="outside"
                        placeholder="알림톡 전화번호를 입력하세요"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.mobile}
                        isInvalid={touched.mobile && !!errors.mobile}
                        errorMessage={touched.mobile && errors.mobile}
                        maxLength={13}
                      ></Input>
                    </FlexTableData>
                  </FlexTableRow>
                </FlexTable>
              </ModalBody>
              <ModalFooter>
                <Button color="default" className="ml-auto" onPress={onCloseUpdate}>
                  닫기
                </Button>
                <Button color="primary" isDisabled={!place} disabled={isSubmitting} onPress={handleUpdateClick}>
                  확인
                </Button>
              </ModalFooter>
            </form>
          </ModalContent>
        )}
      </Modal>
      <AlertModal />
      <Modal isDismissable={false} isOpen={isOpenPostcode} onOpenChange={onOpenChangePostcode} size="lg">
        <ModalContent>
          {close => (
            <>
              <ModalHeader className="flex flex-col gap-1">주소찾기</ModalHeader>
              <Postcode />
            </>
          )}
        </ModalContent>
      </Modal>
      <ModalAddress
        isOpenAddress={isOpenAddress}
        onOpenChangeAddress={onOpenChangeAddress}
        onCloseAddress={onCloseAddress}
        onOpenAddress={onOpenAddress}
        setFieldValue={setFieldValue}
        addrName={'address1'}
        zipName={'zipcode'}
      ></ModalAddress>
    </>
  );
};

export default ModalUpdate;
