import { Api } from '@/api';
import ConfirmModal from '@/components/Modal/ConfirmModal';
import useConfirmModal from '@/hooks/useConfirmModal';
import { formatUtil } from '@/utils/FormatUtil';
import { Button, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Select, SelectItem, Tab, Tabs } from '@nextui-org/react';
import { BuildingOffice, Envelope, IdentificationBadge, Key, Phone, User } from '@phosphor-icons/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

import { useAlertModal, AlertModal } from '@/components/Modal/useAlertModal';
const ModalAdd = ({ isOpenAdd, onOpenChangeAdd, onCloseAdd, reloadList }) => {
  const { confirmOpen, message, requestConfirm, handleConfirm, handleClose } = useConfirmModal();
  const { callAlert } = useAlertModal();

  const [isCheckDuplicate, setIsCheckDuplicate] = useState(false);

  const groupQuery = useQuery({
    queryKey: ['groups'],
    queryFn: () => Api.groups(),
    select: res => res.data.data,
  });
  const roleQuery = useQuery({
    queryKey: ['roles'],
    queryFn: () => Api.roles(),
    select: res => res.data.data,
  });
  const addAdmin = useMutation({
    mutationFn: values => Api.adminAdd(values),
  });

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, validateForm, resetForm } = useFormik({
    initialValues: {
      pndGroupId: '',
      loginId: '',
      loginPw: '',
      adminRoleId: '2',
      loginPwCheck: '',
      adminName: '',
      adminTel: '',
      adminEmail: '',
      useYn: 'Y',
      lockYn: 'Y',
    },
    validationSchema: Yup.object({
      loginId: Yup.string()
        .required('아이디를 입력하세요.')
        .matches(/^(?=.*[A-Za-z])[A-Za-z\d]{5,16}$/, '5~16 자리 / 영문, 숫자만 가능합니다.')
        .test('check-duplicate', '이미 사용 중인 아이디입니다.', async value => {
          if (!value || isCheckDuplicate) return true;
          const result = await Api.adminExistCheck({ loginId: value });
          if (result?.data?.code == 'SUCC') {
            setIsCheckDuplicate(true);
          }
          return result?.data?.code == 'SUCC';
        }),
      loginPw: Yup.string()
        .required('비밀번호를 입력하세요.')
        .matches(/^.*(?=^.{6,30}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&+=]).*$/, '6~30 자리 / 영어 대문자, 소문자, 숫자, 특수문자 중3개 이상 조합 '),
      loginPwCheck: Yup.string()
        .oneOf([Yup.ref('loginPw'), null], '비밀번호가 일치하지 않습니다.')
        .required('비밀번호 확인을 입력하세요.'),
      adminName: Yup.string().required('사용자명을 입력하세요.'),
      adminTel: Yup.string()
        .matches(/^\d{2,3}-\d{3,4}-\d{4}$/, '전화번호 형식이 잘못되었습니다.')
        .required('전화번호를 입력하세요.'),
      adminEmail: Yup.string()
        .matches(/^[^\u3131-\uD79D]+$/, '이메일에 한글은 입력할 수 없습니다.')
        .email('이메일 형식이 잘못되었습니다.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      const { loginPwCheck, ...restValues } = values; // loginPwCheck 값을 제외
      restValues.adminTel = restValues.adminTel.replaceAll('-', '');
      addAdmin.mutate(restValues, {
        onSettled: () => {
          setSubmitting(false);
        },
        onSuccess: result => {
          if (result?.data?.code === 'FAIL' && result?.data?.msg) {
            callAlert(result?.data?.msg);
          } else {
            callAlert('등록되었습니다.', () => {
              onCloseAdd();
              reloadList();
            });
          }
        },
        onError: error => {
          if (error?.response?.data?.code === 'FAIL' && error?.response?.data?.msg) {
            callAlert(error?.response?.data?.msg);
          } else {
            callAlert('등록에 실패하였습니다.');
          }
        },
      });
    },
  });

  useEffect(() => {
    if (values.adminTel) {
      const value = values.adminTel.replace(/\D/g, '');
      setFieldValue('adminTel', formatUtil.getTel(value));
    }
  }, [values.adminTel, setFieldValue]);

  const handleRegisterClick = async () => {
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      requestConfirm({
        message: '등록 하시겠습니까?',
        onConfirm: handleSubmit,
      });
    } else {
      Object.keys(errors).map(item => {
        touched[item] = true;
      });
      callAlert('입력한 데이터가 올바르지 않습니다.');
    }
  };

  useEffect(() => {
    resetForm();
    Object.keys(errors).map(item => {
      touched[item] = false;
    });
  }, [isOpenAdd]);

  useEffect(() => {
    if (groupQuery.data?.groupList) {
      setFieldValue('pndGroupId', String(groupQuery.data.groupList[0].id));
    }
  }, [groupQuery.data, isOpenAdd]);

  return (
    <>
      <Modal isDismissable={false} isOpen={isOpenAdd} onOpenChange={onOpenChangeAdd} size="5xl" scrollBehavior={'outside'}>
        <ConfirmModal isOpen={confirmOpen} title={message} onConfirm={handleConfirm} onClose={handleClose} />
        <ModalContent>
          <form
            onSubmit={event => {
              event.preventDefault();
              handleSubmit();
            }}
          >
            <ModalHeader>계정 등록</ModalHeader>
            <ModalBody>
              <div className="flex flex-col space-y-4">
                <div className="">
                  <div className="mb-2 text-sm">회사명</div>
                  <Select
                    name="pndGroupId"
                    size="md"
                    aria-label="pndGroupId"
                    onChange={handleChange}
                    disallowEmptySelection
                    defaultSelectedKeys={[String(groupQuery.data?.groupList[0]?.id)]}
                    startContent={<BuildingOffice weight="fill" className="text-default-500" />}
                  >
                    {groupQuery.data?.groupList?.map(item => (
                      <SelectItem key={item.id}>{item.name}</SelectItem>
                    ))}
                  </Select>
                </div>
                <div className="flex space-x-2">
                  <Input
                    name="loginId"
                    label="아이디"
                    labelPlacement="outside"
                    placeholder="아이디를 입력하세요"
                    startContent={<IdentificationBadge weight="fill" className="text-default-500" />}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.loginId && !!errors.loginId}
                    errorMessage={touched.loginId && errors.loginId}
                  ></Input>
                </div>
                <div className="flex space-x-2">
                  <Input
                    name="loginPw"
                    label="비밀번호"
                    type="password"
                    autoComplete="new-password"
                    labelPlacement="outside"
                    placeholder="비밀번호를 입력하세요"
                    startContent={<Key weight="fill" className="text-default-500" />}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.loginPw && !!errors.loginPw}
                    errorMessage={touched.loginPw && errors.loginPw}
                  ></Input>
                  <Input
                    name="loginPwCheck"
                    label="비밀번호 확인"
                    type="password"
                    autoComplete="new-password"
                    labelPlacement="outside"
                    placeholder="비밀번호를 입력하세요"
                    startContent={<Key weight="fill" className="text-default-500" />}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.loginPwCheck && !!errors.loginPwCheck}
                    errorMessage={touched.loginPwCheck && errors.loginPwCheck}
                  ></Input>
                </div>
                <div className="hidden">
                  <div className="mb-2 text-sm">권한</div>
                  <Tabs
                    fullWidth
                    selectedKey={values.adminRoleId}
                    onSelectionChange={e => {
                      if (e !== values.adminRoleId) {
                        setFieldValue('adminRoleId', e);
                      }
                    }}
                  >
                    {roleQuery.data?.adminRoleList?.map(item => (
                      <Tab key={item.id} title={item.roleName} />
                    ))}
                  </Tabs>
                </div>

                <div className="flex space-x-2">
                  <Input
                    name="adminName"
                    label="사용자명"
                    labelPlacement="outside"
                    placeholder="사용자명을 입력해주세요"
                    startContent={<User weight="fill" className="text-default-500" />}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.adminName && !!errors.adminName}
                    errorMessage={touched.adminName && errors.adminName}
                    maxLength={20}
                  ></Input>
                </div>
                <div className="flex space-x-2">
                  <Input
                    name="adminTel"
                    label="전화번호"
                    labelPlacement="outside"
                    placeholder="010-0000-0000"
                    startContent={<Phone weight="fill" className="text-default-500" />}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={formatUtil.getMobile(values.adminTel)}
                    isInvalid={touched.adminTel && !!errors.adminTel}
                    errorMessage={touched.adminTel && errors.adminTel}
                    maxLength={13}
                  ></Input>
                </div>
                <div className="flex space-x-2">
                  <Input
                    name="adminEmail"
                    label="이메일"
                    labelPlacement="outside"
                    placeholder="example@example.com"
                    startContent={<Envelope weight="fill" className="text-default-500" />}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.adminEmail && !!errors.adminEmail}
                    errorMessage={touched.adminEmail && errors.adminEmail}
                  ></Input>
                </div>
                <div>
                  <div className="mb-2 text-sm">사용여부</div>
                  <Tabs
                    fullWidth
                    selectedKey={values.useYn}
                    onSelectionChange={e => {
                      if (e !== values.useYn) {
                        setFieldValue('useYn', e);
                      }
                    }}
                  >
                    <Tab key="Y" title="사용" />
                    <Tab key="N" title="미사용" />
                  </Tabs>
                </div>
                <div>
                  <div className="mb-2 text-sm">잠김여부</div>
                  <Tabs
                    fullWidth
                    selectedKey={values.lockYn}
                    onSelectionChange={e => {
                      if (e !== values.lockYn) {
                        setFieldValue('lockYn', e);
                      }
                    }}
                  >
                    <Tab key="Y" title="잠김" />
                    <Tab key="N" title="해제" />
                  </Tabs>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button isLoading={addAdmin.isLoading} fullWidth color="primary" disabled={isSubmitting} onPress={handleRegisterClick}>
                등록
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
      <AlertModal />
    </>
  );
};

export default ModalAdd;
