import { NextUIProvider } from '@nextui-org/react';
import { useNavigate } from 'react-router-dom';
import { ThemeProvider as NextThemesProvider } from 'next-themes';
import toast, { ToastBar, Toaster } from 'react-hot-toast';
import Router from './router/Router';
import DynamicIcon from './components/DynamicIcon';
function App() {
  const navigate = useNavigate();
  return (
    <NextUIProvider navigate={navigate} locale="ko-KR" className="flex h-dvh">
      <NextThemesProvider attribute="class" defaultTheme="light">
        <Toaster
          toastOptions={{
            duration: 4000,
            className: 'border-default-100 border text-sm bg-background dark:bg-default-50 text-content4-foreground dark:text-white shadow-lg border-default-100',
            success: {
              icon: <DynamicIcon iconName={'CheckCircle'} weight={'fill'} size={24} className="text-primary" />,
            },
            error: {
              icon: <DynamicIcon iconName={'XCircle'} weight={'fill'} size={24} className="text-danger" />,
            },
          }}
        ></Toaster>
        <Router />
      </NextThemesProvider>
    </NextUIProvider>
  );
}

export default App;
