import React, { useState, useEffect, createContext, useContext } from 'react';
import { Modal, ModalContent, ModalBody, useDisclosure } from '@nextui-org/react';

const AlertModalContext = createContext();

export const AlertModalProvider = ({ children }) => {
  const [alertStatus, setAlertStatus] = useState({
    status: false,
    message: '',
    callback: null,
  });

  const callAlert = (message, callback) => {
    setAlertStatus({
      status: true,
      message: message,
      callback: callback,
    });
  };

  useEffect(() => {
    let timer;
    if (alertStatus.status) {
      timer = setTimeout(() => {
        setAlertStatus({ status: false, message: '', callback: null });
        alertStatus.callback && alertStatus.callback();
      }, 800); // 0.8초 후에 모달 닫기
    }
    return () => clearTimeout(timer); // 컴포넌트 언마운트 시 타이머 제거
  }, [alertStatus.status]);

  return <AlertModalContext.Provider value={{ callAlert, alertStatus }}>{children}</AlertModalContext.Provider>;
};

export const useAlertModal = () => useContext(AlertModalContext);

export const AlertModal = () => {
  const { alertStatus } = useAlertModal();
  const { isOpen, onOpenChange } = useDisclosure();

  return (
    <Modal
      shadow="none"
      isOpen={alertStatus.status}
      onOpenChange={onOpenChange}
      hideCloseButton
      classNames={{
        backdrop: 'bg-transparent',
      }}
    >
      <ModalContent>
        <ModalBody>
          <div className="text-center">{alertStatus.message}</div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
