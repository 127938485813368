import { Api } from '@/api';
import DynamicIcon from '@/components/DynamicIcon';
import VusMap from '@/components/VusMap';
import { GroupSelect } from '@/components/common/filter';
import useCustomSearchParams from '@/hooks/useCustomSearchParams';
import { useDebouncedEffect } from '@/hooks/useDebounceEffect';
import { colorUtil } from '@/utils/ColorUtil';
import { Button, ButtonGroup, Input, ScrollShadow, Skeleton } from '@nextui-org/react';
import { useQueries, useQuery } from '@tanstack/react-query';
import { useWindowSize } from '@uidotdev/usehooks';
import { addDays, differenceInMinutes, format, isSameDay, isSameMonth, parse } from 'date-fns';
import { isArray } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { formatUtil } from './../../utils/FormatUtil';
import useComponentSize from '@/hooks/useComponentSize';

const View = () => {
  const mapRef = useRef(null);
  const containerRef = useRef(null);
  const [selectedDetailVehicleId, setSelectedDetailVehicleId] = useState(0);
  const [selectedVisibleVehicleId, setSelectedVisibleVehicleId] = useState(new Set());
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const { width, height } = useComponentSize(containerRef);
  const [searchParams] = useSearchParams();
  const isValidFormat = /^\d{4}(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])$/.test(searchParams.get('runDate'));
  const initialParams = {
    pndGroupId: searchParams.get('pndGroupId') || '',
    search: searchParams.get('search') || '',
    runDate: isValidFormat ? searchParams.get('runDate') : format(addDays(new Date(), 0), 'yyyyMMdd'),
    pageNo: '',
    pageSize: '',
  };

  const { params, setParams, queryKey, onRowsPerPageChange, onPageChange, onInputChange } = useCustomSearchParams(initialParams);

  const mapDataQueries = useQueries({
    queries: [
      {
        queryKey: ['locationHistory', params?.runDate, params?.pndGroupId],
        queryFn: () => Api.vehicleLocationHistory({ groupId: params?.pndGroupId, runDate: params?.runDate, accuracyLE: 9 }),
        initialData: {},
        refetchInterval: 1000 * 60 * 5,
        enabled: !!params.pndGroupId,
        select: res => {
          const vehicleLocationHistory = res.data.data.vehicleLocationHistoryList.reduce((accumulator, currentValue) => {
            const vehicleId = currentValue.vehicleId;
            if (!accumulator[vehicleId]) {
              accumulator[vehicleId] = {
                id: vehicleId,
                path: [],
              };
            }

            const lastRunTime = new Date(parse(currentValue.runDate + currentValue.runTime, 'yyyyMMddHHmmss'));
            accumulator[vehicleId].isRunning = differenceInMinutes(new Date(), lastRunTime) <= 10;
            accumulator[vehicleId].path.push([currentValue.x, currentValue.y]);
            return accumulator;
          }, {});
          return vehicleLocationHistory;
        },
      },
      {
        queryKey: ['jobHistory', params?.runDate, params?.pndGroupId],
        queryFn: () => Api.jobList({ pndGroupId: params?.pndGroupId, planOrJobDate: params?.runDate, pageSize: 9999, pageNo: 1, planStatusNot: 'NOT_READY' }),
        initialData: {},
        refetchInterval: 1000 * 60 * 5,
        enabled: !!params.pndGroupId,
        select: res => {
          const vehicleJobHistory = res.data.data.jobUnitPage.content.reduce((accumulator, currentValue) => {
            const vehicleId = currentValue.jobPlan.vehicleId;
            if (!accumulator[vehicleId]) {
              accumulator[vehicleId] = {
                id: vehicleId,
                vehicleNo: currentValue.jobPlan.vehicleNo,
                driverId: currentValue.jobPlan.driverId,
                driverName: currentValue.jobPlan.driverName,
                driverTel: currentValue.jobPlan.driverTel,
                vehicleFlag: currentValue.jobPlan.vehicleFlag,
                rest: currentValue.jobPlan,
                history: [],
              };
            }
            const {
              id, //jobUnitId
              pickupPlaceId,
              pickupPlaceAddress1,
              pickupPlaceAddress2,
              pickupPlaceName,
              pickupPlaceX,
              pickupPlaceY,
              pickupDt,
              pickupFailDt,
              deliveryPlaceId,
              deliveryPlaceAddress1,
              deliveryPlaceAddress2,
              deliveryPlaceName,
              deliveryPlaceX,
              deliveryPlaceY,
              deliveryDt,
              deliveryFailDt,
              jobPlanPackageName,
              failReason,
              jobPlan,
              ...rest
            } = currentValue;

            const pickupHistory = {
              jobUnitId: id,
              id: pickupPlaceId,
              type: 'PICKUP',
              status: pickupFailDt ? 'FAIL' : pickupDt ? 'SUCC' : 'READY',
              failReason: pickupFailDt && failReason,
              address1: pickupPlaceAddress1,
              address2: pickupPlaceAddress2,
              name: pickupPlaceName,
              x: pickupPlaceX,
              y: pickupPlaceY,
              dt: pickupFailDt ?? pickupDt,
              jobPlanPackageName,
            };

            const deliveryHistory = {
              jobUnitId: id,
              id: deliveryPlaceId,
              type: 'DELIVERY',
              status: deliveryFailDt ? 'FAIL' : deliveryDt ? 'SUCC' : 'READY',
              failReason: deliveryFailDt && failReason,
              address1: deliveryPlaceAddress1,
              address2: deliveryPlaceAddress2,
              name: deliveryPlaceName,
              x: deliveryPlaceX,
              y: deliveryPlaceY,
              dt: deliveryFailDt ?? deliveryDt,
              jobPlanPackageName,
            };

            if (!pickupHistory.dt || isSameDay(parse(params.runDate, 'yyyyMMdd', new Date()), parse(pickupHistory.dt, 'yyyyMMddHHmmss', new Date()))) {
              accumulator[vehicleId].history.push(pickupHistory);
            }
            if (pickupHistory.status !== 'FAIL') {
              accumulator[vehicleId].history.push(deliveryHistory);
            }
            accumulator[vehicleId].history.sort((a, b) => {
              if (a.dt === undefined) return 1; // a가 undefined면 b보다 뒤로 감
              if (b.dt === undefined) return -1; // b가 undefined면 a보다 뒤로 감
              return Number(a.dt) - Number(b.dt); // 둘 다 undefined가 아니면 숫자로 비교
            });
            return accumulator;
          }, {});
          return vehicleJobHistory;
        },
      },
    ],
    combine: results => {
      const merged = {};
      results
        .map(result => result.data)
        .forEach(object => {
          if (object) {
            for (const [id, props] of Object.entries(object)) {
              if (!merged[id]) {
                merged[id] = {};
              }
              // id가 같은 객체의 속성들을 합칩니다.
              merged[id] = { ...merged[id], ...props };
            }
          }
        });
      return {
        data: Object.values(merged),
        pending: results.every(result => result.isPending),
        success: results.every(result => result.isSuccess),
      };
    },
  });
  const selectedVehicleData = mapDataQueries.data.find(item => item.id == selectedDetailVehicleId);
  const totalVehicleCount = mapDataQueries.data.length;
  const totalVehicleRunCount = mapDataQueries.data.reduce((count, item) => {
    if (item.isRunning) {
      return count + 1;
    }
    return count;
  }, 0);

  const vehicleSummaryQuery = useQuery({
    queryKey: ['vehicleSummary', selectedVehicleData?.id, params?.runDate, params?.pndGroupId],
    queryFn: () => Api.vehicleRunSummary({ runDate: params?.runDate, vehicleId: selectedVehicleData?.id, groupId: params.pndGroupId }),
    enabled: !!selectedVehicleData && !!params.pndGroupId,
    refetchInterval: 1000 * 60 * 5,
    select: res => res.data.data.data ?? { runTotalMeter: 0, runTotalSeconds: 0 },
  });
  // 운반회사 최초 로딩 시
  const handleGroupFirstLoad = firstGroupId => {
    setParams(prevParams => ({
      ...prevParams,
      pndGroupId: firstGroupId,
    }));
  };

  useDebouncedEffect(
    () => {
      mapRef.current?.redraw();
    },
    300,
    [windowWidth, windowHeight, width, height],
  );
  const handleSelectDetailVehicle = useCallback(vehicle => {
    setSelectedDetailVehicleId(vehicle.id);
  }, []);

  const handleSelectVisibleVehicleId = useCallback(
    vehicleId => {
      setSelectedVisibleVehicleId(prevSet => {
        const newSet = new Set(prevSet);
        // const newSet = new Set();
        if (newSet.has(vehicleId)) {
          newSet.clear();
        } else {
          newSet.clear();
          newSet.add(vehicleId);
          const vehicle = mapDataQueries.data.find(item => item.id == vehicleId);
          if (vehicle.history) {
            const coordinates = vehicle.history.map(item => [item.x, item.y]);
            mapRef.current?.flyToCenter(coordinates);
          }

          setSelectedDetailVehicleId(vehicleId);
        }
        return newSet;
      });
    },
    [mapDataQueries.data],
  );

  const handleFlyCoordinate = useCallback(
    coordinates => {
      mapRef.current.flyToCenter(coordinates);
    },
    [mapRef],
  );

  const isVehicleSelected = useCallback(
    vehicleId => {
      return selectedVisibleVehicleId.size === 0 || selectedVisibleVehicleId.has(vehicleId);
    },
    [selectedVisibleVehicleId],
  );

  useEffect(() => {
    setSelectedVisibleVehicleId(new Set());
    mapRef.current?.redraw();
  }, [params.pndGroupId, params.runDate]);

  const fillterdVehicleList = useCallback(
    list => {
      if (!isArray(list)) return [];
      if (String(params.search).trim() == '') return list;
      return list.filter(vehicle => String(vehicle.vehicleNo).includes(params.search) || String(vehicle.driverName).includes(params.search));
    },
    [params.search],
  );
  return (
    <>
      <div className="flex flex-col overflow-hidden border-r bg-background max-w-80 min-w-80 border-default-100">
        <div className="flex flex-col gap-2 p-3 pt-5 pb-4 border-b border-default-100">
          <div className="flex items-center gap-2 text-lg font-bold">
            <DynamicIcon iconName={'BuildingOffice'} size={20} weight={'duotone'}></DynamicIcon>
            <div>운송회사</div>
          </div>
          <GroupSelect className={'max-w-full'} fullWidth={true} label="" selectedKey={params.pndGroupId} onChange={onInputChange} name="pndGroupId" onFirstLoad={handleGroupFirstLoad} />
        </div>
        <div className="flex flex-col flex-1">
          <div className="flex flex-col gap-2 px-3 pt-4">
            <div className="flex items-center gap-2 text-lg font-bold">
              <DynamicIcon iconName={'TruckTrailer'} size={20} weight={'duotone'}></DynamicIcon>
              <div>운송차량</div>
            </div>
            <Input
              name="search"
              value={params.search}
              onChange={onInputChange}
              startContent={<DynamicIcon iconName={'MagnifyingGlass'} size={20}></DynamicIcon>}
              size="sm"
              placeholder="기사, 차량을 입력해주세요"
            ></Input>
          </div>
          <div className="flex items-center gap-1 p-3">
            <div className="text-base font-bold text-default-600">
              {totalVehicleRunCount}/{totalVehicleCount}
            </div>
            <div className="text-sm text-default-400">(현재운송중차량/운송예정차량)</div>
          </div>
          <ScrollShadow className="flex flex-col flex-1 gap-3 px-3">
            {mapDataQueries.success &&
              fillterdVehicleList(mapDataQueries?.data).map(vehicle => {
                return (
                  <ButtonGroup key={vehicle.id} className={`flex items-stretch justify-normal ${!isVehicleSelected(vehicle.id) && 'opacity-20'}`}>
                    <Button
                      className={`grow p-3 border-l-3 bg-default-50 cursor-pointer !rounded-none justify-start text-left h-auto`}
                      onClick={() => handleSelectVisibleVehicleId(vehicle.id)}
                      style={{
                        borderColor: colorUtil.hashStringToColor(vehicle?.vehicleNo + ''),
                      }}
                    >
                      <div className="flex flex-col select-none">
                        <span className="text-xs text-default-400">{vehicle.driverName}</span>
                        <span className="text-sm font-bold text-default-900">{vehicle.vehicleNo}</span>
                      </div>
                    </Button>
                    {/* {selectedDetailVehicleId !== vehicle.id ? (
                    <Button className={`p-3 bg-default-50 cursor-pointer !rounded-none h-auto text-default-400`} isIconOnly onClick={() => handleSelectDetailVehicle(vehicle)}>
                      <DynamicIcon iconName={'CaretDoubleRight'}></DynamicIcon>
                    </Button>
                  ) : (
                    <Button className={`p-3 bg-default-100 cursor-pointer !rounded-none h-auto text-default-800`} isIconOnly onClick={() => setSelectedDetailVehicleId(null)}>
                      <DynamicIcon iconName={'CaretDoubleLeft'}></DynamicIcon>
                    </Button>
                  )} */}
                  </ButtonGroup>
                );
              })}
          </ScrollShadow>
        </div>
      </div>
      {selectedVehicleData && (
        <div className="flex flex-col border-r min-w-96 max-w-96 bg-background border-default-100">
          <div className="flex flex-col gap-5 px-3 pt-5 pb-3 border-b border-default-100">
            <div className="flex items-center gap-2">
              <DynamicIcon size={16} iconName={'Circle'} weight={'fill'} color={colorUtil.hashStringToColor(selectedVehicleData.vehicleNo)}></DynamicIcon>
              <div className="text-lg font-bold text-default-900">{selectedVehicleData.vehicleNo}</div>
            </div>
            <div className="flex flex-wrap justify-center gap-4">
              <div className="flex flex-col items-center justify-center text-center min-w-20">
                <div className="text-sm">{formatUtil.getTel(selectedVehicleData.driverTel)}</div>
                <div className="text-xs text-default-400">기사 전화번호</div>
              </div>
              <div className="flex flex-col items-center justify-center text-center min-w-20">
                <div className="text-sm">{selectedVehicleData.driverName}</div>
                <div className="text-xs text-default-400">기사명</div>
              </div>
              <div className="flex flex-col items-center justify-center text-center min-w-20">
                <Skeleton className="w-full rounded-lg" isLoaded={vehicleSummaryQuery.isSuccess}>
                  <div className="text-sm">
                    {Math.floor((vehicleSummaryQuery.data?.runTotalSeconds ?? 0) / 3600) + '시간 ' + Math.floor((vehicleSummaryQuery.data?.runTotalSeconds ?? 0) / 60) + '분'}
                  </div>
                </Skeleton>
                <div className="text-xs text-default-400">이동시간</div>
              </div>
              <div className="flex flex-col items-center justify-center text-center min-w-20">
                <Skeleton className="w-full rounded-lg" isLoaded={vehicleSummaryQuery.isSuccess}>
                  <div className="text-sm">{(vehicleSummaryQuery.data?.runTotalMeter ?? 0) / 1000}km</div>
                </Skeleton>
                <div className="text-xs text-default-400">이동거리</div>
              </div>
            </div>
          </div>
          <ScrollShadow className="flex flex-col flex-1 gap-3 p-3" hideScrollBar>
            {mapDataQueries.success &&
              selectedVehicleData.history.map(jobUnit => {
                const color = jobUnit.status == 'SUCC' ? colorUtil.hashStringToColor(selectedVehicleData?.vehicleNo) : jobUnit.status == 'FAIL' ? '#F31260' : '#e4e4e7';
                const iconName = jobUnit.status == 'SUCC' ? (jobUnit.type == 'PICKUP' ? 'ArrowFatLinesUp' : 'ArrowFatLinesDown') : jobUnit.status == 'FAIL' ? 'XCircle' : 'MapPin';
                return (
                  <div className="flex gap-2" key={JSON.stringify(jobUnit)}>
                    <div className="flex flex-col min-w-20 max-w-20">
                      <div className="flex items-center gap-1">
                        <DynamicIcon color={color} size={20} iconName={iconName} weight={jobUnit.status == 'READY' ? 'fill' : 'duotone'}></DynamicIcon>
                        <span className="text-lg font-bold text-default-900">{jobUnit.dt ? format(parse(jobUnit.dt, 'yyyyMMddHHmmss', new Date()), 'HH:mm') : '--:--'}</span>
                      </div>
                      <div className="w-2.5 border-r flex-1 border-default-200"></div>
                    </div>
                    <Button
                      onClick={() => {
                        handleFlyCoordinate([[jobUnit.x, jobUnit.y]]);
                      }}
                      className={`grow p-3 border-l-3 bg-default-50 cursor-pointer !rounded-none justify-start text-left h-auto`}
                      style={{
                        borderColor: color,
                      }}
                    >
                      <div className="flex flex-col flex-1 gap-1 select-none">
                        <div className="flex flex-col">
                          <div className="flex items-center justify-between">
                            <span className="text-xs text-default-400">{jobUnit.type == 'PICKUP' ? '상차지' : '하차지'}</span>
                            <span className="text-xs text-default-400">No.{jobUnit.jobUnitId}</span>
                          </div>
                          <span className={`text-lg font-bold text-default-900 ${jobUnit.status == 'FAIL' && 'text-danger-400'}`}>{jobUnit.name}</span>
                        </div>
                        {jobUnit.failReason && jobUnit.status == 'FAIL' && (
                          <div className="flex flex-wrap gap-1 text-danger-400">
                            <DynamicIcon size={14} iconName={'Warning'} weight={'duotone'}></DynamicIcon>
                            <div className="flex-1 text-sm leading-none text-wrap">{jobUnit.failReason ?? '-'}</div>
                          </div>
                        )}
                        <div className="flex flex-wrap gap-1 text-default-900">
                          <DynamicIcon size={14} iconName={'Package'} weight={'duotone'}></DynamicIcon>
                          <div className="flex-1 text-sm leading-none text-wrape">{jobUnit.jobPlanPackageName ?? '-'}</div>
                        </div>
                      </div>
                    </Button>
                  </div>
                );
              })}
          </ScrollShadow>
          <div></div>
          <div></div>
        </div>
      )}
      <div className="relative overflow-hidden grow" ref={containerRef}>
        <VusMap groupId={params.pndGroupId} data={mapDataQueries.data} visibleVehicleIdSet={selectedVisibleVehicleId} onVehicleClick={handleSelectDetailVehicle} ref={mapRef} />
      </div>
    </>
  );
};

export default View;
