import { Api } from '@/api';
import { FlexTable, FlexTableData, FlexTableHeader, FlexTableRow } from '@/components/FlexTable';
import ConfirmModal from '@/components/Modal/ConfirmModal';
import { AlertModal, useAlertModal } from '@/components/Modal/useAlertModal';
import useConfirmModal from '@/hooks/useConfirmModal';
import { useInput } from '@/hooks/useInput';
import { Button, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from '@nextui-org/react';
import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';

const ModalVehcleAdd = ({ isOpenAdd, onOpenChangeAdd, onCloseAdd, reloadList, groupId }) => {
  const { callAlert } = useAlertModal();

  const [vehicleNoInput, vehicleNoChange, vehicleNoSubmit, vehicleNoReset] = useInput('', value => {
    console.log('Submitted Value:', value);
  });
  const [driverNameInput, driverNameChange, driverNameSubmit, driverNameReset] = useInput('', value => {
    console.log('Submitted Value:', value);
  });
  const [driverTelInput, driverTelChange, driverTelSubmit, driverTelReset] = useInput(
    '',
    value => {
      console.log('Submitted Value:', value);
    },
    { phoneNumberFormat: true }
  );

  const { confirmOpen, message, requestConfirm, handleConfirm, handleClose } = useConfirmModal();

  const addVehicle = useMutation({
    mutationFn: values => Api.vehicleAdd(values),
  });

  const addDriver = useMutation({
    mutationFn: values => Api.driverAdd(values),
  });

  const validation = () => {
    if (!vehicleNoInput) {
      callAlert('차량번호를 입력해 주세요.');
      return false;
    }

    if (!driverNameInput) {
      callAlert('기사명을 입력해 주세요.');
      return false;
    }

    let telCheck = /^\d{2,3}-\d{3,4}-\d{4}$/;

    if (!telCheck.test(driverTelInput)) {
      callAlert('기사 전화번호 형식을 확인해 주세요.');
      return false;
    }

    return true;
  };

  const handleRegisterClick = async () => {
    if (!validation()) {
      return;
    }
    let driverData = {
      pndGroupId: groupId,
      name: driverNameInput,
      tel: driverTelInput.replaceAll('-', ''),
      useYn: 'Y',
    };
    addDriver.mutate(driverData, {
      onSuccess: result => {
        if (result?.data?.code === 'FAIL' && result?.data?.msg) {
          callAlert(result?.data?.msg);
        } else {
          let vehicleData = {
            pndGroupId: groupId,
            vehicleNo: vehicleNoInput,
            driverId: result.data.data.driverId,
            useYn: 'Y',
          };
          addVehicle.mutate(vehicleData, {
            onSuccess: result => {
              callAlert('등록되었습니다.', () => {
                onCloseAdd();
                reloadList();
              });
            },
            onError: error => {
              if (error?.response?.data?.code === 'FAIL' && error?.response?.data?.msg) {
                callAlert(error?.response?.data?.msg);
              } else {
                callAlert('등록에 실패하였습니다.');
              }
            },
          });
        }
      },
      onError: error => {
        if (error?.response?.data?.code === 'FAIL' && error?.response?.data?.msg) {
          callAlert(error?.response?.data?.msg);
        } else {
          callAlert('등록에 실패하였습니다.');
        }
      },
    });
  };

  useEffect(() => {
    vehicleNoReset();
    driverNameReset();
    driverTelReset();
  }, [isOpenAdd]);

  return (
    <>
      <Modal isDismissable={false} isOpen={isOpenAdd} onOpenChange={onOpenChangeAdd} size="5xl" scrollBehavior={'outside'}>
        <ConfirmModal isOpen={confirmOpen} title={message} onConfirm={handleConfirm} onClose={handleClose} />
        <ModalContent>
          <ModalHeader>차량 등록</ModalHeader>
          <ModalBody>
            <FlexTable title={'기본정보'}>
              <FlexTableRow>
                <FlexTableHeader>
                  차량번호<span className="text-rose-500">*</span>
                </FlexTableHeader>
                <FlexTableData>
                  <Input name="vehicleNo" labelPlacement="outside" placeholder="차량번호를 입력하세요." onChange={vehicleNoChange} value={vehicleNoInput}></Input>
                </FlexTableData>
              </FlexTableRow>
              <FlexTableRow>
                <FlexTableHeader>
                  기사명<span className="text-rose-500">*</span>
                </FlexTableHeader>
                <FlexTableData>
                  <Input name="driverName" labelPlacement="outside" placeholder="기사명을 입력하세요." onChange={driverNameChange} value={driverNameInput}></Input>
                </FlexTableData>
              </FlexTableRow>
              <FlexTableRow>
                <FlexTableHeader>
                  기사 전화번호<span className="text-rose-500">*</span>
                </FlexTableHeader>
                <FlexTableData>
                  <Input name="driverTel" labelPlacement="outside" placeholder="숫자만 입력가능합니다." value={driverTelInput} onChange={driverTelChange} maxLength={14}></Input>
                </FlexTableData>
              </FlexTableRow>
            </FlexTable>
          </ModalBody>
          <ModalFooter>
            <ModalFooter>
              <Button color="default" className="ml-auto" onPress={onCloseAdd}>
                닫기
              </Button>
              <Button color="primary" onPress={handleRegisterClick}>
                등록
              </Button>
            </ModalFooter>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AlertModal />
    </>
  );
};

export default ModalVehcleAdd;
