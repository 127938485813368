import { Api } from '@/api';
import LoadingBackground from '@/components/LoadingBackground';
import ConfirmModal from '@/components/Modal/ConfirmModal';
import { AlertModal, useAlertModal } from '@/components/Modal/useAlertModal';
import useConfirmModal from '@/hooks/useConfirmModal';
import { formatUtil } from '@/utils/FormatUtil';
import { Button, Chip, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, useDisclosure } from '@nextui-org/react';
import { useMutation, useQuery } from '@tanstack/react-query';

import { FlexTable, FlexTableRow, FlexTableHeader, FlexTableData } from '@/components/FlexTable';

const ModalView = ({ isOpenView, onOpenView, onOpenChangeView, onCloseView, selectedId, reloadList, onOpenUpdate }) => {
  const { callAlert } = useAlertModal();
  const vehicleQuery = useQuery({
    queryKey: ['vehicle', selectedId],
    queryFn: () => Api.vehicleView({ vehicleId: selectedId }),
    select: res => res.data.data,
    enabled: !!selectedId && isOpenView,
  });

  let vehicle = vehicleQuery?.data?.vehicle;
  const { confirmOpen, message, requestConfirm, handleConfirm, handleClose } = useConfirmModal();

  const handlerUpdatePage = () => {
    onCloseView();
    onOpenUpdate();
  };

  const delVehicle = useMutation({
    mutationFn: id => Api.vehicleDel({ vehicleId: id }),
    onSuccess: data => {
      onCloseView();
      callAlert('처리되었습니다.', () => {
        reloadList();
      });
    },
    onError: error => {
      if (error.response.data.code == 'FAIL' && error.response.data.msg) {
        callAlert(error.response.data.msg);
      }
      console.error('Error adding user', error);
    },
  });

  const handlerDelete = id => {
    delVehicle.mutate(id);
  };

  return (
    <>
      <>
        {vehicleQuery.isPending && isOpenView && <LoadingBackground />}
        <Modal isDismissable={false} isOpen={isOpenView} onOpenChange={onOpenChangeView} size="3xl" scrollBehavior={'outside'}>
          <ModalContent>
            {close => (
              <>
                <ModalHeader>차량 조회</ModalHeader>
                <ModalBody>
                  <FlexTable title={'기본정보'}>
                    <FlexTableRow>
                      <FlexTableHeader>등록일</FlexTableHeader>
                      <FlexTableData>{formatUtil.getDate(vehicle?.regDt, '-')}</FlexTableData>
                      <FlexTableHeader>수정일</FlexTableHeader>
                      <FlexTableData>{formatUtil.getDate(vehicle?.modDt, '-')}</FlexTableData>
                      <FlexTableHeader>작성자</FlexTableHeader>
                      <FlexTableData>{vehicle?.regName}</FlexTableData>
                    </FlexTableRow>
                    <FlexTableRow>
                      <FlexTableHeader>운반회사</FlexTableHeader>
                      <FlexTableData>{vehicle?.groupName}</FlexTableData>
                    </FlexTableRow>

                    <FlexTableRow>
                      <FlexTableHeader>활성화</FlexTableHeader>
                      <FlexTableData>
                        <Chip size="sm" variant="flat" color={vehicle?.useYn == 'Y' ? 'success' : 'default'}>
                          {vehicle?.useYn == 'Y' ? '활성' : '비활성'}
                        </Chip>
                      </FlexTableData>
                    </FlexTableRow>
                    <FlexTableRow>
                      <FlexTableHeader>차량구분</FlexTableHeader>
                      <FlexTableData>{vehicle?.vehicleFlagName ? vehicle?.vehicleFlagName : '-'}</FlexTableData>
                    </FlexTableRow>
                    <FlexTableRow>
                      <FlexTableHeader>차량번호</FlexTableHeader>
                      <FlexTableData>{vehicle?.vehicleNo ? vehicle?.vehicleNo : '-'}</FlexTableData>
                    </FlexTableRow>
                    <FlexTableRow>
                      <FlexTableHeader>차량이름</FlexTableHeader>
                      <FlexTableData>{vehicle?.name ? vehicle?.name : '-'}</FlexTableData>
                    </FlexTableRow>
                    <FlexTableRow>
                      <FlexTableHeader>차량소속</FlexTableHeader>
                      <FlexTableData>{vehicle?.company ? vehicle?.company : '-'}</FlexTableData>
                    </FlexTableRow>
                    <FlexTableRow>
                      <FlexTableHeader>차량유형</FlexTableHeader>
                      <FlexTableData>{vehicle?.typeName ? vehicle?.typeName : '-'}</FlexTableData>
                    </FlexTableRow>
                    <FlexTableRow>
                      <FlexTableHeader>메모</FlexTableHeader>
                      <FlexTableData>{vehicle?.memo ? vehicle?.memo : '-'}</FlexTableData>
                    </FlexTableRow>
                  </FlexTable>
                  <FlexTable title={'기사 및 차주 정보'}>
                    <FlexTableRow>
                      <FlexTableHeader>기사명</FlexTableHeader>
                      <FlexTableData>{vehicle?.driverName ? vehicle?.driverName : '-'}</FlexTableData>
                    </FlexTableRow>
                    <FlexTableRow>
                      <FlexTableHeader>기사 전화번호</FlexTableHeader>
                      <FlexTableData>{vehicle?.driverTel ? formatUtil.getTel(vehicle?.driverTel, '-') : '-'}</FlexTableData>
                    </FlexTableRow>
                    <FlexTableRow>
                      <FlexTableHeader>차주명</FlexTableHeader>
                      <FlexTableData>{vehicle?.owner ? vehicle?.owner : '-'}</FlexTableData>
                    </FlexTableRow>
                    <FlexTableRow>
                      <FlexTableHeader>차주 전화번호</FlexTableHeader>
                      <FlexTableData>{vehicle?.ownerTel ? formatUtil.getTel(vehicle?.ownerTel, '-') : '-'}</FlexTableData>
                    </FlexTableRow>
                  </FlexTable>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="danger"
                    className="mr-auto"
                    isLoading={delVehicle.isPending}
                    onPressEnd={() => {
                      requestConfirm({
                        message: '삭제하시겠습니까??',
                        onConfirm: () => {
                          handlerDelete(vehicleQuery?.data?.vehicle?.id);
                        },
                      });
                    }}
                  >
                    삭제
                  </Button>
                  <Button color="default" onPress={onCloseView}>
                    닫기
                  </Button>
                  <Button color="primary" onPress={handlerUpdatePage}>
                    수정
                  </Button>
                </ModalFooter>
              </>
            )}
          </ModalContent>
        </Modal>

        <ConfirmModal isOpen={confirmOpen} title={message} onConfirm={handleConfirm} onClose={handleClose} />
        <AlertModal />
      </>
    </>
  );
};

export default ModalView;
