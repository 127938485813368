import { Api } from '@/api';
import ConfirmModal from '@/components/Modal/ConfirmModal';
import useConfirmModal from '@/hooks/useConfirmModal';
import { formatUtil } from '@/utils/FormatUtil';
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, getKeyValue, Chip } from '@nextui-org/react';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useEffect } from 'react';

const place = [
  {
    label: 'no',
    key: 'no',
  },
  {
    label: '방문지',
    key: 'name',
  },
  {
    label: '주소',
    key: 'address',
  },
];

function ModalFindPlace({ isOpenPlace, onOpenPlace, onOpenChangePlace, onClosePlace, groupId, selectedKeys, setSelectedKeys, handleAddPlace, toPlaceId, registeredIdList }) {
  const { confirmOpen, message, requestConfirm, handleConfirm, handleClose } = useConfirmModal();

  const fetchPlaceList = () => {
    let postParams = {};
    postParams.pndGroupId = groupId;
    postParams.pageSize = 1000;
    const trimParams = Object.fromEntries(Object.entries(postParams).filter(([, value]) => value));
    return Api.placeList(trimParams);
  };

  const listQuery = useQuery({
    queryKey: ['placeList', groupId],
    queryFn: fetchPlaceList,
    select: res => res.data.data,
    enabled: !!groupId && isOpenPlace,
  });

  let filteredPlaceListDt = listQuery?.data?.placePage?.content
    ?.filter(item => {
      return item.id != toPlaceId && !registeredIdList.includes(item.id);
    })
    ?.sort((a, b) => {
      return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
    });

  const renderCell = useCallback(
    (item, columnKey, idx) => {
      switch (columnKey) {
        case 'no':
          return <div>{Number(idx) + 1}</div>;

        case 'address':
          return <div>{item.address1 + item.address2}</div>;
        case 'managerName':
          return <div>{formatUtil.getMaskName(item.managerName)}</div>;
        case 'managerTel':
          return <div>{formatUtil.getMaskTel(item.managerTel)}</div>;

        case 'useYn':
          return item.useYn === 'Y' ? (
            <Chip size="sm" variant="flat" color={'success'}>
              활성
            </Chip>
          ) : (
            <Chip size="sm" variant="flat" color={'default'}>
              비활성
            </Chip>
          );
        default:
          return getKeyValue(item, columnKey);
      }
    },
    [listQuery?.data?.placePage?.totalElements]
  );

  useEffect(() => {
    setSelectedKeys(new Set([]));
  }, [isOpenPlace]);

  return (
    <div>
      <Modal isDismissable={false} isOpen={isOpenPlace} onOpenChange={onOpenChangePlace} size="xl" scrollBehavior={'outside'}>
        <ModalContent>
          {close => (
            <>
              <ModalHeader>추가할 방문지 리스트</ModalHeader>
              <ModalBody>
                <Table
                  aria-label="placeList"
                  selectionMode="multiple"
                  onRowAction={() => {
                    return;
                  }}
                  selectedKeys={selectedKeys}
                  onSelectionChange={setSelectedKeys}
                >
                  <TableHeader columns={place}>{column => <TableColumn key={column.key}>{column.label}</TableColumn>}</TableHeader>
                  <TableBody emptyContent={'추가 가능한 방문지 정보가 없습니다.'}>
                    {filteredPlaceListDt?.map((item, idx) => (
                      <TableRow key={item.id}>{columnKey => <TableCell>{renderCell(item, columnKey, idx)}</TableCell>}</TableRow>
                    ))}
                  </TableBody>
                </Table>
              </ModalBody>
              <ModalFooter>
                <Button color="default" onPress={onClosePlace}>
                  닫기
                </Button>

                <Button
                  color="primary"
                  onPress={async () => {
                    let dataList = [];
                    if (selectedKeys == 'all') {
                      dataList = filteredPlaceListDt.map(item => item.id);
                    } else {
                      dataList = selectedKeys;
                    }
                    handleAddPlace(dataList);
                    onClosePlace();
                  }}
                >
                  등록
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
      <ConfirmModal isOpen={confirmOpen} title={message} onConfirm={handleConfirm} onClose={handleClose} oneButton={true} />
    </div>
  );
}

export default ModalFindPlace;
