import { Accordion, AccordionItem } from '@nextui-org/react';

export const FlexTable = ({ children, title, isAccordion = false }) => {
  return !isAccordion ? (
    <div>
      {title && <div className="mb-2 text-sm font-medium text-default-600">{title}</div>}
      <div className="flex flex-col w-full overflow-hidden text-sm border rounded-lg border-default-100 ">{children}</div>
    </div>
  ) : (
    <Accordion isCompact className="px-0">
      <AccordionItem
        key="1"
        aria-label={title}
        title={title}
        classNames={{
          trigger: 'py-0 pb-2',
          title: 'text-sm font-medium text-default-600',
          content: 'p-0',
        }}
      >
        <div className="flex flex-col w-full overflow-hidden text-sm border rounded-lg border-default-100 ">{children}</div>
      </AccordionItem>
    </Accordion>
  );
};
export const FlexTableRow = ({ children, className = '' }) => {
  return <div className={`${className} flex flex-1 [&:not(:last-child)]:border-b flex-nowrap border-default-100`}>{children}</div>;
};
export const FlexTableHeader = ({ children, className = '' }) => {
  return (
    <div
      className={`${className} min-h-12 text-xs py-2 px-4 break-keep flex items-center gap-2 text-left bg-default-50 dark:bg-default-100/50 font-medium text-default-500 flex-shrink-0 flex-grow-0 basis-[100px]`}
    >
      {children}
    </div>
  );
};
export const FlexTableData = ({ children, className = '' }) => {
  return <div className={`${className} flex items-center flex-1 min-w-0 gap-2 px-3 py-2 min-h-12 break-keep`}>{children}</div>;
};
