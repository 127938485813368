import { Api } from '@/api';
import DynamicIcon from '@/components/DynamicIcon';
import { FlexTable, FlexTableData, FlexTableHeader, FlexTableRow } from '@/components/FlexTable';
import LoadingBackground from '@/components/LoadingBackground';
import ConfirmModal from '@/components/Modal/ConfirmModal';
import { AlertModal, useAlertModal } from '@/components/Modal/useAlertModal';
import useConfirmModal from '@/hooks/useConfirmModal';
import { expandCellRange, repeatStyle } from '@/utils/ExcelUtil';
import { formatUtil } from '@/utils/FormatUtil';
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from '@nextui-org/react';
import { useInfiniteScroll } from '@nextui-org/use-infinite-scroll';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx-js-style';
import ExcelJS from 'exceljs';

const EXCEL_FILE_EXTENSION = '.xlsx';
const EXCEL_FILE_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

const ModalView = ({ isOpenView, onOpenView, onOpenUpdate, onOpenChangeView, onCloseView, selectedId, reloadList }) => {
  const { callAlert } = useAlertModal();

  const { data: jobCostQuery, isPending } = useQuery({
    queryKey: ['jobCost', selectedId],
    queryFn: () => Api.jobCostView({ jobCostId: selectedId }),
    select: res => res.data.data,
    enabled: !!selectedId,
  });
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, isError } = useInfiniteQuery({
    queryKey: ['jobCostDetails', selectedId],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await Api.jobCostView({ jobCostId: selectedId, pageNo: pageParam, pageSize: 30 });
      return response.data.data;
    },
    getNextPageParam: lastPage => {
      const currentPage = lastPage.jobCostDetailPage.pageNo;
      const totalPages = lastPage.jobCostDetailPage.totalPages;
      return currentPage < totalPages ? currentPage + 1 : undefined;
    },
    select: d =>
      d.pages
        .flatMap(page => page.jobCostDetailPage.content)
        .map((item, idx) => {
          item.no = idx + 1;
          return item;
        }),
    enabled: !!selectedId,
  });

  const handleExcelDownload = async fileName => {
    const staticData = {
      generalInfo: {
        등록일: formatUtil.getDate(jobCostQuery.jobCost.regDt, '-') || '-',
        수정일: formatUtil.getDatetime(jobCostQuery.jobCost.modDt, '-') || '-',
        작성자: jobCostQuery.jobCost.regName || '-',
      },
      supplierInfo: {
        상호: jobCostQuery.jobCost.bizName || '-',
        대표이사: jobCostQuery.jobCost.bizCeo || '-',
        등록번호: formatUtil.getBizNo(jobCostQuery.jobCost.bizNo) || '-',
        주소: `${jobCostQuery.jobCost.bizAddr1 || '-'} ${jobCostQuery.jobCost.bizAddr2 || ''}`,
        전화: formatUtil.getTel(jobCostQuery.jobCost.bizTel) || '-',
        팩스: formatUtil.getTel(jobCostQuery.jobCost.bizFax) || '-',
      },
      vehicleInfo: {
        차주명: jobCostQuery.jobCost.vehicleOwner || '-',
        '차주 전화번호': formatUtil.getTel(jobCostQuery.jobCost.vehicleOwnerTel) || '-',
        차량번호: jobCostQuery.jobCost.vehicleNoList?.map(el => el.vehicleNo).join(', ') || '-',
      },
    };

    // Create a new workbook
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('운송비 내역서');
    worksheet.properties.defaultRowHeight = 30;
    worksheet.columns = [
      { key: 'A', width: 4 },
      { key: 'B', width: 17 },
      { key: 'C', width: 17 },
      { key: 'D', width: 17 },
      { key: 'E', width: 17 },
      { key: 'F', width: 17 },
      { key: 'G', width: 17 },
      { key: 'H', width: 17 },
      { key: 'I', width: 17 },
      { key: 'J', width: 4 },
    ];

    const row_data = [];

    row_data.push(['', '', '', '', '', '', '', '', '', ' ']);
    row_data.push(['', '운송비 내역서', '', '', '', '', '', '', '', '']);
    row_data.push(['', '', '', '', '', '', '', '', '', '']);
    row_data.push(['', '공급받는자', '', '', '', '', '차량정보', '', '', '']);
    row_data.push(['', '상호', staticData.supplierInfo.상호, '대표이사', staticData.supplierInfo.대표이사, '', '차주명', staticData.vehicleInfo.차주명, '', '']);
    row_data.push(['', '등록번호', staticData.supplierInfo.등록번호, '', '', '', '차주 전화번호', staticData.vehicleInfo['차주 전화번호'], '', '']);
    row_data.push(['', '주소', staticData.supplierInfo.주소, '', '', '', '차량번호', staticData.vehicleInfo.차량번호, '', '']);
    row_data.push(['', '대표전화', staticData.supplierInfo.전화, '팩스', staticData.supplierInfo.팩스, '', '귀하의 노고에 감사드립니다.', '', '', '']);
    row_data.push(['', '', '', '', '', '', '', '', '', '']);

    // Process dynamic data
    const dynamicData = data.map(item => ({
      No: item.no,
      차량번호: item.vehicleNo,
      운송일: item.jobDate,
      상차지: item.pickupPlaceName,
      하차지: item.deliveryPlaceName,
      '실중량(Kg)': item.weight,
      '공급가액(원)': formatUtil.getNumber(item.price),
      세액: formatUtil.getNumber(item.vat),
      비고: item.etc || '-',
    }));

    // Calculate totals
    let totalWeight = 0;
    let totalSupplyAmount = 0;
    let totalVat = 0;

    dynamicData.forEach(item => {
      totalWeight += Number(item['실중량(Kg)'].toString().replace(/,/g, '')) || 0;
      totalSupplyAmount += Number(item['공급가액(원)'].toString().replace(/,/g, '')) || 0;
      totalVat += Number(item['세액'].toString().replace(/,/g, '')) || 0;
    });

    const totalAmountIncludingVat = totalSupplyAmount + totalVat;

    row_data.push(['', '', '', '', '', '', '합계(VAT포함)', `₩${totalAmountIncludingVat.toLocaleString()}`, '', '']);
    row_data.push(['', '', '', '', '', '', '', '', '', '']);
    row_data.push(['', '날짜', '차량번호', '상차지', '하차지', '실중량(kg)', '공급가액(원)', '세액(원)', '비고', '']);
    dynamicData.forEach(item => {
      row_data.push([
        '',
        item.운송일,
        item.차량번호,
        item.상차지,
        item.하차지,
        `${item['실중량(Kg)'].toLocaleString()}`,
        `₩${item['공급가액(원)'].toLocaleString()}`,
        `₩${item['세액'].toLocaleString()}`,
        item.비고,
        '',
      ]);
    });
    row_data.push(['', '합계', '', '', '', `₩${totalWeight.toLocaleString()}`, `₩${totalSupplyAmount.toLocaleString()}`, `₩${totalVat.toLocaleString()}`, '', '']);
    row_data.forEach(row => worksheet.addRow(row));
    worksheet.eachRow(row => {
      row.alignment = { vertical: 'middle' };
    });
    worksheet.mergeCells('B2:I2'); // Title
    worksheet.mergeCells('B4:E4'); // 공급받는자
    worksheet.mergeCells('G4:I4'); // 차량정보

    worksheet.mergeCells('C6:E6'); // 등록번호
    worksheet.mergeCells('C7:E7'); // 주소

    worksheet.mergeCells('H5:I5'); // 차주
    worksheet.mergeCells('H6:I6'); // 차주번호
    worksheet.mergeCells('H7:I7'); // 차량번호
    worksheet.mergeCells('G8:I8'); // 귀하의 노고..

    worksheet.mergeCells('H10:I10'); // 합계

    const titleStyle = {
      font: { name: 'Arial', size: 14, bold: true },
      alignment: { vertical: 'middle', horizontal: 'center' },
      border: { top: { style: 'medium' }, left: { style: 'medium' }, bottom: { style: 'medium' }, right: { style: 'medium' } },
    };
    const headerStyle = {
      font: { name: 'Arial', size: 10, bold: true },
      border: { top: { style: 'medium' }, bottom: { style: 'medium' } },
      alignment: { vertical: 'middle', horizontal: 'center' },
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'F3F3F3' },
      },
    };
    const borderBottomStyle = {
      font: { name: 'Arial', size: 10 },
      alignment: { vertical: 'middle', horizontal: 'left' },
      border: { bottom: { style: 'thin' } },
    };
    const commonStyle = {
      font: { name: 'Arial', size: 10 },
      alignment: { vertical: 'middle', horizontal: 'left' },
      border: { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } },
    };

    repeatStyle(worksheet, 'B2:I2', titleStyle);
    repeatStyle(worksheet, 'B4:E4', headerStyle);
    repeatStyle(worksheet, 'G4:I4', headerStyle);
    repeatStyle(worksheet, 'B5:E8', borderBottomStyle);
    repeatStyle(worksheet, 'G5:I7', borderBottomStyle);
    repeatStyle(worksheet, 'B12:I12', {
      font: { name: 'Arial', size: 10 },
      alignment: { vertical: 'middle', horizontal: 'left' },
      border: { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } },
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'F3F3F3' },
      },
    });

    repeatStyle(worksheet, 'G8:I8', {
      font: { name: 'Arial', size: 10 },
      alignment: { vertical: 'middle', horizontal: 'center' },
      border: { bottom: { style: 'thin' } },
    });

    worksheet.getCell('G10').style = {
      font: { name: 'Arial', size: 14, bold: true },
      alignment: { vertical: 'middle', horizontal: 'left' },
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'F3F3F3' },
      },
    };
    worksheet.getCell('H10').style = {
      font: { name: 'Arial', size: 14, bold: true },
      alignment: { vertical: 'middle', horizontal: 'right' },
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'F3F3F3' },
      },
    };

    const dataStartRow = 13;
    const dataEndRow = dataStartRow + dynamicData.length;
    for (let rowIndex = dataStartRow; rowIndex < dataEndRow; rowIndex++) {
      repeatStyle(worksheet, `B${rowIndex}:I${rowIndex}`, commonStyle);
    }

    worksheet.mergeCells(`B${dataEndRow}:E${dataEndRow}`); // 합계
    repeatStyle(worksheet, `F${dataEndRow}:I${dataEndRow}`, {
      font: { name: 'Arial', size: 10 },
      alignment: { vertical: 'middle', horizontal: 'left' },
      border: { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } },
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'F3F3F3' },
      },
    });
    repeatStyle(worksheet, `B${dataEndRow}:E${dataEndRow}`, {
      font: { name: 'Arial', size: 10 },
      alignment: { vertical: 'middle', horizontal: 'center' },
      border: { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } },
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'F3F3F3' },
      },
    });
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    FileSaver.saveAs(blob, `${fileName}.xlsx`);
  };

  const [loaderRef, scrollerRef] = useInfiniteScroll({ hasMore: hasNextPage, onLoadMore: fetchNextPage });

  const { confirmOpen, message, requestConfirm, handleConfirm, handleClose } = useConfirmModal();
  return (
    <>
      <>
        {isPending && isOpenView && <LoadingBackground />}
        {!isPending && (
          <Modal isDismissable={false} isOpen={isOpenView} onOpenChange={onOpenChangeView} size="5xl" scrollBehavior={'outside'}>
            <ModalContent>
              {close => (
                <>
                  <ModalHeader>운송비 내역서</ModalHeader>
                  <ModalBody>
                    <FlexTable title={'기본정보'}>
                      <FlexTableRow>
                        <FlexTableHeader>등록일</FlexTableHeader>
                        <FlexTableData>{formatUtil.getDate(jobCostQuery.jobCost.regDt, '-') || '-'}</FlexTableData>
                        <FlexTableHeader>수정일</FlexTableHeader>
                        <FlexTableData>{formatUtil.getDatetime(jobCostQuery.jobCost.modDt, '-') || '-'}</FlexTableData>
                        <FlexTableHeader>작성자</FlexTableHeader>
                        <FlexTableData>{jobCostQuery.jobCost.regName || '-'}</FlexTableData>
                      </FlexTableRow>
                    </FlexTable>
                    <div className="grid grid-cols-2 gap-6">
                      <FlexTable title={'공급 받는자'}>
                        <FlexTableRow>
                          <FlexTableHeader>상호</FlexTableHeader>
                          <FlexTableData>{jobCostQuery.jobCost.bizName || '-'}</FlexTableData>
                          <FlexTableHeader>대표이사</FlexTableHeader>
                          <FlexTableData>{jobCostQuery.jobCost.bizCeo || '-'}</FlexTableData>
                        </FlexTableRow>
                        <FlexTableRow>
                          <FlexTableHeader>등록번호</FlexTableHeader>
                          <FlexTableData>{formatUtil.getBizNo(jobCostQuery.jobCost.bizNo) || '-'}</FlexTableData>
                        </FlexTableRow>
                        <FlexTableRow>
                          <FlexTableHeader>주소</FlexTableHeader>
                          <FlexTableData>
                            <div>
                              <div>
                                <span>(우)</span>
                                <span>{jobCostQuery.jobCost?.bizZipCode}</span>
                              </div>
                              <div>
                                {jobCostQuery.jobCost?.bizAddr1} {jobCostQuery.jobCost?.bizAddr2}
                              </div>
                            </div>
                          </FlexTableData>
                        </FlexTableRow>
                        <FlexTableRow>
                          <FlexTableHeader>전화</FlexTableHeader>
                          <FlexTableData>{formatUtil.getTel(jobCostQuery.jobCost.bizTel) || '-'}</FlexTableData>
                          <FlexTableHeader>팩스</FlexTableHeader>
                          <FlexTableData>{formatUtil.getTel(jobCostQuery.jobCost.bizFax) || '-'}</FlexTableData>
                        </FlexTableRow>
                      </FlexTable>
                      <FlexTable title={'차량 정보'}>
                        <FlexTableRow>
                          <FlexTableHeader>차주명</FlexTableHeader>
                          <FlexTableData>{jobCostQuery.jobCost.vehicleOwner || '-'}</FlexTableData>
                        </FlexTableRow>
                        <FlexTableRow>
                          <FlexTableHeader>차주 전화번호</FlexTableHeader>
                          <FlexTableData>{formatUtil.getTel(jobCostQuery.jobCost.vehicleOwnerTel) || '-'}</FlexTableData>
                        </FlexTableRow>
                        <FlexTableRow>
                          <FlexTableHeader>차량번호</FlexTableHeader>
                          <FlexTableData>{jobCostQuery.jobCost.vehicleNoList?.length > 0 ? `${jobCostQuery.jobCost.vehicleNoList.map(el => el.vehicleNo).join(', ')}` : '-'}</FlexTableData>
                        </FlexTableRow>
                      </FlexTable>
                    </div>
                    <FlexTable>
                      <FlexTableHeader className="justify-between !text-base max-h-12 !text-default-800">
                        <div>합계(VAT포함)</div>
                        <div>{formatUtil.getNumber(jobCostQuery.jobCost.totalPrice + jobCostQuery.jobCost.totalVat || 0)}원</div>
                      </FlexTableHeader>
                    </FlexTable>
                    {/* 테이블 영역 */}
                    <div>
                      <div className="mb-2 text-sm font-medium text-default-600">{`운송내역 (${jobCostQuery.jobCostDetailPage.totalElements}건)`}</div>
                      <Table
                        isHeaderSticky
                        isCompact
                        baseRef={scrollerRef}
                        removeWrapper
                        aria-label="Job Cost Detail Table"
                        classNames={{
                          thead: '[&>tr]:first:shadow-none',
                          base: 'max-h-[250px] overflow-scroll',
                          // th: 'first:border-s last:border-e border-y',
                        }}
                        bottomContent={hasNextPage ? <div className="flex w-full" ref={loaderRef}></div> : null}
                      >
                        <TableHeader>
                          <TableColumn key="no">No</TableColumn>
                          <TableColumn key="vehicleNo">차량번호</TableColumn>
                          <TableColumn key="jobDate">운송일</TableColumn>
                          <TableColumn key="pickupPlaceName">상차지</TableColumn>
                          <TableColumn key="deliveryPlaceName">하차지</TableColumn>
                          <TableColumn key="weight">실중량(Kg)</TableColumn>
                          <TableColumn key="price">공급가액</TableColumn>
                          <TableColumn key="vat">새액</TableColumn>
                          <TableColumn key="etc">비고</TableColumn>
                        </TableHeader>
                        <TableBody items={data ?? []} emptyContent={'운송내역이 없습니다.'}>
                          {item => (
                            <TableRow key={item.id}>
                              <TableCell>{item.no}</TableCell>
                              <TableCell>{item.vehicleNo}</TableCell>
                              <TableCell>{item.jobDate}</TableCell>
                              <TableCell>{item.pickupPlaceName}</TableCell>
                              <TableCell>{item.deliveryPlaceName}</TableCell>
                              <TableCell>{formatUtil.getNumber(item.weight)}</TableCell>
                              <TableCell>{formatUtil.getNumber(item.price)} 원</TableCell>
                              <TableCell>{formatUtil.getNumber(item.vat)} 원</TableCell>
                              <TableCell>{item.etc ?? '-'}</TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      className="gap-1 mr-auto bg-default-100"
                      startContent={<DynamicIcon iconName={'File'} weight={'fill'} className="text-base text-success-600/80" />}
                      onPress={() => {
                        handleExcelDownload(jobCostQuery?.jobCost?.bizName + '_운송비_내역서');
                      }}
                      variant="flat"
                    >
                      엑셀 다운로드
                    </Button>
                    <Button color="default" onPress={onCloseView}>
                      닫기
                    </Button>
                    <Button
                      color="primary"
                      onPress={() => {
                        onCloseView();
                        onOpenUpdate();
                      }}
                    >
                      수정
                    </Button>
                  </ModalFooter>
                </>
              )}
            </ModalContent>
          </Modal>
        )}

        <ConfirmModal isOpen={confirmOpen} title={message} onConfirm={handleConfirm} onClose={handleClose} />
        <AlertModal />
      </>
    </>
  );
};

export default ModalView;
