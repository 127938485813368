import { Outlet } from 'react-router-dom';
// import Footer from "@/components/Footer";

const OnlyFooterLayout = () => {
  return (
    <>
      <Outlet></Outlet>
    </>
  );
};

export default OnlyFooterLayout;
